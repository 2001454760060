import React,{Component} from "react";
import { NavLink,withRouter } from "react-router-dom";
import ShopsListt from "../components/component.shops.list";
import Global from '../globals/global';
const $ = window.$;

class wishlist extends Global{
  constructor(props) {
    super(props);

    
    var languageid = this.getglobaldata('Id_Language');
    var currencyid = this.getglobaldata('Id_Currency');
    var shopid = null;

    if (this.props.match) {
      if (this.props.match.params) {

        if (this.props.match.params.shopid)
          shopid = this.props.match.params.shopid;
       
        if (this.props.match.params.language)
          languageid =
            this.props.match.params.language.toLowerCase() == "en" ? 1 : 2;
      

        if (this.props.match.params.currency) {
          if (this.props.match.params.currency.toLowerCase() == "mxn")
            currencyid = 2;
          if (this.props.match.params.currency.toLowerCase() == "cad")
            currencyid = 3;
        }
      }
    }

    this.state = {
      shopid:shopid,
      shop:{},
      similarshops:[],
      shopdays:[]
    };
  }

  componentDidMount() {
    //this.starttours();
  }

  componentWillMount() {
    this.getshop();
  }

 getshop() {


    var options = {
      method: "GetShopDetails",
      query: {
        id:this.state.shopid,
        Id_Language: this.state.languageid,
        Id_Currency: this.state.currencyid
        
      },
      callback: function(jsonresponse) {
        
        this.state.shop = jsonresponse.data[0];
        this.state.similarshops = jsonresponse.SimilarShops;
        this.state.shopdays = jsonresponse.shop_days;
       
      }.bind(this)
    };


    this.single(options);
  }


  render() {
    
    var shop = this.state.shop;
    var TabletOriginalImages = [];
    if (shop.Images)
      if (shop.Images.TabletOriginalImages)
        TabletOriginalImages = shop.Images.TabletOriginalImages;
    
    var similartext = this.t('SIMILAR SHOPS');
    var moreurl = '/shopping';

    if(shop.Id_Category == 2){
       similartext = this.t('SIMILAR DINING');
       var moreurl = '/dining';
    } 

    return (

      <div>



       <section className="parallax-window" data-parallax="scroll" style={{backgroundImage:'url('+TabletOriginalImages[0]+')',backgroundRepeat:'no-repeat',backgroundSize:'cover',backgroundPosition:'center center'}}>
        <div className="parallax-content-2">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h1>{shop.Name}</h1>
                <span>{shop.LocationName}</span>
                <div>
                  <span className="range_t_i"> <small> {shop.EconomicType} {this.t("RANGE")}</small></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


         <main>
        <div id="position">
          <div className="container">
            <ul>
              <li><NavLink to="/home">{this.t("Home")}</NavLink>
              </li>
              <li><NavLink to="/dining">{this.t("Dining")}</NavLink>
              </li>
              <li><NavLink to="/shopping">{this.t("Shopping")}</NavLink>
              </li>
              <li>{shop.Name}</li>
            </ul>
          </div>
        </div>
        {/* End Position */}
        <div className="collapse map_t" id="collapseMap">
          
           <iframe src = {"https://maps.google.com/maps?q="+shop.Gps_Latitude+","+shop.Gps_Longitud+"&hl=es;z=8&output=embed"}></iframe>

        </div>
        {/* End Map */}
        <div className="container margin_60">
          <div className="row">
            <div className="col-lg-9">
            </div>
            <div className="col-lg-3">
              <a className="btn_map btn_space" data-toggle="collapse" href="#collapseMap" aria-expanded="false" aria-controls="collapseMap" data-text-swap="Hide map" data-text-original="View on map">{this.t("View on map")}</a>
            </div>
            <div className="col-lg-12">
              {/* Nav tabs */}
              <ul className="nav nav-tabs" role="tablist">
                <li className="nav-item">
                  <a className="nav-link active" data-toggle="tab" href="#menu1">{this.t("Highlights")}</a>
                </li>
              </ul>
              {/* Tab panes */}
              <div className="tab-content">
                <div id="menu1" className="container tab-pane active">
                  <div className="row">
                    <div className="col-lg-6">
                      <img src={TabletOriginalImages[0]} className="img-fluid" />
                    </div>
                    <div className="col-lg-6">
                      <div className="dining_t">
                        

                        {this.state.shopdays.map(function(day,index){
                       
                       return (
                        <div key={index} >
                        <span><i className="icon-clock" /> {day.StartDay} - {day.EndDay}</span>
                        <p>{day.StartTime} - {day.EndTime}</p>
                        </div>
                        )
                      
                      }.bind(this))}
                      <br/>

                      </div>
                      <h6 className="blue_text"><strong>{this.t("Description")}</strong></h6>
                      <p> 
                      {shop.LongDescription}
                      </p>
                      <h6 className="blue_text"><strong>{this.t("Reservations")}</strong></h6>
                      <p> 
                        {shop.Phone_1}, {shop.Phone_2}
                      </p>
                      <h6 className="blue_text"><strong>{this.t("Address")}</strong></h6>
                      <p> 
                        {shop.Address}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8" id="single_tour_desc">
              <p className="d-none d-md-block d-block d-lg-none"><a className="btn_map" data-toggle="collapse" href="#collapseMap" aria-expanded="false" aria-controls="collapseMap" data-text-swap="Hide map" data-text-original={this.t("View on map")}>{this.t("View on map")}</a></p>
              {/* Map button for tablets/mobiles */}
            </div>
            {/*End  single_tour_desc*/}
          </div>
          {/*End row */}
          {/*SIMILAR SHOPS*/}

          <div className="row">

            <div className="col-lg-12">
                <div className="main_title">
                    <h3 className="blue_text"><strong>{similartext}</strong></h3>

                    <NavLink to={moreurl} className="btn_changeloc view_more"><i className="icon-more"></i><strong>{this.t('View More')}</strong></NavLink>
                </div>
            </div>
          </div>

          <ShopsListt shops={this.state.similarshops} />

        </div>
        {/*End container */}
        <div id="overlay" />
        {/* Mask on input focus */}
      </main>
      {/* End main */}

      </div>
      
    );
  }
}

export default wishlist


