import React, { Component } from 'react';
import { NavLink, withRouter } from "react-router-dom";
import moment from "moment";
import spanish from 'moment/locale/es';
import french from 'moment/locale/fr';
import english from 'moment/locale/en-au';
import Global from '../globals/global';

class PurchaseHistoryCard extends Global {

    constructor(props) {
        super(props);

        this.getHour = this.getHour.bind(this);
        this.getDate = this.getDate.bind(this);

        this.state = {
            data: null,
        };
    }

    componentDidMount() {

        
       if(this.getglobaldata('Id_Language') == 2) moment.locale('es',spanish);
       else if(this.getglobaldata('Id_Language') == 3) moment.locale('fr',french);
       else moment.locale('en-au',english);

        this.setState({
            data: this.props.data,
        });
    }

    getHour(value) {
        const date = new Date(value);
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const seconds = date.getSeconds();
        return `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
    }

    getDate(value) {
        const date = new Date(value);
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();
        return `${year}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}`;
    }

    render() {
        return (
            this.state.data !== null ? (
                <div className="strip_booking">
                    <div className="row">
                        <div className="col-md-8 purchase_t">
                            <NavLink to={`/purchase/detail/${this.state.data.Id_UserTransaction}`}>
                                {this.state.data.Description}
                            </NavLink>
                        </div>
                        <div className="col-md-2">

                          
                            <ul className="info_booking">
                                <li><strong>{moment(this.state.data.Date).fromNow()}</strong></li>
                            {/* 
                                <li><strong>{this.t('Hour')}</strong> {this.getHour(this.state.data.Date)}</li>
                                <li><strong>{this.t('Date')}</strong> {this.getDate(this.state.data.Date)} </li>
                            */}
                            </ul>
                            
                        </div>
                        <div className="col-md-2">
                            <div className="booking_buttons">
                                <NavLink to={`/purchase/detail/${this.state.data.Id_UserTransaction}`} className="btn_accdet">{this.t('Details')}</NavLink>
                            </div>
                        </div>
                    </div>
                    {/* End row */}
                </div>
            ) : null
        )
    }
}

export default withRouter(PurchaseHistoryCard)