import React, { Component } from "react";
import Pagination from "../components/component.pagination";
import ToursFilter from "../components/component.tours.filter";
import ToursList from "../components/component.tours.list";
import { NavLink, withRouter } from "react-router-dom";
import Section from "../components/component.section";
import DestinationSelector from "../components/component.destination.selector";
import Global from "../globals/global";
import InfiniteScroll from "react-infinite-scroller";

//if (typeof window === "undefined")  var window={}

const $ = window.$;
const location = window.location;
var KeywordsFilterTime;

class Tours extends Global {
  constructor(props) {
    super(props);

    var page = 1;
    var languageid = this.getglobaldata("Id_Language");
    var currencyid = this.getglobaldata("Id_Currency");
    var destinationid = this.getglobaldata("Id_Destination");
    var locationid = this.getglobaldata("Id_Location");
    var stateid = this.getglobaldata("Id_State");

    if (this.props.match) {
      if (this.props.match.params) {
        if (this.props.match.params.page) {
          page = this.props.match.params.page;
        } else {
          //  window.history.pushState(location.pathname+"/1", 'Tours', location.pathname+"/1");
        }

        if (this.props.match.params.language)
          languageid =
            this.props.match.params.language.toLowerCase() == "en" ? 1 : 2;

        if (this.props.match.params.destinationid) {
          destinationid =
            this.props.match.params.destinationid == 0
              ? ""
              : this.props.match.params.destinationid;

          if (destinationid.search("state_") != -1) {
            //detectamos si la variable lleva la plabra state_

            stateid = Number(destinationid.replace("state_", ""));
            destinationid = "";
            locationid = -1;
          }

          //  console.log('this.props.match.params.locationid',this.props.match.params.locationid)

          if (this.props.match.params.locationid) {
            stateid = "";
            locationid = this.props.match.params.locationid;
          } else locationid = -1;

          if (destinationid.search("area_") != -1) {
            //detectamos si la variable lleva la plabra area_

            locationid = Number(destinationid.replace("area_", ""));
            destinationid = this.getglobaldata("Id_Destination");

            // console.log('this.getglobaldata(destinations)',this.getglobaldata('destinations'))

            /*    if(!destinationid){

                console.log('this.getglobaldata(destinations)',this.getglobaldata('destinations'))
                  if(this.getglobaldata('destinations')){



                    let destinationinfo = this.getglobaldata('destinations').find(destination => {
                      return destination.Id_Destination == 'area_'+locationid
                    })

                    if(destinationinfo) destinationid =destinationinfo.destinationid;

                  }
               }*/

            stateid = "";
          } else if (!this.props.match.params.locationid) locationid = -1;

          // console.log("destinationid", destinationid);
          //  console.log("locationid", locationid);
          //  console.log( "this.getglobaldata(destinationinfo)", this.getglobaldata("destinationinfo")  );

          //  console.log('this.getglobaldata(destinations)',this.getglobaldata('destinations'))

          this.resetdestination(destinationid);

          this.resetstate(stateid);

          this.resetlocation(locationid);
        }

        if (this.props.match.params.currency) {
          if (this.props.match.params.currency.toLowerCase() == "mxn")
            currencyid = 2;
          if (this.props.match.params.currency.toLowerCase() == "cad")
            currencyid = 3;
        }

        //search by category in url
        if (this.props.match.params.category) {
          // console.log("entra en categoria")
          // console.log("category",this.props.match.params.category)

          var tourTypesList_ = [
            "Popular",
            "Water",
            "Land",
            "Adventure",
            "Cultural",
            "Family",
            "Nightlife",
            "Shopping",
            "Nature",
            "Online",
          ];
          tourTypesList_.map((value) => {
            if (value == this.props.match.params.category) {
              //  console.log("hizo match")
              this.settourfilter_global("categories", [
                this.props.match.params.category,
              ]);
            }
          });
        }
        //////////////////////////////////
      }
    }

    this.state = {
      items: Array.from({ length: 20 }),
      responseHasMoreItems: false,

      tours: [],
      alltours: [],
      page: page,
      pages: 1,
      languageid: languageid,
      destinationid: destinationid,
      locationid: locationid,
      stateid: stateid,
      currencyid: currencyid,
      limit: 9,
      totaltours: 0,
      searching: true,
      changeUrl: false,
      searchtours: [],
      searchingtour: false,
      filters: {},
      popupThreeXTwoIsOpen:false
    };
  }

  componentWillMount() {
    ////by category on url
    var startByCategory = false;

    if (this.props.match.params) {
      if (this.props.match.params.category) {
        //console.log("entra en categoria")
        // console.log("category",this.props.match.params.category)

        var tourTypesList_ = [
          "Popular",
          "Water",
          "Land",
          "Adventure",
          "Cultural",
          "Family",
          "Nightlife",
          "Shopping",
          "Nature",
          "Online",
        ];
        tourTypesList_.map((value) => {
          if (value == this.props.match.params.category) {
            //   console.log("hizo match")
            startByCategory = true;
            this.changefilter(true);
          }
        });
      }
    }
    /////////////////////////////////

    ///si la url es por categoria no hace lo de aqui abajo adentro del if, esto lo hacer si es normal las busquedas
    if (startByCategory === false) {
      //si se usa el componente de busqueda y se da click al encontrar un tour
      var s_parameter = "";
      var urlbysearchomponent = new URL(location.href);
      s_parameter = urlbysearchomponent.searchParams.get("byautocomplete");

      if (s_parameter == "true" || s_parameter == true) {
        this.changefilter(true);
      } else {
        this.starttours();
      }
    }

    //////////////////////////////////////////////////////////////////////
    //si se necesita igual que antes comentar todo lo de arriba y descomentar lo de abajo

    //this.starttours();
  }

  componentDidMount() {
    $("header").addClass("tour_bg");

    // this.starttours();
  }

  componentWillUnmount() {
    $("header").removeClass("tour_bg");
  }

  clearableevents() {
    /**
     * Clearable text inputs
     */
    function tog(v) {
      return v ? "addClass" : "removeClass";
    }
    $(document)
      .on("input", ".clearable", function () {
        $(this)[tog(this.value)]("x");
      })
      .on("mousemove", ".x", function (e) {
        $(this)[
          tog(
            this.offsetWidth - 18 <
              e.clientX - this.getBoundingClientRect().left
          )
        ]("onX");
      })
      .on(
        "touchstart click",
        ".onX",
        function (ev) {
          ev.preventDefault();
          $(".clearable").removeClass("x onX").val("").change();
          this.selectword();
          this.changefilter(true);
        }.bind(this)
      );
  }

  /*componentDidUpdate(prevProps, prevState) {
    if (prevProps.match !== this.props.match) {
      var page = 1;
      var languageid = this.getglobaldata('Id_Language');
      var currencyid = this.getglobaldata('Id_Currency');
      var destinationid = this.getglobaldata('Id_Destination');
      var locationid = this.getglobaldata('Id_Location');
      var tourname = null;

      if (this.props.match.params) {

        if (this.props.match.path && this.props.match.path.includes('search')) {
          destinationid = this.props.match.params.destinationid;
          tourname = this.props.match.params.tourname;
        } else {
          if (this.props.match.params.page) page = this.props.match.params.page;
          if (this.props.match.params.language)
            languageid =
              this.props.match.params.language.toLowerCase() == "en" ? 1 : 2;
          if (this.props.match.params.destinationid)
            destinationid = this.props.match.params.destinationid;

          if (this.props.match.params.currency) {
            if (this.props.match.params.currency.toLowerCase() == "mxn")
              currencyid = 2;
            if (this.props.match.params.currency.toLowerCase() == "cad")
              currencyid = 3;
          }
        }
      }

      this.setState({
        tours: [],
        page: page,
        pages: 1,
        languageid: languageid,
        destinationid: destinationid,
        locationid: locationid,
        currencyid: currencyid,
        limit: 9,
        totaltours: 0,
        searching: false,
        tourname: tourname,
        changeUrl: !this.state.changeUrl,
      });
    }

    if (prevState.changeUrl !== this.state.changeUrl) {
      this.starttours();
    }
  }*/

  gettours() {
    this.state.searching = true;
    this.forceUpdate();

    this.state.responseHasMoreItems = false;

    var filters = this.state.filters;

   // console.log("FILTROSSS",filters);

    var params = {
      //Paarametros
      //rangePrice:'0,50,0,1000',

      pagination: this.state.page - 1,
      // limit:this.state.limit,
      Id_Language: this.state.languageid,
      destination: this.state.destinationid,
      Id_State: this.state.stateid,
      Id_Currency: this.state.currencyid,
      location_list: this.state.locationid,
      disabletoursids: this.config().disabletoursids
        ? this.config().disabletoursids
        : "",
      Id_Provider: this.getglobaldata("locationSearch.tourproviderid")
        ? this.getglobaldata("locationSearch.tourproviderid")
        : "",
      ToursStatus: this.getglobaldata("locationSearch.toursstatusid")
        ? this.getglobaldata("locationSearch.toursstatusid")
        : "",
    };

    if(this.config().destinationsIds && (this.state.destinationid == '' || !this.state.destinationid)){
      params.destination = this.config().destinationsIds;
    }

    if(this.config().randomtours){
      params.isRand=true;
    }

    params.showtoursonline = this.config().showtoursonline ? -1 : 0;

    var options = {
      arraydata: "tours", //Este es el nombre del estado  this.state.tours , que sera llenado una vez que se haga la llamada
      method: "GetTours",
      query: params,
      callback: function (tours, jsonresponse) {
        //  console.log('this.getglobaldata(destinations) 22222222222222',this.getglobaldata('destinations'))

        this.state.alltours = this.state.alltours.concat(tours);
        this.setglobaldata("tourslistinfo.tours", this.state.alltours);

        this.state.responseHasMoreItems = tours.length > 0;

        // this.clearableevents();
        // $('.SearchTours').val(filters.TourName);
        if (filters.TourName != "") $(".SearchTours").addClass("x");
        //Calculate total pages
        this.state.pages = Math.ceil(
          jsonresponse.TotalTours / this.state.limit || 1
        ); //Get next integer number   eg: 2.77  to 3, 2.34  to 3,  1.5 to 2, 1.2 to 2, etc...

        this.state.totaltours = jsonresponse.TotalTours;

        this.setglobaldata("tourslistinfo.pages", this.state.pages);
        this.setglobaldata("tourslistinfo.page", this.state.page);
        this.setglobaldata("tourslistinfo.totaltours", this.state.totaltours);
        this.setglobaldata("tourslistinfo.languageid", this.state.languageid);
        this.setglobaldata("tourslistinfo.currencyid", this.state.currencyid);
        this.setglobaldata(
          "tourslistinfo.destinationid",
          this.state.destinationid
        );
        this.setglobaldata("tourslistinfo.locationid", this.state.locationid);
        this.setglobaldata("tourslistinfo.stateid", this.state.stateid);

        if (this.state.totaltours == 0) {
          // this.resetseealldestination()
          /*this.setglobaldata("destinationinfo", null);
          this.resetdestination(null);

          this.resetstate(null);

          this.resetlocation(null);

          window.location.href = "/tours/1"; ///  this.navigate('/tours/1')*/
        }

        this.state.searching = false;
      }.bind(this),
    };



    //dropdownfilter



    if (filters) {
      $.extend(options.query, filters);
    }

    this.all(options);
  }

  changepage(options_) {
    this.state.page = options_.page;

    if (Number(this.state.page) == 1) {
      this.state.alltours = [];
      this.setglobaldata("tourslistinfo.tours", this.state.alltours);
    }

    //this.props.history.push("/tours/"+this.state.page);
    var pathname = String(location.pathname);

    var spathname = pathname.split("/");

    spathname[spathname.length - 1] = this.state.page;

    var jpathname = spathname.join("/");

    var navigateto = jpathname; //pathname.substring(0, pathname.length - 1) + this.state.page; //replace last character location.pathname.slice(0, -numlength)

    if (pathname == "/tours") navigateto = "/tours/" + this.state.page;

    // if (options_.push) {

    //this.navigate(navigateto);
    //window.history.pushState(navigateto, 'Tours',navigateto);
    //this.props.history.push(navigateto);
    this.gettours();
    // }
    // else this.navigate(navigateto);
  }

  starttours() {
    if (this.getglobaldata("tourslistinfo")) {
      let tourslistinfo = this.getglobaldata("tourslistinfo");

      if (tourslistinfo.tours) {
        let currencyid = this.getglobaldata("Id_Currency");
        let languageid = this.getglobaldata("Id_Language");
        let destinationid = this.getglobaldata("Id_Destination");
        let locationid = this.getglobaldata("Id_Location");
        let stateid = this.getglobaldata("Id_State");

        if (!tourslistinfo.languageid) tourslistinfo.languageid = languageid;
        if (!tourslistinfo.currencyid) tourslistinfo.currencyid = currencyid;

        let loaddefaulttours = true;
        if (Number(tourslistinfo.languageid) != Number(languageid)) {
          loaddefaulttours = false;
        }

        if (Number(tourslistinfo.currencyid) != Number(currencyid)) {
          loaddefaulttours = false;
        }

        if (Number(tourslistinfo.destinationid) != Number(destinationid)) {
          loaddefaulttours = false;
        }

        if (Number(tourslistinfo.locationid) != Number(locationid)) {
          loaddefaulttours = false;
        }

        if (Number(tourslistinfo.stateid) != Number(stateid)) {
          loaddefaulttours = false;
        }

        if (tourslistinfo.tours.length > 0 && loaddefaulttours) {
          this.state.alltours = tourslistinfo.tours;
          this.state.pages = tourslistinfo.pages;
          this.state.page = tourslistinfo.page;
          this.state.totaltours = tourslistinfo.totaltours;
          this.state.searching = false;

          this.setfilters();
          return;
        }
      }
    } else this.setglobaldata("tourslistinfo", {});

    this.changefilter();
  }

  setfilters() {
    if (!this.getglobaldata("filter")) this.setglobaldata("filter", {});
    if (!this.getglobaldata("filter.toursfilter"))
      this.setglobaldata("filter.toursfilter", {});
    if (!this.getglobaldata("filter.toursfilter.categories"))
      this.setglobaldata("filter.toursfilter.categories", []);
    if (!this.getglobaldata("filter.toursfilter.istoprated"))
      this.setglobaldata("filter.toursfilter.istoprated", "");
    if (!this.getglobaldata("filter.toursfilter.isspecial"))
      this.setglobaldata("filter.toursfilter.isspecial", "");
    if (!this.getglobaldata("filter.toursfilter.tourname"))
      this.setglobaldata("filter.toursfilter.tourname", "");
    if (!this.getglobaldata("filter.toursfilter.is3x2"))
      this.setglobaldata("filter.toursfilter.is3x2", "");
    if (!this.getglobaldata("filter.toursfilter.rangeprice"))
      this.setglobaldata("filter.toursfilter.rangeprice", "");
    
    if(this.config().providerfilter){
      if (!this.getglobaldata("filter.toursfilter.Id_Provider")){
        this.setglobaldata("filter.toursfilter.Id_Provider", "");
      }
    }


    var filters = {};

    var categories = this.getglobaldata("filter.toursfilter.categories");
    var istoprated = this.getglobaldata("filter.toursfilter.istoprated");
    var isspecial = this.getglobaldata("filter.toursfilter.isspecial");
    var tourname = this.getglobaldata("filter.toursfilter.tourname");
    var is3x2 = this.getglobaldata("filter.toursfilter.is3x2");
    var rangeprice = this.getglobaldata("filter.toursfilter.rangeprice");
    
    if(this.config().providerfilter){
      var providerfilter = this.getglobaldata("filter.toursfilter.Id_Provider");
    }

    filters.CategoriesTypes = categories.toString();
    filters.TopRatedOnly = istoprated;
    filters.OfferAvailable = isspecial;
    filters.TourName = tourname;
    filters.isThreeXTwo = is3x2;
    filters.rangePrice = rangeprice;

    if(this.config().providerfilter){
      filters.Id_Provider = providerfilter;
    }

    filters.highestprice = this.getglobaldata(
      "filter.toursfilter.highestprice"
    );
    filters.lowestprice = this.getglobaldata("filter.toursfilter.lowestprice");
    filters.longerduration = this.getglobaldata(
      "filter.toursfilter.longerduration"
    );
    filters.shorterduration = this.getglobaldata(
      "filter.toursfilter.shorterduration"
    );

    filters.shorterduration = this.getglobaldata(
      "filter.toursfilter.shorterduration"
    );

    this.state.filters = filters;
  }

  changefilter(isfilter,fromComponent) {
    this.setfilters();

    if (isfilter) {
      this.changepage({ page: 1, push: true });
      $.magnificPopup.close();
    } else this.gettours();

    if(this.state.filters.isThreeXTwo == -1 && fromComponent) this.openpopup()
  }

  openpopup(event) {

   // alert();
    if(event) event.preventDefault();

    if(this.state.popupThreeXTwoIsOpen) return;

    $.magnificPopup.open({
      items: {
        src: "#condi_tour",
      },
      type: "inline",
      fixedContentPos: true,
      fixedBgPos: true,
      overflowY: "auto",
      closeBtnInside: true,
      preloader: false,
      midClick: true,
      removalDelay: 300,
      mainClass: "my-mfp-zoom-in",
      callbacks: {
        open: function () {this.state.popupThreeXTwoIsOpen = true}.bind(this),

        close: function () {this.state.popupThreeXTwoIsOpen=false}.bind(this),
      },
    });
  }

  openfilterpopup() {
    $.magnificPopup.open({
      items: {
        src: "#filter_p",
      },
      type: "inline",
      fixedContentPos: true,
      fixedBgPos: true,
      overflowY: "auto",
      closeBtnInside: true,
      preloader: false,
      midClick: true,
      removalDelay: 300,
      mainClass: "my-mfp-zoom-in",
      callbacks: {
        open: function () {},

        close: function () {}.bind(this),
      },
    });
  }

  closepopup(event) {
    event.preventDefault();
    $.magnificPopup.close();

     this.senddispatcher("tours.filter", {value:4});
  }

  KeywordsFilter(event) {
    clearTimeout(KeywordsFilterTime);

    var key = event.key;
    if (key == "Enter") {
      this.selectword();
      this.changefilter(true);
      return;
    }

    KeywordsFilterTime = setTimeout(
      function () {
        this.SearchTours();
      }.bind(this),
      1000
    );
  }

  selectword(Tour_) {
    var TourName = $(".SearchTours").val();
    this.state.searchtours = [];
    this.forceUpdate();
    this.setglobaldata("filter.toursfilter.tourname", TourName);
    this.setglobaldata("filter.toursfilter.categories", []);
    this.setglobaldata("filter.toursfilter.istoprated", "");
    this.setglobaldata("filter.toursfilter.isspecial", "");
    this.setglobaldata("filter.toursfilter.is3x2", "");

    if (Tour_)
      this.navigate("tourdetails/" + Tour_.friendlyurl + "/" + Tour_.Id_Tour+this.queryData()); //window.location.href = 'tourdetails/'+Tour_.UrlName+'/'+Tour_.Id_Tour;
    //this.changefilter(true);
  }

  SearchTours() {
    var text = $(".SearchTours").val();
    this.state.searchtours = [];
    this.state.searchingtour = true;
    this.forceUpdate();

    var options = {
      arraydata: "searchtours", //Este es el nombre del estado  this.state.tours , que sera llenado una vez que se haga la llamada
      method: "SearchTours",
      query: {
        QtyWords: 100,
        Name: text,
        Id_Language: this.state.languageid,
        Id_Destination: this.state.destinationid,
        Id_Currency: this.state.currencyid,
        Id_Location: this.state.locationid,
      },
      callback: function (tours, jsonresponse) {
        this.state.searchtours = jsonresponse.data;
        this.state.searchingtour = false;
      }.bind(this),
    };

    this.all(options);
  }

  sectionchildren(destination) {
    return (
      <div className="parallax-content-1 paral_filter">
        <div className="animated fadeInDown">
          <section id="filter_container">
            <div id="filtert">
              <div className="row">
                <div className="col-md-8 col-12 mx-auto filter_searcht">
                  <h4 className="blue_text">{destination}</h4>
                  <div className="form-group" style={{ position: "relative" }}>
                    <input
                      type="text"
                      onKeyPress={this.KeywordsFilter.bind(this)}
                      className="form-control SearchTours searchtour clearable"
                      placeholder={this.t("Type your search keyword here")}
                    />

                    <ul
                      className="list-group text-left"
                      style={{
                        position: "absolute",
                        top: "40px",
                        left: "0",
                        right: "0",
                      }}
                    >
                      {this.state.searchingtour ? (
                        <li className="list-group-item list-group-item-light">
                          {this.spinner("....")}
                        </li>
                      ) : (
                        ""
                      )}
                      {this.state.searchtours.map(
                        function (Tour, index_) {
                          return (
                            <li
                              key={index_}
                              style={{ cursor: "pointer" }}
                              onClick={this.selectword.bind(this, Tour)}
                              className="list-group-item list-group-item-light"
                            >
                              {Tour.TourName}
                            </li>
                          );
                        }.bind(this)
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }

  render() {
    /*  var paginationoptions = {
      page: this.state.page,
      pages: this.state.pages,
      sidePages: 3,
      beginPages: 2, // optional
      endPages: 2 // optional
    };*/

    var paginationoptions = {
      page: this.state.page,
      pages: this.state.pages,
      loadmore: true,
    };

    var tours = this.state.tours;
    var cdnimages = this.cdnimages() + "tours/";
    var randomtour = tours[Math.floor(Math.random() * tours.length)] || 1;

    var sectionimage = cdnimages + randomtour.Id + "_1_tablet.jpg";

    if (randomtour == 1) sectionimage = false; //return this.preloader();

    var agentmoduleclass = "";
    if (this.config().enableagentsearch)
      agentmoduleclass = "tour_wtitle_admin mt_150";

    var titleclass = "tour_wtitle";

    if (this.config().toursListCheckBox) titleclass = "";

    if (location.pathname.search("/group/") != -1) titleclass = "";



    let discountMessageBrands = [''];
    let discountMessageDestinations = [1,2,5];  //cancun, vallarta, cabos

    let showDiscountMessage = discountMessageBrands.indexOf(this.config().brand) != -1?true:false;

    if(showDiscountMessage){

      showDiscountMessage = discountMessageDestinations.indexOf(Number(this.state.destinationid)) != -1?true:false;

    }

    return (
      <div>
        <main className="custom_main_tours">
          <div className="clearfix" />
          <div className="container m-t-10">
            <div className={`${titleclass} ${agentmoduleclass}`}>
              <div className="row">
                <div className="mb-40">&nbsp;</div>

      
        {this.config().brand == "medicalholidaydirect" ? (
            <div className="col-lg-12">
                            <h3
                          className="discount_msg"
                          dangerouslySetInnerHTML={{
                            __html: this.t(
                              ' <strong>  Special Offer: <i class="icon-tag"></i>Get a 15% Off discount for MHD members <span>*Restrictions apply</span></strong>'
                            ),
                          }}
                        ></h3>
            </div>
                        ) : null}

                {showDiscountMessage ? (
                  <div className="col-lg-12">

                    <h3
                  className="discount_msg"
                  dangerouslySetInnerHTML={{
                    __html: this.t(
                      '<strong>  Special Offer: <i class="icon-tag"></i>25% discount on experiences ony Black Friday and Cyber Monday, November 27 and 30, 2020. <span>*Restrictions apply</span></strong>'
                    ),
                  }}
                ></h3>

                    
                  </div>
            ) : null}


                <div className="col-lg-12">
                  {Number(this.getglobaldata("Id_Destination")) == 600 ? (
                    <div className="card warning_msg_c jamaica_s">
                      <div className="card-body card_tour">
                        <div className="row">
                          <div className="col-2">
                            <img
                              src="https://cdn.mobilesail.com/reactproject/img/warning_c.png"
                              className="img-fluid mx-auto d-block"
                            />
                          </div>
                          <div className="col-10">
                            <p>
                              {this.t(
                                "Dear visitor, due the contingency of COVID-19, a travel authorization is now required to travel. Get everything you need to know about travelling to Jamaica at this time:"
                              )}
                              <a
                                href="https://www.visitjamaica.com/travelauthorization/"
                                className="jamaic_btn"
                                target="_blank"
                              >
                                {this.t("Find out more and get yours here")}
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  <DestinationSelector
                    locationNotification={false}
                    origin="page"
                    text={this.t("Tour & ACTIVITES in")}
                    destinationid={this.state.destinationid}
                  />
                </div>
              </div>
            </div>

            <ToursFilter onChange={this.changefilter.bind(this, true,true)} />

            {/*TOURS LIST*/}

            {/*<div className="row">
              <div className="col-lg-6 col-8 text_btour">
                <button onClick={()=>{this.resetseealldestination()}} className="btn coupon_btn"><i className="icon-location" /> {this.t("View All Destinations")}</button>
              </div>
            </div>*/}

            <div className="row">
              <div className="col-lg-12 col-md-12 col-8 text_btour">
                {this.state.searching ? (
                  <div>{this.spinner()}</div>
                ) : (
                  <p>
                    ({this.state.totaltours} {this.t("Tours found")})
                    <span className="precio_imp_text"> {this.t("All listed prices include taxes")}</span>
                  </p>
                )}
              </div>

              <div className="col-4 btn_filtertour text-right">
                <a
                  href="javascript:void(0)"
                  onClick={this.openfilterpopup.bind(this)}
                  className="btnfilter_to filter_pop"
                >
                  <i className="icon-filter"></i>
                  {this.t("FILTERS")}
                </a>
              </div>
            </div>
            {/* Validación si se encuentra dentro de iframe, para activar el infinity scroll oel paginador mediante boton */}
            {
              // (window.self !== window.top)
              //   ? <>
              //       <ToursList tours={this.state.alltours} onClick3x2={(e)=>this.openpopup(e)} />
              //         <div className="d-flex justify-content-around">
              //           <Pagination
              //             options={paginationoptions}
              //             onChange={this.changepage.bind(this)}
              //           />
              //         </div>
              //     </>
              //     : <InfiniteScroll
              //       threshold={250}
              //       hasMore={
              //         !this.state.searching && this.state.page < this.state.pages + 1
              //       }
              //       loadMore={page => {
              //         // cuando esto este cargando, `hasMore` debe ser igual a falso para
              //         // evitar el limite maximo de la actualizacion del estado
              //         this.changepage({ page });
              //       }}
              //     >
              //       <ToursList tours={this.state.alltours} onClick3x2={(e)=>this.openpopup(e)} />
              //     </InfiniteScroll>
                  
            }


            {/* <InfiniteScroll
              threshold={250}
              hasMore={
                !this.state.searching && this.state.page < this.state.pages + 1
              }
              loadMore={page => {
                // cuando esto este cargando, `hasMore` debe ser igual a falso para
                // evitar el limite maximo de la actualizacion del estado
                this.changepage({ page });
              }}
            >
              <ToursList tours={this.state.alltours} onClick3x2={(e)=>this.openpopup(e)} />
            </InfiniteScroll>
          
           */}
            {/* Se utiliza para ver más servicios con un botón */}
            <ToursList tours={this.state.alltours} onClick3x2={(e)=>this.openpopup(e)} />

            <div className="d-flex justify-content-around">
              <Pagination
                options={paginationoptions}
                onChange={this.changepage.bind(this)}
              />
            </div>

            <div className="d-flex justify-content-center">
              {this.state.searching && <span>{this.spinner()}</span>}
            </div>

            <br />
            <br />
            <br />
          </div>
          {/*End container */}
          <div id="overlay" />
          {/* Mask on input focus */}
        </main>

        <div id="condi_tour" className="zoom-anim-dialog mfp-hide">
          <div className="small-dialog-header">
            <h3
                dangerouslySetInnerHTML={{
                  __html: this.t(
                    "3<sup>rd</sup> Tour FREE!"
                  ),
                }}
              ></h3>

          </div>
          <div className="tour_container">
            <div className="tour_title">
              <h4
                className="text-center blue_text"
                dangerouslySetInnerHTML={{
                  __html: this.t(
                    "<strong>3<sup className='t_low'>rd</sup> Tour FREE!</strong>"
                  ),
                }}
              ></h4>
              <h3
                className="text-center blue_text"
                dangerouslySetInnerHTML={{
                  __html: this.t(
                    "<strong>Here's How 3<sup className='t_low'>rd</sup> Tour Free Promo Works</strong>"
                  ),
                }}
              ></h3>
            </div>
            <div className="tour_title">
              <div className="col-md-12 tour_condit">
                <h6>
                  <span className="blue_b">1</span>
                  <strong> </strong>
                </h6>
                <strong></strong>
                <p
                  className="text-center"
                  dangerouslySetInnerHTML={{
                    __html: this.t(
                      '<strong>Book 3 Different Tours - ALL MUST BE FROM THOSE LISTED AND SHOWN AS "Valid 3<sup className="t_low">rd</sup> Tour Free" on the promotion page.</strong>'
                    ),
                  }}
                ></p>
                <h6>
                  <span className="blue_b">2</span>
                  <strong />
                </h6>
                <strong></strong>
                <p className="text-center">
                  <strong>{this.t("Book ALL 3 Tours at the SAME time using the shopping cart")}</strong>
                </p>
                <h6>
                  <span className="blue_b">3</span>
                  <strong />
                </h6>
                <strong></strong>
                <p className="text-center">
                  <strong>
                    {this.t(
                      "BOOK ALL 3 TOURS in ONE Reservation through the shopping cart."
                    )}
                  </strong>
                </p>
                <div className="divider" />
                <p>
                  <strong className="blue_text">{this.t("NOTE:")}</strong>{" "}
                  {this.t(
                    "ALL participants on the reservation must be doing the exact same 3 tours...otherwise, make separate reservations when the selections vary by participant."
                  )}
                </p>
                <p>
                  {this.t(
                    "The Free Tour is the one of equal or lesser value to the others and your savings is automatically calculated and shown at checkout just before you finalize your purchase online. Or, you may contact us to make your reservation on phone, we will review the pricing and details with you when we make your booking."
                  )}
                </p>
                <div className="divider" />
                <h4 className="text-center blue_text">
                  <strong>{this.t("IMPORTANT RULES/RESTRICTIONS")}</strong>
                </h4>
                <p
                  dangerouslySetInnerHTML={{
                    __html: this.t(
                      'Only the valid displayed <span className="blue_text"><strong>"3<sup className="t_low">rd</sup> Tour Free" </strong></span> activities shown online are valid in this promotion. The promotion rules are for a "set-offer" as described, and once booked.'
                    ),
                  }}
                ></p>
                <h4 className="text-center blue_text">
                  <strong>
                    {this.t("NO RETURNS, CHANGES OR CANCELLATIONS ARE ALLOWED")}
                  </strong>
                </h4>
                <a
                  href="#"
                  onClick={this.closepopup.bind(this)}
                  className="btn add_cartbtn"
                  dangerouslySetInnerHTML={{
                    __html: this.t(
                      "SEE ALL 3<sub className='t_low'>rd</sub> Tour FREE Activities"
                    ),
                  }}
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Tours;
