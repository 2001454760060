import React, { Component } from 'react';
import { NavLink, withRouter } from "react-router-dom";
import Global from '../globals/global';
class CartHeader extends Global {

    constructor(props) {
        super(props);

        this.totalitemscart = this.totalitemscart.bind(this);
    }

    componentDidMount(){
      this.getdispatcher('cart.header',function(data){
        this.forceUpdate();
      }.bind(this))
    }

    totalitemscart(){
        return (this.getglobaldata('Cart') || []).length;
    }

    render() {
        return (
            <div className="dropdown dropdown-cart">
                <NavLink to="/cart" data-toggle_="dropdown" className="cart_bt"><i className="icon-cart"></i><strong>{this.totalitemscart()}</strong></NavLink>
             
              {/*  <ul className="dropdown-menu" id="cart_items">
                    <li>
                        <div className="image"><img src="img/thumb_cart_1.jpg" alt="image" /></div>
                        <strong><a href="#">Louvre museum</a>1x $36.00 </strong>
                        <a href="#" className="action"><i className="icon-trash"></i></a>
                    </li>
                    <li>
                        <div className="image"><img src="img/thumb_cart_2.jpg" alt="image" /></div>
                        <strong><a href="#">Versailles tour</a>2x $36.00 </strong>
                        <a href="#" className="action"><i className="icon-trash"></i></a>
                    </li>
                    <li>
                        <div className="image"><img src="img/thumb_cart_3.jpg" alt="image" /></div>
                        <strong><a href="#">Versailles tour</a>1x $36.00 </strong>
                        <a href="#" className="action"><i className="icon-trash"></i></a>
                    </li>
                    <li>
                        <div>Total: <span>$120.00</span></div>
                        <a href="cart.html" className="button_drop">Go to cart</a>
                        <a href="payment.html" className="button_drop outline">Check out</a>
                    </li>
                </ul>
                */}
            </div>
        )
    }
}

export default withRouter(CartHeader)