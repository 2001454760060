import React, { Component } from 'react';
import { NavLink, withRouter } from "react-router-dom";
import Global from '../globals/global';
class AirlinesSelector extends Global {

    constructor(props) {
        super(props);

        this.state = {
            type: this.props.type,
            airlines: null,
            flightNumber: this.props.flightNumber,
            airline: this.props.airline,
            option: this.props.option,
            airlinename:this.props.airlinename,
        };
    }

    componentDidMount() {
        var options = {
            arraydata: 'airlines',
            method: 'GetAirlines',
            callback: function (data) {
                
                this.setState({
                   // type: this.props.type,
                    //option: this.props.option,
                    airlines: data,
                    //flightNumber: '',
                    //airline: -1,
                    airline: this.state.airline?this.state.airline:data[0].Id,
                    airlinename: this.state.airline?this.state.airlinename:data[0].Name,
                });
            }.bind(this)
        };
        this.all(options);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.airlines !== this.state.airlines) {
           /* this.setState({
                airline: this.state.airlines[0].Id,
                airlinename: this.state.airlines[0].Name,
            });*/
        }
        if (prevState.airline !== this.state.airline ||
            prevState.flightNumber !== this.state.flightNumber) {
            this.props.handle(this.state.airline, this.state.flightNumber,this.state.airlinename);
        }
    }

    changeairline(event){
      
      var airlinename = this.state.airlines[event.target.selectedIndex-1].Name;
     
      this.setState({ airline: event.target.value,airlinename:airlinename})

    }

    render() {
       
        return (
            this.state.airlines !== null ? (
                <div className="form-group row">
                    <div className="col-md-6">
                        <label>
                            {
                                this.state.type === 'round' ? (this.state.option === 'start' ? this.t('Arrival Airline') : this.state.option === 'end' ? this.t('Departure Airline') : this.t('Date')) :
                                    this.state.type === 'arrival' ? this.t('Arrival Airline') :
                                        this.state.type === 'departure' ? this.t('Departure Airline') : ''
                            }
                        </label>
                        {
                            this.state.airlines.length > 0 ? (
                                <select className="form-control" value={this.state.airline}
                                    onChange={this.changeairline.bind(this)}>
                                    <option value="-1" disabled>{this.t('Select Airline')}</option>
                                    {
                                        this.state.airlines.map((airline, index) => {
                                            return <option key={`airline-${index}`} value={airline.Id}>{airline.Name}</option>
                                        })
                                    }
                                </select>
                            ) : <div>{this.t('No results found')}</div>
                        }
                    </div>

                    <div className="col-md-6">
                        <label>
                            {
                                this.state.type === 'round' ? (this.state.option === 'start' ? this.t('Arrival Flight Number') : this.state.option === 'end' ? this.t('Departure Flight Number') : this.t('Date')) :
                                    this.state.type === 'arrival' ? this.t('Arrival Flight Number') :
                                        this.state.type === 'departure' ? this.t('Departure Flight Number') : ''
                            }
                        </label>
                        <input className="form-control" value={this.state.flightNumber} type="text" onChange={event => { this.setState({ flightNumber: event.target.value }) }} />
                    </div>
                </div>

            ) : <div>{this.t('Loading...')}</div>
        )
    }
}

export default withRouter(AirlinesSelector)