import React, { Component } from 'react';
import { NavLink, withRouter } from "react-router-dom";
import Global from '../globals/global';

class PurchaseHistoryDetailTransferCard extends Global {
    render() {
        const { title, date, time, airline, flight, airport, instructions } = this.props.data;
        return (
            <div className="table-responsive">
                <h6 className="title_accdet">{title}</h6>
                <table className="table table-striped">
                    <thead>
                        <tr className="success_t">
                            <th>{this.t('Date')}</th>
                            <th>{this.t('Time')}</th>
                            <th>{this.t('Airline')}</th>
                            <th>{this.t('Flight#')}</th>
                            <th>{this.t('Airport')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{date}</td>
                            <td>{time}</td>
                            <td>{airline}</td>
                            <td>{flight}</td>
                            <td>{airport}</td>
                        </tr>
                        <tr className="info_acc">
                            <td><b>{this.t('INSTRUCTIONS:')}</b></td>
                            <td is="true" colspan="4">
                                <span><div dangerouslySetInnerHTML={{ __html: instructions }} /></span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }
}

export default withRouter(PurchaseHistoryDetailTransferCard)