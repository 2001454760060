import React, { Component } from 'react';
import { NavLink, withRouter } from "react-router-dom";
import Global from '../globals/global';
class TourReviews extends Global{
    render() {
        return (
            this.props.data !== null && this.props.data.length > 0 ? (
                this.props.data.map((review, index) => {                    
                   return (
                        <div key={`TourReviews-${index}`} className="review_strip_single">
                            <h4>{`${review.UserName}, ${review.State}, ${review.Country}`}</h4>
                            <p>{review.Description}</p>
                        </div>
                    )
                })
            ) : <div>{this.t('Without reviews')}</div>
        )
    }
}

export default withRouter(TourReviews)