import React, { Component } from "react";
import Global from "../globals/global";
import moment from "moment";
import { NavLink, withRouter } from "react-router-dom";
import ReviewSend from "./component.review.send";

class PurchaseHistoryDetailTour extends Global {
  resendConfirmationEmail() {
    if (
      !window.confirm(
        "Esta seguro que quiere reenviar el correo de confirmacion de pago"
      )
    ) {
      return;
    }

    let options = {
      method: "SendTourBookingEmailConfirm",
      query: {
        Find_Id_TourBooking: this.props.data.Id_Trans,
        SendEmail: -1,
        onlyPayment: -1,
        idsClient:-1,
        idsAdmin:0,
        isFromFront:-1
      },
      callback: function (data, result) {
        alert("El correo fue enviado correctamente");
      }.bind(this),
    };
    this.all(options);
  }

  render() {

   

    try {
      this.props.data.ProviderConfirmCode = this.t(
        this.props.data.ProviderConfirmCode
      );
      console.log(this.props.data.ProviderConfirmCode);
    } catch (error) {
      console.log(error);
    }

    let {
      Description,
      Date,
      TourDate,
      DepartureTime,
      NAdults,
      NChilds,
      NChildFree,
      ProviderConfirmCode,
      ConfirmCode,
      Status,
      Instructions,
      MeetingPoint,
      Code,
      Amount,
      Id_ProviderConfirmCodeStatus,
    } = this.props.data;



    var meetingpointdiv = (
      <div>
        <h6 class="title_accdet ">{this.t("MEETING POINT")}</h6>
        <p
          class="info_text_acc "
          dangerouslySetInnerHTML={{ __html: MeetingPoint }}
        ></p>
      </div>
    );

    var insttitle = <h6 class="title_accdet ">{this.t("INSTRUCTIONS")}</h6>;

    if (process.env.REACT_APP_BOOKING_WIDGET == "bookingwidget2") {
      if (Number(Id_ProviderConfirmCodeStatus) == 2) {
        Instructions =
          "<p>En breve, recibirás un correo con todos los detalles importantes sobre el 'Punto y hora de reunión'. En caso de no recibirlo favor de comunicarse al centro de atención 800-444-0656.</p><p>Para poder realizar la experiencia, es importante presentar una identificación así como el mail de confirmación en su versión digital o impresa.</p><p>Recuerda llegar de forma puntual para que no te pierdas de una gran experiencia.</p>";

        insttitle = <h6 class="title_accdet ">Aviso Importante</h6>;
        meetingpointdiv = null;

        if (this.config().brand == "viajeypunto") {
          Instructions = `<p>Esta es tu confirmación de solicitud de reservación, el pago se encuentra en proceso de validación. En caso de que hayas solicitado pagar con BBVA Wallet, uno de nuestros representantes se comunicará contigo para validar tu codigo virtual de seguridad.</p> 
                              <p>Una vez que se haya procesado tu pago recibirás un correo con todos los detalles importantes de tu reservación como el 'Punto y hora de reunión'.</p> 
                              <p>Si tu pago se ha procesado correctamente y no recibiste el segundo correo por favor comunícate al centro de atención 553 690 8888.</p>  
                              <p>Recuerda que para poder realizar la experiencia, es importante presentar una identificación así como el email de confirmación en su versión digital o impresa.</p> 
                              <p>Te recomendamos llegar de forma puntual para que no te pierdas de una gran experiencia.</p>`;

          meetingpointdiv = (
            <div className="text-right">
              <button
                onClick={() => {
                  this.resendConfirmationEmail();
                }}
                className="btn coupon_btn"
              >
                <i className="icon-mail" /> Reenviar Email de Reservación
              </button>
            </div>
          );
        }
      }
    }

    return (
      <div>
        <div className="row">
          <div className="col-lg-12 purchasetitle">
            <h3>{this.t("Purchase History")}</h3>
            <NavLink to="/myaccount">
              <i className="icon-back"></i>
              {this.t("Back")}
            </NavLink>
          </div>
        </div>

        <h6 class="title_accdet mt-15">{Description}</h6>
        <table class="table_account">
          <thead>
            <tr class="success_t">
              <th scope="col">{this.t("Transaction Date")}</th>
              <th scope="col">{this.t("Tour Date")}</th>
              <th scope="col">{this.t("Departure Time")}</th>
              <th scope="col">{this.t("Reservation Recap")}</th>
              <th scope="col">{this.t("Provider Cfrm#")}</th>
              <th scope="col">{this.t("Res Cfrm#")}</th>
              <th scope="col">{this.t("Status")}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td data-label={this.t("Transaction Date")}>{Date}</td>
              <td data-label={this.t("Tour Date")}>{TourDate}</td>
              <td data-label={this.t("Departure Time")}>
                {moment(DepartureTime, "hh:mm A").format("hh:mm A")}
              </td>
              <td data-label={this.t("Reservation Recap")}>
                {`${NAdults} ${this.t("Adults")}`}
                {NChilds > 0 ? `, ${NChilds} ${this.t("Children")}` : ""}
                {NChildFree > 0
                  ? `, ${NChildFree} ${this.t("Children Free")}`
                  : ""}
              </td>
              <td data-label={this.t("Provider Cfrm#")}>
                {ProviderConfirmCode}
              </td>
              <td data-label={this.t("Res Cfrm#")}>{ConfirmCode}</td>
              <td data-label={this.t("Status")}>{Status}</td>
            </tr>
          </tbody>
        </table>

        <div class="row ">
          <div class="col-md-12 purchase_t ">
            {insttitle}
            <p
              class="info_text_acc "
              dangerouslySetInnerHTML={{ __html: Instructions }}
            ></p>

            {meetingpointdiv}

            

            <h5
                className=""
              >
               {this.t("Amount Paid:")}  {this.currencyformat(Amount,`${Code} $`)}
              </h5>
              

          </div>
        </div>

        {this.props.fromPyamentConfirm?null:<ReviewSend data={this.props.data} />}
        <br/>
      </div>
    );
  }
}

export default withRouter(PurchaseHistoryDetailTour);
