import React, { Component } from 'react';
var config = require('../configs/config'); //important require for write config. Not import
//if (typeof window === "undefined")  var window={}
const $ = window.$;


if(window.brandconfig) config = $.extend(config,window.brandconfig);




export default class crud extends Component{

    constructor(props) {
    super(props);

   /* console.log('config.brand ',config.brand);
        console.log('indow.location.host.search(',window.location.host.search('admin-totoura'));

     if(config.brand == 'viajeypunto' && window.location.host.search('admin-totoura') != -1){

        console.log('config.brand 2',config.brand);
        console.log('indow.location.host.search( 2',window.location.host.search('admin-totoura'));
     
        if(this.getglobaldata('config')) {
            console.log('Entra get data',this.getglobaldata('config'));
            config = this.getglobaldata('config');
        }
        else {

            this.setglobaldata('config',config);
            console.log('Entra set data',this.getglobaldata('config'));
        }
     
    }*/

    }

    setglobaldata(varname_, varvalue_) {
  var globaldata = this.getglobaldata();

  varvalue_ = JSON.stringify(varvalue_);

  eval("globaldata." + varname_ + " = " + varvalue_);

  if(varvalue_ === null) delete globaldata[varname_];

  this.saveglobaldata(globaldata);
}

saveglobaldata(globaldata_) {

  let brand = this.config().brand;

  localStorage.setItem("globaldata_"+brand, JSON.stringify(globaldata_));
}

getglobaldata(varname_) {

  let brand = this.config().brand;

  if (!localStorage.getItem("globaldata_"+brand))
    localStorage.setItem("globaldata_"+brand, JSON.stringify({}));

  var globaldata = JSON.parse(localStorage.getItem("globaldata_"+brand));
  var customvar = varname_ ? eval("globaldata." + varname_) : globaldata;

  return customvar;
}

config(){

  return config;
}

cdnimages(){
   return config.imagescdn;
}
//#############
//all documents
all(options_) {
    var controller = options_.controller;
    var method = options_.method;
    var data = {};
    if (options_.query) data = options_.query;
    var path = method;
    if(options_.controller) path = controller + '/' + method;
  

    $.ajax({
        url: config.apiurl + path,
        xhrFields: {withCredentials: true},
        dataType: "json",
        data: data,
        success: function(JSONResponse) {
            if (JSONResponse.result) {
                if(options_.controller){
                if (this.state[controller]) {
                    if(JSONResponse.data.docs) this.state[controller] = JSONResponse.data.docs;
                    else if(JSONResponse.data) this.state[controller] = JSONResponse.data;

                }
               }

                if(options_.arraydata){
                if (this.state[options_.arraydata]) {
                    if(JSONResponse.data.docs) this.state[options_.arraydata] = JSONResponse.data.docs;
                    else if(JSONResponse.data) this.state[options_.arraydata] = JSONResponse.data;

                }
            }


                    if(options_.callback) options_.callback(JSONResponse.data,JSONResponse);

                this.forceUpdate();
            }else {
                if(options_.error) options_.error(JSONResponse);
                else alert(JSONResponse.errorMessage);
            }
        }.bind(this),
        error: function(error, data) {
           // alert(error);
        }
    });
}
//#############
//single document
single(options_) {
     var controller = options_.controller;
    var method = options_.method;
    var data = {};
    if (options_.query) data = options_.query;
    var path = method;
    if(options_.controller) path = controller + '/' + method;
   

   var url = config.apiurl + path;

   if(options_.url) url = options_.url;
    $.ajax({
        url: url,
        xhrFields: {withCredentials: true},
        dataType: "json",
        data: data,
        success: function(JSONResponse) {
            if (JSONResponse.result) {

                if (this.state[options_.object]) {
                    if(JSONResponse.data.docs) this.state[options_.object] = JSONResponse.data.docs;
                    else if(JSONResponse.data) this.state[options_.object] = JSONResponse.data;
                    else if(JSONResponse) this.state[options_.object] = JSONResponse;

                }

                if (this.state[options_.arraydata]) {


                    if(JSONResponse.data.docs) this.state[options_.arraydata] = JSONResponse.data.docs;
                    else if(JSONResponse.data) this.state[options_.arraydata] = JSONResponse.data;



                }
                

                if(options_.callback) options_.callback(JSONResponse);

                this.forceUpdate();

            }else {
                 if(options_.error) options_.error(JSONResponse);
                 else if(JSONResponse.errorMessage) alert(JSONResponse.errorMessage);
            }

        }.bind(this),
        error: function(error, data) {
           // alert(error);
        }
    });
}



//#############
//edit document
edit(options_) {
    var controller = options_.controller;
    var method = options_.method;
    var data ={};
    if (options_.body) data = options_.body;
    var path = method;
    if(options_.controller) path = controller + '/' + method;
  
    $.ajax({
        url: config.apiurl + path,
        xhrFields: {withCredentials: true},
        dataType: "json",
        method: 'POST',
        data: data,
        success: function(JSONResponse) {
            if (JSONResponse.result) {
                alert('guardado');
            }
        }.bind(this),
        error: function(error, data) {
          //  alert(error);
        }
    });
}
//#############
//create documents
create(options_) {
    var controller = options_.controller;
    var method = options_.method;
    var data = {};
    if (options_.body) data = options_.body;

    var path = method;
    if(options_.controller) path = controller + '/' + method;


    console.log('options_.type...............',options_.type)
   
    $.ajax({
        url: config.apiurl + path,
        xhrFields: {withCredentials: true},
        dataType: "json",//"jsonp",
        type: options_.type?options_.type:'GET',
        data: data,
        success: function(JSONResponse) {
            if (JSONResponse.result) {
                if(options_.callback) options_.callback(JSONResponse);
            }else{
                if(options_.error) options_.error(JSONResponse);
                else alert(JSONResponse.errorMessage);
            }
        }.bind(this),
        error: function(error, data) {
          //  alert(error);
        }
    });
}
//#############
//delete documents
delete(options_) {
    var controller = options_.controller;
    var method = options_.method;
    var data = {};
    if (options_.body) data = options_.body;
    var path = method;
    if(options_.controller) path = controller + '/' + method;
  
    $.ajax({
        url: config.apiurl + path,
        //xhrFields: {withCredentials: true}, 
        dataType: "jsonp", 
        method: 'POST',
        data: data,
        success: function(JSONResponse) {
            if (JSONResponse.result) {

               if(options_.callback) options_.callback(JSONResponse);

            }else{
                if(options_.error) options_.error(JSONResponse);
                else alert(JSONResponse.errorMessage);
            }
        }.bind(this),  
        error: function(error, data) {
          //  alert(error);
        }
    });
}
}