import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import Global from '../globals/global';

class tourslist extends Global {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    var shops = this.props.shops;
    var cdnimages = this.cdnimages() + "shops/";

    return (
      <div className="row">
        <div className="col-lg-12">
          <div className="main_title"></div>
        </div>

       
        {shops.map(function(shop,index){
          

             var typenames = [];

             if(shop.TypeName) typenames = shop.TypeName.split(',');

             return(
              <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.1s">
                <div className="tour_container">
                  <div className="img_container">
                   <a href={"shopdetails/"+shop.Id}>
                      <img src={cdnimages+"thm_" +shop.Id +"_1_tablet.jpg?crc=" +shop.CRC32} width={800} height={533} className="img-fluid" alt="image" />
                      <i className="icon-info-circled info_special" />
                    </a> 
                    
                  </div>
                  <div className="tour_title">
                    <h3><a href={"shopdetails/"+shop.Id}><strong>{shop.Name}</strong></a></h3>
                    <p>{shop.LocationName}</p>
                    <div className="row">
                      <div className="col-8 dining_t">

                      {shop.shop_days.map(function(day,index){
                       
                       return (
                        <div key={index} >
                        <span><i className="icon-clock" /> {day.StartDay} - {day.EndDay}</span>
                        <p>{day.StartTime} - {day.EndTime}</p>
                        </div>
                        )
                      
                      }.bind(this))}

                      </div>

                      <div className="col-4">
                        <span className="range_t"><small>{shop.EconomicType} {this.t('RANGE')}</small></span>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-12 dining_tags">

                        {typenames.map(function(type,index){

                        return <span style={{marginRight:'2px'}}><a href={"shopdetails/"+shop.Id} >{type}</a> </span>

                         }.bind(this))}

                      </div>
                    </div>
                  </div>{/*end tour title*/}
                </div>
              </div>
              )
             
             }.bind(this))}

      </div>
    );
  }
}

export default withRouter(tourslist)