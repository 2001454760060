import React, {Component} from "react";
import Global from '../globals/global';
import GlobalHeader from './partial.header.global';
import WidgetHeader from './partial.header.widget';
import ViajeYPuntoHeader from './partial.header.viajeypunto';
import DelMarBoutique from './partial.header.delmarboutiqueaccommodation';
//if (typeof window === "undefined")  var window={}
const $ = window.$;

class header extends Global{

    constructor(props) {
        super(props);

       

        this.state = {};

    }



    render() {

    	if(this.config().brand == 'viajeypunto') return (<ViajeYPuntoHeader />)

        if(this.config().brand == 'delmarboutiqueaccommodation') return (<DelMarBoutique />)

        if(this.config().enableagentsearch || this.config().topmenu) return (<WidgetHeader />)
        else return (<GlobalHeader />)

      }
}

export default header;