import React from "react";
import { Redirect } from "react-router-dom";
import ReactGA from "react-ga"; //google analytics
import FacebookPixel from "react-facebook-pixel";
import I18n from "./global.i18n";
import { createBrowserHistory } from "history";
import queryString from "query-string";
import CurrencyFormat from "react-currency-format";
import moment from "moment";
////if (typeof window === "undefined")  var window={}

const $ = window.$;
const location = window.location;

var timeout;

export default class utils extends I18n {
  currencyformat(amount, prefix) {
    if (!prefix) prefix = "";
    return (
      <CurrencyFormat
        value={amount}
        displayType={"text"}
        thousandSeparator={true}
        prefix={prefix}
      />
    );
  }

  version() {
    return process.env.REACT_APP_VERSION;
  }

  whatsappChat() {
    var urlReferer = `${location.host}${location.pathname}`;

   // var phoneAvis = "529983889884";
    //var phonePriceTravel = "523221808458";

    //this.config().whatsappNumber = phoneAvis;

    var phone = this.config().whatsappNumber? this.config().whatsappNumber:'';

    const travelAgentPhones = [
      {id:2232,phone:'121212121212'},
    ];

    const travelagentid = this.config().travelagentid;

    const agentInfo = travelAgentPhones.find((agent)=>{
        return travelagentid == agent.id;
    });

    if(agentInfo){
      phone = agentInfo.phone;
    }

    if ( 
      phone != "" &&
      urlReferer.search("checkout") == -1 
    )
      return (
        <a
          href={`https://api.whatsapp.com/send?phone=${phone}&text=Desde ${urlReferer}`}
          className="whats_icon"
          target="_blank"
        >
          <img src={this.image("img/whats_i.png")} />
        </a>
      );
    else return null;
  }

  initializationdidmount() {
    //first page to send to Google Analytics
    this.ga().pageview(window.location.pathname + window.location.search);
    this.fbp().pageView();
    //Iniciar Google Tag Manager
    this.gtminitialize();

    //escuhamos cuando cambie la pagina y enviamos a Google Analytics
    this.props.history.listen(
      function () {
        //Iniciar Google Tag Manager
        // this.gtminitialize();

        this.ga().pageview(
          window.location.pathname + this.getglobaldata("query")
        );

        console.log("page view pixel");
        this.fbp().pageView();

        
        // si la pagina esta dentro de un iframe ejectamos

        if(window.self !== window.top){

         // alert('ifm')
       /*  window.history.pushState(
        
        location.pathname + '?iframe=true',
         "Title",
         location.pathname + '?iframe=true'
        );*/
     }

        //  if(location.pathname.search('tourdetails/') != -1) window.location.replace(location.pathname+this.getglobaldata('query')); //window.history.pushState(location.pathname+this.getglobaldata('query'), 'Title', location.pathname+this.getglobaldata('query'));
      }.bind(this)
    );
  }

  initialization() {
    this.clearLocalStorage();

    const Id_Destination = this.getglobaldata("Id_Destination");
    const Id_Location = this.getglobaldata("Id_Location");

    if (!this.getglobaldata("Id_Currency"))
      this.setglobaldata("Id_Currency", this.config().defaultcurrencyid);
    /*  else if(this.getglobaldata('defaultcurrencyid') != this.config().defaultcurrencyid) {
      this.setglobaldata('Id_Currency',this.config().defaultcurrencyid); 
       this.setglobaldata('defaultcurrencyid',this.config().defaultcurrencyid);
    }*/

    if (!this.getglobaldata("Id_Language"))
      this.setglobaldata("Id_Language", this.config().defaultlanguageid);
    /* else if(this.getglobaldata('defaultlanguageid') != this.config().defaultlanguageid) {
      this.setglobaldata('Id_Language',this.config().defaultlanguageid);
      this.setglobaldata('defaultlanguageid',this.config().defaultlanguageid);
    }*/

    this.setquery();

    /*  if (!Id_Destination) {
      this.setglobaldata('Id_Destination', 1);
    }
    if (!Id_Location) {
      this.setglobaldata('Id_Location', 1);
    }*/

    //this.loadfiles();

    //iniciar Google Analitycs
    this.ga().initialize(this.config().gaid);

    //inicia facebook pixel

    this.fbp().init(this.config().fbpid);

    this.ajaxsetup();
    this.loftloader();
    this.gettranlation();

    this.setdefaultdestiation();
    this.setgroupinfo();
    this.setlandinginfo();
    this.setinitialconfig();
  }

  fbp() {
    var options = {
      init: () => {},
      track: () => {},
      trackCustom: () => {},
      pageView: () => {},
    };

    if (this.config().fbpid) return FacebookPixel;
    else return options;
  }

  searchEvents(text) {
    var destinationinfo = this.getglobaldata("destinationinfo");
    //facebook pixel event
    this.fbp().trackCustom("Search", {
      content_type: "product",
      search_string: text,
      content_id: "1",
      //product_group:['3568354979886981'],
      city: destinationinfo !== undefined ? destinationinfo.Name : "",
      country: destinationinfo !== undefined ? destinationinfo.country : "",
      region: "",
      checkin_date: "",
      checkout_date: "",
      //product_catalog_id:'241939520697998'
    });
  }

  setinitialconfig() {
    if (this.getglobaldata("locationSearch.travelagentid")) {
      this.config().travelagentid = this.getglobaldata(
        "locationSearch.travelagentid"
      );
    }
  }

  select(event) {
    $(event.target).select();
  }

  setdefaultdestiation() {




    if (!this.getglobaldata("Id_Destination")) {
      if (this.config().defaultdestinationid) {
        this.setglobaldata(
          "Id_Destination",
          this.config().defaultdestinationid
        );
        this.setglobaldata("Id_Location", -1);
      }
    }
    if (this.config().defaultlocationid)
      this.setglobaldata("Id_Location", this.config().defaultlocationid);

    this.setglobaldata("changedestination", this.config().changedestination);

    var query = queryString.parse(location.search);

    if (query.destinationid) {
       this.setglobaldata(
          "Id_Destination",
          query.destinationid
        );
        this.setglobaldata("Id_Location", -1);
    }

  }

  setagencyinfo() {
    if (this.config().agencyinfo) {
      if (this.getglobaldata("groupinfo")) {
        if (
          this.config().agencyinfo.UrlName !=
          this.getglobaldata("groupinfo").AgencyUrl
        )
          this.setglobaldata("groupinfo", null);
      }

      if (this.getglobaldata("landinginfo")) {
        if (
          this.config().agencyinfo.UrlName !=
          this.getglobaldata("landinginfo").AgencyUrl
        )
          this.setglobaldata("landinginfo", null);
      }
    }
  }

  setgroupinfo() {
    this.setagencyinfo();

    let isgroupinfourl = location.pathname.search("/group/") != -1;

    if (isgroupinfourl) {
      this.config().landinginfo = null;
      this.setglobaldata("landinginfo", null);
    }

    if (this.config().groupinfo) {
      this.setglobaldata("groupinfo", this.config().groupinfo);
    } else if (this.getglobaldata("groupinfo")) {
      this.config().groupinfo = this.getglobaldata("groupinfo");
    }

    //check once if group info is defined
    if (this.config().groupinfo) {
      // this.config().landinginfo = null;

      if (isgroupinfourl) this.setglobaldata("changedestination", false);

      this.setglobaldata("tourslistinfo", null);
      this.config().transfers =
        this.config().groupinfo.EnableTransfers == -1 ? true : false;
      // this.config().brandname = this.config().groupinfo.Name
    }
  }

  setlandinginfo() {
    this.setagencyinfo();

    let islandinginfourl = location.pathname.search("/landing/") != -1;

    if (islandinginfourl) {
      this.config().groupinfo = null;
      this.setglobaldata("groupinfo", null);
    }

    if (this.config().landinginfo) {
      this.setglobaldata("landinginfo", this.config().landinginfo);
    } else if (this.getglobaldata("landinginfo")) {
      this.config().landinginfo = this.getglobaldata("landinginfo");
    }

    //check once if landinginfo is defined
    if (this.config().landinginfo) {
      // this.config().groupinfo = null;
      if (islandinginfourl) this.setglobaldata("changedestination", false);
      this.setglobaldata("tourslistinfo", null);
      this.config().transfers =
        this.config().landinginfo.EnableTransfers == -1 ? true : false;
      // this.config().brandname = this.config().groupinfo.Name
    }
  }

  setmenuoptions() {
    let destinationinfo = this.getglobaldata("destinationinfo");

    let modules = [];

    if (destinationinfo) {
      if (destinationinfo.DisabledPages) {
        modules = destinationinfo.DisabledPages.split(",");
      }
    }

    modules.map(
      function (module, index) {
        if (this.config()[module]) {
          this.config()[module] = false;
        }
      }.bind(this)
    );

    if (!destinationinfo) {
     if(!this.config().topmenu) this.config().trending = false;
      this.config().specials = false;
      this.config().dining = false;
      this.config().shopping = false;
    } else {
      if (
        this.config().brand != "autopartner" &&
        (Number(destinationinfo.Id_Destination) == 1 ||
          Number(destinationinfo.Id_Destination) == 2 ||
          destinationinfo.Id_Destination == "area_26" ||
          destinationinfo.Id_Destination == "area_30" ||
          destinationinfo.Id_Destination == "area_1" ||
          destinationinfo.Id_Destination == "area_2") 
      ) {
        if(!this.config().topmenu) this.config().trending = true;
        this.config().specials = false;
      } else {
        if(!this.config().topmenu) this.config().trending = false;
        this.config().specials = false;
        this.config().dining = false;
        this.config().shopping = false;
      }

      

    }
  }

  urlQuery() {
    return queryString.parse(location.search);
  }

  setquery() {
    var query = queryString.parse(location.search);


    if (!this.getglobaldata("locationSearch"))
      this.setglobaldata("locationSearch", {});

    if (query.languageid) this.setglobaldata("Id_Language", query.languageid);
    if (query.agentid)
      this.setglobaldata("locationSearch.travelagentid", query.agentid);
    if (query.Id_Ambassador)
      this.setglobaldata("locationSearch.Id_Ambassador", query.Id_Ambassador);
    if (query.SupID) {
      this.setglobaldata("locationSearch.tourproviderid", query.SupID);
    } /*else{
      this.setglobaldata("locationSearch.tourproviderid", null);
    }*/

    if (query.TStatus)
      this.setglobaldata("locationSearch.toursstatusid", query.TStatus);
    /*else{
      this.setglobaldata("locationSearch.toursstatusid", null);
    }*/

    query.languageid = this.getglobaldata("Id_Language");

    if (this.getglobaldata("locationSearch.travelagentid")) {
      query.agentid = this.getglobaldata("locationSearch.travelagentid");
    }

    if (this.getglobaldata("locationSearch.Id_Ambassador")) {
      query.Id_Ambassador = this.getglobaldata("locationSearch.Id_Ambassador");
    }

    if (this.getglobaldata("locationSearch.tourproviderid")) {
      query.SupID = this.getglobaldata("locationSearch.tourproviderid");
    }

    if (this.getglobaldata("locationSearch.toursstatusid")) {
      query.TStatus = this.getglobaldata("locationSearch.toursstatusid");
    }

    if (query.menuhome){
      this.setglobaldata("locationSearch.menuhome", query.menuhome);
    }

    if (this.getglobaldata("locationSearch.menuhome")) {
      query.menuhome = this.getglobaldata("locationSearch.menuhome");
    }

    if (query.menutrending){
      this.setglobaldata("locationSearch.menutrending", query.menutrending);
    }

    if (this.getglobaldata("locationSearch.menutrending")) {
      query.menutrending = this.getglobaldata("locationSearch.menutrending");
    }

    if (query.enuspecials){
      this.setglobaldata("locationSearch.enuspecials", query.enuspecials);
    }

    if (this.getglobaldata("locationSearch.menuspecials")) {
      query.menuspecials = this.getglobaldata("locationSearch.menuspecials");
    }

    if (query.menuwishlist){
      this.setglobaldata("locationSearch.menuwishlist", query.menuwishlist);
    }

    if (this.getglobaldata("locationSearch.menuwishlist")) {
      query.menuwishlist = this.getglobaldata("locationSearch.menuwishlist");
    }

    if (query.menudining){
      this.setglobaldata("locationSearch.menudining", query.menudining);
    }

    if (this.getglobaldata("locationSearch.menudining")) {
      query.menudining = this.getglobaldata("locationSearch.menudining");
    }

    if (query.menushopping){
      this.setglobaldata("locationSearch.menushopping", query.menushopping);
    }

    if (this.getglobaldata("locationSearch.menushopping")) {
      query.menushopping = this.getglobaldata("locationSearch.menushopping");
    }

    if (query.menucontact){
      this.setglobaldata("locationSearch.menucontact", query.menucontact);
    }

    if (this.getglobaldata("locationSearch.menucontact")) {
      query.menucontact = this.getglobaldata("locationSearch.menucontact");
    }

    if (query.menulanguages){
      this.setglobaldata("locationSearch.menulanguages", query.menulanguages);
    }

    if (this.getglobaldata("locationSearch.menulanguages")) {
      query.menulanguages = this.getglobaldata("locationSearch.menulanguages");
    }

    if (query.menucurrencies){
      this.setglobaldata("locationSearch.menucurrencies", query.menucurrencies);
    }

    if (this.getglobaldata("locationSearch.menucurrencies")) {
      query.menucurrencies = this.getglobaldata("locationSearch.menucurrencies");
    }

    if (query.menumyaccount){
      this.setglobaldata("locationSearch.menumyaccount", query.menumyaccount);
    }

    if (this.getglobaldata("locationSearch.menumyaccount")) {
      query.menumyaccount = this.getglobaldata("locationSearch.menumyaccount");
    }

   /* if (query.topmenu){
      this.setglobaldata("locationSearch.topmenu", query.topmenu);
    }

    if (this.getglobaldata("locationSearch.topmenu")) {
      query.topmenu = this.getglobaldata("locationSearch.topmenu");
    }

    if (query.footer){
      this.setglobaldata("locationSearch.footer", query.footer);
    }

    if (this.getglobaldata("locationSearch.footer")) {
      query.footer = this.getglobaldata("locationSearch.footer");
    }

    if (query.header){
      this.setglobaldata("locationSearch.header", query.header);
    }

    if (this.getglobaldata("locationSearch.header")) {
      query.header = this.getglobaldata("locationSearch.header");
    }

    */

   // query.iframe = true;




    const stringified = queryString.stringify(query);

    this.setglobaldata("query", `?${stringified}`);

    //window.location.search = "?test=test";



    window.history.pushState(
      location.pathname + this.getglobaldata("query"),
      "Title",
      location.pathname + this.getglobaldata("query")
    );

    //if(location.pathname.search('tourdetails/') != -1) location.search = this.getglobaldata('query');
    //location.search = this.getglobaldata('query');

    //this.props.history.push(location.pathname+this.getglobaldata('query'););

    //window.location.replace(location.pathname+this.getglobaldata('query')); //window.history.pushState(location.pathname+this.getglobaldata('query'), 'Title', location.pathname+this.getglobaldata('query'));

    // if(location.pathname.search('tourdetails/') != -1) window.location.replace(location.pathname+this.getglobaldata('query')); //window.history.pushState(location.pathname+this.getglobaldata('query'), 'Title', location.pathname+this.getglobaldata('query'));
  }

  cleardelay() {
    clearTimeout(timeout);
  }

  delay(options_) {
    var duration = 1000;
    if (options_) {
      if (options_.duration) duration = options_.duration * 1000;
      if (options_.clear) this.cleardelay();
    }

    timeout = setTimeout(
      function () {
        if (options_) if (options_.callback) options_.callback();
      }.bind(this),
      duration
    );
  }

  //Change Imput
  changeinput(event, data) {
    var target = $(event.target);
    var value = target.val();

    var attr = target.attr("name");

    if (data) {
      value = data.value;
      attr = data.name;
    }

    if (!attr || attr == "") return false;

    var arr = attr.split(".");

    var state_ = "this.state";

    for (var i = 0; i < arr.length; i++) {
      state_ = state_ + "[arr[" + i + "]]";
      // console.log('arr['+i+']',arr[i])
    }

    if (state_ != "this.state") eval(state_ + "='" + value + "'");

    var options = {
      callback: this.forceUpdate.bind(this),
      clear: true,
    };
    this.delay(options);
  }

  pushnavigate(pathname_) {
    var query = "";

    if (this.getglobaldata("query")) {
      query = this.getglobaldata("query");
    }

    if (pathname_) this.props.history.push(pathname_ + query);
  }

  navigate(pathname_) {
    var query = "";

    if (this.getglobaldata("query")) {
      query = this.getglobaldata("query");
    }
    //console.log('pathname_',pathname_);
    // if(pathname_) this.props.history.push(pathname_)

    if (pathname_) $(location).attr("href", pathname_ + query);

    // if(pathname_) this.props.history.push(pathname_+query);

    /*  var history = createBrowserHistory({
    //forceRefresh: true
  });
 
 history.push(pathname_)*/
  }

  goback() {
    var history = createBrowserHistory({
      forceRefresh: true,
    });

    history.goBack();
  }

  selectoptions(options_, to_, hascero) {
    var selectoptions = [];

    if (Array.isArray(options_.array)) {
      var valuename = options_.valuename || "";
      var textname = options_.textname || "";
      options_.array.map(function (option, index) {
        var value = valuename == "" ? option : eval("option." + valuename);
        var text = textname == "" ? option : eval("option." + textname);

        selectoptions.push(
          <option key={index} value={value}>
            {text}
          </option>
        );
      });
    } else {
      var from = options_;

      if (from && to_)
        for (var i = from; i <= to_; i++) {
          if (hascero == "ceroleft1-9" && i <= 9) i = "0" + i;
          selectoptions.push(
            <option key={i} value={i}>
              {i}
            </option>
          );
        }
    }

    return selectoptions;
  }

  senddispatcher(actiontype, data) {
    var customEvent = new CustomEvent(actiontype, { detail: data });
    window.dispatchEvent(customEvent);
  }

  getdispatcher(actiontype, callback) {
    window.addEventListener(actiontype, callback);
  }

  ajaxsetup() {
    var user = this.getglobaldata("user");

    var data = {
      brand: location.hostname + location.pathname,
      Id_Language: this.getglobaldata("Id_Language"),
      Id_Currency: this.getglobaldata("Id_Currency"),
      partner: this.config().brand,
      public_key:'6ec39e971963f9790254961a2fbd5c75'  //totoura general
    };

    if (
      process.env.REACT_APP_BOOKING_WIDGET == "bookingwidget2" ||
      process.env.REACT_APP_BOOKING_WIDGET == "bookingwidget7" ||
      process.env.REACT_APP_BOOKING_WIDGET == "bookingwidget5"
    ) {
      data.Id_SBI = this.config().sbiid;
    }

    if (process.env.REACT_APP_BOOKING_WIDGET == "bookingwidget") {
      if (this.config().sbiid) data.Id_SBI = this.config().sbiid;
    }

    if (
      this.config().brand == "taconnect" ||
      this.config().brand == "autopartner" ||
      this.config().brand == "pricetravel" ||
      this.config().brand == "delmarboutiqueaccommodation" ||
      this.config().brand == "naturleon"
    )
      data.Id_SBI = this.config().sbiid;

    if (this.config().activityrateid) {
      if (this.config().activityrateid > 1)
        data.Id_ActivityRate = this.config().activityrateid;
    }

    if (user) {
      data.DeviceAccessToken = user.DeviceAccessToken;
      data.UserAccessToken = user.UserAccessToken;
      //  data.timestamp = Math.floor(Date.now() /1000);
    }

    $.ajaxSetup({
      data: data,
    });
  }

  setuserinfo(data) {
    data.DeviceAccessToken =
      "test.v2.mobisail$2a$05$MV9Oq6fiI0aGU3gBecjQF.Y14D0kt.Dwh7kT123AzEA6DQed/UAjq";
    this.setglobaldata("user", data);
    this.ajaxsetup();
  }

  ismobile() {
    return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  }

  isandroidmobile() {
    return /Android/i.test(navigator.userAgent);
  }

  isiomobile() {
    return /iPhone|iPad|iPod/i.test(navigator.userAgent);
  }

  setlivechatdepid() {
    var destinationinfo = this.getglobaldata("destinationinfo") || {};
    var languageid = this.getglobaldata("Id_Language");
    var languages = { 1: "", 2: "_Esp", 3: "Fra" };
    var languageprefix = languages[Number(languageid)];

    var livechatdepid = 1;
    if (destinationinfo["PHPLiveDeptID" + languageprefix]) {
      livechatdepid = destinationinfo["PHPLiveDeptID" + languageprefix];
    }

    if (this.config().livechatdepid)
      livechatdepid = this.config().livechatdepid;

    this.setglobaldata("livechatdepid", livechatdepid);
  }

  eventpreventdefault(e) {
    e.preventDefault();
  }

  openchat(e) {
    e.preventDefault();
    this.setlivechatdepid();

    var livechatdepid = this.getglobaldata("livechatdepid") || 1; //1 default

    try {
      window.phplive_launch_chat(livechatdepid);
    } catch (err) {
      $.getScript(
        "https://www.mobilesail.com/apps/livesupport/js/phplive_v2.js.php?v=" +
          livechatdepid
      ).done(
        function (script, textStatus) {
          setTimeout(function () {
            window.phplive_launch_chat(livechatdepid);
          }, 1000);
        }.bind(this)
      );
    }
  }

  preloader() {
    //this.loftloader({load_time:5});

    return null;
  }

  scrolltop(element_) {
    let element = "html, body";
    if (element_) element = element_;

    $(element).animate({ scrollTop: 0 }, "slow");
  }

  focusdiv(element) {
    if (!element) return;

    var offsets = $(element).offset();
    var top = offsets.top;
    var left = offsets.left;

    $("html, body").animate({ scrollTop: top }, "slow");
  }

  logout() {
    this.setglobaldata("user", null);
    this.ajaxsetup();
  }

  getappurl() {
    var appurl =
      "https://itunes.apple.com/us/app/totoura-vacation-concierge/id986402403?mt=8";

    if (this.isandroidmobile())
      appurl =
        "https://play.google.com/store/apps/details?id=com.mobilesail.totoura&hl=en";

    if (this.isiomobile())
      appurl =
        "https://itunes.apple.com/us/app/totoura-vacation-concierge/id986402403?mt=8";

    return appurl;
  }

  isemail(email) {
    var regex = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(email);
  }

  spinner(text_) {
    return (
      <div className="load-container load7">
        <div className="loader"></div>
        {text_ ? (
          <strong>{text_}</strong>
        ) : (
          <strong className="text-center">{this.t("Loading...")}</strong>
        )}
      </div>
    );
  }

  bancomerprocesso(url, params, callback) {
    $.extend({
      redirectPost: function (location, args, callback) {
        var form = "";
        $.each(args, function (key, value) {
          form +=
            "<input type='hidden' name='" + key + "' value='" + value + "'>";
        });
        $(
          '<form target="bancomerprocessor" action="' +
            location +
            '" method="POST">' +
            form +
            "</form>"
        )
          .appendTo($(document.body))
          .submit();

        if (callback) callback();
      },
    });

    var bf = new Buffer(JSON.stringify(params));
    var paramsencoded = bf.toString("base64");

    var params = { params: paramsencoded };

    if (this.getglobaldata("user")) {
      const user = this.getglobaldata("user");
      params.UserAccessToken = user.UserAccessToken;
      params.DeviceAccessToken = user.DeviceAccessToken;
    }

    $.redirectPost(url, params, callback);
  }

  loftloader(options_) {
    //load_time

    return;

    var options = this.config().loftloaderoptions;

    options.message_text = this.translate(options.message_text);
    options.loader_image = this.image(
      options.loader_image + "?v=" + this.version()
    );

    if (location.pathname.search("tourdetails") != -1) options.load_time = 10;

    if (options_) $.extend(options, options_);

    //$('#loftloader-wrapper').loftloader(this.config().loftloaderoptions); // DISABLE LOADER

    /*
  $(document).ajaxStop(function() {
  // place code to be executed on completion of last outstanding ajax call here
});*/
  }

  resetdestinationandfilters(destinationid, disablefilter) {
    if (!this.getglobaldata("filter")) this.setglobaldata("filter", {});
    if (!this.getglobaldata("tourslistinfo"))
      this.setglobaldata("tourslistinfo", {});

    if (!disablefilter) {
      this.setglobaldata("filter.toursfilter", {});
      this.setglobaldata("tourslistinfo.tours", []);
    }

    this.setglobaldata("Id_Destination", destinationid);
    //this.setglobaldata('Id_Location', -1);
  }

  resetlocationandfilters(locationid, disablefilter) {
    if (!this.getglobaldata("filter")) this.setglobaldata("filter", {});
    if (!this.getglobaldata("tourslistinfo"))
      this.setglobaldata("tourslistinfo", {});

    if (!disablefilter) {
      this.setglobaldata("filter.toursfilter", {});
      this.setglobaldata("tourslistinfo.tours", []);
    }
    this.setglobaldata("Id_Location", locationid);
  }

  resetstateandfilters(stateid, disablefilter) {
    if (!this.getglobaldata("filter")) this.setglobaldata("filter", {});
    if (!this.getglobaldata("tourslistinfo"))
      this.setglobaldata("tourslistinfo", {});

    if (!disablefilter) {
      this.setglobaldata("filter.toursfilter", {});
      this.setglobaldata("tourslistinfo.tours", []);
    }

    this.setglobaldata("Id_State", stateid);
  }

  resetdestination(destinationid) {
    this.resetdestinationandfilters(destinationid, true);
  }

  resetlocation(locationid) {
    this.resetlocationandfilters(locationid, true);
  }

  resetstate(stateid) {
    this.resetstateandfilters(stateid, true);
  }

  image(image) {
    return this.config().cdn + image;
  }

  link(link) {
    return link;
  }

  iframecontrol(query_) {

    console.log('query_ height',query_);
    var query = {
      iframecontrol: true,
    };

    var pathname = location.pathname;

    var basename = pathname.split("/")[1];
    query.pathname = pathname;

    if (this.getglobaldata("query")) {
      //  window.history.pushState(pathname, 'Title', pathname+this.getglobaldata('query'));
    }

    query = $.extend(query, query_);

    console.log('query height 2',query);

    if (window.parent) window.parent.postMessage(query, "*");
  }

  toursdestinationredirect() {
    var destinationname = "all-destinations";
    var destinationid = 0;
    var locationid = -1;

    if (this.getglobaldata("Id_Destination")) {
      destinationname = "unknow";
      destinationid = this.getglobaldata("Id_Destination");
    }

    if (this.getglobaldata("destinationinfo")) {
      destinationname = this.getglobaldata("destinationinfo").friendlyurl; //.toLowerCase().replace(/ /g,'-');
      destinationid = this.getglobaldata("destinationinfo").Id_Destination;

      if (destinationid.search("area_") != -1) {
        //detectamos si la variable lleva la plabra area_

        locationid = Number(destinationid.replace("area_", ""));
        destinationid = this.getglobaldata("destinationinfo").destinationid;
      }
    }

    //si se usa el componente de busqueda y se da click al encontrar un tour
    var s_parameter = false;
    var urlbysearchomponent = new URL(location.href);
    s_parameter = urlbysearchomponent.searchParams.get("byautocomplete");

    var url = "/tours/" + destinationname + "/" + destinationid + "/1";
    if (locationid != -1)
      url =
        "/tours/" +
        destinationname +
        "/" +
        destinationid +
        "/" +
        locationid +
        "/1";

    if (s_parameter == "true" || s_parameter == true) {
      url = url + "?byautocomplete=true";
    }

    //  if(locationid != -1) url = '/tours/'+destinationname+'/'+destinationid+'/'+locationid+'/1';

    return <Redirect to={url} />;
  }

  ga() {
    return ReactGA;
  }

  settourfilter(filter, value, event) {
    //  event.preventDefault();

    if (!this.getglobaldata("filter")) this.setglobaldata("filter", {});
    this.setglobaldata("filter.toursfilter", {});

    if (filter)
      this.setglobaldata('filter.toursfilter["' + filter + '"]', value);
  }

  gotours(destination,event) {
 
    this.eventpreventdefault(event)
    let locationid = -1;

    var stateid = "";
    var destinationid = destination.Id_Destination;

    if (destination.Id_Destination.search("state_") != -1) {
      //detectamos si la variable lleva la plabra state_

      stateid = Number(destination.Id_Destination.replace("state_", ""));
      destinationid = "";
    }
   
    if (destination.Id_Destination.search("area_") != -1) {
      //detectamos si la variable lleva la plabra area_

      locationid = Number(destination.Id_Destination.replace("area_", ""));
      destinationid = destination.destinationid;
      stateid = "";
    }

    this.setglobaldata("destinationinfo", destination);

    this.resetdestinationandfilters(destinationid);
    this.resetlocationandfilters(locationid);
    this.resetstateandfilters(stateid);


     if(destination.setOtherFilters) destination.setOtherFilters();

     this.gaDestinationEvent(destination.label);

    if(locationid != -1) this.navigate("tours/"+destination.friendlyurl+"/"+destinationid+"/"+locationid+"/1");
    else  this.navigate("tours/"+destination.friendlyurl+"/"+destinationid+"/1");
  }
  

  resetseealldestination() {
    this.setglobaldata("destinationinfo", null);
    this.resetdestination(null);

    this.resetstate(null);

    this.resetlocation(null);

    window.location.href = "/tours/1"; ///  this.navigate('/tours/1')
  }

  settourfilter_global(filter, value, event) {
    //  event.preventDefault();

    if (!this.getglobaldata("filter")) this.setglobaldata("filter", {});
    this.setglobaldata("filter.toursfilter", {});
    if (filter)
      this.setglobaldata('filter.toursfilter["' + filter + '"]', value);
  }

  clearLocalStorage() {
    if (!this.getglobaldata("localStorageStartDate")) {
      localStorage.clear();
      this.setlocalStorageStartDate();
    } else {
      if (
        moment(this.getglobaldata("localStorageStartDate")) <
        moment("2021-12-05 01:00")
      ) {
        localStorage.clear();
        this.setlocalStorageStartDate();
      }
    }
  }

  setlocalStorageStartDate() {
    if (!this.getglobaldata("localStorageStartDate")) {
      this.setglobaldata("localStorageStartDate", moment());
    }
  }

  normalizeText(text) {
    return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }

  gaDestinationEvent(destination) {
    //google analytics event
    this.ga().event({
      category: "Destinations",
      action: "Change Destination",
      label: destination,
    });
  }

  dataQuery(){
      return this.getglobaldata('query')?this.getglobaldata('query'):'';
  }

    reloadPage(action){



      if(this.domainRedirect(action)) return;

   // this.config().urlParams = '?algo=si&algo2=si2';
    // alert(this.config().urlParams);


   //  alert(window.self == window.top);
    
    if(this.config().urlParams && window.self !== window.top){


    
     var query = Object.assign(queryString.parse(location.search),queryString.parse(this.config().urlParams));

     const stringified = queryString.stringify(query);

     //alert(stringified);

     window.location.href =  `${window.location.origin}${window.location.pathname}?${stringified}`;
    
    }else{

      window.location.reload(true);

    }

  }


  domainRedirect(action){


    let destinatioid = Number(this.getglobaldata('Id_Destination'));
    let languageid = Number(this.getglobaldata('Id_Language'));


    
    if(this.config().brand == 'trafictours' && action == 'destination'){
       
       if(destinatioid == 3){
            window.location.href = "http://turissimo.com/tours/1?destinationid=3&languageid="+languageid;
            return true;
       }

       if(destinatioid == 6){

        window.location.href = "http://turissimojamaica.com/tours/1?destinationid=6&languageid="+languageid;
        return true;
        
       }

    }

   // this.config().brand = 'turissimojamaica';


    

   
    var turissimoDestinations = [3,6];

    if((this.config().brand == 'turissimo' || this.config().brand == 'turissimojamaica') && action == 'destination'){

     
       
       if(turissimoDestinations.indexOf(destinatioid) == -1){

            window.location.href = "https://www.trafictours.com/tours/1?destinationid="+destinatioid+"&languageid="+languageid;
            return true;
       }

    }


    return false;

  }



}
