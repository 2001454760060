import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router} from 'react-router-dom';
//import "./globals/global.scripts";

import config from "./configs/config" 
import Routes from './routes/routes';

/*
var basename = '';
if(location.host != 'localhost:3000')  basename = config.basename;
*/

//$('.test').val();


ReactDOM.render(
  <Router basename={config.basename}> 
   <Routes /> 
  </Router>,
  document.getElementById('root')
);


