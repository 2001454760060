import React,{Component} from "react";
import CartList from "../components/component.cart.list";
import moment from "moment";
import { NavLink,withRouter } from "react-router-dom";
import Section from '../components/component.section';
import CartAside from '../components/component.cart.aside'
import Global from '../globals/global';

//if (typeof window === "undefined")  var window={}

const $ = window.$;

class cart extends Global {


  constructor(props) {
    super(props);
    this.state = {
      update:false
    };
  }
 
  updateview(){

    
    this.setState({update:true},function(){
      if(this.props.updateview) this.props.updateview();
    });
  }

  /**
   * Component Did Mound
   * Adding Before Unload Behavior
   **/
  componentDidMount() {
     //window.onbeforeunload = function() { return  this.t("Sure you want leave the site?"); }.bind(this);
  }

  /**
   * Component Will Unmound
   * Removing Before Unload Behavior
   **/
  componentWillUnmount() {
     //window.onbeforeunload = function() { }
  }
  

  render() {

    console.log('star Cart');
    var Cart = this.getglobaldata("Cart") || [];

    if(Cart.length == 0) return (

        <Section origin='empty.cart' />


      );

    return (
      <div>
         <Section origin='cart' />
        {/* End section */}
        <main className="custom_main_city">
          <div id="position">
            <div className="container">
              <ul>
                <li>
                  <NavLink to='/tours'>{this.t("Tours")}</NavLink>
                </li>
                <li>{this.t("Cart")}</li>
              </ul>
            </div>
          </div>
          {/* End Position */}
          <div className="container margin_60">
            <div className="row">
              {/* Col-lg-8 */}
              <div className="col-lg-8">
              
              <CartList updateview={this.updateview.bind(this)}  />


                <div className="citylife_see">
                  <img src={this.image("img/logo_2x_cityexpressions_cart.png")} className="mx-auto d-block" />
                  <br />
                  <a href="">VER MÁS</a>
                  <div className="mt-30"></div>
                </div>
           
              </div>

              {/* End col lg-8 */}
              {/* Aside */}
              
             <CartAside />
              {/* End aside */}
            </div>
            {/*End row */}
          </div>
          {/*End container */}
          <div id="overlay" />
          {/* Mask on input focus */}
        </main>
        {/* End main */}
      </div>
    );
  }
}

export default cart

