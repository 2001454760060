import React, { Component } from 'react';
import { NavLink, withRouter } from "react-router-dom";
import InputSearchHotel from './component.input.search.hotel';
import SearchTransfersResult from './component.search.transfers.result';
import Select from 'react-select';
import Global from '../globals/global';
import autocomplete from 'autocompleter';
//if (typeof window === "undefined")  var window={}
const $ = window.$;
//const autocomplete = window.autocomplete;

class SearchTransfers extends Global {

    constructor(props) {
        super(props);

        this.changeDestination = this.changeDestination.bind(this);
        this.changeHotel = this.changeHotel.bind(this);
        
        this.handleHotel = this.handleHotel.bind(this);
        this.writehotelname = this.writehotelname.bind(this);
        this.clean = this.clean.bind(this);

        this.state = {
            destinations: this.getglobaldata('destinations') || [],
            location: this.getglobaldata('Id_Location'),
            hotels: null,
            hotel: null,
            transfers: null,
            searching: false,
            hotelid: null,
            hotelname: '',
            allowedChars: new RegExp(/^[a-zA-Z\s]+$/), 
            defaultdestinationname:''
        };
    }

    componentDidMount() {


        this.getdispatcher('load.destinations',function(data){

            this.setState({
                    destinations: this.getglobaldata('destinations') || [],
                    location: this.getglobaldata('Id_Location'),
                }, function(){
                     this.setdefaults();
                }.bind(this));

        }.bind(this));

        
        window.addEventListener('selected.hotel.general', this.handleHotel);
    }

    setdefaults(){
      this.setautocomplete();
      var destination = this.getdefaultdestination();
      this.state.defaultdestinationname = destination?destination.Name:'';
      this.forceUpdate();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.destinations !== this.state.destinations) {
            const Id_Location = this.getglobaldata('Id_Location');
            this.setState({
                location: Id_Location,
            });
        }
        if (prevState.location !== this.state.location && this.state.location !== -1) {
            this.setState({ hotels: null });
            var options = {
                arraydata: 'hotels',
                method: 'GetHotels',
                query: {
                    Id_Destination: this.state.destination,
                    Id_Location: this.state.location
                },
                callback: function (data) {
                    this.setState({
                        hotels: data,
                        hotel: -1,
                        allowedChars: new RegExp(/^[a-zA-Z\s]+$/),
                    });
                }.bind(this)
            };
            this.all(options);
        }

        if (prevState.hotels !== this.state.hotels) {
            let hotels = this.state.hotels;
            const allowedChars = this.state.allowedChars;
            autocomplete({
                input: document.getElementById('search_tr1'+this.props.identifierSearch),
                minLength: 1,
                onSelect: (item, inputfield) => {
                    inputfield.value = item.Name
                    this.state.hotelname = item.Name;
                    const detail = {
                        data: item.Id,
                        name: item.Name
                    };
                    var customEvent = new CustomEvent('selected.hotel.general', { detail: detail });
                    window.dispatchEvent(customEvent);
                },
                fetch: function (text, callback) {
                   var match = this.normalizeText(text.toLowerCase());
                                    callback(hotels.filter(hotel => {

                                        var hotelName = this.normalizeText(hotel.Name);
                                        return hotelName.toLowerCase().indexOf(match) !== -1;
                                    }));
                },
                render: function (item, value) {
                    var itemElement = document.createElement("div");
                    if (allowedChars.test(value)) {
                        var regex = new RegExp(value, 'gi');
                        var inner = item.Name.replace(regex, function (match) { return "<span style='font-weight:bold'>" + match + "</span>" });
                        itemElement.innerHTML = inner;
                    } else {
                        itemElement.textContent = item.label;
                    }
                    return itemElement;
                },
                emptyMsg: this.t("No Hotels found"),
                customize: function (input, inputRect, container, maxHeight) {
                    if(this.ismobile()){
                        container.style.top = "";
                        container.style.bottom = (window.innerHeight - inputRect.bottom + input.offsetHeight) + "px";
                        container.style.maxHeight = "140px";
                        container.style.left = "5px";
                        container.style.right = "5px";
                        container.style.width = "";
                    }
                    else if (maxHeight < 100) {
                        container.style.top = "";
                        container.style.bottom = (window.innerHeight - inputRect.bottom + input.offsetHeight) + "px";
                        container.style.maxHeight = "140px";
                    }
                }.bind(this)
            });
        }/*
        if (prevState.hotelname !== this.state.hotelname) {
            var customEvent = new CustomEvent('selected.hotel.general', {
                detail: {
                    data: this.state.hotelid,
                    name: this.state.hotelname
                }
            });
            window.dispatchEvent(customEvent);
        }*/
    }

    changeDestination(data) {

        this.resetlocationandfilters(data.Id_Location);
        this.resetdestinationandfilters(data.Id_Destination);

        this.setState({ location: data.Id_Location,defaultdestinationname:data.Name });
    }

    changeHotel(event) {
        this.setState({
            hotel: event.target.value,
            transfers: null,
        });
    }

    

    handleHotel(event) {
        this.setState({
            hotel: event.detail.data,
            hotelname: event.detail.name
        });
    }

    writehotelname(event) {
        console.log(this.state.defaultdestinationname)
        this.setState({ hotelname: event.target.value });
    }

    clean() {
        const detail = {
            data: -1,
            name: '',
        };
        var customEvent = new CustomEvent('selected.hotel.general', { detail: detail });
        window.dispatchEvent(customEvent);
        this.setState({
            hotelname: ''
        });
    }

    getdefaultdestination(){
        return this.state.destinations.find(destination => {return destination.Id_Location === this.state.location;});

    }


    setautocomplete(){
            let destinations = this.state.destinations;
            const allowedChars = this.state.allowedChars;
            autocomplete({
                input: document.getElementById('destinations_autocomplete3'),
                minLength: 1,
                onSelect: (item, inputfield) => {
                    inputfield.value = item.Name;
                    $('.autocomplete').hide();
                    this.changeDestination(item);
                },
                fetch: function (text, callback) {
                    var match = this.normalizeText(text.toLowerCase())
                    callback(destinations.filter(destination => {

                        var string_norm = this.normalizeText(destination.label)

                        return string_norm.toLowerCase().indexOf(match) !== -1;
                    }));
                }.bind(this),
                render: function (item, value) {
                    var itemElement = document.createElement("div");
                    if (allowedChars.test(value)) {
                        var regex = new RegExp(value, 'gi');
                        var inner = item.label.replace(regex, function (match) { return "<span style='font-weight:bold'>" + match + "</span>" });
                        itemElement.innerHTML = inner;
                    } else {
                        itemElement.textContent = item.label;
                    }
                    return itemElement;
                },
                emptyMsg: this.t("No Destination found"),
                customize: function (input, inputRect, container, maxHeight) {
                    if (maxHeight < 100) {
                        container.style.top = "";
                        container.style.bottom = (window.innerHeight - inputRect.bottom + input.offsetHeight) + "px";
                        container.style.maxHeight = "140px";
                    }
                }
            });
    }

    writecity(event) {
        $('.autocomplete').show();
        this.setState({ defaultdestinationname: event.target.value });
        
    }

    cleandestination(){
      this.setState({ defaultdestinationname: ''});
    }


    render() {
        return (
            this.state.destinations !== null ? (
                
                                <form className="input-group input_cleart">
                                    <input id={"search_tr1"+this.props.identifierSearch} type="text" placeholder={this.t("Type your Hotel Name")}
                                        autoComplete="off" value={this.state.hotelname} onChange={this.writehotelname}
                                        className="form-control input_tour" />
                                    <div className="input-group-append inp_appet">
                                        <button className="btn btn-outline-secondary btn_clearb" type="button"><i className="icon-cancel" onClick={this.clean}></i></button>
                                    </div>
                                </form>
                               
            ) : null
        )
    }
}

export default withRouter(SearchTransfers)