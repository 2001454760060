import Payment from "payment";

function clearNumber(value = "") {
  return value.replace(/\D+/g, "");
}

export function formatCreditCardNumber(value) {
  if (!value) {
    return value;
  }

  const issuer = Payment.fns.cardType(value);
  const clearValue = clearNumber(value);
  let nextValue;

  console.log("issuer", issuer);

  switch (issuer) {
    case "amex":
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10
      )} ${clearValue.slice(10, 15)}`;
      break;
    case "dinersclub":
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10
      )} ${clearValue.slice(10, 14)}`;
      break;
    default:
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        8
      )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 16)}`;
      break;
  }

  return nextValue.trim();
}

export function formatCVC(value, prevValue, allValues = {}) {
  const clearValue = clearNumber(value);
  let maxLength = 4;

  if (allValues.number) {
    const issuer = Payment.fns.cardType(allValues.number);
    maxLength = issuer === "amex" ? 4 : 3;
  }

  return clearValue.slice(0, maxLength);
}

export function formatExpirationDate(value) {
  const clearValue = clearNumber(value);

  if (clearValue.length >= 3) {
    return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
  }

  return clearValue;
}

export function formatFormData(data) {
  return Object.keys(data).map((d) => `${d}: ${data[d]}`);
}

export function isBBVACreditCard(creditCardNumber) {
  let bines = [
    "4555 01",
    "4555 02",
    "4555 25",
    "4555 26",
    "4555 27",
    "4153 27",
    "4180 73",
    "4180 75",
    "4440 85",
    "4097 28",
    "4101 80",
    "4101 81",
    "4413 10",
    "4413 11",
    "4555 00",
    "4555 03",
    "4772 91",
    "4555 04",
    "4555 05",
    "4555 06",
    "4555 07",
    "4555 08",
    "4555 13",
    "4555 14",
    "4555 15",
    "4555 18",
    "4555 19",
    "4555 29",
    "4555 40",
    "4555 45",
    "4931 60",
    "4931 61",
    "4931 62",
    "4943 98",
    "5420 10",
    "5429 77",
    "5440 53",
    "5445 51",
    "5470 77",
    "5470 86",
    "5470 95",
    "5471 55",
    "5471 56",
    "5474 92",
    "4098 51",
    "4098 52",
    "4101 77",
    "4152 31",
    "4413 12",
    "4413 13",
    "4461 15",
    "4461 16",
    "4461 17",
    "4517 12",
    "4555 09",
    "4555 10",
    "4555 11",
    "4555 33",
    "4555 37",
    "4180 91",
    "4180 92",
    "4180 93",
    "4180 94",
    "4461 18",
    "4118 08",
    "4180 78",
    "4461 19",
    "5224 98",
    "4180 80",
    "4180 77",
    "4180 90",
    "4083 41",
    "4083 43",
    "4772 10",
    "4772 12",
    "4772 13",
    "4772 14",
    "4772 91",
    "4772 92",
    "5358 75",
    "5546 29",
    "4555 2930",
  ];

  let result = false;

  bines.map((bine) => {
    if (String(creditCardNumber).search(String(bine)) >= 0) {
      result = true;
    }
  });

  return result;
}
