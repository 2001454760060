import React, { Component } from 'react';
import { NavLink, withRouter } from "react-router-dom";
import Global from '../globals/global';

//if (typeof window === "undefined")  var window={}

const $ = window.$;

class SearchToursResult extends Global {

    constructor(props) {
        super(props);

        this.closePopup = this.closePopup.bind(this);
    }

    closePopup() {
        $.magnificPopup.close();
    }

    render() {
        return (
            <div class="tour_container">
                <div class="tour_title">
                    <h3><strong>{this.props.data.Name}</strong></h3>
                    <p>{this.props.data.LocationName}</p>

                    <div class="row">
                        <div class="col-8 dining_t">
                            <span>{this.props.data.ShortDescription}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 dining_tags">
                            <NavLink to={`/tourdetails/${this.props.data.friendlyurl}/${this.props.data.Id}`} onClick={this.closePopup}>{this.t('Detail')}</NavLink>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(SearchToursResult)