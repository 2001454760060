import React, { Component } from 'react';
import { NavLink, withRouter } from "react-router-dom";
import Global from '../globals/global';
const $ = window.$;
class Profile extends Global {

    constructor(props) {
        super(props);

        this.state = {
            user: null,
        };
    }

    componentDidMount() {
        this.setState({
            user: this.getglobaldata('user'),
        });
    }

    componentWillUnmount() {
        $.magnificPopup.close();
    }

    render() {
        return (
            this.state.user !== null ? (
                <div>
                    <div className="row">
                        <div className="col-md-12">
                            <h4 className="blue_text"><strong>{this.t('Your profile')}</strong></h4>
                            <ul id="profile_summary">
                                <li>{this.t('Username')}: <span>{this.state.user.Email}</span>
                                </li>
                                <li>{this.t('First name')}: <span>{this.state.user.FirstName}</span>
                                </li>
                                <li>{this.t('Last name')}: <span>{this.state.user.LastName}</span>
                                </li>
                                <li>{this.t('Mail')}: <span>{this.state.user.Email}</span>
                                </li>
                            </ul>
                        </div>
                       {/*  <div className="col-md-4">
                            <img src="img/user_b.png" className="img-fluid profile_pic" />
                        </div>
                        */}
                    </div>
                    {/* End row */}

                    <div id="close_ses" className="zoom-anim-dialog mfp-hide">
                        <div className="small-dialog-header">
                            <h3>{this.t('Close')}</h3>
                        </div>
                        <div className="tour_container">
                            <div className="tour_title">
                                <span><strong>{this.t('Are you sure you want to close session?')}</strong></span>
                            </div>

                            <div className="tour_title">

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="tour_rates">
                                            <NavLink to="/" onClick={this.logout.bind(this)} className="btn add_cartbtn">{this.t('Yes')}</NavLink>

                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="tour_rates">
                                            <a onClick={() => { $.magnificPopup.close() }} className="btn close_se_n">{this.t('No')}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null
        )
    }
}

export default withRouter(Profile)