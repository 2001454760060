import React, { Component } from 'react';
import { NavLink, withRouter } from "react-router-dom";
import Global from '../globals/global';

class totouraterms extends Global {
    render() {
      if(this.config().brand == 'toorzy') return null;
        return (
            

  <div className="row">

  <div className="col-lg-12">
    <h3 className="blue_text"><strong>Terms and Conditions</strong></h3>
    <p>Conditions of use of {this.config().termsinfo.name} web site.</p>
  </div>

  <div className="col-sm-6 text-justify" >
    <h5 className="terms_title mt-15">1.  TERMS AND CONDITIONS.</h5>
    <p>This document contains the terms and conditions that govern the use of the internet web site {this.config().termsinfo.domain} (hereafter the “Web Site”) {this.config().termsinfo.tradenam !=''?<span>a property of {this.config().termsinfo.tradename} </span>:''} (“{this.config().termsinfo.abbreviation}”).  Any reservations made using the Web Site constitutes a binding agreement signifying your acceptance of and your agreement to follow these terms and conditions and to be bound by them.  Please ensure you read carefully and understand the terms and conditions prior to booking.</p>

    <p>{this.config().termsinfo.abbreviation} reserves the right, at any time, to modify the content of the present conditions of use and the information that is provided in the Web Site.  The customers, users and visitors of the Web Site shall be responsible of accessing the Web Site and the present terms and conditions of use from time to time to see if modifications have taken place.</p>

    <p>Every individual or legal entity (hereafter referred to as the “Customer”) by the mere fact of accessing, visiting or registering himself/herself in the Web Site, is automatically subject to the present terms and conditions, as well as to its future modifications. The Customer hereby declares and warrants that he/she is over 18 years of age and has the legal capacity and attributions necessary to purchase or hire in his/her own capacity or in behalf of the legal entity that the Customer declares to represent, as well as to order the charges to the credit card which information he/she provides. The parents and tutors are responsible for the purchases or reservations on-line that are made by the children under their responsibility, with or without their prior approval.</p>

    <h5 className="terms_title mt-15">2.  TOURS AND EXCURSIONS PACKAGES.</h5>
    <p>
    The purchase of one or more of the packages offered by {this.config().termsinfo.abbreviation} by the Customer constitutes a contractual agreement between {this.config().termsinfo.abbreviation} and the Customer and implies the acceptance by the Customer of {this.config().termsinfo.abbreviation}’ terms and conditions described herein.</p>

    <h5 className="terms_title mt-15">3.  INCLUSIONS AND EXCLUSIONS.</h5>
    <p>
    Unless specified otherwise, the base price of the package sold to a Customer will include the selected local excursion, transportation on site and any other inclusions as specified in the selected product description on the Web Site, but will exclude personal expenses, meals and drinks, insurances, taxes and service charge or other services not specified at the time of the booking.</p>

    <h5 className="terms_title mt-15">4.  PRODUCT DESCRIPTIONS.</h5>
    <p>
    {this.config().termsinfo.abbreviation} has put forth all necessary efforts to ensure that, at time of publication, all products' descriptions on this Web Site are accurate. For several reasons beyond the control of {this.config().termsinfo.abbreviation}, modifications can take place which may affect products' descriptions. These modifications will be noted on the Web Site and {this.config().termsinfo.abbreviation}’ computer reservation link will be updated accordingly. It is the Customers’ responsibility to consult the Web Site to be aware of these modifications.</p>

    <h5 className="terms_title mt-15">5.  PRICES INDICATED.</h5>
    <p>Prices are quoted in United-States dollars (US$), unless otherwise indicated.</p>
    <p>The prices confirmed at the time of the booking are the valid prices and supersede any pricing advertised or indicated before.</p>
    <p>Unless the Customer has paid its reservation in full, the prices are subject to change at any time, without prior notice.</p>

    <h5 className="terms_title mt-15 ">6.  PAYMENT.</h5>
    <p>Customers must settle the total of all sums due at the time of the booking by means of a valid credit card.  The use of a credit card number by a Customer indicates its agreement and compliance with the present terms and conditions.</p>
    <p>Tour availability is not guaranteed until full payment is received.</p>

    <h5 className="terms_title mt-15">7.  CONFIRMATION OF RESERVATION.</h5>
    <p>Customers must settle the total of all sums due at the time of the booking by means of a valid credit card.  The use of a credit card number by a Customer indicates its agreement and compliance with the present terms and conditions.</p>
    <p>Tour availability is not guaranteed until full payment is received.</p>

    <h5 className="terms_title mt-15">8.  MODIFICATION/CANCELLATION TO A RESERVATION.</h5>
   {/* <p>Date and time changes are permitted no later than twenty-four (24) hours prior to scheduled departure time.  To modify your reservation, please contact your local representative. Contact information is shown on your voucher.</p>
    <p>After payment has been processed, all cancellations are subject to penalties.</p>
    <p>Cancellation requests received more than twenty-four (24) hours prior to scheduled departure time are subject to a five (5) percent penalty based upon the total amount of the reservation.</p>
    <p>Cancellation requests received less than twenty-four (24) hours prior to scheduled departure time will be considered a “no show” and NO REFUNDS WILL BE MADE.</p>
    */}

   <p> Date and time changes are permitted until 48 hours prior to your scheduled reservation of your specific tour or transfer. To modify your reservation, please contact your local representative. Contact information and options are shown on the website and mobile app.</p>
<p>After payment has been processed, changes or cancellations made less than 48 hours prior to the scheduled activity are subject to the following charges:</p>
<p>Change requests received with less than 48 hours prior to the scheduled activity are subject to a hundred (100) percent penalty based upon the total amount of the reservation.</p>
<p>Cancellation requests received with less than 48 hours prior to the scheduled activity will be considered and treated the same as a “no show” and NO REFUNDS WILL BE MADE.</p>


    <h5 className="terms_title mt-15">9.  REFUNDS.</h5>
    <p>Except as provided in Article 8, no reimbursements or refunds will be granted to Customer for unused services.</p>
    <p>Most tours operate rain or shine; boat excursions will only be cancelled if the port authority deems sea conditions unsafe.  Customer must contact its local representative to confirm tour will operate as scheduled.</p>
    <p>In the event of a medical and/or other related emergency, you may reschedule or cancel with a valid medical certificate.  Customer must contact its local representative.</p>

    <h5 className="terms_title mt-15">10.  EXCLUSION OF LIABILITY.</h5>
    <p>{this.config().termsinfo.abbreviation} makes arrangements with third-party service providers and other independent parties to provide Customers with the tours and excursions packages they purchase.  Although {this.config().termsinfo.abbreviation} takes great care in selecting these service providers, it does not have any control over their business activities and cannot be held responsible for their acts, omissions, fault or negligence, or that of their employees, nor for any loss or damages suffered as a result thereof.</p>
    <p>{this.config().termsinfo.abbreviation} shall not be liable for the quality or safety of activities and/or services (such as excursions, optional tours, activities, etc.) offered by its service providers, nor for any representations made by {this.config().termsinfo.abbreviation}’ agent on any such activities and/or services.  Moreover, {this.config().termsinfo.abbreviation} shall not be liable for the failure by the service providers to provide the services due to a force majeure event or any other cause beyond the reasonable control of {this.config().termsinfo.abbreviation} or the service providers.</p>
    <p>{this.config().termsinfo.abbreviation} shall not be liable in any manner for any claims, loss, damage, cost or expense arising out of personal injury, accident or death, or loss, damage to the Customers’ property or any inconvenience, loss of enjoyment or disappointment that may arise from the participation of the Customer in any such activities and/or services which are offered by {this.config().termsinfo.abbreviation}’ service providers.</p>
    <p>In the Web Site, there could be links to web pages of third parties. Also, the Web Site could provide information that is displayed by third parties that are not part of {this.config().termsinfo.abbreviation}.  It is hereby clearly established that {this.config().termsinfo.abbreviation} does not grant any class of warranty with regards to such information and that it shall not be held responsible for any lack of compliance by the third parties.</p>
    <p>{this.config().termsinfo.abbreviation}, as well as its associates, affiliates or subsidiaries are not and shall not be responsible in the following cases that are stated in an illustrative and not limitative manner:</p>
    <p><strong>(a)</strong> transfer of electronic virus or similar when accessing the Web Site;<br />
    <strong>(b)</strong> typographic, technical, orthographic, design errors or those caused by the delay in the update of the server and/or Web Site or part of these, the lack of availability or delay in sending or delivering the messages of data (including electronic mail, reservation requests or sending of reservation confirmations);<br />
    <strong>(c)</strong> intromissions to the internet web page or interception of data messages;<br />
    <strong>(d)</strong> errors of the Customer when providing the information required by the Web Site, that are not immediately notified by the Customer to {this.config().termsinfo.abbreviation} for its attention;<br />
    <strong>(e)</strong> duplicating or mistake in the processing of the charges to a credit card, due to the responsibility of the credit or banking entity.</p>
    <p>Notwithstanding the above, in case that any responsibility arises on behalf of {this.config().termsinfo.abbreviation}, such liability shall always be limited to the amount of the consideration paid by the Customer.</p>
  </div>
  <div className="col-sm-6 text-justify">
    <h5 className="terms_title mt-15">11. CHANGES TO TOURS AND EXCURSIONS PACKAGES.</h5>
    <p>Sometimes, reserved tours or excursion packages become no longer available for different reasons beyond {this.config().termsinfo.abbreviation}’ control. In such case, {this.config().termsinfo.abbreviation} reserves the right to substitute the tour or the excursion package for a comparable alternate package or, alternatively, to cancel it.  If the substitute package is of a lesser value, the liability of {this.config().termsinfo.abbreviation} shall be limited to the sole difference in cost.  If the substitute package is of a greater value, the Customer will be required to disburse an amount equal to the difference between the price of the initial package purchased and the price of the substitute package. The Customer will be entitled to refuse such substitution.  In such a case, and in the event of a cancellation, the liability of {this.config().termsinfo.abbreviation} shall be limited to the reimbursement of the price of the package paid by the Customer.</p>

    <h5 className="terms_title mt-15">12. GENERAL COMMENTS AND COMPLAINTS.</h5>
    <p>Any incident, service cancellation, problem or dissatisfaction must be reported by the Customer at the time of the event or as soon as possible after, to allow {this.config().termsinfo.abbreviation} the opportunity to rectify the situation or provide assistance.</p>
    <p>At destination, the Customer must contact Trafictour’s local representative to get assistance and to remedy any problem without delay whenever such problem can be reasonably remedied. The representative can be contacted via telephone or email as shown on the Customer’s voucher. If a problem cannot be solved or dealt with immediately, Customers are requested to forward the specifics of the problem to {this.config().termsinfo.abbreviation}, within thirty (30) days upon return from their trip, in writing to the following address:</p>
   
    {this.config().termsinfo.tradename !=''?
        <p><strong>{this.config().termsinfo.tradename}</strong><br />{this.config().termsinfo.address}</p>
    :''}

    <h5 className="terms_title mt-15">13. INTELLECTUAL PROPERTY.</h5>
    <p>The access to the Web Site does not grant to the Customer any class of license to use the nominative, non nominative or mixed trademarks, domain names, etc., whether they are registered or not, as well as any images or any other kind of industrial or intellectual property right that is presented through the Web Site.</p>

    <h5 className="terms_title mt-15">14. LAWS.</h5>
    <p>This Agreement shall be governed, interpreted and construed in accordance with the laws of Barbados, and the courts of St. Michael Barbados shall have exclusive jurisdiction for all matters or proceedings between {this.config().termsinfo.abbreviation} and the Customer.</p>

    <h5 className="terms_title mt-15">15. PRIVACY POLICY.</h5>
    <p>All personal information provided on the Web Site, during the booking process, will be kept confidential.</p>
    <p>{this.config().termsinfo.abbreviation} strictly complies with principles of confidentiality with respect to Customers' personal information.</p>
    <p>{this.config().termsinfo.abbreviation} is committed to protecting the privacy of your personal information and have taken measures to ensure the privacy and confidentiality of that information.  Our commitment to safeguarding your privacy is reflected in our privacy policy (the “Policy”).  We have developed this Policy to ensure the highest standards are applied to information gathered from our clients.</p>
    <p>{this.config().termsinfo.abbreviation} takes the privacy rights of individuals very seriously and, in this respect, takes all measures possible to ensure the protection of the personal information that we collect in providing our services.  {this.config().termsinfo.abbreviation} may be required to exchange personal information with third parties or individuals for the purpose of performing our services.  {this.config().termsinfo.abbreviation} and such third parties may also exchange information on individuals that is not of a sensitive nature regarding their preferences for the purpose of offering and providing quality benefits, products, goods, and services in an efficient manner. Nevertheless, {this.config().termsinfo.abbreviation} will not collect, use, or disclose any personal information on an individual without his or her consent, unless compelled to do so by law.</p>
    <p><strong>{this.config().termsinfo.abbreviation}’ undertakings:</strong></p>
    <p>•    We shall collect only such personal information as is required to offer services, benefits, goods and products to individuals;</p>
    <p>•    We shall not disclose any personal information to a third party without the prior express or tacit consent of the individual or unless bound by law to do so, except to our service providers that performs certain services for {this.config().termsinfo.abbreviation}; </p>

    <p>•    We may, on occasion, release personal information to our external agencies or suppliers for processing, to identify customers likely to be interested in benefits, products, goods, or services offered by {this.config().termsinfo.abbreviation}. Through contractual agreements or other comparable means, we shall ensure that your personal information is protected when processed by our suppliers and external agencies;</p>

    <p>•    In order to increase the opportunities of {this.config().termsinfo.abbreviation} customers to obtain special benefits, we may occasionally exchange personal information with a third party, in the form of a list of those customers who meet certain general criteria. The lists may not contain any information of a sensitive nature, and the use of such information by the third party for any purpose other than as set forth in this Policy is strictly prohibited. Under no circumstances may personal information be disclosed without the express or tacit consent, as applicable of the individual concerned;</p>

    <p>•    We will provide individuals with explanations on how to delete their names from any lists we exchange with third parties. Individuals may contact {this.config().termsinfo.abbreviation} at the address above-mentioned, at any time, to have their names removed from these lists; </p>

    <p>•    We guarantee the security and confidentiality of the information that individuals provide us with; </p>

    <p>•    If requested to reveal personal information to a third party, we shall assume responsibility for the disclosures and shall take the necessary measures to ensure that the undertakings and rules referred to in this Policy are observed by this third party; </p>

    <p>•    We require that all organizations that provide us with administrative support services or that process information at our request comply with the undertakings and rules contained in this Policy; and</p>

    <p>•    Any individual is entitled to take cognizance of the information we hold on him or her, subject to restrictions in the law, and that person may require that inaccurate or incomplete information be corrected. Where applicable, we shall transmit this information to the person concerned and shall make the necessary corrections without delay.</p>

    <p><strong>Complaints concerning non-compliance with the Policy:</strong></p>

    <p>Should an individual feel that this Policy is not being complied with or should he or she be concerned by {this.config().termsinfo.abbreviation}’ practices, the individual concerned may submit a complaint to {this.config().termsinfo.abbreviation} by sending it to the address provided above in Article 12.</p>

    <p>Any complaint or request for investigation shall be the subject of an inquiry or a reply within sixty (60) working days. If the complaint is justified, {this.config().termsinfo.abbreviation} will take the appropriate follow-up measures including providing access to the personal information requested, the correction of the information, or, where applicable, by modifying this Policy. </p>

    <h5 className="terms_title mt-15">16. ENTIRE AGREEMENT.</h5>
    <p>These terms and conditions constitute the entire agreement between {this.config().termsinfo.abbreviation} and the Customer and therefore, any prior agreement, whether verbal or written, is as of this moment superseded.</p>
  </div>
</div>

        )
    }
}

export default withRouter(totouraterms)