import React, { Component } from 'react';
import { NavLink,withRouter } from "react-router-dom";
import Section from '../components/component.section';
import PrivacyTotoura from '../components/component.privacy.policy.totoura';
import PrivacySunExcursions from '../components/component.privacy.policy.sunexcursions';
import PrivacyTraficTours from '../components/component.privacy.policy.trafictours';
import PrivacyBBVA from '../components/component.privacy.bbva';
import PrivacyToorzy from '../components/component.privacy.toorzy';
import Global from '../globals/global';
const $ = window.$;

class Privacy extends Global {
    render() {
        return (
            <div>
                 <Section origin='privacy' />

                {/* End section */}

                <main>
                    <div id="position">
                        <div className="container">
                            <ul>
                                <li><NavLink to="/home">{this.t("Home")}</NavLink>
                                </li>
                                <li>{this.t('Privacy Policy')}</li>
                            </ul>
                        </div>
                    </div>
                    {/* End Position */}


                    <div className="container margin_60">

                        <div className="card">
                            <div className="card-body bor_t">


                             
                             {this.config().brand == 'trafictours'?<PrivacyTraficTours />:''}
                             {this.config().brand == 'sunexcursions'?<PrivacySunExcursions />:''}
                             {this.config().brand == 'viajeypunto'?<PrivacyBBVA />:''}
                             {this.config().brand == 'toorzy'?<PrivacyToorzy />:''}
                             
                             {this.config().brand != 'trafictours' && this.config().brand != 'sunexcursions' && this.config().brand != 'islandsunexperience' && this.config().brand != 'turissimo' && this.config().brand != 'turissimojamaica' && this.config().brand != 'viajeypunto' && this.config().brand != 'toorzy'?<PrivacyTotoura />:''}




                            </div>{/* end card-body */}
                        </div>{/* end card */}



                    </div>
                    {/* End container */}



                    <div id="overlay"></div>
                    {/* Mask on input focus */}

                </main>
                {/* End main */}
            </div>
        )
    }
}

export default Privacy