import React, { Component } from 'react';
import { NavLink, withRouter } from "react-router-dom";
import DestinationSelector from "./component.destination.selector";
import Global from '../globals/global';
import SearchAllTours from '../components/component.search.alltours';
import autocomplete from 'autocompleter';

//if (typeof window === "undefined")  var window={}
const $ = window.$;
//const autocomplete = window.autocomplete;
class filter extends Global {

  constructor(props) {
    super(props);

    this.state = {
      tours:[],
      searching:false,
      currencyid: this.getglobaldata('Id_Currency'),
      providersList: []      
    };
  }

  componentDidMount() {

    this.getdispatcher(
      "tours.filter",
      function (data) {
        //console.log('data.............',data)
        this.changeorder(data.detail.value);
      }.bind(this)
    );


    //this.autocomplete()

   
    /**
 * Clearable text inputs
 */
    function tog(v) { return v ? 'addClass' : 'removeClass'; }
    $(document).on('input', '.clearable', function () {

      $(this)[tog(this.value)]('x');
    }).on('mousemove', '.x', function (e) {

      $(this)[tog(this.offsetWidth - 18 < e.clientX - this.getBoundingClientRect().left)]('onX');
    }).on('touchstart click', '.onX', function (ev) {

      ev.preventDefault();
      $('.clearable').removeClass('x onX').val('').change();
      this.clearfilter();
      this.searchtour('')
    }.bind(this));

    //providers list

    if(this.config().providerfilter)this.getProviderslist();
    

    this.setfilter();
  }

  setfilter() {
    var categories = this.getglobaldata('filter.toursfilter.categories');
    if(categories){
    categories.map(function (category, index) {

      $(".categoriesfilter input[name=" + category + "]").prop('checked', true);
      $('#collapse_branches, #collapse_branches_pop').collapse('show');

    });
    }
    
    var tourname = this.getglobaldata('filter.toursfilter.tourname');

    if (tourname != '') $(".searchtour").addClass('x onX');
    $(".searchtour").val(tourname);


    if(this.getglobaldata('filter.toursfilter.istoprated') == -1) $('.selectfilter').val(2);
    if(this.getglobaldata('filter.toursfilter.isspecial') == -1) $('.selectfilter').val(3);
    if(this.getglobaldata('filter.toursfilter.is3x2') == -1) $('.selectfilter').val(4);

    if(this.getglobaldata('filter.toursfilter.highestprice') == -1) $('.selectfilter').val(5);
    if(this.getglobaldata('filter.toursfilter.lowestprice') == -1) $('.selectfilter').val(6);
    if(this.getglobaldata('filter.toursfilter.longerduration') == -1) $('.selectfilter').val(7);
    if(this.getglobaldata('filter.toursfilter.shorterduration') == -1) $('.selectfilter').val(8);

    $('.rangeprice').val(this.getglobaldata('filter.toursfilter.rangeprice'));

    
    if(this.config().providerfilter)$('.providersListSelect').val(this.getglobaldata('filter.toursfilter.Id_Provider'));

       

  }

  selectallcategories(option, event) {
    if (option) $(".categoriesfilter input").not('.UnselectAll').prop('checked', true);
    else $(".categoriesfilter input").not('.UnselectAll').removeAttr('checked');
    this.selectcategories(event);
  }

  selectcategories(event) {
    var categoryselected = $(event.target).attr('name') || 'Water';

    var categories = [];

    $(".categoriesfilter input").each(function (index) {
      var category = $(this).attr('name');

      if ($(this).is(':checked') && category != 'SelectAll' && category != 'UnselectAll') {
        categories.push(category);
      }
    });

    if (categoryselected != 'SelectAll' && categoryselected != 'UnselectAll') {
      $('.UnselectAll, .SelectAll').prop('checked', false);
    }

   if(!this.ismobile() || categoryselected == 'gotours'){
    this.setglobaldata('filter.toursfilter.categories', categories);

    this.searchEvents(`${JSON.stringify(categories)}`);

    this.props.onChange();
   }
    
  }



  closefilterpopup(){
     $.magnificPopup.close();
  }

  selectcategory(event) {
    
    this.clearfilter();
    this.selectcategories(event)
  }

  resetFiltersBtn(){
    this.clearfilter(); 
    this.setfilter();
    this.selectallcategories(null,false);
    this.setglobaldata('filter.toursfilter.rangeprice', '');
    $('.rangeprice').val(null);
    this.props.onChange();
  }

  clearfilter(){
    $('#collapse_branches, #collapse_branches_pop').collapse('hide');
    this.setglobaldata('filter.toursfilter.categories', []);
    this.setglobaldata('filter.toursfilter.istoprated', '');
    this.setglobaldata('filter.toursfilter.isspecial', '');
    this.setglobaldata('filter.toursfilter.is3x2', '');
    this.setglobaldata('filter.toursfilter.tourname', '');

    this.setglobaldata('filter.toursfilter.highestprice', '');
    this.setglobaldata('filter.toursfilter.lowestprice', '');
    this.setglobaldata('filter.toursfilter.shorterduration', '');
    this.setglobaldata('filter.toursfilter.longerduration', '');
    this.setglobaldata('filter.toursfilter.Id_Provider', '');


    $(".searchtour").val('');
    $('.selectfilter').val(-1);
    this.state.tours = [];

    this.forceUpdate();
  }

  selecttoprated() {
    this.clearfilter();
    this.setglobaldata('filter.toursfilter.istoprated', -1);
    this.setfilter();

    this.searchEvents(`top rated`);

    this.props.onChange();
  }

  selectspecial() {

    this.clearfilter();
    this.setglobaldata('filter.toursfilter.isspecial', -1);
    this.setfilter();
    this.searchEvents(`especiales`);
    this.props.onChange();
  }

  searchtour(tourname) {
  

    console.log('tourname',tourname)
    this.clearfilter();
    this.setglobaldata('filter.toursfilter.tourname', tourname);
    this.setfilter()
    this.searchEvents(tourname);
    this.props.onChange();
  }

  selecttourfree() {

    this.clearfilter();
    this.setglobaldata('filter.toursfilter.is3x2', -1);
    this.setfilter()

    this.searchEvents('Promo 3x2');

    this.props.onChange();
  }
   


  orderfilter(orderby) {

    this.clearfilter();
    this.setglobaldata('filter.toursfilter.'+orderby, -1);
    this.setfilter()

    this.searchEvents(`order by ${orderby}`);

    this.props.onChange();
  }
 

  changeorder(event){

   var value = event.target?event.target.value:event;

   if(value == 2) this.selecttoprated();
   else if(value == 3) this.selectspecial();
   else if(value == 4) this.selecttourfree();
   else if(value == 5) this.orderfilter('highestprice');
   else if(value == 6) this.orderfilter('lowestprice');
   else if(value == 7) this.orderfilter('longerduration');
   else if(value == 8) this.orderfilter('shorterduration');
   
   else if(value == -1){
    this.clearfilter();
    this.props.onChange();
   }

  }


  rangepricefilter(event) {
    
    var value = event.target.value;
    //this.clearfilter();
    this.setglobaldata('filter.toursfilter.rangeprice', value);
    this.setfilter()

    this.props.onChange();
  }

  providerfilter(event) {
    
    var value = event.target.value;
    //this.clearfilter();
    this.setglobaldata('filter.toursfilter.Id_Provider', value);
    this.setfilter()

    this.props.onChange();
  }

  getProviderslist(){

    var options = {
      method: "GetSBIProviders",
      query: {      
        Id_Destination: this.getglobaldata("Id_Destination")?this.getglobaldata("Id_Destination"):""  
      },
      callback: function (jsonresponse) {

        //console.log("providers",jsonresponse);
        this.setState({
          providersList: jsonresponse.data
        },function(){

        }.bind(this))        

      }.bind(this),
      error: function (jsonresponse) {
        
        this.setState({
          providersList:[]
        },function(){

        }.bind(this))

      }.bind(this)
    };
    
    this.single(options);

  }


  AutocompleteTours(text,callback) {

    var languageid = this.getglobaldata('Id_Language');
    var currencyid = this.getglobaldata('Id_Currency');
    var destinationid = this.getglobaldata('Id_Destination');
    var locationid = this.getglobaldata('Id_Location');
    var stateid = this.getglobaldata('Id_State');

    //var text = $('.searchtour').val();
    this.state.tours = [];
    this.state.searching = true;
    this.forceUpdate();

    var options = {
      arraydata: "searchtours", //Este es el nombre del estado  this.state.tours , que sera llenado una vez que se haga la llamada
      method: "SearchTours",
      query: {
        QtyWords:100,
        TourName:text,
        Id_Language: languageid,
        destination: destinationid,
        Id_Currency: currencyid,
        location_list: locationid,
        Id_State:stateid,
        limit:10,
        disabletoursids:this.config().disabletoursids?this.config().disabletoursids:'',
      },
      callback: function (tours, jsonresponse) {
        this.state.tours = jsonresponse.data;
        this.state.searching = false;
        var match = this.normalizeText(text.toLowerCase());

        callback(this.state.tours.filter(tour => {

          //  if(this.config().disabletoursids) return tour.Name.toLowerCase().indexOf(match) !== -1 && this.config().disabletoursids.indexOf(Number(tour.Id)) == -1;
            var tourName = this.normalizeText(tour.Name);
            return tourName.toLowerCase().indexOf(match) !== -1;

        }));

      }.bind(this)
    };

    
    this.all(options);
  }


  keywords(event){
    
    var tourname = $(".searchtour").val();

    console.log('tourname..1:',tourname)
     console.log('event.target.value',event.target.value)


  var key = event.key;
    if (key == 'Enter') {
      this.searchtour(event);
      return;
    }
  

  var options ={
    callback:this.AutocompleteTours.bind(this),
    clear:true
  }
  this.delay(options)

}


selectword(Tour_,inputvalue_){

  // var TourName = $('.searchtour').val();
   this.clearfilter();
   this.setglobaldata('filter.toursfilter.tourname',inputvalue_);
  
   
   if(Tour_) this.navigate('tourdetails/'+Tour_.friendlyurl+'/'+Tour_.Id);// window.location.href = 'tourdetails/'+Tour_.UrlName+'/'+Tour_.Id_Tour;
  
}

focusout(){

  if(this.getglobaldata('filter.toursfilter.tourname') == ''){
    $('.searchtour').val('');
    this.state.tours = [];
    this.forceUpdate();
  }
}


/*
autocomplete(){

  let allowedChars = new RegExp(/^[a-zA-Z\s]+$/);

  let autocomplete__ = autocomplete({
                input: document.getElementById('tours_autocomplete'),
                minLength: 1,
                onSelect: (item, inputfield, keyCode) => {

                  $(inputfield).off('keypress').on('keypress',function(event) {

                      
                       if (event.keyCode == 13) {
                          console.log('carga lista abajo', inputfield.value)
                          this.searchtour(inputfield.value)
                       }

                  }.bind(this))


                       if (!keyCode){
                         this.selectword(item,inputfield.value)
                       }


                  


                   
                },
                fetch: function (text, callback) {
                    var match = text.toLowerCase();

                    this.AutocompleteTours(text,callback)
                    
                }.bind(this),
                render: function (item, value) {
                    $('.autocomplete').remove();
                    var itemElement = document.createElement("div");
                    if (allowedChars.test(value)) {
                        var regex = new RegExp(value, 'gi');
                        var inner = item.Name.replace(regex, function (match) { return "<span style='font-weight:bold'>" + match + "</span>" });
                        itemElement.innerHTML = inner;
                    } else {
                        itemElement.textContent = item.Name;
                    }


                    return itemElement;
                },
                emptyMsg: this.t("No tours found"),
                preventSubmit: true,
                debounceWaitMs:500,
                showOnFocus:true,
                customize: function (input, inputRect, container, maxHeight) {



                    if(this.ismobile()){
                        container.style.top = "";
                        container.style.bottom = (window.innerHeight - inputRect.bottom + input.offsetHeight) + "px";
                        container.style.maxHeight = "140px";
                        container.style.left = "5px";
                        container.style.right = "5px";
                        container.style.width = "";
                    }
                    else if (maxHeight < 100) {
                        container.style.top = "";
                        container.style.bottom = (window.innerHeight - inputRect.bottom + input.offsetHeight) + "px";
                        container.style.maxHeight = "140px";
                    }
                }.bind(this)
            });
  
 // autocomplete__.destroy();
}
*/
filterpopup(){
  
  return (

      <div id="filter_p" className="zoom-anim-dialog mfp-hide">
        <div className="small-dialog-header">
          <h3>{this.t('Filters')}</h3>
          
        </div>
        <div className="card_tour">
          <div className="row">

          <SearchAllTours redirect={false} onChange={()=>{this.props.onChange()}} setfilter={()=>{this.setfilter()}} clearfilter={()=>{this.clearfilter()}}/>

            {/*<div className="col-lg-4">
              <div className="form-group has-search">
                <span className="icon-search form-control-feedback" />
                
               <div className='input-group'>
                          <input  id="tours_autocomplete" type="text" placeholder={this.t("What you are looking?")} autoComplete="off" className='form-control input_tour searchtour clearable' />
                </div>
                

              </div>
            </div>*/}


            <div className="col-lg-4 col-6 order-lg-1 order-2 filt_tou">
              <div className="form-group">
                <span>{this.t('FILTER BY')} </span>
                <button onClick={this.selectcategory.bind(this)} className="btn_tourco" type="button" data-toggle="collapse" data-target="#collapse_branches_pop" aria-expanded="false" aria-controls="collapse_branches_pop">{this.t('Activity type')}</button>
              </div>


              {this.rangePriceSelect()}

              

            </div>
            <div className="col-lg-4 col-6 order-lg-1 filt_tou">
              <div className="form-group">

              
                
                <span>{this.t('ORDER BY')} </span>
                <select className="form_sel_tour selectfilter" name="sort" onChange={this.changeorder.bind(this)} >
                  <option value={-1}>{this.t('All')}</option>
                  <option value={2}>{this.t('Top Rated')}</option>
                  <option value={3}>{this.t('Specials')}</option>
                  <option value={4}>{this.t('3rd Tour Free')}</option>
                 
                  <option value={5}>{this.t('Highest Price')}</option>
                  <option value={6}>{this.t('Lowest Price')}</option>
                  
                  <option value={7}>{this.t('Longer Duration')}</option>
                  <option value={8}>{this.t('Shorter Duration')}</option>
                  
                </select>

              </div>
            </div>
          </div>
          {/* End row */}
          <div className="row">
            <div className="col-lg-12">
              <div className="collapse" id="collapse_branches_pop">
                <div className="card_check">
                  <div className="row">
                    <div className="col-lg-12">
                      {/*Filters*/}
                      <div className="form-group form_check">
                        <div className="checkbox_style categoriesfilter" onChange={this.selectcategories.bind(this)}>
                          
                          <label className="custom_check custom-control overflow-checkbox">
                          <input type="checkbox" className="overflow-control-input" name="Water" />
                          <span className="overflow-control-indicator" />
                          <span className="overflow-control-description">{this.t('Water')}</span>
                        </label>
                        <label className="custom_check custom-control  overflow-checkbox">
                          <input type="checkbox" className="overflow-control-input" name="Land" />
                          <span className="overflow-control-indicator" />
                          <span className="overflow-control-description">{this.t('Land')}</span>
                        </label>
                        <label className="custom_check custom-control  overflow-checkbox">
                          <input type="checkbox" className="overflow-control-input" name="Cultural" />
                          <span className="overflow-control-indicator" />
                          <span className="overflow-control-description">{this.t('Cultural')}</span>
                        </label>
                        <label className="custom_check custom-control  overflow-checkbox">
                          <input type="checkbox" className="overflow-control-input" name="Nightlife" />
                          <span className="overflow-control-indicator" />
                          <span className="overflow-control-description">{this.t('Nightlife')}</span>
                        </label>
                        <label className="custom_check custom-control  overflow-checkbox">
                          <input type="checkbox" className="overflow-control-input" name="Adventure" />
                          <span className="overflow-control-indicator" />
                          <span className="overflow-control-description">{this.t('Adventure')}</span>
                        </label>
                        <label className="custom_check custom-control  overflow-checkbox">
                          <input type="checkbox" className="overflow-control-input" name="Family" />
                          <span className="overflow-control-indicator" />
                          <span className="overflow-control-description">{this.t('Family')}</span>
                        </label>
                        <label className="custom_check custom-control  overflow-checkbox">
                          <input type="checkbox" className="overflow-control-input" name="Shopping" />
                          <span className="overflow-control-indicator" />
                          <span className="overflow-control-description">{this.t('Shopping')}</span>
                        </label>

                        {/*this.config().showtoursonline?
                        <label className="custom_check custom-control  overflow-checkbox">
                          <input type="checkbox" className="overflow-control-input" name="Online" />
                          <span className="overflow-control-indicator" />
                          <span className="overflow-control-description">{this.t('Online')}</span>
                        </label>
                        :null
                        */}

                        <label className="custom_check custom-control  overflow-checkbox">
                          <input type="radio" className="overflow-control-input SelectAll" name="SelectAll" onChange={this.selectallcategories.bind(this, true)} />
                          <span className="overflow-control-indicator" />
                          <span className="overflow-control-description">{this.t('Select All')}</span>
                        </label>
                        <label className="custom_check custom-control  overflow-checkbox">
                          <input type="radio" className="overflow-control-input UnselectAll" name="SelectAll" onChange={this.selectallcategories.bind(this, false)} />
                          <span className="overflow-control-indicator" />
                          <span className="overflow-control-description">{this.t('Unselect All')}</span>
                        </label>

                        </div>

                        <button className="btn add_filterb" onClick={this.selectcategories.bind(this)} name='gotours'>{this.t("Filter")} <i className="icon-filter"></i></button>

                      </div>
                      {/*End Filters*/}
                    </div>
                  </div>
                </div>
              </div>{/*end collapse*/}
            </div>
          </div>{/*end row*/}
        </div>{/*end card_tour*/}
      </div>
      )
    
}

rangePriceSelect(){

  return(
    <div className="form-group">
                <span>{this.t('Range Prices')}: </span>
                <select className="form_sel_tour rangeprice" name="sort" onChange={this.rangepricefilter.bind(this)} >
                  <option value=''>{this.t('All')}</option>
                  <option value='0,72,0,1500'>{this.state.currencyid == 2?'$0 - $1500':'$0 - $72'}</option>
                  <option value='72,121,1500,2500'>{this.state.currencyid == 2?'$1500 - $2500':'$72 - $121'}</option>
                  <option value='121,10000,2500,100000'>{this.state.currencyid == 2?'$2500 +':'$121 +'} </option>
                </select>
              </div>
              )
}

providersSelect(){

  return(
    <div className="form-group">
                <span>{this.t('Providers')}: </span>
                <select className="form_sel_tour providersListSelect" name="sort" onChange={this.providerfilter.bind(this)} >
                  <option value=''>{this.t('All')}</option>
                  
                  {this.state.providersList.map(value=>{
                    return <option value={value.Id_Provider} >{value.Name}</option>
                  })}
                  
                  
                </select>
              </div>
              )
}

  render() {

    if(this.ismobile()) return this.filterpopup();

    return (
      
        <div>



         <div className="card tour_wrapper">
         <div className="card-body card_tour">         
          <div className="row">
          <SearchAllTours redirect={false} onChange={()=>{this.props.onChange()}} setfilter={()=>{this.setfilter()}} clearfilter={()=>{this.clearfilter()}}/>
            
            {/*<div className="col-lg-4">
              <div className="form-group has-search" style={{position:'relative'}}>
                                     
              <span className="icon-search form-control-feedback"></span>
                        <div className='input-group'>
                          <input  id="tours_autocomplete" type="text" placeholder={this.t("What you are looking?")} autoComplete="off" className='form-control input_tour searchtour clearable' />
                        </div>
              </div>
            </div>*/}


            <div className="col-lg-4 order-lg-1 order-2 filt_tou">
               {/* <div className="form-group">
                <span>{this.t('FILTER BY')} </span>
               <button onClick={this.selectcategory.bind(this)} className="btn_tourco" type="button" data-toggle="collapse" data-target="#collapse_branches" aria-expanded="false" aria-controls="collapse_branches">{this.t('Activity type')}</button>
              </div> */}

              {/*    <button className="btn_tourco" type="button" data-toggle="collapse" data-target="#collapse_branches" aria-expanded="false" aria-controls="collapse_branches">{this.t('Activity type')}</button>
             */}

              {this.rangePriceSelect()}

              

            </div>

            <div className="col-lg-4 order-lg-1 filt_tou">
              <div className="form-group">
                <span>{this.t('ORDER BY')} </span>
                <select className="form_sel_tour selectfilter" name="sort" onChange={this.changeorder.bind(this)} >
                  <option value={-1}>{this.t('All')}</option>
                  <option value={2}>{this.t('Top Rated')}</option>
                  <option value={3}>{this.t('Specials')}</option>
                  <option value={4}>{this.t('3rd Tour Free')}</option>
                  <option value={5}>{this.t('Highest Price')}</option>
                  <option value={6}>{this.t('Lowest Price')}</option>
                  <option value={7}>{this.t('Longer Duration')}</option>
                  <option value={8}>{this.t('Shorter Duration')}</option>
                </select>
              </div>
            </div>

            {this.config().providerfilter?
            <div className="col-lg-4 order-lg-1 order-2 filt_tou">
              {this.providersSelect()}
            </div>:null}

            
           

          </div>

          
          {/* End row */}
          <div className="row">
            <div className="col-lg-12">
              {/*<div className="collapse" id="collapse_branches">*/}
              <div className="" id="">

                <div className="card_check">
                  <div className="row">
                    <div className="col-lg-12">
                      {/*Filters*/}
                      <div className="form-group form_check">
                        <div className="checkbox_style categoriesfilter" onChange={this.selectcategories.bind(this)}>
                        <label className="custom_check custom-control overflow-checkbox">
                          <input type="checkbox" className="overflow-control-input" name="Water" />
                          <span className="overflow-control-indicator" />
                          <span className="overflow-control-description">{this.t('Water')}</span>
                        </label>
                        <label className="custom_check custom-control  overflow-checkbox">
                          <input type="checkbox" className="overflow-control-input" name="Land" />
                          <span className="overflow-control-indicator" />
                          <span className="overflow-control-description">{this.t('Land')}</span>
                        </label>
                        <label className="custom_check custom-control  overflow-checkbox">
                          <input type="checkbox" className="overflow-control-input" name="Cultural" />
                          <span className="overflow-control-indicator" />
                          <span className="overflow-control-description">{this.t('Cultural')}</span>
                        </label>
                        <label className="custom_check custom-control  overflow-checkbox">
                          <input type="checkbox" className="overflow-control-input" name="Nightlife" />
                          <span className="overflow-control-indicator" />
                          <span className="overflow-control-description">{this.t('Nightlife')}</span>
                        </label>
                        <label className="custom_check custom-control  overflow-checkbox">
                          <input type="checkbox" className="overflow-control-input" name="Adventure" />
                          <span className="overflow-control-indicator" />
                          <span className="overflow-control-description">{this.t('Adventure')}</span>
                        </label>
                        <label className="custom_check custom-control  overflow-checkbox">
                          <input type="checkbox" className="overflow-control-input" name="Family" />
                          <span className="overflow-control-indicator" />
                          <span className="overflow-control-description">{this.t('Family')}</span>
                        </label>
                        <label className="custom_check custom-control  overflow-checkbox">
                          <input type="checkbox" className="overflow-control-input" name="Shopping" />
                          <span className="overflow-control-indicator" />
                          <span className="overflow-control-description">{this.t('Shopping')}</span>
                        </label>
                        {/*this.config().showtoursonline?
                        <label className="custom_check custom-control  overflow-checkbox">
                          <input type="checkbox" className="overflow-control-input" name="Online" />
                          <span className="overflow-control-indicator" />
                          <span className="overflow-control-description">{this.t('Online')}</span>
                        </label>
                        :null
                        */}

                        <label className="custom_check custom-control  overflow-checkbox">
                          <input type="radio" className="overflow-control-input SelectAll" name="SelectAll" onChange={this.selectallcategories.bind(this, true)} />
                          <span className="overflow-control-indicator" />
                          <span className="overflow-control-description">{this.t('Select All')}</span>
                        </label>
                        <label className="custom_check custom-control  overflow-checkbox">
                          <input type="radio" className="overflow-control-input UnselectAll" name="SelectAll" onChange={this.selectallcategories.bind(this, false)} />
                          <span className="overflow-control-indicator" />
                          <span className="overflow-control-description">{this.t('Unselect All')}</span>
                        </label>
                      </div>
                      </div>


                      {this.config().providerfilter?
                      <div className="row">
                      <div className="col-lg-12">
                        <button onClick={()=>this.resetFiltersBtn()} className="btn add_cartbtn mt-3" type="button">{this.t('Clear filters')}</button>
                      </div></div>:null}
                      {/*End Filters*/}
                    </div>
                  </div>
                </div>
              </div>{/*end collapse*/}
            </div>
          </div>{/*end row*/}
        </div>{/*end card-body*/}
      </div>

      </div>

    )
  }
}

export default withRouter(filter)