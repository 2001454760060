import React, { Component } from 'react';
import { NavLink, withRouter } from "react-router-dom";
import Global from '../globals/global';
/*Section.propTypes = {
  children: React.PropTypes.func,
};*/

//if (typeof window === "undefined")  var window={}

const $ = window.$;


class Section extends Global {

    constructor(props) {
        super(props);

        this.state = {
            origin: null,
            configuration: null,
        };
    }

    componentDidMount() {

      this.getdispatcher('load.destinations',function(data){

            this.forceUpdate();

        }.bind(this));


        this.setState({
            origin: this.props.origin
        });
    }

    componentWillReceiveProps(){
       // this.forceUpdate();
    }

    componentDidUpdate(prevProps, prevState) {

    if(prevProps.src != this.props.src){

        this.setState({},function(){

            $('.parallax-window').attr('style',"background:url("+(this.props.src || this.state.configuration.image)+"); background-repeat:no-repeat;background-size:cover;background-position:center center");
        });
    }

        const CONFIGURATIONS = [
    {
        name: 'trending.reviews',
        image: this.image('img/reviews_back.jpg'),
        title: this.t('Tours Reviews'),
        destination: true,
        subtitle: null,
    },
    {
        name: 'transfers',
        image: this.image('img/transfers_back2.jpg'),
        title: this.t('Transfers'),
        destination: false,
        subtitle: this.t('Airport - Hotel Transfer Services'),
    },
    {
        name: 'terms.conditions',
        image: this.image('img/terms_back.jpg'),
        title: this.t('Terms & Conditions'),
        destination: false,
        subtitle: null,
    },
    {
        name: 'specials',
        image: this.image('img/special_back.jpg'),
        title: this.t('Special Offers'),
        destination: true,
        subtitle: null,
    },

    {
        name: 'tours',
        image: this.image('img/tours_back.jpg?v12'),
        title: this.t('Tours'),
        destination: true,
        subtitle: this.t('Tours'),
    },

    {
        name: 'wishlist',
        image: this.image('img/wishlist_back.jpg'),
        title: this.t('Wishlist'),
        destination: true,
        subtitle: null,
    },

    

    {
        name: 'about.us',
        image: this.image('img/about_back.jpg'),
        title: this.t('We make good vacations great'),
        destination: false,
        subtitle: null,
    },

    {
        name: 'checkout',
        image: this.image('img/checkout_back.jpg'),
        title: this.t('CHECKOUT'),
        destination: false,
        subtitle: this.t('Your details'),
    },

    {
        name: 'empty.cart',
        image: this.image('img/checkout_back.jpg'),
        title: this.t('Empty Cart'),
        destination: false,
        subtitle: null,
    },

    {
        name: 'cart',
        image: this.image('img/cart_back.jpg'),
        title: this.t('PLACE YOUR ORDER'),
        destination: false,
        subtitle: this.t('Your Cart'),
    },

    {
        name: 'contact',
        image: this.image('img/contact_back.jpg'),
        title: this.t('Contact'),
        destination: false,
        subtitle: this.t("We'd love to hear from you."),
    },

    {
        name: 'dining',
        image: this.image('img/dining_back.jpg'),
        title: this.t('Dining'),
        destination: true,
        subtitle: null,
    },

    {
        name: 'shopping',
        image: this.image('img/shop_back.jpg'),
        title: this.t('Shopping'),
        destination: true,
        subtitle: null,
    },

    {
        name: 'privacy',
        image: this.image('img/terms_back.jpg'),
        title: this.t('Privacy Policy'),
        destination: false,
        subtitle: null,
    },
    {
        name: 'cookies',
        image: this.image('img/terms_back.jpg'),
        title: this.t('Cookies Policy'),
        destination: false,
        subtitle: null,
    },
    {
        name: 'payment',
        image: this.image('img/cart_back.jpg'),
        title: this.t('Payment'),
        destination: false,
        subtitle: null,
    }
];

        if (prevState.origin !== this.state.origin) {
            this.setState({
                configuration: CONFIGURATIONS.find(configuration => configuration.name === this.state.origin),
            });
        }
      
    }

    render() {
        var destinationinfo = this.getglobaldata('destinationinfo');
   
   var imagestyle = {};
   if(this.state.configuration) imagestyle = {background:'url('+(this.props.src || this.state.configuration.image)+')',backgroundRepeat:'no-repeat',backgroundSize:'cover',backgroundPosition:'center center'};
     
        return (
            this.state.configuration !== null ? (
                <section className="parallax-window" data-parallax="scroll" style={imagestyle} > {/* data-natural-width="1400" data-natural-height="470" */}
                    {!this.props.children?<div className="parallax-content-1">
                        <div className="animated fadeInDown">
                            <h1>{this.state.configuration.title}</h1>
                            {
                                this.state.configuration.destination ? <p>{destinationinfo?destinationinfo.Name:''}</p> : this.state.configuration.subtitle
                            }
                        </div>
                    </div>:this.props.children(destinationinfo?destinationinfo.Name:'')}
                </section>
            ) : null
        )
    }
}

export default withRouter(Section)