import React, { Component } from 'react';
import { NavLink, withRouter } from "react-router-dom";
import Global from '../globals/global';

//if (typeof window === "undefined")  var window={}
const $ = window.$;
const location = window.location;
class SearchTransfersResult extends Global {

    constructor(props) {
        super(props);

        this.closePopup = this.closePopup.bind(this);
    }

    closePopup() {
        $.magnificPopup.close();
    }

    render() {
        return (
            <div class="tour_container">
                <div className="tour_title">
                    <h3><strong>{this.props.data.Description}</strong></h3>
                    <br />
                    <span className="color_red">{this.props.data.Name}</span>

                    <div class="row">
                        <div class="col-12 dining_tags">
                            <NavLink to={`/transfers/prices/${this.props.data.Id}/${this.props.data.Id_TransferPrice}/${this.props.data.Id_Airport}/${this.props.data.Id_Airport}/arrival`}
                                onClick={this.closePopup}
                                className={this.props.data.Price_Arrival_Disabled === 'disabled="disabled"' ? 'btn disabled' : 'btn'}>{this.t('Arrival')}</NavLink>
                            <NavLink to={`/transfers/prices/${this.props.data.Id}/${this.props.data.Id_TransferPrice}/${this.props.data.Id_Airport}/${this.props.data.Id_Airport}/departure`}
                                onClick={this.closePopup}
                                className={this.props.data.Price_Departure_Disabled === 'disabled="disabled"' ? 'btn disabled' : 'btn'}>{this.t('Departure')}</NavLink>
                            <NavLink to={`/transfers/prices/${this.props.data.Id}/${this.props.data.Id_TransferPrice}/${this.props.data.Id_Airport}/${this.props.data.Id_Airport}/round`}
                                onClick={this.closePopup}
                                className={this.props.data.Price_Round_Disabled === 'disabled="disabled"' ? 'btn disabled' : 'btn'}>{this.t('Round')}</NavLink>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(SearchTransfersResult)