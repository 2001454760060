import React, { Component } from 'react';
import Section from '../components/component.section';
import { NavLink,withRouter } from "react-router-dom";
import Global from '../globals/global';
const $ = window.$;

class Cookies extends Global {

   render() {
    {/* if(this.config().brand != 'trafictours') return null; */}
  if(this.getglobaldata('Id_Language') == 2)
   return ( 
 
<div>
    <Section origin='cookies' />

    <main>
        <div id="position">
            <div className="container">
                <ul>
                    <li><NavLink to="/home">{this.t("Home")}</NavLink>
                    </li>
                    <li>{this.t('Cookies Policy')}</li>
                </ul>
            </div>
        </div>
        {/* End Position */}


        <div className="container margin_60">


            <div className="card">
                <div className="card-body bor_t">

                <div className="row">
                    <div className="col-lg-12">

                      
                        <h2 className="terms_title mt-15"><strong>&iquest;Qu&eacute; es una cookie?</strong></h2>
                        <p>La mayor&iacute;a de los sitios web que usted visita utilizan cookies para mejorar su experiencia de usuario y poder por ejemplo &laquo;acordarse&raquo; de usted durante su visita (con una &laquo;cookie de sesi&oacute;n&raquo;) o en visitas sucesivas (con una &laquo;cookie persistente&raquo;).</p>
                        <p>Las cookies pueden desempe&ntilde;ar multitud de funciones. Con ellas se puede navegar por las p&aacute;ginas de forma eficaz, conservar las preferencias y, en general, mejorar la experiencia de usuario al hacer m&aacute;s f&aacute;cil y r&aacute;pida la interacci&oacute;n entre usted y el sitio web. Si un sitio no utiliza cookies, cada vez que usted pase dentro de este a otra p&aacute;gina creer&aacute; que se trata de un usuario nuevo: por ejemplo, al cerrar un men&uacute; y cambiar de p&aacute;gina, no se acordar&aacute; de lo que usted ya hab&iacute;a hecho y le presentar&aacute; la p&aacute;gina siguiente con el men&uacute; abierto.</p>
                        <p>Algunos sitios web utilizan tambi&eacute;n cookies para poder dirigir sus mensajes publicitarios o de mercadotecnia bas&aacute;ndose, por ejemplo, en la ubicaci&oacute;n o las costumbres de navegaci&oacute;n del usuario.</p>
                        <p>Las cookies pueden instalarlas tanto el sitio web que se est&aacute; visitando (&laquo;cookies propias&raquo;) como otros sitios que tengan contenidos en la p&aacute;gina por la que se navega (&laquo;cookies de terceros&raquo;).</p>
                        <h2 className="terms_title mt-15"><strong>&iquest;Qu&eacute; contienen las cookies?</strong></h2>
                        <p>Una cookie es un simple archivo de texto que se almacena en el ordenador o dispositivo m&oacute;vil desde el servidor de un sitio web. A partir de ese momento, dicho servidor podr&aacute; recuperar o leer su contenido. Las cookies son gestionadas por el navegador. Cada cookie es &uacute;nica y contiene informaci&oacute;n an&oacute;nima con un identificador &uacute;nico, el nombre del sitio web, cifras y letras, de modo que el sitio pueda acordarse de las preferencias de navegaci&oacute;n del usuario.</p>
                        <h2 className="terms_title mt-15"><strong>Tipos de cookies</strong></h2>
                        <ul>
                        <li><strong>Cookies propias:</strong></li>
                        </ul>
                        <p>Las cookies propias son instaladas por el sitio web que se est&aacute; visitando, y dicho sitio es el &uacute;nico que puede leerlas.</p>
                        <ul>
                        <li><strong>Cookies de terceros:</strong></li>
                        </ul>
                        <p>Las cookies de terceros son las que instala y utiliza una organizaci&oacute;n distinta a la del propietario del sitio web que se est&aacute; visitando. Por ejemplo, un sitio que quiera medir y analizar su tr&aacute;fico puede recurrir a una tercera empresa que, a su vez, instalar&aacute; su propia cookie para efectuar el servicio. El sitio web que se visita tambi&eacute;n puede incorporar contenidos integrados, como v&iacute;deos de YouTube, y estos sitios pueden asimismo instalar sus propias cookies.</p>
                        <p>Lo que es m&aacute;s importante, un sitio web puede utilizar una red publicitaria de terceros para difundir publicidad dirigida.</p>
                        <ul>
                        <li><strong>Cookies de sesi&oacute;n:</strong></li>
                        </ul>
                        <p>Las cookies de sesi&oacute;n se almacenan temporalmente durante una sesi&oacute;n de navegaci&oacute;n y se suprimen del terminal del usuario al cerrar el navegador.</p>
                        <ul>
                        <li><strong>Cookies persistentes:</strong></li>
                        </ul>
                        <p>Este tipo de cookie se guarda en el ordenador durante un espacio de tiempo determinado (generalmente, un a&ntilde;o o m&aacute;s) y no se suprime al cerrar el navegador. Se utiliza cuando hace falta saber qui&eacute;n es el usuario para m&aacute;s de una sesi&oacute;n de navegaci&oacute;n.</p>
                        <h2 className="terms_title mt-15"><strong>&iquest;Qu&eacute; es el almacenamiento local?</strong></h2>
                        <p>Se trata de la posibilidad de que en el navegador del usuario se almacenen datos de las aplicaciones web. Hasta la aparici&oacute;n de HTML5, los datos de las aplicaciones ten&iacute;an que almacenarse en las cookies, las cuales se inclu&iacute;an en todas las solicitudes al servidor. Adem&aacute;s de ser m&aacute;s seguro, con el almacenamiento local se puede disponer de gran cantidad de datos sin que se vea afectado el funcionamiento del sitio web.</p>
                        <h2 className="terms_title mt-15"><strong>Uso de tecnolog&iacute;as de rastreo en nuestro portal de internet</strong></h2>
                        <p>Le informamos que en nuestra p&aacute;gina de internet utilizamos cookies u otras tecnolog&iacute;as, a trav&eacute;s de las cuales es posible monitorear su comportamiento como usuario de internet, as&iacute; como brindarle un mejor servicio y experiencia al navegar en nuestra p&aacute;gina.</p>
                        <p>Los datos personales que recabamos a trav&eacute;s de estas tecnolog&iacute;as, los utilizaremos para los siguientes fines:</p>
                        <p>Para monitorear y prevenir el uso indebido de las plataformas digitales; para mejorar nuestras herramientas digitales y actualizarlas conforme evoluciona la tecnolog&iacute;a; y para ofrecer contenido personalizado.</p>
                        <p>Los datos personales que obtenemos de estas tecnolog&iacute;as de rastreo son los siguientes:</p>
                        <ul>
                        <li>Identificadores de una sesi&oacute;n.</li>
                        <li>Idioma preferido por el usuario.</li>
                        <li>Regi&oacute;n en la que se encuentra el usuario.</li>
                        <li>Tipo de navegador del usuario.</li>
                        <li>Tipo de sistema operativo del usuario.</li>
                        <li>P&aacute;ginas web visitadas por un usuario.</li>
                        <li>B&uacute;squedas realizadas por un usuario.</li>
                        <li>Publicidad revisada por un usuario.</li>
                        </ul>
                        <h2 className="terms_title mt-15"><strong>Tipos de Cookies utilizadas:</strong></h2>
                        <ul>
                        <li><em>Cookies propias:</em> El sitio web de <span className="cap_text">{this.config().brandname}.com</span>{this.config().brand == 'transat'?<span className="cap_text_tran">totoura.com/transat</span>:''}, instala cookies propias con el &uacute;nico objetivo de hacer posibles o mejorar las funcionalidades que ofrece y la experiencia de navegaci&oacute;n para el usuario.</li>
                        <li><em>Cookies de Terceros:</em> &eacute;stas cookies son instaladas por herramientas de terceros, con el objetivo de controlar la calidad de la experiencia del usuario que visitan nuestro sitio web. El sitio web de <span className="cap_text">{this.config().brandname}.com</span>{this.config().brand == 'transat'?<span className="cap_text_tran">totoura.com/transat</span>:''}, puede alojar cookies de terceros, incluyendo las que se describen a continuaci&oacute;n:</li>
                        </ul>

                        <h2 className="terms_title mt-15"><strong>Google Analytics:</strong></h2>
                        <p>&nbsp;<em>__utma:</em> Esta cookie permite determinar el n&uacute;mero de veces que un usuario ha visitado el sitio web de <span className="cap_text">{this.config().brandname}.com</span>{this.config().brand == 'transat'?<span className="cap_text_tran">totoura.com/transat</span>:''};</p>
                        <p><em>__utmb:</em> Esta cookie sirve para saber cu&aacute;nto tiempo dura una visita al sitio web;</p>
                        <p><em>__utmc:</em> Esta cookie sirve para saber cu&aacute;nto tiempo dura una visita al sitio web y caduca al finalizar la sesi&oacute;n;</p>
                        <p><em>__utmz:</em> Esta cookie permite determinar de qu&eacute; manera ha llegado el usuario al sitio web. Un m&oacute;dulo complementario de&nbsp;<a href="https://tools.google.com/dlpage/gaoptout?hl=en">Google Analytics</a>&nbsp;permite su desactivaci&oacute;n.</p>
                        <h2 className="terms_title mt-15"><strong>&iquest;Qu&eacute; debe hacer si no desea que se instalen cookies en su equipo de c&oacute;mputo o dispositivo m&oacute;vil?</strong></h2>
                        <p>Estas tecnolog&iacute;as podr&aacute;n deshabilitarse siguiendo los siguientes pasos:</p>
                        <p>Puede cambiar los permisos de su navegador para aceptar o bloquear cookies dentro de las opciones de configuraci&oacute;n de privacidad de su navegador.</p>
                        <p>Para m&aacute;s informaci&oacute;n se sugiere realizar una b&uacute;squeda en su explorador de internet la frase: &ldquo;&iquest;c&oacute;mo administrar las cookies en [nombre del navegador aplicable?".</p>
                        <p>Tambi&eacute;n podr&aacute; encontrar m&aacute;s informaci&oacute;n sobre cookies en general, incluso sobre c&oacute;mo ver qu&eacute; cookies se instalan en su dispositivo (m&oacute;vil) y c&oacute;mo gestionarlas y eliminarlas en&nbsp;<a href="http://www.allaboutcookies.org/">www.allaboutcookies.org</a>&nbsp;y&nbsp;<a href="http://www.youronlinechoices.com/es/">http://www.youronlinechoices.com/es/</a>.</p>
                        <h2 className="terms_title mt-15">&Uacute;ltima actualizaci&oacute;n: 22/07/2021</h2>

                   
                   
                    </div>

                </div>
                {/* End row */}


                </div>
            </div>




        </div>
        {/* End container */}




        <div id="overlay"></div>
        {/* Mask on input focus */}

    </main>
    {/* End main */}
</div>
 
 );
 
 ////////////////////////////////////////////SPANISH
 else return (
 
<div>
    <Section origin='cookies' />

    <main>
        <div id="position">
            <div className="container">
                <ul>
                    <li><NavLink to="/home">{this.t("Home")}</NavLink>
                    </li>
                    <li>{this.t('Cookies Policy')}</li>
                </ul>
            </div>
        </div>
        {/* End Position */}


        <div className="container margin_60">


            <div className="card">
                <div className="card-body bor_t">

                <div className="row">
                    <div className="col-lg-12">

                    <h2 className="terms_title mt-15"><strong>What is a cookie? </strong></h2>

                    <p>Most websites that you visit use cookies to improve your user experience and be able to, for example, &ldquo;remember&rdquo; you during your visit (with a session cookie) o subsequent visits (with a persistent cookie).</p>

                    <p>Cookies can perform many functions. With them you can browse the pages effectively, preserve preferences and, in general, improve the user experience by making the interaction between you and the web site easier and faster. If a site does not use cookies, every time you go in it to another page it will believe that you are a new user: for example, when closing a menu and changing the page, it will not remember what you had already done and will show you the next page with the menu open.</p>

                    <p>Some web sites also use cookies to be able to target their advertising or marketing messages based on, for example, location or browsing behavior of the user.</p>

                    <p>Cookies may be installed both, in the website that you are visiting (&ldquo;own cookies&rdquo;) and other sites contained in the page in which the user is browsing (&ldquo;third-party cookies&rdquo;).</p>

                    <h2 className="terms_title mt-15"><strong>What does cookies contain? </strong></h2>

                    <p>A cookie is a simple text file that is stored in a computer or mobile device from a web site server. As of that moment, the said server may recover or read its content. The browser may manage cookies. Each cookie is unique and contains anonymous information with a unique identifier, the name of the website, numbers and letters, so the site can remember the browsing behavior preferences of the user.</p>

                    <h2 className="terms_title mt-15"><strong>Type of cookies</strong></h2>

                    <ul>
                    <li><strong>Own cookies:</strong></li>
                    </ul>
                    <p>Own cookies are installed by the website that the user is visiting, and the said site is the only one that can read them.</p>

                    <ul>
                    <li><strong>Third-party cookies:</strong></li>
                    </ul>
                    <p>Third-party cookies are those installed and used by an organization other than the owner of the website being visited. For example, a site that wants to measure and analyze its traffic can turn to a third company that, in turn, will install its own cookie to perform the service. The website you visit can also incorporate integrated content, such as YouTube videos, and these sites can also install their own cookies.</p>
                    <p>Most importantly, a website can use a third-party ad network to spread targeted advertising.</p>

                    <ul>
                    <li><strong>Session cookies:</strong></li>
                    </ul>
                    <p>Session cookies are stored temporarily during a browsing session and are deleted from the user's terminal when the browser is closed.</p>

                    <ul>
                    <li><strong>Persistent cookies:</strong></li>
                    </ul>
                    <p>This type of cookie is stored on the computer for a certain period of time (usually a year or more) and is not deleted when the browser is closed. It is used when you need to know who the user is for more than one browsing session.</p>

                    <h2 className="terms_title mt-15"><strong>What is local storage?</strong></h2>

                    <p>Is the possibility that web application data is stored in the user's browser. Until the appearance of HTML5, the application data had to be stored in cookies, which were included in all requests to the server. In addition to being more secure, a large amount of data can be available with local storage without affecting the operation of the website.</p>

                    <h2 className="terms_title mt-15"><strong>Use of tracking technologies in our internet portal</strong></h2>

                    <p>We inform you that our internet page uses cookies and other technologies through which it is possible to monitor your behavior as an Internet user, as well as provide you with a better service and experience when browsing our page.</p>

                    <p>The personal data that we collect through these technologies will be used for the following purposes:</p>

                    <p>To monitor and prevent the misuse of digital platforms; to improve our digital tools and update them as technology evolves; and to offer personalized content.</p>

                    <p>The persona data obtained with these tracking technologies are the following:</p>

                    <ul>
                    <li>Identifiers, username and passwords of a session.</li>
                    <li>Language preferred by the user.</li>
                    <li>Region in which the user is located.</li>
                    <li>User&rsquo;s type of browser.</li>
                    <li>User&rsquo;s type of operating system.</li>
                    <li>Web pages visited by a user.</li>
                    <li>Searches made by a user.</li>
                    <li>Reviewed advertising by user.</li>
                    </ul>


                    <h2 className="terms_title mt-15"><strong>Type of cookies used:</strong></h2>

                    <ul>
                    <li>Own cookies: The site of <span className="cap_text">{this.config().brandname}.com</span>{this.config().brand == 'transat'?<span className="cap_text_tran">totoura.com/transat</span>:''}, installs own cookies with the sole purpose of making possible or handle the functionalities offered and the browsing experience for the user.</li>
                    <li>Third-party cookies: these are cookies installed by third-party tools, with the purpose of controlling the quality of experience of the user visiting our website. The website of <span className="cap_text">{this.config().brandname}.com</span>{this.config().brand == 'transat'?<span className="cap_text_tran">totoura.com/transat</span>:''} may host third-party cookies, including the ones described below:</li>
                    </ul>

                    <h2 className="terms_title mt-15"><strong>Google Analytics:</strong></h2>

                    <p>_utma: this cookie allows to determine the number of times that a user has visited the website of <span className="cap_text">{this.config().brandname}.com</span>{this.config().brand == 'transat'?<span className="cap_text_tran">totoura.com/transat</span>:''};</p>

                    <p>_utmb: this cookie is used to know how much time a visit to the website lasts;</p>

                    <p>_utmc: This cookie serves to know how long a visit to the website lasts and expires at the end of the session;</p>

                    <p>_utmz: This cookie allows you to determine how the user has reached the website. A complementary Google Analytics module allows its deactivation.</p>

                    <h2 className="terms_title mt-15"><strong>What should you do if you do not wish cookies to be installed in your computer or mobile device?</strong></h2>

                    <p>Following the steps below can disable these technologies:</p>

                    <p>You can change the permissions of your browser to accept or block cookies within the privacy settings of your browser.</p>

                    <p>For more information, it is suggested to perform a search in your internet browser of the phrase: "How to manage cookies in [applicable browser name?".</p>

                    <p>You can also find more information about cookies in general, including how to see what cookies are installed on your (mobile) device and how to manage and delete them in <a href="http://www.allaboutcookies.org">www.allaboutcookies.org</a> and <a href="http://www.youronlinechoices.com/es/">http://www.youronlinechoices.com/es/</a>.</p>

                    <h2 className="terms_title mt-15">Last update: 22/07/2021</h2>

                   
                   
                    </div>

                </div>
                {/* End row */}


                </div>
            </div>




        </div>
        {/* End container */}




        <div id="overlay"></div>
        {/* Mask on input focus */}

    </main>
    {/* End main */}
</div>
 
 
 );
     }
}

export default Cookies