import React, { Component } from 'react';
import { NavLink, withRouter } from "react-router-dom";
import Global from '../globals/global';

//if (typeof window === "undefined")  var window={}

const $ = window.$;
class GenericIFrame extends Global {

  constructor(props) {
    super(props);

    this.state = {
      url: this.props.url
    };
  }

  componentDidMount() {

  }

  componentWillReceiveProps(nextProps) {
    if(this.ismobile()) {
      $('#payment_bancomer').attr('style','height:1850px');
    } else {
      $('#payment_bancomer').attr('style','height:1550px');
    }
    this.setState({url:nextProps.url})
  }

  onload() {
    $('#payment_bancomer').attr('style','height:500px');
  }

  render() {

    if(!this.state.url) {
      return null;
    }

    return (
      <iframe onLoad={this.onload.bind(this)} src={this.state.url} frameBorder="0" scrolling="no" seamless="seamless" />
      )
  }
}

export default withRouter(GenericIFrame)