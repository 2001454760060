import React, { Component } from 'react';
import { NavLink,withRouter } from "react-router-dom";
import Section from '../components/component.section';
import TotouraTerms from '../components/component.terms.totoura';
import OtherTerms from '../components/component.terms.other';
import BBVATerms from '../components/component.terms.bbva';
import ToorzyTerms from '../components/component.terms.toorzy';
import Global from '../globals/global';
const $ = window.$;

class TermsConditions extends Global {
    render() {
        
        return (
            <div>
                <Section origin="terms.conditions" />

                <main>
                    <div id="position">
                        <div className="container">
                            <ul>
                                <li><NavLink to="/home">{this.t("Home")}</NavLink>
                                </li>
                                <li>{this.t('Terms & Conditions')}</li>
                            </ul>
                        </div>
                    </div>
                    {/* End Position */}


                    <div className="container margin_60">

                        <div className="card">
                            <div className="card-body bor_t">


                            {this.config().termsinfo?<OtherTerms />:this.config().brand == "viajeypunto"?<BBVATerms />:<TotouraTerms />}

                            {this.config().brand == 'toorzy'?<ToorzyTerms />:''}


                            </div>{/* end card-body */}
                        </div>{/* end card */}



                    </div>
                    {/* End container */}



                    <div id="overlay"></div>
                    {/* Mask on input focus */}

                </main>
                {/* End main */}
            </div>
        )
    }
}

export default TermsConditions