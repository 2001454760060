import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import moment from "moment";
import Global from "../globals/global";
class Coupon extends Global {
    constructor(props) {
        super(props);

       // this.config().couponcode = "PTRIT"

        this.validate = this.validate.bind(this);
        this.dateFormat = this.dateFormat.bind(this);
        this.timeFormat = this.timeFormat.bind(this);

        this.state = {
            Code: this.config().couponcode || "",
            discount: null,
            loading: false,
            validcoupon: true,
        };
    }

    componentDidMount() {
        if (this.props.onlytable || this.props.code != "") {
            this.state.Code = this.props.code;
            this.validate();
        }else if(this.state.Code != ""){
            this.validate();
        }
    }

    componentWillMount() {}

    componentDidUpdate(prevProps, prevState) {
        if (
            this.props.code != "" &&
            (prevProps.ActivityDate != this.props.ActivityDate ||
                prevProps.ActivityTime != this.props.ActivityTime ||
                prevProps.AdultsNum != this.props.AdultsNum ||
                prevProps.ChildsNum != this.props.ChildsNum ||
                prevProps.ChildrenAges != this.props.ChildrenAges)
        ) {
            this.state.Code = this.props.code;
            this.validate();
        }

        if (prevState.discount !== this.state.discount) {
            this.props.handle(this.state.discount);
        }
    }

    dateFormat(date) {
        // return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
        return moment(date).format("YYYY-MM-DD");
    }

    timeFormat(date) {
        // return `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
        return moment(date).format("HH:mm");
    }

    validate() {
        if (!this.state.Code || this.state.Code == "") return;
        this.setState({ loading: true, discount: null, validcoupon: true });
        const Id_Destination = this.getglobaldata("Id_Destination");
        const Id_Location = this.getglobaldata("Id_Location");
        let query = {
            Id_ActivityType: this.props.Id_ActivityType,
            ArrivalDate: this.dateFormat(this.props.ArrivalDate),
            DepartureDate: this.dateFormat(this.props.DepartureDate),
            ArrivalTime: this.timeFormat(this.props.ArrivalDate),
            DepartureTime: this.timeFormat(this.props.DepartureDate),
            AdultsNum: this.props.AdultsNum,
            ChildsNum: this.props.ChildsNum,
            Code: this.state.Code,
            Id_Activity: this.props.Id_Activity,
            Id_TransferPrice: this.props.Id_TransferPrice,
            hasMultipleAirPorts: this.props.hasMultipleAirPorts,
            Id_Destination: Id_Destination !== null ? Id_Destination : "",
            Id_Location: Id_Location !== null ? Id_Location : "",
            Id_TripType: this.props.Id_TripType,
            Id_TravelAgent:
                this.props.Id_TravelAgent !== null
                    ? this.props.Id_TravelAgent
                    : "",
            Id_Language: this.getglobaldata("Id_Language"),
            Id_Currency: this.getglobaldata("Id_Language"),
            Total: this.props.total,
            ActivityDate: this.props.ActivityDate,
            ActivityTime: this.props.ActivityTime,
        };

        var options = {
            // arraydata: 'discount',
            method: "getCouponDiscountAmount",
            query: query,
            callback: function (data, result) {
                // console.log('data', data);
                // console.log('result', result);
                this.setState(
                    {
                        discount: data,
                        loading: false,
                    },
                    function () {
                        // this.props.handle(this.state.discount);
                    }.bind(this)
                );
            }.bind(this),
            error: function (error) {
                this.setState(
                    {
                        discount: error,
                        loading: false,
                        validcoupon: false,
                    },
                    function () {
                        // this.props.handle(this.state.discount);
                    }.bind(this)
                );
            }.bind(this),
        };
        this.single(options);
    }

    render() {
        // return null;

        //console.log('this.state.Code',this.state.Code)

        if (this.props.onlytable) return null;
        return (
            <div className="form-group row">
                <div className="col-md-6">
                    <input
                        type="text"
                        value={this.state.Code}
                        className="form-control"
                        placeholder={this.t("Coupon Code")}
                        onChange={(event) =>
                            this.setState({
                                Code: event.target.value,
                                validcoupon: true,
                            })
                        }
                    />
                </div>

                <div className="col-md-6">
                    {this.state.loading ? (
                        <b className="text-info">
                            {this.t("Validating Coupon..")}
                        </b>
                    ) : (
                        <div>
                            <button
                                className="btn coupon_btn"
                                onClick={this.validate}
                            >
                                {this.t("Validate Coupon")}
                            </button>
                            <br />
                            <br />
                            {!this.state.validcoupon ? (
                                <span className="text-danger">
                                    {this.t("Invalid Coupon")}
                                </span>
                            ) : (
                                ""
                            )}
                        </div>
                    )}
                </div>

                {/*this.config().brand == "viajeypunto" ? (
                    <div className="col-md-12">
                        <h3 className="discount_msg discount_msg_t">
                            <strong>
                                {this.t(
                                    "10% discount on experiences valid through Wednesday September 30th, using the coupon code: VGB10SE"
                                )}
                            </strong>
                        </h3>
                    </div>
                                ) : null*/
                }


                                   



            </div>
        );
    }
}

export default withRouter(Coupon);
