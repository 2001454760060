import React, { Component } from 'react';
import { NavLink, withRouter } from "react-router-dom";
import Global from '../globals/global';
import TransferCardOption from './component.transfer.card.option';

class TransferCard extends Global {

    constructor(props) {
        super(props);

        this.state = {
            data: null,
            currency: null,
            airports: null,
        };
    }

    componentDidMount() {
        this.setState({
            data: this.props.data,
            currency: this.getglobaldata('currency'),
            airports: {
                Id_Airport_Arrival: this.props.Id_Airport_Arrival,
                Id_Airport_Departure: this.props.Id_Airport_Departure,
            },
        });
    }

    render() {
        return (
            this.state.data !== null ? (
                <div className="col-lg-4">
                    <div className="tra_back">
                        <span className="tra_shared">{this.state.data.Name}</span>
                        <span className="blue_text">{this.state.data.LocationName}</span>
                        <p>{this.t('Rates are per')} <strong>{this.state.data.PaymentBy.toUpperCase()}</strong> {this.t('in')} <strong>{this.state.data.CurrencyCode}</strong></p>

                        <TransferCardOption type="round" data={this.state.data} airports={this.state.airports} />
                        <TransferCardOption type="arrival" data={this.state.data} airports={this.state.airports} />
                        <TransferCardOption type="departure" data={this.state.data} airports={this.state.airports} />

                        <p><strong>{this.t('DESCRIPTION')}</strong></p>
                        <span>{this.state.data.Description}</span>
                    </div>
                </div>
            ) : <div>{this.spinner()}</div>
        )
    }
}
export default withRouter(TransferCard)