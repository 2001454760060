import React, { Component } from "react";
import { findDOMNode } from "react-dom";
import { NavLink, withRouter } from "react-router-dom";
import Select from "react-select";
import Global from "../globals/global";
import GenericIFrame from "../components/component.generic.iframe.js";
import autocomplete from 'autocompleter';
//if (typeof window === "undefined")  var window={}
const location = window.location;
const $ = window.$;
//const autocomplete = window.autocomplete;

class DestinationSelector extends Global {
    constructor(props) {
        super(props);

        this.chargeDestinations = this.chargeDestinations.bind(this);
        this.changeDestination = this.changeDestination.bind(this);
        this.changeLocation = this.changeLocation.bind(this);
        this.findDestinationName = this.findDestinationName.bind(this);

        this.state = {
            Id_Location: this.getglobaldata("Id_Location"),
            Id_Destination: this.getglobaldata("Id_Destination"),
            Id_State: this.getglobaldata("Id_State"),
            destinations: this.getglobaldata("destinations") || null,
            useLocationAsDestination:
                this.getglobaldata("useLocationAsDestination") || null,
            locations: null,
            allowedChars: new RegExp(/^[a-zA-Z\s]+$/),
            defaultdestinationname: "",
            currentGeolocation: this.getglobaldata("currentGeolocation"),
            Id_currentGeolocation: this.getglobaldata("Id_currentGeolocation"),
            b_shownotify: this.getglobaldata("b_shownotify") || null,
            instructionn_url: "",
            selectedfilter:null,
            selectedFiltervalue:null
        };

        this.geomodal = "";
        this.instructionmodal = "";
    }


    getDestination(id,otherfilter){ 

    var destinations = []



    if(this.state.destinations) destinations = this.state.destinations;


   // console.log('destinationsdestinationsdestinations+++++',destinations)

    var destination_ = destinations.find(function(destination) {
      return Number(destination.Id_Destination) == id;
    });

   // console.log('destination_destination_destination_destination_+++++',destination_)

    if(otherfilter && destination_) destination_.setOtherFilters = this.setOtherFilters.bind(this);

    console.log("GET DESTINATION",destination_);
    
    return destination_;

    
  }


  setOtherFilters(){
     this.settourfilter(this.state.selectedfilter, this.state.selectedFiltervalue);
  }


      /**
   * @function opendiagrampopup
   **/
  opendiagrampopup(filter, value, event) {

    this.setState({
      selectedfilter:filter,
      selectedFiltervalue:value
    })
    
    $.magnificPopup.open({
      items: {
        src: "#askdestiny",
      },
      type: "inline",
      fixedContentPos: true,
      fixedBgPos: true,
      overflowY: "auto",
      closeBtnInside: true,
      preloader: false,
      midClick: true,
      removalDelay: 300,
      mainClass: "my-mfp-zoom-in",
      callbacks: {
        open: function () {},

        close: function () {
          //this.state.bancomerurl = false;
        }.bind(this),
      },
    });
  }

    componentDidMount() {

        var params =  {
                countries: this.config().destinationscountries,
                regions: this.config().destinationsregions,
                withdestinations: true,
            }

            if(this.config().destinationsIds) params.destinations = this.config().destinationsIds;

        var options = {
            method: "SearchDestinations",
            query: params,
            callback: function (data) {
                this.setdestinationslist(data);
                // Se simula un primer click (Tema con Jquery y React)
                const el = findDOMNode(this.refs.change_place_pop);
                $(el).magnificPopup();
            }.bind(this),
        };
        this.all(options);

        if (
            this.getglobaldata("useLocationAsDestination") == null ||
            this.getglobaldata("useLocationAsDestination") == false
        ) {
            this.setglobaldata("b_shownotify", true);

            //  this.getGeolocation();
        }
    }

    setdestinationslist(destinations) {
        // var destinations = [];

        /* regions.map(function(region,index){
         /*  region.Id_Destination = -(index+1);
           region.Name = '---- '+region.Name.toUpperCase()+' ----';
           region.disabled = true;
           region.isDisabled = true;
           destinations.push(region);*/

        /* region.destinations.data.map(function(destination,index_){
               destinations = destinations.concat(destination.Locations);   
           });
           //destinations = destinations.concat(region.destinations.data);
       })*/

        destinations = destinations.filter((destination) => {

            if(this.config().brand == 'cityexpressions' && destination.Id_Destination =='area_2'){
                destination.label = "Riviera Maya - Playa del Carmen, Quinta Roo, México";
                destination.Name = "Riviera Maya - Playa del Carmen";
            }
            return (
                Number(destination.QtyTours) > 0 || destination.QtyTours == ""
            );
        });

        this.setglobaldata("destinations", destinations);
        this.senddispatcher("load.destinations", {});

        this.setState(
            {
                destinations: destinations,
            },
            function () {
                this.setautocomplete();
                this.state.defaultdestinationname = this.findDestinationName();
            }.bind(this)
        );
    }

    componentWillReceiveProps() {}

    componentDidUpdate(prevProps, prevState) {
        if (prevState.destinations !== this.state.destinations) {
            const Id_Destination =
                this.props.destinationid === undefined
                    ? this.getglobaldata("Id_Destination")
                    : this.props.destinationid;

            this.setState({
                Id_Destination: Id_Destination,
            });
        }
        if (prevState.Id_Destination !== this.state.Id_Destination) {
            if (this.props.destinationid === undefined) {
                this.setglobaldata("Id_Destination", this.state.Id_Destination);
            }

            var destination = this.state.destinations.find((destination) => {
                return destination.Id_Destination === this.state.Id_Destination;
            });

            if (!destination) {
                destination = this.state.destinations[1] || {};
            }

            this.setState({
                locations: destination.Locations || [],
            });
        }

        /* if (prevState.locations !== this.state.locations) {
            const Id_Location = this.props.destinationid === undefined ? this.getglobaldata('Id_Location') : -1;

            this.setState({
                Id_Location: Id_Location,
            });
        }*/
        if (prevState.Id_Location !== this.state.Id_Location) {
            this.setglobaldata("Id_Location", this.state.Id_Location);
        }
    }

    chargeDestinations(e) {
        e.preventDefault();

        var changedestination = this.getglobaldata("changedestination");

        if (!changedestination) return;



       if(this.config().brand == 'toorzy') {
        this.opendiagrampopup("categories", ["Water"])
        return;
       }


        $.magnificPopup.open({
            items: {
                src: "#change_place",
            },
            type: "inline",
            fixedContentPos: true,
            fixedBgPos: true,
            overflowY: "auto",
            closeBtnInside: true,
            preloader: false,
            midClick: true,
            removalDelay: 300,
            mainClass: "my-mfp-zoom-in",
            callbacks: {
                open: function () {},
                close: function () {}.bind(this),
            },
        });

        /*  const el = findDOMNode(this.refs.change_place_pop);
       $(el).magnificPopup();*/
    }

    changeDestination(data) {
        var locationid = -1;

        var stateid = "";
        var destinationid = data.Id_Destination;
        if (data.Id_Destination.search("state_") != -1) {
            //detectamos si la variable lleva la plabra state_

            stateid = Number(data.Id_Destination.replace("state_", ""));
            destinationid = "";
        }

        if (data.Id_Destination.search("area_") != -1) {
            //detectamos si la variable lleva la plabra area_

            locationid = Number(data.Id_Destination.replace("area_", ""));
            destinationid = data.destinationid;
            stateid = "";
        }

        this.resetstateandfilters(stateid);

        this.resetdestinationandfilters(destinationid);

        this.resetlocationandfilters(locationid);

        var pathname = location.pathname;

        //data.Name = data.Name.replace(/[^a-zA-Z ]/g, "");
        var destinationname = data.friendlyurl; //data.Name.toLowerCase().replace(/ /g,'-');

        this.setglobaldata("destinationinfo", data);

        if (this.config().brand == "viajeypunto") {
            if (this.config().adminid) {
                this.iframecontrol({ changedestination: true });
                return;
            }
        }

        this.searchEvents(data.label);
        this.gaDestinationEvent(data.label);

        if (pathname.search("tours/") != -1) {

              if(this.domainRedirect('destination')) return;
              
            //  alert(destinationname)

            //  if(locationid != -1) this.navigate("tours/"+destinationname+"/"+destinationid+"/"+locationid+"/1");
            // else
            if (locationid != -1)
                this.navigate(
                    "tours/" +
                        destinationname +
                        "/" +
                        destinationid +
                        "/" +
                        locationid +
                        "/1"
                );
            else
                this.navigate(
                    "tours/" + destinationname + "/" + destinationid + "/1"
                );
        } else {
            this.reloadPage('destination');
        }
    }

   

    changeLocation(event) {
        var locationid = event.target.value;

        this.resetlocationandfilters(locationid);
        this.setState({
            Id_Location: locationid,
        });
        window.location.reload(true);
    }

    findDestinationName() {
        var destination = this.getdefaultdestination();

        if (!destination) {
            //destination = this.state.destinations[1]  || {};
            // this.resetdestinationandfilters(destination.Id_Destination);
            return this.t("All Destinations");
        } else {
            this.setglobaldata("destinationinfo", destination); //guarda la informacion del destino seleccionado para su uso posterios
            return destination.Name;
        }
    }

    getdefaultdestination() {
        /*  this.state.Id_Location  = this.getglobaldata('Id_Location')
        this.state.Id_Destination  = this.getglobaldata('Id_Destination')
        this.state.Id_State  = this.getglobaldata('Id_State') */

        return this.state.destinations.find((destination) => {
            if (Number(destination.destinationid))
                return (
                    String(destination.Id_Destination) ===
                    String("area_" + this.state.Id_Location)
                );
            if (Number(destination.Id_Destination))
                return (
                    Number(destination.Id_Destination) ===
                    Number(this.state.Id_Destination)
                );
            if (String(destination.Id_Destination))
                return (
                    String(destination.Id_Destination) ===
                    String("state_" + this.state.Id_State)
                );
        });
    }

    setautocomplete() {
        let destinations = this.state.destinations;
        const allowedChars = this.state.allowedChars;

        autocomplete({
            input: document.getElementById("destinations_autocomplete"),
            minLength: 1,
            onSelect: (item, inputfield) => {

                this.changeDestination(item);
            },
            fetch: function (text, callback) {
                var match = this.normalizeText(text.toLowerCase());
                callback(
                    destinations.filter((destination) => {
                        var string_norm = this.normalizeText(destination.label);

                        return string_norm.toLowerCase().indexOf(match) !== -1;
                    })
                );
            }.bind(this),

            source: function (request, response) {
                var matcher = new RegExp(
                    $.ui.autocomplete.escapeRegex(request.term),
                    "i"
                );

               // console.log("matcher", matcher);

                response(
                    $.grep(destinations, function (value) {
                        return (
                            matcher.test(value.label) ||
                            matcher.test(this.normalizeText(value.label))
                        );
                    })
                );
            }.bind(this),

            render: function (item, value) {
                $(".autocomplete").remove();
                var itemElement = document.createElement("div");
                if (allowedChars.test(value)) {
                    var regex = new RegExp(value, "gi");
                    var inner = item.label.replace(regex, function (match) {
                        return (
                            "<span style='font-weight:bold'>" +
                            match +
                            "</span>"
                        );
                    });
                    itemElement.innerHTML = inner;
                } else {
                    itemElement.textContent = item.label;
                }
                return itemElement;
            },
            emptyMsg: this.t("No Destination found"),
            customize: function (input, inputRect, container, maxHeight) {


                //console.log('container',container)

              //  alert('entra')
                if (this.ismobile()) {
                   // container.style.position = 'absolute';
                  //  alert('entra2')
                   /* container.style.top = "";
                    container.style.bottom =
                        window.innerHeight -
                        inputRect.bottom +
                        input.offsetHeight +
                        "px";*/
                  /*  container.style.maxHeight = "140px";
                    container.style.left = "5px";
                    container.style.right = "5px";
                    container.style.width = "";*/
                } else if (maxHeight < 100) {
                   // alert('entra3')
                  /*  container.style.top = "";
                    container.style.bottom =
                        window.innerHeight -
                        inputRect.bottom +
                        input.offsetHeight +
                        "px";
                    container.style.maxHeight = "140px";*/
                }
            }.bind(this),
        });
    }

    writecity(event) {
       
        this.setState({ defaultdestinationname: event.target.value });
    }

    clean() {
        this.setState({ defaultdestinationname: "" });
    }

    /**
     * @function getGeolocation
     * @return location
     **/
    getGeolocation(callback) {
        try {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    // Esperando respuesta positiva para regresar el modal
                    (position) => {
                        // Obtener url google maps con coordenadas actuales
                        var url =
                            "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
                            position.coords.latitude +
                            "," +
                            position.coords.longitude +
                            "&key=AIzaSyByfY_3xfMs6KS1j7WT_obUx6YtjXAkZ1k";
                        // Obtener localización desde la url
                        var currentLocation = fetch(url)
                            .then((response) => response.json())
                            .then((data) => {
                                // Formato localización acftual para hacer match con base de datos
                                let geoloc = this.t(
                                    data.results[0].address_components[4]
                                        .long_name
                                );
                                // Guardando localización en variable state
                                this.state.currentGeolocation = geoloc;
                                // Regresanto localización como result
                                return geoloc;
                            })
                            .then((result) => {
                                // Enviando localización actual a modal
                                var openmodal = this.openconfirm(result);
                            });
                    },
                    // En caso de error
                    (error) => {
                        // Caso de haber negado el servicio previamente
                        if (error.code == error.PERMISSION_DENIED) {
                            if (this.state.b_shownotify) {
                                if (this.props.locationNotification) {
                                   /* console.log(
                                        "Geolocation is deny (locationNotification) " +
                                            this.props.locationNotification
                                    );*/
                                    this.shownotify();
                                }
                            }
                            // insertar logica para notificación de geolocalización desactivada
                        }
                    }
                );
            }
        } catch (error) {
           // console.log("error geolocation: " + error);
        } finally {
           // console.log("end try geolocation");
        }
    }

    /**
     * @function getdefaultdestinationbyname
     * @return destination
     **/
    getdefaultdestinationbyname() {
        return this.state.destinations.find((destination) => {
            if (String(destination.Id_Destination)) {
                this.setState({
                    //Id_Location: default_destination.id_location,
                });
                return (
                    this.getStringWithoutAccents(String(destination.state)) ===
                    this.getStringWithoutAccents(
                        String(this.state.currentGeolocation)
                    )
                );
            }
        });
    }

    /**
     * @function getdefaultdestinationbyname
     * @return string text_wo_accents
     **/
    getStringWithoutAccents(text) {
        var text_wo_accents = text
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "");
        return text_wo_accents;
    }

    /**
     * @function openconfirm
     **/
    openconfirm(geo_location) {
       // console.log("######## openconfirm ########");
       // console.log(geo_location);

        this.state.currentGeolocation = geo_location;
        var geoLable = document.getElementById("myLocationText");
        var isCurrentLocLikeDest = false;

        geoLable.innerHTML =
            "<span><strong> " +
            this.state.currentGeolocation +
            "</strong></span>";
        this.setglobaldata("currentGeolocation", this.state.currentGeolocation);
        isCurrentLocLikeDest =
            this.state.Id_State === this.state.Id_currentGeolocation;
        //console.log('Id_Destination [' + this.state.Id_State + '] Id_currentGeolocation [' + this.state.Id_currentGeolocation + ']');
       // console.log(isCurrentLocLikeDest);

        if (
            this.state.defaultdestinationname.length > 0 &&
            this.state.defaultdestinationname != this.t("Todos Los Destinos")
        ) {
           // console.log("defaultname: " + this.state.defaultdestinationname);
        } else if (this.state.Id_State === undefined) {
            //{|| !(isCurrentLocLikeDest)){ // Use to change on any distinc destiny
            $.magnificPopup.open({
                items: {
                    src: "#exitm",
                },
                type: "inline",
                fixedContentPos: true,
                fixedBgPos: true,
                overflowY: "auto",
                closeBtnInside: true,
                preloader: false,
                midClick: true,
                removalDelay: 300,
                mainClass: "my-mfp-zoom-in",
                callbacks: {
                    open: function () {},
                    close: function () {}.bind(this),
                },
            });
            this.geomodal = $.magnificPopup.instance;
        }
      //  console.log("######## openconfirm ########");
    }

    /**
     * @function closeconfirm
     **/
    closeconfirm() {
      //  console.log($("#rememberGeoCode").prop("checked"));
        let isChecked = $("#rememberGeoCode").prop("checked");
        this.setglobaldata("useLocationAsDestination", isChecked);

        this.geomodal.close();
    }

    /**
     * @function set
     **/
    setCurrentDestination() {
      //  console.log($("#rememberGeoCode").prop("checked"));

        let destination = this.getdefaultdestinationbyname();

      // console.log("####### setCurrentDestination ######");
        try {
          //  console.log(JSON.stringify(destination));

            if (destination === undefined) {
              //  console.log("Id_Destination undefined");
                this.closeconfirm();
            } else {
                this.state.Id_currentGeolocation = Number(
                    destination.Id_Destination.replace("state_", "")
                );
                this.setglobaldata(
                    "Id_currentGeolocation",
                    this.state.Id_currentGeolocation
                );

                this.changeDestination(destination);
            }
        } catch (error) {
            this.state.currentGeolocation = this.t("Todos Los Destinos");
            //destination = this.getdefaultdestinationbyname();

          //  console.log(JSON.stringify(destination));

            if (destination === undefined) {
               // console.log("error setCurrentDestination: " + error);
                this.closeconfirm();
            } else {
                this.state.Id_currentGeolocation = Number(
                    destination.Id_Destination.replace("state_", "")
                );
                this.setglobaldata(
                    "Id_currentGeolocation",
                    this.state.Id_currentGeolocation
                );

                this.changeDestination(destination);
                //console.log('error setCurrentDestination: ' + error);
            }
        } finally {
           // console.log("end try setCurrentDestination");
        }

       // console.log("####### setCurrentDestination ######");
    }

    /**
     * @function shownotify
     **/
    shownotify() {
        var text_deny = this.t("Geolocalización desactivada");
        var text_body = this.t(
            "El sistema de geolocalización esta desactivado, para activarlo sigue las instrucciones."
        );
        var text_button = this.t("Ver instrucciones");
        var url = "https://support.google.com/chrome/answer/142065?hl=en";
        var code =
            "$.magnificPopup.open({items:{src:'#geo_instructions'},type:'inline',fixedContentPos: true,fixedBgPos: true,overflowY: 'auto',closeBtnInside: true,preloader: false,midClick: true,removalDelay: 300,mainClass: 'my-mfp-zoom-in',callbacks:{open:function(){},close:function(){}.bind(this)}});";

        $(function () {
            setTimeout(function () {
                $.notify(
                    {
                        // options
                        icon: "icon_set_1_icon-56 color_demo",
                        title: "<h4>" + text_deny + "</h4> ",
                        message:
                            "<p>" +
                            text_body +
                            '</p> <div onClick="' +
                            code +
                            '" class="btn_1">' +
                            text_button +
                            "</div> ",
                    },
                    {
                        // settings
                        type: "info",
                        delay: 5000,
                        timer: 3000,
                        z_index: 9999,
                        showProgressbar: false,
                        placement: {
                            from: "bottom",
                            align: "right",
                        },
                        animate: {
                            enter: "animated bounceInUp",
                            exit: "animated bounceOutDown",
                        },
                    }
                );
            }, 1000); // change the start delay
        });
    }

    /**
     * @function openinstructions
     **/
    openinstructions() {
        $.magnificPopup.open({
            items: {
                src: "#generic_frame",
            },
            type: "inline",
            fixedContentPos: true,
            fixedBgPos: true,
            overflowY: "auto",
            closeBtnInside: true,
            preloader: false,
            midClick: true,
            removalDelay: 300,
            mainClass: "my-mfp-zoom-in",
            callbacks: {
                open: function () {},
                close: function () {}.bind(this),
            },
        });
        this.instructionmodal = $.magnificPopup.instance;
    }

    /**
     * @function closeconfirm
     **/
    closeinstructions() {
        this.instructionmodal.close();
    }


    render() {
        var changedestination = this.getglobaldata("changedestination");
        return (
            <div className="destination-selector-container">



                     


                {this.props.origin == "page" ? (
                    <h3 className="blue_text">
                        <strong>
                            {this.props.text}{" "}
                            {this.state.destinations !== null &&
                            this.state.Id_Destination !== null
                                ? this.findDestinationName()
                                : null}{" "}
                        </strong>{" "}
                        <a
                            href="#"
                            className="btn_changeloc"
                            onClick={this.chargeDestinations}
                        >
                            <i className="icon-location" />
                            {changedestination ? (
                                <strong>({this.t("CHANGE")})</strong>
                            ) : (
                                ""
                            )}
                        </a>
                        
                    </h3>
                ) : (
                    <a
                        href="#"
                        className="change_place_pop_"
                        onClick={this.chargeDestinations}
                    >
                        <i className="icon-location"></i>
                        <strong>
                            {this.state.destinations !== null &&
                            this.state.Id_Destination !== null ? (
                                <span>
                                    {this.findDestinationName()}{" "}
                                    {changedestination ? (
                                        <strong>({this.t("CHANGE")})</strong>
                                    ) : (
                                        ""
                                    )}
                                </span>
                            ) : null}
                        </strong>
                    </a>
                )}

<div id="askdestiny" className="zoom-anim-dialog mfp-hide">
        <div className="small-dialog-header ask_dialog_dest">
          <h3>{this.t("Destiny")}</h3>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <h4 className="destiny_question">{this.t("What destination do you want to visit?")}</h4>
          </div>
          
          <div className="col-lg-4 col-md-4 col-sm-6 col-12 mx-auto">
            <a href="#" onClick={this.gotours.bind(this, this.getDestination(2))} >
              <div className="card card-block d-flex border-0">

              <img
             src={this.image(
             "img/ambassador/q3_vallarta.jpg"
              )}
             className="card-img card_ask_image"
           />


                <div className="card-overlay" />
                <div className="card-body align-items-center d-flex justify-content-center card-img-overlay border-top-0">
                  <h5 className="askcard_title">Vallarta</h5>
                </div>
              </div>
            </a>
          </div>
          
          
          {this.config().brand != 'pricetravel' && this.config().brand != 'marivaldistinct' && this.config().brand != 'marivalemotions' && this.config().brand != 'marivalarmony'?    
          <div className="col-lg-4 col-md-4 col-sm-6 col-12 mx-auto">
            <a href="#" onClick={(e)=>e.preventDefault()}>
              <div className="card card-block d-flex border-0 not_available_t">

                <img
             src={this.image(
             "img/ambassador/q1_cancun.jpg"
              )}
             className="card-img card_ask_image"
           />

            <div className="card-overlay" />
                <div className="card-body align-items-center d-flex justify-content-center card-img-overlay border-top-0">
                  <h5 className="askcard_title">{this.t("Cancunp")}</h5>
                </div>
              </div>
            </a>
            <h6 class="text-center commingSoon"><strong>{this.t("Coming Soon")}</strong></h6>
          </div>
          :''}

          {this.config().brand != 'marivaldistinct' && this.config().brand != 'marivalemotions' && this.config().brand != 'marivalarmony'?    
          <div className="col-lg-4 col-md-4 col-sm-6 col-12 mx-auto">
            <a href="#" onClick={this.gotours.bind(this, this.getDestination(5))} >
              <div className="card card-block d-flex border-0 ">

              <img
             src={this.image(
             "img/ambassador/q2_cabos.jpg"
              )}
             className="card-img card_ask_image"
           />

                <div className="card-overlay" />
                <div className="card-body align-items-center d-flex justify-content-center card-img-overlay border-top-0">
                  <h5 className="askcard_title">Cabos</h5>
                </div>
              </div>
            </a>
            
          </div>
          :''}
          

        </div>
      </div>

                <div id="change_place" className="zoom-anim-dialog mfp-hide">
                    <div className="small-dialog-header">
                        <h3>{this.t("Customize your experience")}</h3>
                    </div>

                    <div className="sign-in-wrapper">
                        <div className="form-group">
                            <label>{this.t("Choose a destination")}</label>
                            {this.state.destinations !== null &&
                            this.state.Id_Location !== null ? (
                                <div className="input-group">
                                    <input
                                        onClick={this.select.bind(this)}
                                        value={
                                            this.state.defaultdestinationname
                                        }

                                        onChange={this.writecity.bind(this)}
                                        id="destinations_autocomplete"
                                        type="text"
                                        placeholder={this.t("Type your City")}
                                        autoComplete="off"
                                        className="form-control input_tour"
                                    />
                                    <div className="input-group-append">
                                        <button
                                            className="btn btn-outline-secondary btn_clearb btn_search_h"
                                            type="button"
                                        >
                                            <span className="icon-search form-control-feedback"></span>
                                        </button>
                                    </div>
                                    {/* <div className='input-group-append inp_appet'>
                          <button className="btn btn-outline-secondary btn_clearb" type="button"><i className="icon-cancel" onClick={this.clean.bind(this)}></i></button>
                          </div> */}
                                </div>
                            ) : (
                                <div>{this.spinner("Loading")}</div>
                            )}
                        </div>
                    </div>
                </div>

                <div id="exitm" className="zoom-anim-dialog mfp-hide">
                    <div className="small-dialog-header">
                        <h3>Close</h3>
                    </div>
                    <div className="tour_container">
                        <div className="tour_title">
                            <span>
                                <strong>
                                    {this.t(
                                        "¿Desea utilizar su ubicacion actual como destino?"
                                    )}
                                </strong>
                            </span>
                            <p id="myLocationText"></p>
                            <input type="checkbox" id="rememberGeoCode" />{" "}
                            <strong>{this.t("Recordar Selección")}</strong>{" "}
                            <br></br>
                        </div>
                        <div className="tour_title">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="tour_rates">
                                        <div
                                            className="btn add_cartbtn"
                                            to="/checkout"
                                            onClick={this.setCurrentDestination.bind(
                                                this
                                            )}
                                        >
                                            {this.t("Yes")}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="tour_rates">
                                        <div
                                            className="btn close_se_n"
                                            onClick={this.closeconfirm.bind(
                                                this
                                            )}
                                        >
                                            {this.t("No")}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    id="geo_instructions"
                    className="zoom-anim-dialog mfp-hide"
                >
                    <div className="small-dialog-header">
                        <h3>{this.t("Location")}</h3>
                    </div>
                    <div className="tour_container">
                        <div className="tour_title">
                            <h3>
                                <strong>
                                    {this.t("Let a site know your location")}
                                </strong>
                            </h3>

                            <p>
                                {this.t(
                                    "By default, Chrome asks you when a site wants to see your location. To let the site know where you are, choose Allow. Before sharing your location, review the site's privacy policy."
                                )}
                            </p>
                            <p>
                                {this.t(
                                    "If you use Google as your default search engine on your phone, your location is used by default for your searches on Google."
                                )}
                            </p>
                        </div>
                        <ul className="nav nav-tabs" role="tablist">
                            <li className="nav-item">
                                <a
                                    className="nav-link active border_l border_ho"
                                    data-toggle="tab"
                                    href="#geo_menu1"
                                >
                                    {this.t("Computer")}
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link border_l"
                                    data-toggle="tab"
                                    href="#geo_menu2"
                                >
                                    {this.t("Android")}
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link border_l"
                                    data-toggle="tab"
                                    href="#geo_menu3"
                                >
                                    {this.t("iPhone & iPad")}
                                </a>
                            </li>
                        </ul>
                        <div className="tab-content">
                            <div
                                id="geo_menu1"
                                className="container tab-pane active"
                            >
                                <p className="blue_text">
                                    <strong>
                                        {this.t(
                                            "Change your default location settings"
                                        )}
                                    </strong>
                                </p>
                                <ol>
                                    <li>
                                        {this.t(
                                            "On your computer, open Chrome."
                                        )}
                                    </li>
                                    <li>
                                        {this.t(
                                            "At the top right, click More "
                                        )}
                                        <img
                                            src={this.image(
                                                "img/svg/points1.svg"
                                            )}
                                            width="18"
                                            height="18"
                                            alt="More"
                                            title="More"
                                        />
                                        <img
                                            src={this.image(
                                                "img/arrow_right.png"
                                            )}
                                            width="auto"
                                            height="18"
                                            alt="and then"
                                            title="and then"
                                        />
                                        <strong>{this.t(" Settings.")}</strong>
                                    </li>
                                    <li>
                                        {this.t("At the bottom, click")}
                                        <strong>{this.t(" Advanced.")}</strong>
                                    </li>
                                    <li>
                                        {this.t(
                                            'Under "Privacy and security," click '
                                        )}
                                        <strong>
                                            {this.t("Site settings.")}
                                        </strong>
                                    </li>
                                    <li>
                                        {this.t("Click ")}
                                        <strong>{this.t("Location.")}</strong>
                                    </li>
                                    <li>
                                        {this.t("Turn ")}
                                        <strong>
                                            {this.t("Ask before accessing ")}
                                        </strong>
                                        {this.t("on or off.")}
                                    </li>
                                </ol>
                            </div>
                            <div
                                id="geo_menu2"
                                className="container tab-pane fade"
                            >
                                <p className="blue_text">
                                    {" "}
                                    <strong>
                                        {this.t(
                                            "Change your default location settings"
                                        )}
                                    </strong>
                                </p>
                                <ol>
                                    <li>
                                        {this.t(
                                            "On your Android phone or tablet, open the Chrome app "
                                        )}
                                        <img
                                            src={this.image("img/chrome_1.png")}
                                            width="18"
                                            height="18"
                                            alt="Chrome"
                                            title="Chrome"
                                        />
                                    </li>
                                    <li>
                                        {this.t(
                                            "To the right of the address bar, tap More "
                                        )}
                                        <img
                                            src={this.image(
                                                "img/svg/points1.svg"
                                            )}
                                            width="18"
                                            height="18"
                                            alt="More"
                                            title="More"
                                        />
                                        <img
                                            src={this.image(
                                                "img/arrow_right.png"
                                            )}
                                            width="auto"
                                            height="18"
                                            alt="and then"
                                            title="and then"
                                        />
                                        <strong>{this.t(" Settings")}</strong>
                                    </li>
                                    <li>
                                        {this.t("Tap ")}
                                        <strong>
                                            {this.t("Site settings ")}
                                        </strong>
                                        <img
                                            src={this.image(
                                                "img/arrow_right.png"
                                            )}
                                            width="auto"
                                            height="18"
                                            alt="and then"
                                            title="and then"
                                        />
                                        <strong>{this.t(" Location.")}</strong>
                                    </li>
                                    <li>
                                        {this.t("Turn ")}
                                        <strong>{this.t("Location ")}</strong>
                                        {this.t(" on or off.")}
                                    </li>
                                </ol>
                            </div>
                            <div
                                id="geo_menu3"
                                className="container tab-pane fade"
                            >
                                <p className="blue_text">
                                    <strong>
                                        {this.t(
                                            "Change your default location settings"
                                        )}
                                    </strong>
                                </p>
                                <ol>
                                    <li>
                                        {this.t(
                                            "On your iPhone or iPad, open the settings app."
                                        )}
                                    </li>
                                    <li>
                                        {this.t("Find and tap ")}
                                        <strong>{this.t("Chrome.")}</strong>
                                    </li>
                                    <li>
                                        {this.t("Tap ")}
                                        <strong>{this.t("Location")}</strong>
                                    </li>
                                    <li>
                                        {this.t(
                                            "Choose the option you want as your default setting."
                                        )}
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    <button
                        title="Close (Esc)"
                        type="button"
                        className="mfp-close"
                    ></button>
                </div>
            </div>
        );
    }
}

export default withRouter(DestinationSelector);
