import React, { Component } from 'react';
import { NavLink, withRouter } from "react-router-dom";
import Global from '../globals/global';
import NotificationsAlertsCard from './component.notifications.alerts.card';
const $ = window.$;

class NotificationsAlerts extends Global {

    constructor(props) {
        super(props);

        this.handleNotificationsAlertsCard = this.handleNotificationsAlertsCard.bind(this);
        this.getNotifications = this.getNotifications.bind(this);
        this.deleteNotifications = this.deleteNotifications.bind(this);
        this.deleteConfirmationYes = this.deleteConfirmationYes.bind(this);
        this.confirmationNo = this.confirmationNo.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.selectAll = this.selectAll.bind(this);
        this.totalToDelete = this.totalToDelete.bind(this);

        this.state = {
            user: null,
            data: null,
            loaded: null,
            total: null,
            updated: null,
            deleted: null,
            errors: null,
            select_active: null,
        };
    }

    componentDidMount() {
        this.setState({
            user: this.getglobaldata('user'),
            errors: [],
            loaded: false,
        });
        window.addEventListener('action.delete', this.handleDelete);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.user !== this.state.user) {
            this.getNotifications();
        }
        if (prevState.select_active !== this.state.select_active) {
            if (this.state.select_active) {
                let data = this.state.data.map(obj => obj);
                data = data.map(obj => {
                    obj.checked = true;
                    var customEvent = new CustomEvent(`action.check.${obj.Id_Notification}`, { detail: true });
                    window.dispatchEvent(customEvent);
                    return obj;
                });
                this.setState({
                    data: data,
                    loaded: true,
                    select_active: false,
                });
            }
        }
    }

    getNotifications() {
        this.setState({ data: [] });
        let options = {
            arraydata: 'data',
            method: 'GetUserNotification',
            query: {
                UserAccessToken: this.state.user.UserAccessToken,
                DeviceAccessToken: this.state.user.DeviceAccessToken,
            },
            callback: function (data, result) {
                data = data.map(obj => {
                    obj.checked = false;
                    return obj;
                });
                this.setState({
                    data: data,
                    loaded: true,
                    total: result.data_count,
                });
                let options = {
                    arraydata: 'updated',
                    method: 'UpdateUserNotification',
                    query: {
                        UserAccessToken: this.state.user.UserAccessToken,
                        DeviceAccessToken: this.state.user.DeviceAccessToken,
                    },
                    callback: function (data, result) {
                        console.log('result', result)
                    }.bind(this),
                    error: function (error) {
                        console.log('error', error);
                    }.bind(this)
                };
                this.all(options);
            }.bind(this)
        };
        this.all(options);
    }

    handleNotificationsAlertsCard(Id_Notification, checked) {
        let data = this.state.data.map(obj => {
            if (obj.Id_Notification === Id_Notification) {
                obj.checked = checked;
            }
            return obj;
        });
        this.setState({
            data: data,
            errors: [],
        });
    }

    deleteNotifications() {
        if (this.totalToDelete() > 0) {
            $.magnificPopup.open({
                items: {
                    src: '#notif_m'
                },
                type: 'inline',
                fixedContentPos: true,
                fixedBgPos: true,
                overflowY: 'auto',
                closeBtnInside: true,
                preloader: false,
                midClick: true,
                removalDelay: 300,
                mainClass: 'my-mfp-zoom-in',
            });
        }
    }

    deleteConfirmationYes() {
        let errors = [];
        let selected = this.state.data.filter(data => {
            return data.checked === true;
        });
        if (selected.length > 0) {
            let ids = [];
            selected.forEach(data => {
                ids.push(data.Id_Notification);
            });
            let options = {
                arraydata: 'deleted',
                method: 'DeleteUserNotification',
                query: {
                    Id_Notification: ids,
                    UserAccessToken: this.state.user.UserAccessToken,
                    DeviceAccessToken: this.state.user.DeviceAccessToken,
                },
                callback: function (data, result) {
                    if (result.result) {
                        var customEvent = new CustomEvent('action.delete', {});
                        window.dispatchEvent(customEvent);
                    }
                }.bind(this),
                error: function (error) {
                    let errors = [];
                    errors.push(error);
                    this.setState({
                        errors: errors,
                    });
                }.bind(this)
            };
            this.all(options);
        } else {
            errors.push(this.t('There are no changes to delete!'));
        }
        this.setState({
            errors: errors,
        });
        $.magnificPopup.close();
    }

    confirmationNo() {
        $.magnificPopup.close();
    }

    handleDelete() {
        this.getNotifications();
    }

    selectAll() {
        this.setState({
            loaded: false,
            errors: [],
            select_active: true,
        });
    }

    totalToDelete() {
        let temporal = this.state.data.filter(data => data.checked);
        return temporal.length;
    }

    render() {
        return (
            this.state.data !== null ? (
                <div>
                    <div className="row">
                        <div className="col-lg-12 purchasetitle">
                            <h3>{this.t('Notifications settings')}</h3>
                            <div className="numbs_notif">
                                <span className="numb_b">0</span>
                                <span className="numb_a">{this.state.total}</span>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="btn_selenotif">
                                <a onClick={this.selectAll} className="btn btn_selecg">{this.t('Select All')}</a>
                                <a onClick={this.deleteNotifications} className="btn btn_selecdis notifdel">{this.t('Dismiss Selected')}</a>
                            </div>
                        </div>
                    </div>
                    {
                        (this.state.errors !== null && this.state.errors.length > 0) ? (
                            <div className="errors">
                                {
                                    this.state.errors.map((error, index) => {
                                        return (
                                            <div key={`icon-exclamation-${index}`}>
                                                <i className="icon-exclamation"></i> <span>{error}</span><br />
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        ) : null
                    }
                    {
                        this.state.loaded ? (
                            this.state.data.map((notification, index) => {
                                return (
                                    <NotificationsAlertsCard key={`NotificationsAlertsCard-${index}`} data={notification}
                                        handle={this.handleNotificationsAlertsCard} />
                                )
                            })
                        ) : <div>{this.t('Loading...')}</div>
                    }
                    <div id="notif_m" className="zoom-anim-dialog mfp-hide">
                        <div className="small-dialog-header">
                            <h3>{this.t('Delete Notifications')}</h3>
                        </div>
                        <div className="tour_container">
                            <div className="tour_title">
                                <span>
                                    <strong>
                                        <i className="icon-warning color_yell"></i>
                                        {`${this.t('Are you sure you want to delete these')} ${this.totalToDelete()} ${this.t('message(s)? Once deleted, you will not be able to retrieve them again!')}`}
                                    </strong>
                                </span>
                            </div>

                            <div className="tour_title">

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="tour_rates">
                                            <a onClick={this.deleteConfirmationYes} className="btn add_cartbtn">{this.t('Yes, delete')}</a>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="tour_rates">
                                            <a onClick={this.confirmationNo} className="btn trans_btnc">{this.t('No, cancel')}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null
        )
    }
}

export default withRouter(NotificationsAlerts)