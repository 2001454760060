import React, { Component } from 'react';
import { NavLink,withRouter } from "react-router-dom";
import queryString from 'query-string';
import Global from '../globals/global';

//if (typeof window === "undefined")  var window={}

const $ = window.$;
const location = window.location;

class mitendpoint extends Global {

    constructor(props) {
        super(props);

          
          var result = false;

         if (this.props.match) {
      if (this.props.match.params) {

          if (this.props.match.params.result) result = this.props.match.params.result;
          
        
      }
    }

        this.state = {
           result:result
        };
    }

   

    componentDidMount() {

        var query = queryString.parse(location.search);

       // if(this.state.result == 'success')  
          window.parent.postMessage(query, '*');

        if(window.webkit) window.webkit.messageHandlers.iosHndler.postMessage(query);


       if(this.state.result != 'success'){

           //google analytics event
        this.ga().event({
         category: 'Checkout',
         action: 'Payment declined',
         label: 'Payment declined'
        });

        }



    }

    componentDidUpdate(prevProps, prevState) {
       
    }

  
    render() {
      var query = queryString.parse(location.search);
     
      return (
        <main>
         
         <div className="clearfix" />
        <div className="container m-t-10">

{this.state.result == 'pending'?
<div className="row">
        <div className="col-md-7 mx-auto icon_approved">
          <i className="icon-ok-circled approved_i" />
          <h3>{this.t('Pendiente de aprobación')}</h3>
          
        </div>
      </div>:''}


{this.state.result == 'success'?
 <div className="row">
        <div className="col-md-7 mx-auto icon_approved">
          <i className="icon-ok-circled approved_i" />
          <h3>{query.nbResponse?this.t(query.nbResponse):''}</h3>

          
        </div>
      </div>:''}


               {this.state.result == 'error'?    <div className="row">
                        <div className="col-md-7 mx-auto icon_approved">
                  
                   
                            <i className="icon-cancel-circled cancel_i"></i>
                            <h3>{query.nbResponse?this.t(query.nbResponse):''}</h3>
                             <p>{query.cdResponse?this.t(query.cdResponse):''}</p>
                             <p>{query.nb_error?this.t(query.nb_error):''}</p>

                           
                            <div className="table-responsive">
                                        <table className="table table-striped table_approved">

                                            <thead>
                                                <tr className="agent_tabl">
                                                    <th>{this.t('Amount')}</th>
                                                    <th>{this.t('Currency')}</th>
                                                    <th>{this.t('Reference')}</th>
                                                 </tr>
                                            </thead>
                                        
                                           <tbody>
                                                <tr className="info_acc">
                                                    <td>{query.importe?query.importe:''}</td>
                                                    <td>{query.nbMoneda?query.nbMoneda:''}</td>
                                                    <td>{query.referencia?query.referencia:''}</td>
                                                </tr>
                                           </tbody>
                                        </table>

                                    </div>

                            <p>
                            <a href={this.config().apiurl+"doMITPayment?params[reference]="+query.referencia}>{this.t('Ingresa otro método de pago')}   <i className="icon-credit-card credit_i"></i></a>
                             </p>

                             <p>
                             {this.t("o si necesitas ayuda comunicate al {{phone}}", {phone:this.config().phone})}
                             </p>
                        </div>

                    </div>:''}


        </div>
       
        </main>

        );

        return null;
    }
}

export default mitendpoint