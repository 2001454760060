import React, { Component } from "react";

export default (props) => {

  const onClick = (e)=>{
    e.preventDefault()

    if(props.loading) return;

    props.onClick()

  }

  var className = "btn btn-primary btn-block py-2";
  
  if(props.className) className = props.className;

  return (
  

  <a href="#" className={`${className} ${props.loading?'disabled':''}`}   onClick={onClick}>
            {props.loading?
              <i className="">{props.loadingText}</i>
              :
              <b className="">{props.text}</b>
            }
          </a>

  );
};
