import React, { Component } from 'react';
import { NavLink, withRouter } from "react-router-dom";
import Global from '../globals/global';

class totouraterms extends Global {

    render() {
        if(this.getglobaldata('Id_Language') == 2)
   return ( 
 
    <div className="row">
       <div className="col-lg-12">

       <p>
    Usted al ingresar a nuestra página web y/o plataformas disponibles a través
    de la dirección “<u>toorzy.com</u>” y/o aplicaciones móviles acepta y
    conviene en obligarse a los términos y condiciones que se establecen a
    continuación, mismos que rigen el uso de la misma, así como del
    ofrecimiento, venta o concesión del uso o disfrute de bienes o servicios, y
según estos sean modificados de tiempo en tiempo.    

<strong>
    Motivo por el cual todo uso, adquisición o concesión de bienes o servicios,
    creación de usuarios y/o accesos, descargas de contenidos y/o aplicaciones
    a través de esta página web y/o plataformas disponibles a través de la
    dirección “<u>toorzy.com</u>” y/o aplicaciones móviles acepta constituye la
    aceptación de estos términos y condiciones, el uso de medios electrónicos,
    así como sus funcionalidades, que Mobile Sail Tecnologies, S.A. de C.V.,
    como operadora de dicha página, con domicilio en Paseo Cocoteros S/N,
    interior local 170, Hotel Marival Emotions Resort &amp; Suites, Nuevo
    Vallarta, Nayarit, México, Código Postal 63732 y con Registro Federal de
    Contribuyentes OER070622BKA, ofrece (a la que en lo sucesivo se le
    denominará “TOORZY”).
</strong>


</p>

<p>
    <strong></strong>
</p>

<p>
    <strong>
        Esta página web y/o plataformas disponibles a través de la dirección
        “toorzy.com” y/o aplicaciones móviles, su contenido, características y
        funcionalidad son una propiedad de o software patentado con licencia
        para TOORZY y están protegidos por derechos de autor internacionales,
        marcas registradas, patentes, secretos comerciales y demás leyes de
        propiedad intelectual o de derechos de propiedad.
    </strong>
    <strong></strong>
</p>
<p>
    <strong></strong>
</p>
<p>
    <strong>
        Esta página web, plataformas disponibles a través de la dirección
        “toorzy.com”, aplicaciones móviles y sus componentes se ofrecen
        únicamente con fines informativos; por lo que TOORZY no se hace
        responsable de la exactitud, utilidad o disponibilidad de cualquier
        información que se transmita o ponga a disposición a través de la
        misma; TOORZY no será responsable por cualquier error u omisión en
        dicha información. Asimismo, TOORZY no se hace responsable en caso de
        incidencias o fallas técnicas derivadas de caso fortuito o fuerza
        mayor.
    </strong>
    <strong></strong>
</p>
<p>
    <strong></strong>
</p>
<p>
    <strong>
        En caso que no desee aceptar ni obligarse a cumplir con los términos y
        condiciones siguientes, ni a aceptar con las funcionalidades, esquemas
        de comisión y demás aspectos que TOORZY ofrece a través de su página
        web y/o plataformas disponibles a través de la dirección “toorzy.com”
        y/o aplicaciones móviles y sus componentes, por favor no lo utilice.
    </strong>
    <strong></strong>
</p>
<p>
    <strong></strong>
</p>
<p>
    <strong>
        Lea cuidadosa y detenidamente estos Términos y Condiciones para
        asegurarse de que los comprende antes de hacer una reservación y/o
        crear algún acceso y/o usuario y/o realizar una compra.
    </strong>
    <strong></strong>
</p>
<p>
    Toda persona física o moral (en adelante, el “Usuario”) que acceda, visite,
    se registrarse en nuestra página web y/o plataformas disponibles a través
    de la dirección “<u>toorzy.com</u>” y/o descargue aplicaciones móviles
    (todas ellas en su conjunto en adelante, las “Plataformas Electrónicas”)
    acepta, declara y garantiza que es mayor de edad; es decir tiene cumplidos
    18 años de edad, y la capacidad legal suficiente para adquirir o contratar
    por su propio derecho o en nombre y representación de la persona moral que
    el Usuario representa, así como para realizar los cargos a través de
    tarjeta de crédito y/o débito. Los padres y tutores son responsables de las
    compras o reservas en línea que realicen los niños bajo su responsabilidad,
    con o sin su aprobación previa.
</p>
<p>
    <strong> </strong>
</p>
<p className="blue_text">
    <strong>LICENCIA.</strong>
</p>
<p>
    TOORZY otorga al Cliente una licencia limitada, no exclusiva, no perpetua,
    revocable para usar las Plataformas Electrónicas para hacer una reservación
y/o crear algún acceso y/o usuario y/o realizar una compra,    <strong> </strong>ya sea navegando en la página web www.toorzy.com y/o
    descargando la aplicación Mobile Concierge (la "Aplicación") al dispositivo
    de comunicación inalámbrico en el que está instalado inicialmente y/o a
    través del Sistema Toorzy. Todos los derechos no otorgados expresamente
    están reservados por TOORZY o su licenciante de software. Esta licencia no
    permite que el Usuario utilice la Aplicación en ningún otro dispositivo de
    comunicación inalámbrico que no sea en el que se instaló inicialmente, sin
    el consentimiento previo de TOORZY, y tampoco podrá distribuir o hacer que
    la Aplicación esté disponible a través de una red donde pueda ser utilizada
    por múltiples dispositivos al mismo tiempo. El Usuario no puede alquilar,
    arrendar, prestar, vender, redistribuir o sublicenciar la Aplicación ni su
    acceso al Sistema Toorzy. El Usuario no puede copiar, descompilar, realizar
    ingeniería inversa, desensamblar, intentar derivar el código fuente,
    modificar o crear trabajos derivados de las Plataformas Electrónicas,
    ninguna actualización o cualquier parte del mismo, salvo prohibición
    expresa en la ley aplicable a la materia. Cualquier intento de llevar a
    cabo cualquier acción de las antes mencionadas, se considerará una
    violación de los derechos de TOORZY y sus licenciantes. Los términos de
    esta licencia regirán cualquier actualización provista por TOORZY que
    reemplace y/o complemente las Plataformas Electrónicas originales, a menos
    que dicha actualización vaya acompañada de una licencia separada, en cuyo
    caso los términos de esa licencia regirán.<strong></strong>
</p>
<p className="blue_text">
    <strong>CONSENTIMIENTO PARA USO DE DATOS.</strong>
</p>
<p>
    Para utilizar las Plataformas Electrónicas, se le puede solicitar al
    Usuario que se registre para crear una cuenta y se le puede solicitar que
    proporcione ciertos datos personales, que incluyen, entre otros, nombre
    completo, dirección de correo electrónico, el destino del viaje, el hotel,
    las fechas de viaje y la línea aérea de salida, número de vuelo, edad de
    sus acompañantes, incluyendo menores de edad e información de contacto.
    TOORZY recopilará y utilizará esta información con el fin de configurar la
    contraseña y el nombre de usuario seleccionados del Usuario, mantener y
    administrar el acceso a la cuenta de la Aplicación y/o del Sistema Toorzy
    del Usuario y procesar las ventas de excursiones en línea realizadas por el
    Usuario o procesar y operar con proveedores terceros la prestación de
    servicios o bienes, que se muestran o al Usuario a través de la Aplicación
y/o del catálogo de la página web    <a href="http://www.toorzy.com">www.toorzy.com</a> y/o en el Sistema
    Toorzy. TOORZY también puede utilizar la información personal del Usuario
    para enviarle un correo electrónico u otra comunicación sobre productos y/o
    servicios que TOORZY cree que pueden ser de interés del Usuario. El Usuario
    solicitar que deje de recibir esta última información enviando un correo
electrónico a la siguiente dirección:    <a href="mailto:info@toorzy.com">info@toorzy.com</a>.
</p>
<p>
    TOORZY puede divulgar la información personal del Usuario según lo exija la
    ley o a terceros que procesen la información personal en nombre de TOORZY.
    Algunos de estos proveedores de servicios pueden estar ubicados en
    jurisdicciones extranjeras y la información personal del Usuario puede ser
    recopilada, utilizada, divulgada, almacenada o procesada en dichas
    jurisdicciones extranjeras y luego estará sujeta a los requisitos legales
    de esas jurisdicciones, incluidos los requisitos legales para divulgar
    información personal a las autoridades gubernamentales en esas
    jurisdicciones.
</p>
<p>
    El Usuario consiente y autoriza específicamente a TOORZY a utilizar toda la
    información que le proporcione al crear un acceso y/o usuario para la
    Aplicación y/o el Sistema Toorzy de acuerdo con estos Términos y
    Condiciones. Además, el Usuario acepta y declara que proporcionará
    información precisa, actual y veraz. El Usuario reconoce y acepta que el
    Usuario es responsable de todas las actividades que se realicen a través de
    sus accesos y/o usuarios, por lo que se obliga a mantener la
    confidencialidad de su contraseña y cualquier otra información de la
    Aplicación y/o sistema Toorzy; es decirla, a hacer un uso de manera
    personal e intransferible. Si el Usuario sospecha que alguien está
    accediendo o usando su cuenta sin su permiso u autorización deberá
    comunicarse de manera inmediata con Toorzy enviando un correo electrónico a
la siguiente dirección:    <a href="mailto:info@toorzy.com">info@toorzy.com</a>, o sí necesita cambiar
    su contraseña o información de cuenta. En caso contrario, cualquier uso no
    autorizado y no reconocido por el Usuario será vinculante.
</p>
<p>
    Para hacer una reservación y/o compra a través de las Plataformas
    Electrónicas, se le podrá solicitar al Usuario información adicional,
    incluida la información de cuentas bancarias y de facturación. Esta
    información puede ser recopilada y utilizada por TOORZY o por proveedor del
    servicio, por lo que el Usuario acepta que la recopilación, el uso y la
    divulgación de esta información personal también estará sujeta a los
    términos y condiciones del acuerdo que rija dicho servicio.
</p>
<p>
    TOORZY puede recopilar y utilizar periódicamente datos técnicos e
    información relacionada, que incluye, entre otros: información técnica
    sobre el dispositivo de comunicación inalámbrico, el Sistema Toorzy, el
    software de la Aplicación y los periféricos recopilados para facilitar el
    suministro de actualizaciones de software, soporte de productos y otros
    servicios para el Usuario relacionados con las Plataformas Electrónicas.
    TOORZY puede utilizar esta información para otros fines, incluyendo, de
    manera enunciativa mas no limitativamente, para mejorar sus productos o
    para proporcionar servicios o tecnologías adicionales al Usuario, siempre
    de modo que la información del Usuario no lo identifique personalmente.
</p>
<p>
Para conocer mayor información sobre los términos y condiciones en que serán tratados sus datos personales, como los terceros con quienes compartimos su información personal y la forma en que podrá ejercer sus derechos ARCO, puede consultar el aviso de privacidad integral en <a href="https://toorzy.com/privacy">https://toorzy.com/privacy</a>  o contactarnos a través de <a href="mailto:privacidad@marivalgroup.com">privacidad@marivalgroup.com</a>
</p>
<p>
    <strong> </strong>
</p>
<p className="blue_text">
    <strong>PAQUETES DE EXPERIENCIAS.</strong>
</p>
<p>
    La adquisición y/o reserva de uno o más de los paquetes ofrecidos por
    TOORZY al Usuario en relación con tours, excursiones o actividades
    recreativas (en lo sucesivo referidas en su conjunto como “Experiencias”),
    constituye un vínculo contractual entre ambas partes, por lo que constituye
    prueba plena por parte del Usuario de que ha aceptado los Términos y
Condiciones de las Plataformas Electrónicas de TOORZY.    <strong><em></em></strong>
</p>
<p className="blue_text">
    <strong>INCLUSIONES Y EXCLUSIONES.</strong>
</p>
<p>
    A menos que se especifique lo contrario, el precio base de una Experiencia
    adquirida por el Usuario incluirá la excursión local seleccionada, el
    transporte en el sitio junto con otras inclusiones, de ser el caso, y según
    se encuentren especificadas en la descripción del producto seleccionado en
    las Plataformas Electrónicas.
    <strong>
        El Usuario acepta y reconoce que el precio base de una Experiencia, no
        incluye: gastos personales, comidas y bebidas, seguros, impuestos y
        cargos por servicios u otros servicios no especificados en el momento
        de la reserva.
    </strong>
</p>
<p className="blue_text">
    <strong>DESCRIPCIONES DE LAS EXPERIENCIAS.</strong>
</p>
<p>
    TOORZY ha realizado todos los esfuerzos necesarios para garantizar que, en
    el momento de la publicación, todas las descripciones de los productos en
    cualquiera de las Plataformas Electrónicas estén actualizadas. Por diversas
    razones fuera del control de TOORZY, pueden llegar a existir modificaciones
    que pueden afectar las descripciones de las Experiencias, como, de manera
    enunciativa, mas no limitativa, caso fortuito o fuerza mayor. TOORZY hará
    sus mejores esfuerzos razonables para mantener actualizada la información
    de las Plataformas Electrónicas al recibir una notificación sobre algún
    cambio. Es responsabilidad del Usuario consultar las Plataformas
    Electrónicas para estar al tanto de cualquier modificación.
</p>
<p className="blue_text">
    <strong>PRECIOS INDICADOS.</strong>
</p>
<p>
    Los precios se muestran en pesos mexicanos, moneda de curso legal en los
    Estados Unidos Mexicanos (MXN) o en dólares, moneda de curso legal en los
    Estados Unidos de Norteamérica (US$), según la moneda seleccionada por el
    Usuario. Los precios confirmados en el momento de la adquisición y/o
    reserva son los precios que se cobrarán al Usuario y sustituyen cualquier
    precio anunciado o indicado antes de la confirmación de la adquisición o
    reserva.
    <strong>
        Asimismo, el Usuario acepta y reconoce que los precios están sujetos a
        cambios adicionales en cualquier momento, sin previo aviso, hasta que
        el Usuario haya pagado la reserva en su totalidad.
    </strong>
</p>
<p>
    No obstante lo anterior, las obligaciones de pago asumidas por el Usuario
    en dólares, moneda de curso legal en los Estados Unidos de Norteamérica
    (US$), contraídas dentro o fuera de los Estados Unidos Mexicanos para ser
    cumplidas en este, se solventarán entregando el equivalente en moneda
    nacional, al tipo de cambio que rija en el lugar y fecha en que se haga el
    pago. Este tipo de cambio se determinará conforme a las disposiciones que
    para esos efectos expida el Banco de México o de acuerdo con los términos
    del contrato que rija el uso de la tarjeta de crédito o débito que utilice
    el Usuario para hacer su pago.
</p>
<p className="blue_text">
    <strong>PAGO.</strong>
</p>
<p>
    A efecto de garantizar la disponibilidad de las Experiencias, el Usuario
    deberá liquidar la totalidad del precio a la brevedad posible una vez que
    haya hecho la reservación y deberá pagar el total del precio de las
    Experiencias antes de que las mismas tengan lugar.
</p>
<p>
    <strong> </strong>
</p>
<p className="blue_text">
    <strong>CONFIRMACIÓN DE UNA EXPERIENCIA.</strong>
</p>
<p>
    TOORZY emitirá un boleto electrónico o un cupón a confirmar la adquisición
    de una Experiencia. El Usuario deberá imprimir el/los boleto(s) o deberá
    presentar el cupón/cupones electrónico(s) para realizar su registro en el
    lugar en que vaya a tener lugar la Experiencia. Po lo que es
    responsabilidad del Usuario asegurarse de que la información que aparece en
    el boleto electrónico, el cupón y cualquier otro documento de su
    Experiencia sea de conformidad lo que quiso adquirir el Usuario.
</p>
<p>
    También se le podrá solicitar al Usuario al momento de su registro que
    proporcione su número de confirmación junto con una identificación con
    fotografía.
</p>
<p>
    Cuando estén disponibles en las Plataformas Electrónicas, reservaciones en
    restaurantes, se procesarán de acuerdo con las políticas de cada uno de los
    restaurantes afiliados a las Plataformas Electrónicas.
</p>
<p className="blue_text">
    <strong>MODIFICACIÓN/CANCELACIÓN DE UNA EXPERIENCIA.</strong>
</p>
<p>
    El Usuario podrá realizar cambios de fecha y hora con al menos 48 horas de
    anticipación a la fecha y hora programada en que vaya a tener lugar la
    Experiencia. Para modificar la Experiencia, el Usuario debe comunicarse con
    TOORZY: a) mediante el formulario de contacto disponible en
    www.toorzy.com/contact, o b) llamando a nuestro número de teléfono gratuito
    o local, o c) enviando un correo a la dirección de correo electrónico:
    info@toorzy.com o d) por chat en línea disponible a través de la interfaz
    interactiva de la página web y/o la Aplicación.
</p>
<p>
    <strong><em> </em></strong>
</p>
<p>
    Cualquier cambio con menos de 48 horas de anticipación a la fecha y hora
    programada en que vaya a tener lugar la Experiencia, estará sujeto a los
    siguientes cargos:
</p>
<ul>
    <li>
        Las solicitudes de cancelación de una Experiencia recibida con menos de
        48 horas de anticipación y más de 24 horas de anticipación a la fecha y
        hora programada en que vaya a tener lugar la Experiencia,
        <strong>
            estará sujeta a un cargo del 5 (cinco por ciento) al 50 (cincuenta
            por ciento) del monto total de la Experiencia.
        </strong>
    </li>
    <li>
        <strong>
            Las solicitudes de cancelación de una Experiencia recibida con
            menos de 24 horas de anticipación a la fecha y hora programada en
            que vaya a tener lugar la Experiencia,
        </strong>
        <strong>
            se considerarán "No Show" y NO procederá reembolso alguno
        </strong>
        .
    </li>
</ul>
<p className="blue_text">
    <strong>REEMBOLSOS.</strong>
</p>
<p>
    Debido a que la mayoría de las Experiencias son al aire libre, estás podrán
    llegar a cancelarse si las condiciones climáticas así lo ameritan, por
    solicitud de las autoridades para salvaguardar la integridad y la seguridad
    de los Usuarios y, en su caso, de sus acompañantes en cuyo caso le será
    reembolsado la totalidad del precio de la Experiencia pagado por el
    Usuario.
</p>
<p>
    Del mismo modo, en caso de presentarse una emergencia médica, el Usuario
    podrá reprogramar o cancelar la Experiencia enviando a TOORZY un
    certificado médico que contenga al menos la siguiente información: nombre
    completo del Usuario con fecha reciente a su envío; es decir, una
    antigüedad no mayor a 7 (siete) días, nombre completo, número de la cédula
    del médico y firma autógrafa del médico. El cliente debe comunicarse con
    TOORZY utilizando su forma de contacto preferida: a) mediante el formulario
    de contacto disponible en www.toorzy.com/contact, o b) llamando a nuestro
    número de teléfono gratuito o local, o c) enviando un correo a la dirección
    de correo electrónico: info@toorzy.com o d) por chat en línea disponible a
    través de la interfaz interactiva de la página web y/o la Aplicación.
</p>
<p>
    Todos los reembolsos se procesan entre 4 (cuatro) a 7 (siete) días hábiles;
    salvo en el caso de las tarjetas de débito debido a que los bancos pueden
    demorar hasta 30 (treinta) días.
</p>
<p className="blue_text">
    <strong>EXCLUSIÓN DE RESPONSABILIDAD.</strong>
</p>
<p>
    El Usuario acepta y reconoce que TOORZY pone a disposición del Usuario una
    amplia gama de Experiencias que ofrecen los proveedores de servicios
    independientes con los que tiene celebrado un acuerdo para su promoción y
    oferta entre los Usuarios, motivo por el cual TOORZY no tiene control
    alguno sobre sus actividades comerciales, ni se hace responsable de sus
    actos, omisiones, culpa o negligencia, y/o la de sus empleados, ni de
    ninguna pérdida o daño sufrido como un resultado de ello. TOORZY tampoco
    será responsable de la calidad o seguridad de las Experiencias.
</p>
<p>
    Además, TOORZY no será responsable por el hecho de que los proveedores de
    servicios independientes con los que tiene celebrado un acuerdo para la
    promoción y oferta de las Experiencias no presten sus servicios debido a un
    evento de fuerza mayor o cualquier otra causa que esté fuera del control de
    dichos proveedores ni de TOORZY.
</p>
<p>
    TOORZY no será responsable de ninguna manera por cualquier reclamo,
    pérdida, daño, costo o gasto que surja de una lesión personal, accidente o
    muerte del Usuario y/o sus acompañantes, ni por cualquier reclamo, pérdida,
    daño, costo o gasto que surja por pérdida y/o daños en las pertenencias del
    Usuario y/o sus acompañantes ni de cualquier inconveniente, pérdida de
    disfrute o decepción que pueda surgir de la participación del Usuario y/o
    sus acompañantes en las Experiencias.
</p>
<p>
    El Usuario acepta y reconoce que en las Plataformas Electrónicas, podrán
    existir enlaces a páginas web de terceros, motivo por el cual TOORZY no es
    responsable de la información que aparezca en dichas páginas web.
</p>
<p>
    El Usuario aceptan específicamente que TOORZY, así como sus asociados,
    afiliados o subsidiarias, no son ni serán responsables de los daños,
    directos, indirectos, incidentales, especiales o consecuentes, que puedan
    surgir del uso de la Plataformas Electrónicas, de manera enunciativa mas no
    limitativa, las siguientes: mal funcionamiento, transferencia de virus
    electrónicos o similares al acceder, descargar o utilizar las Plataformas
    Electrónicas; errores tipográficos, técnicos, ortográficos, de diseño o
    causados por la demora en la actualización del servidor y/o las Plataformas
    Electrónicas, por la falta de disponibilidad o demora en el envío o la
    entrega de mensajes de datos (incluido el correo electrónico); solicitudes
    o envío de confirmaciones de las Experiencias); intromisiones a las
    Plataformas Electrónicas o intercepción de mensajes de datos; errores del
    Usuario al proporcionar la información requerida por las Plataformas
    Electrónicas, que no sean informados de manera inmediata por el Usuario a
    TOORZY para su atención; duplicación o error en el procesamiento de los
    cargos a una tarjeta de crédito y/o débito. No obstante lo anterior, en
    caso de que surja cualquier responsabilidad en nombre de TOORZY, dicha
    responsabilidad siempre estará limitará al monto de la contraprestación
    pagada por el Usuario.
</p>
<p className="blue_text">
    <strong>CAMBIOS EN LOS PAQUETES DE EXPERIENCIAS.</strong>
</p>
<p>
    En caso de que alguna Experiencia adquirida por el Usuario no esté
    disponible por razones fuera del alcance de TOORZY, el Usuario acepta y
    reconoce que TOORZY se reserva el derecho de sustituir la Experiencia no
    disponible por una Experiencia alternativa comparable o, alternativamente,
    cancelar la reserva del Usuario para dicha Experiencia.
</p>
<p>
    Si TOORZY elige sustituir una la Experiencia adquirida por una Experiencia
    comparable y la misma es de un precio menor, el Usuario podrá rechazar
    dicha sustitución y TOORZY deberá reembolsar al Usuario el precio pagado
    por el Usuario para la Experiencia adquirida no disponible. Si el Usuario
    acepta dicha sustitución, la única obligación de TOORZY con el Usuario será
    reembolsar la diferencia en el costo al Usuario. El Usuario notificará a
    TOORZY dentro de las 48 horas siguientes a la notificación de la
    sustitución propuesta por TOORZY, si rechaza dicha sustitución; si
    transcurre el plazo de 48 horas sin que TOORZY reciba respuesta alguna por
    parte del Usuario, se entenderá que el Usuario ha dado su consentimiento
    para realizar la sustitución.
</p>
<p>
    Si TOORZY elige sustituir una la Experiencia adquirida por una Experiencia
    comparable y la misma es de un precio mayor, el Usuario podrá rechazar
    dicha sustitución y TOORZY deberá reembolsar el monto del precio pagado por
    el Usuario por la Experiencia adquirida no disponible. Si el Usuario acepta
    dicha sustitución, el Usuario tendrá un plazo de 48 horas para cubrir la
    diferencia en el costo de la Experiencia a TOORZY. El Usuario notificará a
    TOORZY dentro de las 48 horas siguientes a la notificación de la
    sustitución propuesta por TOORZY, si rechaza dicha sustitución; si
    transcurre el plazo de 48 horas sin que TOORZY reciba respuesta alguna por
    parte del Usuario, se entenderá que el Usuario ha dado su consentimiento
    para realizar la sustitución.
</p>
<p>
    Si TOORZY opta por cancelar Experiencia no disponible, la responsabilidad
    de TOORZY se limitará al reembolso del precio de la misma pagado por el
    Usuario.
</p>
<p>
    <strong> </strong>
</p>
<p className="blue_text">
    <strong>COMENTARIOS GENERALES Y QUEJAS.</strong>
</p>
<p>
    Cualquier incidente, cancelación del servicio, problema o insatisfacción
    debe ser reportado por el Cliente en el momento del evento o tan pronto
    como sea posible, para permitir que TOORZY tenga la oportunidad de
    rectificar la situación o brindar asistencia.
</p>
<p>
    En cada destino, el Usuario debe ponerse en contacto con el departamento de
    servicio al cliente local de TOORZY para obtener asistencia y solucionar
    cualquier problema sin demora cada vez que se pueda remediar razonablemente
    dicho problema. El departamento de servicio al cliente de TOORZY se pondrá
    en contacto ya sea vía telefónica o por correo electrónico como se muestra
    en www.toorzy.com o la interfaz interactiva de la Aplicación. Si un
    problema no puede resolverse o solucionarse de inmediato, se solicitará a
    los Usuarios que envíen el detalle del problema o inconveniente a TOORZY,
    dentro de los 30 (treinta) días posteriores al regreso de su viaje, por
    escrito a la siguiente dirección: info@toorzy.com
</p>
<p>
    <strong> </strong>
</p>
<p className="blue_text">
    <strong>PROPIEDAD INTELECTUAL.</strong>
</p>
<p>
    A excepción de la licencia que se describe a continuación, el acceso a las
    Plataformas Electrónicas no otorga al Usuario ninguna otra clase de derecho
    a utilizar las marcas comerciales nominativas, no nominativas o mixtas, los
    nombres de dominio, etc., estén o no registrados, ni a las imágenes ni a
    cualquier otro tipo de derecho de propiedad industrial o intelectual que se
    presente a través de las Plataformas Electrónicas.
</p>
<p>
    Al aceptar los Términos y Condiciones de este apartado, el Usuario acepta
    que las Plataformas Electrónicas contienen información y material de
    propiedad exclusiva de TOORZY y/o sus licenciantes y/o proveedores de
    servicios independientes con los que tiene celebrado un acuerdo para
    promoción y oferta entre los Usuarios de las Experiencias, y están
    protegidas por la propiedad intelectual aplicable y otras leyes, incluidos,
    entre otros, los derechos de autor. El Usuario no podrá utilizar dicha
    información o materiales de propiedad intelectual de ninguna manera,
    excepto para el uso de las Plataformas Electrónicas de conformidad con
    estos Términos y Condiciones.
</p>
<p>
    Sin perjuicio de cualquier otra disposición en estos Términos y
    Condiciones, TOORZY y sus licenciatarios se reservan el derecho de cambiar,
    suspender, eliminar o deshabilitar el acceso a cualquiera de las
    Plataformas Electrónicas, su contenido u otros materiales que se presenten
    a través de las mismas sin necesidad de información previa. En ningún caso
    TOORZY será responsable al realizar estos cambios. TOORZY también podrá
    imponer límites adicionales en el uso o acceso a ciertas funciones o partes
    de las Plataformas Electrónicas, en cualquier caso y sin necesidad de aviso
    previo.
</p>
<p>
    TOORZY y/o sus licenciantes y/o proveedores de servicios independientes con
    los que tiene celebrado un acuerdo para promoción y oferta entre los
    Usuarios de las Experiencias se reservan todos los derechos de autor
    derivados de las Plataformas Electrónicas. El uso no autorizado o ilegal de
    las Plataformas Electrónicas estará sujeto a sanciones civiles y penales,
    así como a daños materiales por infracción de derechos de autor.
</p>
<p>
    El Usuario no tendrá ningún derecho o licencia de uso sobre cualquier
    material o contenido de
</p>
<p>
    proveedores de servicios independientes con los que tiene TOORZY celebrado
    un acuerdo para promoción y oferta entre los Usuarios de las Experiencias.
</p>
<p className="blue_text">
    <strong>LEYES.</strong>
</p>
<p>
    Este Acuerdo se regirá, interpretará e interpretará de acuerdo con las
    leyes de los Estados Unidos Mexicanos (“México”), y los tribunales de Bahía
    de Banderas tendrán jurisdicción exclusiva para todos los asuntos o
    procedimientos entre TOORZY y el Cliente. En el caso de que una o más de
    las disposiciones contenidas en estos Términos y Condiciones se considere
    inválida(s), ilegal(es) o inaplicable(s) en cualquier sentido por un
    tribunal de jurisdicción competente, dicha invalidez, ilegalidad o
    inaplicabilidad no afectará de manera alguna el resto de las disposiciones.
</p>
<p className="blue_text">
    <strong>PRIVACIDAD, NOTIFICACIONES Y ALERTAS.</strong>
</p>
<p>
    Al acceder a cualquiera de las Plataformas Electrónica, el Usuario podrá
    elegir su desea o no recibir por parte de TOORZY alertas y/o notificaciones
    de forma continua sobre los detalles de las Experiencias, así como ofertas
    y promociones que pudieran llegar a ser de interés del Usuario.
</p>
<p>
    Para obtener el Aviso de privacidad integral actual y completo de TOORZY,
consulte la sección de “    <a href="https://toorzy.com/privacy">Privacy Notice</a>”.
</p>
<p className="blue_text">
    <strong>ACUERDO COMPLETO.</strong>
</p>
<p>
    Estos Términos y Condiciones, según sean modificados de tiempo en tiempo
    por TOORZY en caso de que lo considere oportuna su modificación,
    constituyen el acuerdo completo entre TOORZY y el Usuario. Motivo de lo
    anterior, TOORZY le recomienda al Usuario volver a leer esta declaración de
    manera regular, ya que el uso regular y/o permanente de las Plataformas
    Electrónicas significa la aceptación de cualquier cambio a los Términos y
    Condiciones, así como a su Política de Privacidad y demás funcionalidades
    específicas.
</p>
<p>
    Fecha de última actualización 2 de noviembre de 2021.
</p>

        </div>

    </div>
 
 );
 
 ////////////////////////////////////////////SPANISH
 else return (
 
 <div className="row">
  <div className="col-lg-12">

  <p>
    Usted al ingresar a nuestra página web y/o plataformas disponibles a través
    de la dirección “<u>toorzy.com</u>” y/o aplicaciones móviles acepta y
    conviene en obligarse a los términos y condiciones que se establecen a
    continuación, mismos que rigen el uso de la misma, así como del
    ofrecimiento, venta o concesión del uso o disfrute de bienes o servicios, y
según estos sean modificados de tiempo en tiempo.    

<strong>
    Motivo por el cual todo uso, adquisición o concesión de bienes o servicios,
    creación de usuarios y/o accesos, descargas de contenidos y/o aplicaciones
    a través de esta página web y/o plataformas disponibles a través de la
    dirección “<u>toorzy.com</u>” y/o aplicaciones móviles acepta constituye la
    aceptación de estos términos y condiciones, el uso de medios electrónicos,
    así como sus funcionalidades, que Mobile Sail Tecnologies, S.A. de C.V.,
    como operadora de dicha página, con domicilio en Paseo Cocoteros S/N,
    interior local 170, Hotel Marival Emotions Resort &amp; Suites, Nuevo
    Vallarta, Nayarit, México, Código Postal 63732 y con Registro Federal de
    Contribuyentes OER070622BKA, ofrece (a la que en lo sucesivo se le
    denominará “TOORZY”).
</strong>


</p>

<p>
    <strong></strong>
</p>

<p>
    <strong>
        Esta página web y/o plataformas disponibles a través de la dirección
        “toorzy.com” y/o aplicaciones móviles, su contenido, características y
        funcionalidad son una propiedad de o software patentado con licencia
        para TOORZY y están protegidos por derechos de autor internacionales,
        marcas registradas, patentes, secretos comerciales y demás leyes de
        propiedad intelectual o de derechos de propiedad.
    </strong>
    <strong></strong>
</p>
<p>
    <strong></strong>
</p>
<p>
    <strong>
        Esta página web, plataformas disponibles a través de la dirección
        “toorzy.com”, aplicaciones móviles y sus componentes se ofrecen
        únicamente con fines informativos; por lo que TOORZY no se hace
        responsable de la exactitud, utilidad o disponibilidad de cualquier
        información que se transmita o ponga a disposición a través de la
        misma; TOORZY no será responsable por cualquier error u omisión en
        dicha información. Asimismo, TOORZY no se hace responsable en caso de
        incidencias o fallas técnicas derivadas de caso fortuito o fuerza
        mayor.
    </strong>
    <strong></strong>
</p>
<p>
    <strong></strong>
</p>
<p>
    <strong>
        En caso que no desee aceptar ni obligarse a cumplir con los términos y
        condiciones siguientes, ni a aceptar con las funcionalidades, esquemas
        de comisión y demás aspectos que TOORZY ofrece a través de su página
        web y/o plataformas disponibles a través de la dirección “toorzy.com”
        y/o aplicaciones móviles y sus componentes, por favor no lo utilice.
    </strong>
    <strong></strong>
</p>
<p>
    <strong></strong>
</p>
<p>
    <strong>
        Lea cuidadosa y detenidamente estos Términos y Condiciones para
        asegurarse de que los comprende antes de hacer una reservación y/o
        crear algún acceso y/o usuario y/o realizar una compra.
    </strong>
    <strong></strong>
</p>
<p>
    Toda persona física o moral (en adelante, el “Usuario”) que acceda, visite,
    se registrarse en nuestra página web y/o plataformas disponibles a través
    de la dirección “<u>toorzy.com</u>” y/o descargue aplicaciones móviles
    (todas ellas en su conjunto en adelante, las “Plataformas Electrónicas”)
    acepta, declara y garantiza que es mayor de edad; es decir tiene cumplidos
    18 años de edad, y la capacidad legal suficiente para adquirir o contratar
    por su propio derecho o en nombre y representación de la persona moral que
    el Usuario representa, así como para realizar los cargos a través de
    tarjeta de crédito y/o débito. Los padres y tutores son responsables de las
    compras o reservas en línea que realicen los niños bajo su responsabilidad,
    con o sin su aprobación previa.
</p>
<p>
    <strong> </strong>
</p>
<p className="blue_text">
    <strong>LICENCIA.</strong>
</p>
<p>
    TOORZY otorga al Cliente una licencia limitada, no exclusiva, no perpetua,
    revocable para usar las Plataformas Electrónicas para hacer una reservación
y/o crear algún acceso y/o usuario y/o realizar una compra,    <strong> </strong>ya sea navegando en la página web www.toorzy.com y/o
    descargando la aplicación Mobile Concierge (la "Aplicación") al dispositivo
    de comunicación inalámbrico en el que está instalado inicialmente y/o a
    través del Sistema Toorzy. Todos los derechos no otorgados expresamente
    están reservados por TOORZY o su licenciante de software. Esta licencia no
    permite que el Usuario utilice la Aplicación en ningún otro dispositivo de
    comunicación inalámbrico que no sea en el que se instaló inicialmente, sin
    el consentimiento previo de TOORZY, y tampoco podrá distribuir o hacer que
    la Aplicación esté disponible a través de una red donde pueda ser utilizada
    por múltiples dispositivos al mismo tiempo. El Usuario no puede alquilar,
    arrendar, prestar, vender, redistribuir o sublicenciar la Aplicación ni su
    acceso al Sistema Toorzy. El Usuario no puede copiar, descompilar, realizar
    ingeniería inversa, desensamblar, intentar derivar el código fuente,
    modificar o crear trabajos derivados de las Plataformas Electrónicas,
    ninguna actualización o cualquier parte del mismo, salvo prohibición
    expresa en la ley aplicable a la materia. Cualquier intento de llevar a
    cabo cualquier acción de las antes mencionadas, se considerará una
    violación de los derechos de TOORZY y sus licenciantes. Los términos de
    esta licencia regirán cualquier actualización provista por TOORZY que
    reemplace y/o complemente las Plataformas Electrónicas originales, a menos
    que dicha actualización vaya acompañada de una licencia separada, en cuyo
    caso los términos de esa licencia regirán.<strong></strong>
</p>
<p className="blue_text">
    <strong>CONSENTIMIENTO PARA USO DE DATOS.</strong>
</p>
<p>
    Para utilizar las Plataformas Electrónicas, se le puede solicitar al
    Usuario que se registre para crear una cuenta y se le puede solicitar que
    proporcione ciertos datos personales, que incluyen, entre otros, nombre
    completo, dirección de correo electrónico, el destino del viaje, el hotel,
    las fechas de viaje y la línea aérea de salida, número de vuelo, edad de
    sus acompañantes, incluyendo menores de edad e información de contacto.
    TOORZY recopilará y utilizará esta información con el fin de configurar la
    contraseña y el nombre de usuario seleccionados del Usuario, mantener y
    administrar el acceso a la cuenta de la Aplicación y/o del Sistema Toorzy
    del Usuario y procesar las ventas de excursiones en línea realizadas por el
    Usuario o procesar y operar con proveedores terceros la prestación de
    servicios o bienes, que se muestran o al Usuario a través de la Aplicación
y/o del catálogo de la página web    <a href="http://www.toorzy.com">www.toorzy.com</a> y/o en el Sistema
    Toorzy. TOORZY también puede utilizar la información personal del Usuario
    para enviarle un correo electrónico u otra comunicación sobre productos y/o
    servicios que TOORZY cree que pueden ser de interés del Usuario. El Usuario
    solicitar que deje de recibir esta última información enviando un correo
electrónico a la siguiente dirección:    <a href="mailto:info@toorzy.com">info@toorzy.com</a>.
</p>
<p>
    TOORZY puede divulgar la información personal del Usuario según lo exija la
    ley o a terceros que procesen la información personal en nombre de TOORZY.
    Algunos de estos proveedores de servicios pueden estar ubicados en
    jurisdicciones extranjeras y la información personal del Usuario puede ser
    recopilada, utilizada, divulgada, almacenada o procesada en dichas
    jurisdicciones extranjeras y luego estará sujeta a los requisitos legales
    de esas jurisdicciones, incluidos los requisitos legales para divulgar
    información personal a las autoridades gubernamentales en esas
    jurisdicciones.
</p>
<p>
    El Usuario consiente y autoriza específicamente a TOORZY a utilizar toda la
    información que le proporcione al crear un acceso y/o usuario para la
    Aplicación y/o el Sistema Toorzy de acuerdo con estos Términos y
    Condiciones. Además, el Usuario acepta y declara que proporcionará
    información precisa, actual y veraz. El Usuario reconoce y acepta que el
    Usuario es responsable de todas las actividades que se realicen a través de
    sus accesos y/o usuarios, por lo que se obliga a mantener la
    confidencialidad de su contraseña y cualquier otra información de la
    Aplicación y/o sistema Toorzy; es decirla, a hacer un uso de manera
    personal e intransferible. Si el Usuario sospecha que alguien está
    accediendo o usando su cuenta sin su permiso u autorización deberá
    comunicarse de manera inmediata con Toorzy enviando un correo electrónico a
la siguiente dirección:    <a href="mailto:info@toorzy.com">info@toorzy.com</a>, o sí necesita cambiar
    su contraseña o información de cuenta. En caso contrario, cualquier uso no
    autorizado y no reconocido por el Usuario será vinculante.
</p>
<p>
    Para hacer una reservación y/o compra a través de las Plataformas
    Electrónicas, se le podrá solicitar al Usuario información adicional,
    incluida la información de cuentas bancarias y de facturación. Esta
    información puede ser recopilada y utilizada por TOORZY o por proveedor del
    servicio, por lo que el Usuario acepta que la recopilación, el uso y la
    divulgación de esta información personal también estará sujeta a los
    términos y condiciones del acuerdo que rija dicho servicio.
</p>
<p>
    TOORZY puede recopilar y utilizar periódicamente datos técnicos e
    información relacionada, que incluye, entre otros: información técnica
    sobre el dispositivo de comunicación inalámbrico, el Sistema Toorzy, el
    software de la Aplicación y los periféricos recopilados para facilitar el
    suministro de actualizaciones de software, soporte de productos y otros
    servicios para el Usuario relacionados con las Plataformas Electrónicas.
    TOORZY puede utilizar esta información para otros fines, incluyendo, de
    manera enunciativa mas no limitativamente, para mejorar sus productos o
    para proporcionar servicios o tecnologías adicionales al Usuario, siempre
    de modo que la información del Usuario no lo identifique personalmente.
</p>
<p>
Para conocer mayor información sobre los términos y condiciones en que serán tratados sus datos personales, como los terceros con quienes compartimos su información personal y la forma en que podrá ejercer sus derechos ARCO, puede consultar el aviso de privacidad integral en <a href="https://toorzy.com/privacy">https://toorzy.com/privacy</a>  o contactarnos a través de <a href="mailto:privacidad@marivalgroup.com">privacidad@marivalgroup.com</a>
</p>
<p>
    <strong> </strong>
</p>
<p className="blue_text">
    <strong>PAQUETES DE EXPERIENCIAS.</strong>
</p>
<p>
    La adquisición y/o reserva de uno o más de los paquetes ofrecidos por
    TOORZY al Usuario en relación con tours, excursiones o actividades
    recreativas (en lo sucesivo referidas en su conjunto como “Experiencias”),
    constituye un vínculo contractual entre ambas partes, por lo que constituye
    prueba plena por parte del Usuario de que ha aceptado los Términos y
Condiciones de las Plataformas Electrónicas de TOORZY.    <strong><em></em></strong>
</p>
<p className="blue_text">
    <strong>INCLUSIONES Y EXCLUSIONES.</strong>
</p>
<p>
    A menos que se especifique lo contrario, el precio base de una Experiencia
    adquirida por el Usuario incluirá la excursión local seleccionada, el
    transporte en el sitio junto con otras inclusiones, de ser el caso, y según
    se encuentren especificadas en la descripción del producto seleccionado en
    las Plataformas Electrónicas.
    <strong>
        El Usuario acepta y reconoce que el precio base de una Experiencia, no
        incluye: gastos personales, comidas y bebidas, seguros, impuestos y
        cargos por servicios u otros servicios no especificados en el momento
        de la reserva.
    </strong>
</p>
<p className="blue_text">
    <strong>DESCRIPCIONES DE LAS EXPERIENCIAS.</strong>
</p>
<p>
    TOORZY ha realizado todos los esfuerzos necesarios para garantizar que, en
    el momento de la publicación, todas las descripciones de los productos en
    cualquiera de las Plataformas Electrónicas estén actualizadas. Por diversas
    razones fuera del control de TOORZY, pueden llegar a existir modificaciones
    que pueden afectar las descripciones de las Experiencias, como, de manera
    enunciativa, mas no limitativa, caso fortuito o fuerza mayor. TOORZY hará
    sus mejores esfuerzos razonables para mantener actualizada la información
    de las Plataformas Electrónicas al recibir una notificación sobre algún
    cambio. Es responsabilidad del Usuario consultar las Plataformas
    Electrónicas para estar al tanto de cualquier modificación.
</p>
<p className="blue_text">
    <strong>PRECIOS INDICADOS.</strong>
</p>
<p>
    Los precios se muestran en pesos mexicanos, moneda de curso legal en los
    Estados Unidos Mexicanos (MXN) o en dólares, moneda de curso legal en los
    Estados Unidos de Norteamérica (US$), según la moneda seleccionada por el
    Usuario. Los precios confirmados en el momento de la adquisición y/o
    reserva son los precios que se cobrarán al Usuario y sustituyen cualquier
    precio anunciado o indicado antes de la confirmación de la adquisición o
    reserva.
    <strong>
        Asimismo, el Usuario acepta y reconoce que los precios están sujetos a
        cambios adicionales en cualquier momento, sin previo aviso, hasta que
        el Usuario haya pagado la reserva en su totalidad.
    </strong>
</p>
<p>
    No obstante lo anterior, las obligaciones de pago asumidas por el Usuario
    en dólares, moneda de curso legal en los Estados Unidos de Norteamérica
    (US$), contraídas dentro o fuera de los Estados Unidos Mexicanos para ser
    cumplidas en este, se solventarán entregando el equivalente en moneda
    nacional, al tipo de cambio que rija en el lugar y fecha en que se haga el
    pago. Este tipo de cambio se determinará conforme a las disposiciones que
    para esos efectos expida el Banco de México o de acuerdo con los términos
    del contrato que rija el uso de la tarjeta de crédito o débito que utilice
    el Usuario para hacer su pago.
</p>
<p className="blue_text">
    <strong>PAGO.</strong>
</p>
<p>
    A efecto de garantizar la disponibilidad de las Experiencias, el Usuario
    deberá liquidar la totalidad del precio a la brevedad posible una vez que
    haya hecho la reservación y deberá pagar el total del precio de las
    Experiencias antes de que las mismas tengan lugar.
</p>
<p>
    <strong> </strong>
</p>
<p className="blue_text">
    <strong>CONFIRMACIÓN DE UNA EXPERIENCIA.</strong>
</p>
<p>
    TOORZY emitirá un boleto electrónico o un cupón a confirmar la adquisición
    de una Experiencia. El Usuario deberá imprimir el/los boleto(s) o deberá
    presentar el cupón/cupones electrónico(s) para realizar su registro en el
    lugar en que vaya a tener lugar la Experiencia. Po lo que es
    responsabilidad del Usuario asegurarse de que la información que aparece en
    el boleto electrónico, el cupón y cualquier otro documento de su
    Experiencia sea de conformidad lo que quiso adquirir el Usuario.
</p>
<p>
    También se le podrá solicitar al Usuario al momento de su registro que
    proporcione su número de confirmación junto con una identificación con
    fotografía.
</p>
<p>
    Cuando estén disponibles en las Plataformas Electrónicas, reservaciones en
    restaurantes, se procesarán de acuerdo con las políticas de cada uno de los
    restaurantes afiliados a las Plataformas Electrónicas.
</p>
<p className="blue_text">
    <strong>MODIFICACIÓN/CANCELACIÓN DE UNA EXPERIENCIA.</strong>
</p>
<p>
    El Usuario podrá realizar cambios de fecha y hora con al menos 48 horas de
    anticipación a la fecha y hora programada en que vaya a tener lugar la
    Experiencia. Para modificar la Experiencia, el Usuario debe comunicarse con
    TOORZY: a) mediante el formulario de contacto disponible en
    www.toorzy.com/contact, o b) llamando a nuestro número de teléfono gratuito
    o local, o c) enviando un correo a la dirección de correo electrónico:
    info@toorzy.com o d) por chat en línea disponible a través de la interfaz
    interactiva de la página web y/o la Aplicación.
</p>
<p>
    <strong><em> </em></strong>
</p>
<p>
    Cualquier cambio con menos de 48 horas de anticipación a la fecha y hora
    programada en que vaya a tener lugar la Experiencia, estará sujeto a los
    siguientes cargos:
</p>
<ul>
    <li>
        Las solicitudes de cancelación de una Experiencia recibida con menos de
        48 horas de anticipación y más de 24 horas de anticipación a la fecha y
        hora programada en que vaya a tener lugar la Experiencia,
        <strong>
            estará sujeta a un cargo del 5 (cinco por ciento) al 50 (cincuenta
            por ciento) del monto total de la Experiencia.
        </strong>
    </li>
    <li>
        <strong>
            Las solicitudes de cancelación de una Experiencia recibida con
            menos de 24 horas de anticipación a la fecha y hora programada en
            que vaya a tener lugar la Experiencia,
        </strong>
        <strong>
            se considerarán "No Show" y NO procederá reembolso alguno
        </strong>
        .
    </li>
</ul>
<p className="blue_text">
    <strong>REEMBOLSOS.</strong>
</p>
<p>
    Debido a que la mayoría de las Experiencias son al aire libre, estás podrán
    llegar a cancelarse si las condiciones climáticas así lo ameritan, por
    solicitud de las autoridades para salvaguardar la integridad y la seguridad
    de los Usuarios y, en su caso, de sus acompañantes en cuyo caso le será
    reembolsado la totalidad del precio de la Experiencia pagado por el
    Usuario.
</p>
<p>
    Del mismo modo, en caso de presentarse una emergencia médica, el Usuario
    podrá reprogramar o cancelar la Experiencia enviando a TOORZY un
    certificado médico que contenga al menos la siguiente información: nombre
    completo del Usuario con fecha reciente a su envío; es decir, una
    antigüedad no mayor a 7 (siete) días, nombre completo, número de la cédula
    del médico y firma autógrafa del médico. El cliente debe comunicarse con
    TOORZY utilizando su forma de contacto preferida: a) mediante el formulario
    de contacto disponible en www.toorzy.com/contact, o b) llamando a nuestro
    número de teléfono gratuito o local, o c) enviando un correo a la dirección
    de correo electrónico: info@toorzy.com o d) por chat en línea disponible a
    través de la interfaz interactiva de la página web y/o la Aplicación.
</p>
<p>
    Todos los reembolsos se procesan entre 4 (cuatro) a 7 (siete) días hábiles;
    salvo en el caso de las tarjetas de débito debido a que los bancos pueden
    demorar hasta 30 (treinta) días.
</p>
<p className="blue_text">
    <strong>EXCLUSIÓN DE RESPONSABILIDAD.</strong>
</p>
<p>
    El Usuario acepta y reconoce que TOORZY pone a disposición del Usuario una
    amplia gama de Experiencias que ofrecen los proveedores de servicios
    independientes con los que tiene celebrado un acuerdo para su promoción y
    oferta entre los Usuarios, motivo por el cual TOORZY no tiene control
    alguno sobre sus actividades comerciales, ni se hace responsable de sus
    actos, omisiones, culpa o negligencia, y/o la de sus empleados, ni de
    ninguna pérdida o daño sufrido como un resultado de ello. TOORZY tampoco
    será responsable de la calidad o seguridad de las Experiencias.
</p>
<p>
    Además, TOORZY no será responsable por el hecho de que los proveedores de
    servicios independientes con los que tiene celebrado un acuerdo para la
    promoción y oferta de las Experiencias no presten sus servicios debido a un
    evento de fuerza mayor o cualquier otra causa que esté fuera del control de
    dichos proveedores ni de TOORZY.
</p>
<p>
    TOORZY no será responsable de ninguna manera por cualquier reclamo,
    pérdida, daño, costo o gasto que surja de una lesión personal, accidente o
    muerte del Usuario y/o sus acompañantes, ni por cualquier reclamo, pérdida,
    daño, costo o gasto que surja por pérdida y/o daños en las pertenencias del
    Usuario y/o sus acompañantes ni de cualquier inconveniente, pérdida de
    disfrute o decepción que pueda surgir de la participación del Usuario y/o
    sus acompañantes en las Experiencias.
</p>
<p>
    El Usuario acepta y reconoce que en las Plataformas Electrónicas, podrán
    existir enlaces a páginas web de terceros, motivo por el cual TOORZY no es
    responsable de la información que aparezca en dichas páginas web.
</p>
<p>
    El Usuario aceptan específicamente que TOORZY, así como sus asociados,
    afiliados o subsidiarias, no son ni serán responsables de los daños,
    directos, indirectos, incidentales, especiales o consecuentes, que puedan
    surgir del uso de la Plataformas Electrónicas, de manera enunciativa mas no
    limitativa, las siguientes: mal funcionamiento, transferencia de virus
    electrónicos o similares al acceder, descargar o utilizar las Plataformas
    Electrónicas; errores tipográficos, técnicos, ortográficos, de diseño o
    causados por la demora en la actualización del servidor y/o las Plataformas
    Electrónicas, por la falta de disponibilidad o demora en el envío o la
    entrega de mensajes de datos (incluido el correo electrónico); solicitudes
    o envío de confirmaciones de las Experiencias); intromisiones a las
    Plataformas Electrónicas o intercepción de mensajes de datos; errores del
    Usuario al proporcionar la información requerida por las Plataformas
    Electrónicas, que no sean informados de manera inmediata por el Usuario a
    TOORZY para su atención; duplicación o error en el procesamiento de los
    cargos a una tarjeta de crédito y/o débito. No obstante lo anterior, en
    caso de que surja cualquier responsabilidad en nombre de TOORZY, dicha
    responsabilidad siempre estará limitará al monto de la contraprestación
    pagada por el Usuario.
</p>
<p className="blue_text">
    <strong>CAMBIOS EN LOS PAQUETES DE EXPERIENCIAS.</strong>
</p>
<p>
    En caso de que alguna Experiencia adquirida por el Usuario no esté
    disponible por razones fuera del alcance de TOORZY, el Usuario acepta y
    reconoce que TOORZY se reserva el derecho de sustituir la Experiencia no
    disponible por una Experiencia alternativa comparable o, alternativamente,
    cancelar la reserva del Usuario para dicha Experiencia.
</p>
<p>
    Si TOORZY elige sustituir una la Experiencia adquirida por una Experiencia
    comparable y la misma es de un precio menor, el Usuario podrá rechazar
    dicha sustitución y TOORZY deberá reembolsar al Usuario el precio pagado
    por el Usuario para la Experiencia adquirida no disponible. Si el Usuario
    acepta dicha sustitución, la única obligación de TOORZY con el Usuario será
    reembolsar la diferencia en el costo al Usuario. El Usuario notificará a
    TOORZY dentro de las 48 horas siguientes a la notificación de la
    sustitución propuesta por TOORZY, si rechaza dicha sustitución; si
    transcurre el plazo de 48 horas sin que TOORZY reciba respuesta alguna por
    parte del Usuario, se entenderá que el Usuario ha dado su consentimiento
    para realizar la sustitución.
</p>
<p>
    Si TOORZY elige sustituir una la Experiencia adquirida por una Experiencia
    comparable y la misma es de un precio mayor, el Usuario podrá rechazar
    dicha sustitución y TOORZY deberá reembolsar el monto del precio pagado por
    el Usuario por la Experiencia adquirida no disponible. Si el Usuario acepta
    dicha sustitución, el Usuario tendrá un plazo de 48 horas para cubrir la
    diferencia en el costo de la Experiencia a TOORZY. El Usuario notificará a
    TOORZY dentro de las 48 horas siguientes a la notificación de la
    sustitución propuesta por TOORZY, si rechaza dicha sustitución; si
    transcurre el plazo de 48 horas sin que TOORZY reciba respuesta alguna por
    parte del Usuario, se entenderá que el Usuario ha dado su consentimiento
    para realizar la sustitución.
</p>
<p>
    Si TOORZY opta por cancelar Experiencia no disponible, la responsabilidad
    de TOORZY se limitará al reembolso del precio de la misma pagado por el
    Usuario.
</p>
<p>
    <strong> </strong>
</p>
<p className="blue_text">
    <strong>COMENTARIOS GENERALES Y QUEJAS.</strong>
</p>
<p>
    Cualquier incidente, cancelación del servicio, problema o insatisfacción
    debe ser reportado por el Cliente en el momento del evento o tan pronto
    como sea posible, para permitir que TOORZY tenga la oportunidad de
    rectificar la situación o brindar asistencia.
</p>
<p>
    En cada destino, el Usuario debe ponerse en contacto con el departamento de
    servicio al cliente local de TOORZY para obtener asistencia y solucionar
    cualquier problema sin demora cada vez que se pueda remediar razonablemente
    dicho problema. El departamento de servicio al cliente de TOORZY se pondrá
    en contacto ya sea vía telefónica o por correo electrónico como se muestra
    en www.toorzy.com o la interfaz interactiva de la Aplicación. Si un
    problema no puede resolverse o solucionarse de inmediato, se solicitará a
    los Usuarios que envíen el detalle del problema o inconveniente a TOORZY,
    dentro de los 30 (treinta) días posteriores al regreso de su viaje, por
    escrito a la siguiente dirección: info@toorzy.com
</p>
<p>
    <strong> </strong>
</p>
<p className="blue_text">
    <strong>PROPIEDAD INTELECTUAL.</strong>
</p>
<p>
    A excepción de la licencia que se describe a continuación, el acceso a las
    Plataformas Electrónicas no otorga al Usuario ninguna otra clase de derecho
    a utilizar las marcas comerciales nominativas, no nominativas o mixtas, los
    nombres de dominio, etc., estén o no registrados, ni a las imágenes ni a
    cualquier otro tipo de derecho de propiedad industrial o intelectual que se
    presente a través de las Plataformas Electrónicas.
</p>
<p>
    Al aceptar los Términos y Condiciones de este apartado, el Usuario acepta
    que las Plataformas Electrónicas contienen información y material de
    propiedad exclusiva de TOORZY y/o sus licenciantes y/o proveedores de
    servicios independientes con los que tiene celebrado un acuerdo para
    promoción y oferta entre los Usuarios de las Experiencias, y están
    protegidas por la propiedad intelectual aplicable y otras leyes, incluidos,
    entre otros, los derechos de autor. El Usuario no podrá utilizar dicha
    información o materiales de propiedad intelectual de ninguna manera,
    excepto para el uso de las Plataformas Electrónicas de conformidad con
    estos Términos y Condiciones.
</p>
<p>
    Sin perjuicio de cualquier otra disposición en estos Términos y
    Condiciones, TOORZY y sus licenciatarios se reservan el derecho de cambiar,
    suspender, eliminar o deshabilitar el acceso a cualquiera de las
    Plataformas Electrónicas, su contenido u otros materiales que se presenten
    a través de las mismas sin necesidad de información previa. En ningún caso
    TOORZY será responsable al realizar estos cambios. TOORZY también podrá
    imponer límites adicionales en el uso o acceso a ciertas funciones o partes
    de las Plataformas Electrónicas, en cualquier caso y sin necesidad de aviso
    previo.
</p>
<p>
    TOORZY y/o sus licenciantes y/o proveedores de servicios independientes con
    los que tiene celebrado un acuerdo para promoción y oferta entre los
    Usuarios de las Experiencias se reservan todos los derechos de autor
    derivados de las Plataformas Electrónicas. El uso no autorizado o ilegal de
    las Plataformas Electrónicas estará sujeto a sanciones civiles y penales,
    así como a daños materiales por infracción de derechos de autor.
</p>
<p>
    El Usuario no tendrá ningún derecho o licencia de uso sobre cualquier
    material o contenido de
</p>
<p>
    proveedores de servicios independientes con los que tiene TOORZY celebrado
    un acuerdo para promoción y oferta entre los Usuarios de las Experiencias.
</p>
<p className="blue_text">
    <strong>LEYES.</strong>
</p>
<p>
    Este Acuerdo se regirá, interpretará e interpretará de acuerdo con las
    leyes de los Estados Unidos Mexicanos (“México”), y los tribunales de Bahía
    de Banderas tendrán jurisdicción exclusiva para todos los asuntos o
    procedimientos entre TOORZY y el Cliente. En el caso de que una o más de
    las disposiciones contenidas en estos Términos y Condiciones se considere
    inválida(s), ilegal(es) o inaplicable(s) en cualquier sentido por un
    tribunal de jurisdicción competente, dicha invalidez, ilegalidad o
    inaplicabilidad no afectará de manera alguna el resto de las disposiciones.
</p>
<p className="blue_text">
    <strong>PRIVACIDAD, NOTIFICACIONES Y ALERTAS.</strong>
</p>
<p>
    Al acceder a cualquiera de las Plataformas Electrónica, el Usuario podrá
    elegir su desea o no recibir por parte de TOORZY alertas y/o notificaciones
    de forma continua sobre los detalles de las Experiencias, así como ofertas
    y promociones que pudieran llegar a ser de interés del Usuario.
</p>
<p>
    Para obtener el Aviso de privacidad integral actual y completo de TOORZY,
consulte la sección de “    <a href="https://toorzy.com/privacy">Privacy Notice</a>”.
</p>
<p className="blue_text">
    <strong>ACUERDO COMPLETO.</strong>
</p>
<p>
    Estos Términos y Condiciones, según sean modificados de tiempo en tiempo
    por TOORZY en caso de que lo considere oportuna su modificación,
    constituyen el acuerdo completo entre TOORZY y el Usuario. Motivo de lo
    anterior, TOORZY le recomienda al Usuario volver a leer esta declaración de
    manera regular, ya que el uso regular y/o permanente de las Plataformas
    Electrónicas significa la aceptación de cualquier cambio a los Términos y
    Condiciones, así como a su Política de Privacidad y demás funcionalidades
    específicas.
</p>
<p>
    Fecha de última actualización 2 de noviembre de 2021.
</p>

</div>
 
 </div>
 
 
 
 );
     }


}

export default withRouter(totouraterms)