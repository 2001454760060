var brandconfig = require(`./config.${process.env.REACT_APP_BRAND}`);

if (process.env.NODE_ENV == "production") {
	brandconfig = require(`./config.${process.env.REACT_APP_BOOKING_WIDGET}`);
}

if (process.env.NODE_ENV == "development") {

	brandconfig.cdn = '/assets/';

	brandconfig.basename = "";

	brandconfig.apiurl =
		process.env.REACT_APP_API_ENVIRONMENT == ""
			? brandconfig.apiurl
					.replace("-sandbox", process.env.REACT_APP_API_ENVIRONMENT)
					.replace("-devel", process.env.REACT_APP_API_ENVIRONMENT)
			: brandconfig.apiurl
					.replace("sandbox", process.env.REACT_APP_API_ENVIRONMENT)
					.replace("devel", process.env.REACT_APP_API_ENVIRONMENT);
	brandconfig.apiurl =
		brandconfig.apiurl.search("-devel") == -1 &&
		brandconfig.apiurl.search("-sandbox") == -1 &&
		process.env.REACT_APP_API_ENVIRONMENT != ""
			? brandconfig.apiurl.replace(
					".mobilesail.com",
					`-${process.env.REACT_APP_API_ENVIRONMENT}.mobilesail.com`
			  )
			: brandconfig.apiurl;
	brandconfig.imagescdn =
		process.env.REACT_APP_API_ENVIRONMENT == ""
			? brandconfig.imagescdn
					.replace("-sandbox", process.env.REACT_APP_API_ENVIRONMENT)
					.replace("-devel", process.env.REACT_APP_API_ENVIRONMENT)
			: brandconfig.imagescdn
					.replace("sandbox", process.env.REACT_APP_API_ENVIRONMENT)
					.replace("devel", process.env.REACT_APP_API_ENVIRONMENT);
	brandconfig.imagescdn =
		brandconfig.imagescdn.search("-devel") == -1 &&
		brandconfig.imagescdn.search("-sandbox") == -1 &&
		process.env.REACT_APP_API_ENVIRONMENT != ""
			? brandconfig.imagescdn.replace(
					".mobilesail.com",
					`-${process.env.REACT_APP_API_ENVIRONMENT}.mobilesail.com`
			  )
			: brandconfig.imagescdn;
}

module.exports = brandconfig;
