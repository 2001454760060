import React,{Component} from "react";
import TourPricesTable from "../components/component.tour.prices.table";
import CartList from "../components/component.cart.list";
import moment from "moment";
import { NavLink, withRouter } from "react-router-dom";
import ToursList from "../components/component.tours.list";
import Section from '../components/component.section';
import RulesThings from '../components/component.tour.rules.things';
import TourReviews from '../components/component.tour.reviews';
import IconWishlist from '../components/component.icon.wishlist';
import TourAvailability from '../components/component.tour.availability';
import ReactDOM from 'react-dom';
import Global from '../globals/global';
import AdviceMessage from "../components/component.advice.message";
const $ = window.$;
class tourdetails extends Global {
  constructor(props) {
    super(props);

    var tourid = null;
    var disablemodule = false;
    var soldout = false;

    console.log('this.props.params',this.props.params)

    if (this.props.match) {
      if (this.props.match.params) {
        if (this.props.match.params.tourid)
          tourid = this.props.match.params.tourid;
        
      }
    }

    if (this.props.params){
          disablemodule = this.props.params.disablemodule;
          tourid = this.props.params.tourid;
          soldout = Number(this.props.params.soldout) == -1?true:false;
          this.props.match.params = this.props.params;
      }

    this.state = {
      tourid: tourid,
      tour: {},
      relatedtours: [],
      tourbring: [],
      tourextras: [],
      tourinclusions: [],
      tourrequirements: [],
      tourreviews: [],
      disablemodule:disablemodule,
      soldout:soldout
    };
  }

  componentDidMount() {


    this.scrolltop();
    this.gettour();

    /*  $('[data-toggle="collapse"]').click('shown.bs.collapse', function() {  
      var googleIframe = $('.map_t iframe');
      googleIframe.attr('src',googleIframe.attr('src')+'');
  });*/

  }

  loadslide() {
    $("#Img_carousel").sliderPro({
      width: 800,
      height: 500,
      fade: true,
      arrows: true,
      buttons: false,
      fullScreen: false,
      smallSize: 500,
      startSlide: 0,
      mediumSize: 1000,
      largeSize: 3000,
      thumbnailArrows: true,
      autoplay: true
    });
  }


 componentWillReceiveProps (nextProps) {


    if(this.props.match.params.tourid  != nextProps.match.params.tourid){
      console.log('this.props',this.props);

      this.setState({

         tourid:nextProps.match.params.tourid

        },function(){
           
           this.scrolltop();
           this.gettour();

        }.bind(this))

      
    }

    }

  gettour() {
    const Id_Language = this.getglobaldata("Id_Language");
    const Id_Currency = this.getglobaldata("Id_Currency");

    var options = {
      method: "GetTourDetails",
      query: {
        id: this.state.tourid,
        Id_Language: Id_Language,
        Id_Currency: Id_Currency,
        timestamp:'', //remove timestamp
      },
      callback: function (jsonresponse) {

        this.setState({
         tour:jsonresponse.tour_data[0],
         relatedtours:jsonresponse.SimilarTours,
         tourbring:jsonresponse.tour_bring,
         tourextras:jsonresponse.tour_extras,
         tourinclusions:jsonresponse.tour_inclusions,
         tourrequirements:jsonresponse.tour_requirements,
         tourreviews:jsonresponse.tour_reviews,

        },function(){

          //facebook pixel event
          this.fbp().trackCustom('TourDetails',{
            content_type:'product',
            content_name:this.state.tour.Name,
            //product_group:['3568354979886981'],
            city:this.state.tour.LocationName,
            content_ids:[this.state.tour.Id],
            country:'',
            region:'',
            checkin_date:'',
            checkout_date:'',
            //product_catalog_id:'241939520697998'
          })


          //$.loftloader.animation.finish(); // AVOID ERROR WHEN NO INIT

          this.forceUpdate();
          this.loadslide();

        }.bind(this))

        
        
        

      }.bind(this),
      error: function (jsonresponse) {
        this.pushnavigate("/tourdetailnotfound");
      }.bind(this)
    };
    console.log('options',options)

    this.single(options);
  }

  focusbooking(){
    
    ReactDOM.findDOMNode(this.refs.bookingt).focus();
  }


  randomnumberbetween(min,max){
   return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  urgentmessages(){
    
    var nowtimestamp = Math.floor(Date.now() /1000);
    var tourview = {
      timestamp:nowtimestamp,
      qty_1:5,
      qty_2:10,
      qty_3:10,
      qty_4:10,
      qty_5:5,
      qty_6:3,
      qty_7:this.randomnumberbetween(10,20)
    }

    if(!this.getglobaldata('tourview')) this.setglobaldata('tourview',tourview);

    var lasttourview = this.getglobaldata('tourview');


    if(Number(nowtimestamp)-Number(lasttourview.timestamp) > 3600) {
      tourview.qty_1 = this.randomnumberbetween(3,6);
      tourview.qty_2 = Number(lasttourview.qty_2)-1 == 0?tourview.qty_2:Number(lasttourview.qty_2)-1;
      tourview.qty_3 = Number(lasttourview.qty_3)-1 == 0?tourview.qty_3:Number(lasttourview.qty_3)-1;
      tourview.qty_4 = Number(lasttourview.qty_4)-1 == 0?tourview.qty_4:Number(lasttourview.qty_4)-1;
      tourview.qty_5 = this.randomnumberbetween(3,6);
      tourview.qty_6 = this.randomnumberbetween(2,4);
      
      this.getglobaldata('tourview',tourview);
    }

    var messages = [
       this.t('{{qty}} people booked in the last 24 hours',{qty:tourview.qty_1}),
       this.t('We have only {{qty}} tickets',{qty:tourview.qty_2}),
       this.t('High demand we have few places'),
       this.t('Only {{qty}} places left',{qty:tourview.qty_3}),
       this.t('Last minute offer'),
       this.t('OFFER'),
       this.t('Only {{qty}} tickets of this type are available in {{brandname}}',{qty:tourview.qty_4,brandname:this.config().brandname}),
       this.t('Booked {{qty}} times in the last 24 hours',{qty:tourview.qty_5}),
       this.t('Booked {{qty}} times in the last 6 hours',{qty:tourview.qty_6}),
       this.t('There are 24 hours left with this rate'),
       this.t('Limited availability'),
       this.t('Special sale'),
       this.t('There are {{qty}} more users watching this activity',{qty:tourview.qty_7}),
       //this.t('Offer 15% discount on this activity (offers on activities in the City)'),
       this.t('Weekend preference, high demand'),
       this.t('This activity is ready to close due to space limit'),

    ]
   /* var messages = [
      this.t('Este tour se ha reservado {{qty}} veces',{qty:5}),
      this.t(''),
      this.t(''),
      this.t(''),
      this.t(''),
      this.t(''),
      this.t(''),
      this.t(''),
      this.t(''),
      this.t(''),
    ]*/

    var randommessage = messages[Math.floor(Math.random() * messages.length)] || messages[0];

    return randommessage;

  }

  render() {



    var tour = this.state.tour;
    var relatedtours = this.state.relatedtours;

    var priceWithoutDiscount = Math.round((100*Math.round(tour.Price))/(100-tour.discountag));

    if (!this.state.tourid || !tour.Images) return null;



    var TabletThumbImages = [];
    if (tour.Images)
      if (tour.Images.TabletThumbImages)
        TabletThumbImages = tour.Images.TabletThumbImages;
     let disablemodule = this.state.disablemodule;

     let margin_60 = 'margin_60';
     if(disablemodule)  margin_60 = '';

    return (
      <div>

        {!disablemodule?
        <section
          className="parallax-window"
          data-parallax="scroll"
          
        >
          <div className="module-before" style={{ backgroundImage: 'url(' + TabletThumbImages[0] + ')', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center center' }}></div>
        

          <div className="parallax-content-2">
            <div className="container">
              <div className="row">
                <div className="col-md-7">
                  <h1>{tour.Name}</h1>
                  <span>{tour.LocationName}</span>



                  <div className="clock_i price_resptourA">
                    <i className="icon-clock" />
                    <small>
                      {" "}
                      {tour.hDuration == 0 ? '' : tour.hDuration + 'h'} {tour.mDuration == 0 ? '' : tour.mDuration + 'm'}
                    </small>
                  </div>
                </div>

                <div className="col-md-5 price_resptourA">
                        <div id="price_single_main">
                            {this.t("From")}<span><sup>$</sup>{this.currencyformat(Math.round(tour.Price)) || '...'}</span> {tour.CurrencyCode}
                            <p className="tour_msg">{this.urgentmessages()}</p>
                        </div>
                </div>


              </div>


              {/*This only shows on responsive*/} 
                <div className="row price_resptourB">
                    <div className="col-md-7">
                        <div id="price_single_main">
                            {this.t("From")}<span><sup>$</sup>{this.currencyformat(Math.round(tour.Price)) || '...'}</span> {tour.CurrencyCode}
                           <p className="tour_msg">{this.urgentmessages()}</p> 
                        </div>
                    </div>

                    <div className="col-md-5">
                     <div className="m-t-10"></div>
                        <div className="clock_i">
                            <i className="icon-clock"></i> 
                            <small>
                      {tour.hDuration == 0 ? '' : tour.hDuration + 'h'} {tour.mDuration == 0 ? '' : tour.mDuration + 'm'}
                    </small>
                        </div>
                    </div>


                </div>

            </div>
          </div>
        </section>:''}
        {/* End section */}


        
       <main>

       {!disablemodule?
        <div id="position">
            <div className="container">
              <ul>
                <li>
                  <NavLink to='/tours/1'>{this.t('Tours')}</NavLink>
                </li>
                <li>
                  {tour.Name}
                </li>
              </ul>
            </div>
          </div>:''}
          {/* End Position */}


        <div className={"container "+margin_60}>
        
        
        <AdviceMessage id_destination= {Number(tour.Id_Destination)} />
        
        
          <div className="row">
            <div className="col-lg-8">
        <div id="Img_carousel" className="slider-pro">
          <div className="sp-slides">
           
             {TabletThumbImages.map(function (image, index) {
                              return (
                                <div key={index} className="sp-slide">
                                 
                                  <img
                                    alt="Image"
                                    className="sp-image"
                                    src={this.image("css/images/blank.gif")}
                                    data-src={image}
                                    data-small={image}
                                    data-medium={image}
                                    data-large={image}
                                    data-retina={image}
                                  />
                                </div>
                              );
                            }.bind(this))}

          </div>
          <div className="sp-thumbnails">
            {TabletThumbImages.map(function (image_, index_) {
                              return (
                                <img
                                  key={index_}
                                  alt="Image"
                                  className="sp-thumbnail"
                                  src={image_}
                                />
                              );
                            })}
          </div>
          

          {tour.discountag != '' && this.config().discounttag?
          <div className="discount_tag_details"><i className="icon-tag"></i>-{tour.discountag}%</div>
          :null
           }
        </div>
        <div className="m-t-30" />

        {/* Nav tabs */}
        <ul className="nav nav-tabs" role="tablist">
          <li className="nav-item">
            <a className="nav-link active border_l border_ho" data-toggle="tab" href="#menu1">{this.t("Highlights")}</a>
          </li>
          <li className="nav-item">
            <a className="nav-link border_l" data-toggle="tab" href="#menu2">{this.t("Rules & Things")}</a>
          </li>

          {this.state.tourreviews.length > 0 ?
          <li className="nav-item">
            <a className="nav-link border_l" data-toggle="tab" href="#menu3">{this.t("Reviews")}</a>
          </li>
          :''}

          <li className="nav-item">
            <a className="nav-link border_l" data-toggle="tab" href="#menu4">{this.t("Map")}</a>
          </li>
          <a className="nav-link border_l navtab_resp" onClick={this.focusdiv.bind(this,'#bookingt')} href="javascript:void(0)">{this.t("Booking")}</a>
        </ul>
        {/* Tab panes */}
        <div className="tab-content">
          <div id="menu1" className="container tab-pane active">
            <div className="row">
              <div className="col-lg-12">
                <p className="blue_text"> <strong>{this.t('Tour Description')}</strong></p>
                <p style={{ whiteSpace: 'pre-line' }} dangerouslySetInnerHTML={{ __html: tour.LongDescription }}></p>

                {tour.hasTransportationIncluded == -1 && this.config().brand != 'avis'?
                  <p className="transport_i"><i className="icon-bus"></i><strong>{this.t('Transportation Included from Hotel')}</strong></p>
                  :''
                }

                {tour.cancellation_policy != ''?
                  <div>{tour.cancellation_policy}</div>
                  :null
                }

                {tour.hasOptionalTransportationIncluded == -1 && this.config().brand != 'avis'?
                  <p className="transport_i"><i className="icon-bus"></i><strong>{this.t('Transportation optional with additional cost is available')}</strong></p>
                  :''
                }

              {this.config().landinginfo?
               <p>
                <span className="blue_text"><b><i className="icon-clock"></i> <span className="tour_dur_title">{this.t('Tour:')} </span>{this.t('Duration:')}  {tour.hDuration == 0 ? '' : tour.hDuration + 'h'} {tour.mDuration == 0 ? '' : tour.mDuration + 'm'}</b></span>
                {this.config().landinginfo.MeetingPointTime !=''?<span className="blue_text"><br/><b><i className="icon-dot-circled"></i> {this.config().landinginfo.MeetingPointTime}</b></span>:''}
                {this.config().landinginfo.TourSpecification !=''?<span className="blue_text"><br/><b><i className="icon-dot-circled"></i> {this.config().landinginfo.TourSpecification}</b></span>:''}
              </p>:''}

              </div>
            </div>
          </div>{/*end menu1*/}


          {tour.hasStaticIERPage == -1 && tour.StaticIERPage  && (tour.StaticIERPage !='')?
          <div id="menu2" className="container tab-pane fade" dangerouslySetInnerHTML={{ __html: tour.StaticIERPage }}></div>
          :
          <div id="menu2" className="container tab-pane fade">
            
                      <RulesThings list={this.state.tourinclusions} title={this.t("What's included")} />
                      <RulesThings list={this.state.tourrequirements} title={this.t("Tour Requirement")} />
                      <RulesThings list={this.state.tourbring} title={this.t("What To Bring")} />

          </div>
          }
           {/*end menu2*/}


          {this.state.tourreviews.length > 0 ?
          <div id="menu3" className="container tab-pane fade">
            <div className="row">
              <div className="col-lg-12">
                {/* End general_rating */}
               
                {/* End review strip */}
                <TourReviews data={this.state.tourreviews} />
                {/* End review strip */}
              </div>
            </div>{/*end row*/}
          </div>
          :''}


          <div id="menu4" className="container tab-pane fade">
            <div className="row">
              <div className="col-lg-12">
                <div className="map_t collapse show" id="collapseMap" style={{height:'500px',overflow:'hidden'}}>  

<iframe frameBorder="0" style={{border:"0", marginTop: "-150px"}} src={"https://maps.google.com/maps?q=" + tour.GpsLocationLat + "," + tour.GpsLocationLong + "&hl=es;z=8&ie=UTF8&iwloc=B&output=embed"}></iframe>
         </div>   
              </div>
            </div>{/*end row*/}
          </div>{/*end menu4*/}
        </div>{/*end tab-content*/}

        
      


        {/* COVID Message */}

{/* 
        <div className="card">
        <div className="card-body card_tour">
          <div className="row">
            <div className="col-12">
              <div className="message_cov">
              <span className="blue_text"> {this.t('What to expect during your visit:')}:  <button className="seemore_cov" type="button" data-toggle="collapse" data-target="#collapse_cov" aria-expanded="false" aria-controls="collapse_tour">
              {this.t('Ver detalles')}
                  </button>
              </span>
                <div className="clearfix" />
                <div className="collapse" id="collapse_cov">
                  <div className="row">
                    <div className="col-lg-9">
                      <ul className="list_ok">
                        <li>{this.t('Face mask is mandatory for travelers in all public spaces')}</li>
                        <li>{this.t('Face mask is mandatory for guides in all public spaces')}</li>
                        <li>{this.t('Hand sanitizer available to travelers and staff')}</li>
                        <li>{this.t('Social distancing mandatory during all experience')}</li>
                        <li>{this.t('High traffic zones are sanitized regularly')}</li>
                        <li>{this.t('Equipment is sanitized between uses')}</li>
                        <li>{this.t('Transportation vehicles are sanitized regularly')}</li>
                        <li>{this.t('Guides are required to wash hands regularly')}</li>
                        <li>{this.t('Temperature checks for staff')}</li>
                        <li>{this.t('Temperature checks for travellers before the activity')}</li>
                      </ul>
                    </div>
                    <div className="col-lg-3">

                      <img
                        src={this.image(
                        "img/safe_travels.png"
                        )}
                       className="img-fluid safe_logo"
                      />
                

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
*/}




      </div>{/*end col-8*/}

            <div className="col-lg-4">
             <div id="bookingt" ref='bookingt'></div>
                  <div className="box_style_1">
                    <h3 className="inner">- {this.t("Booking")} -</h3>
                    

                    {this.state.soldout?
                    <h1 className="text-center sold_out_btn">{this.t('Sold Out')}</h1>
                    :
                     <TourAvailability match={this.props.match} origin='tourdetails' />
                    }

                  {/*  <a className="btn_wishactive" href="#"><i className=" icon-heart" />{this.t("Remove from whislist")}</a>
                  */} 
                  </div>

                 {this.config().brand != 'delmarboutiqueaccommodation'?
                  <div className="box_style_4">
                    <i className="icon_set_1_icon-57" />
                    <h4 dangerouslySetInnerHTML={{__html: this.t('Book online <span className="blue_text">or call</span>')}}></h4>
                   {this.config().phone != ''?
                    <a href={"tel:"+this.config().phone} className="phone"><i className="icon-mobile mobile_cart" />{this.config().phone}</a>
                   :''}
                    <a href={"mailto:"+this.config().email}>{this.config().email}</a><br/>


                    <small>{this.t("Our Vacation Concierge is available.")} <br />
                    {this.t("Everyday from 9:00am to 6:00pm Central")}</small>
                  </div>:null}

                
            </div>

          </div>
          {/*End row */}
          <div className="row">
          </div>
          {/*PEOPLE ALSO LIKE*/}


        {relatedtours.length >0 && this.config().brand != 'taconnect' && !disablemodule && process.env.REACT_APP_BOOKING_WIDGET != 'bookingwidget' && !this.config().adminid? 
        <div className="row">
              <div className="col-lg-12">
                <div className="main_title">
                  <h3 className="blue_text">
                    <strong>{this.translate('PEOPLE ALSO LIKE')}</strong>
                  </h3>
                </div>
              </div>
            </div>
            :''}

            {relatedtours.length >0  && this.config().brand != 'taconnect'  && !disablemodule && process.env.REACT_APP_BOOKING_WIDGET != 'bookingwidget' && !this.config().adminid?<ToursList tours={relatedtours} slider={true} />:''}

          </div>


        {/*End container */}
        <div id="overlay" />
        {/* Mask on input focus */}
      </main>

      </div>
    );
  }
}
export default withRouter(tourdetails);
