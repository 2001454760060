import React from "react";
import { NavLink,withRouter } from "react-router-dom";
import Global from '../globals/global';

//if (typeof window === "undefined")  var window={}
const $ = window.$;
class travelagentmessage extends Global {
  constructor(props) {
    super(props);


    this.state = {
    };
  }

  componentDidMount() {
   this.scrolltop();
    $('.jquery-background-video').bgVideo({fadeIn: 2000});
  }

  componentWillMount() {
   this.setappinstall();
  }


  setappinstall() {



    var options = {
      method: "TAgentClientAppInstall",
      callback: function(user) {
        
      }.bind(this),
      error: function(jsonresponse) {
       
      }.bind(this),

    };


    this.single(options);
  }


  render() {

    var user = this.getglobaldata('user');

    return (

        <section>
        {/* Start video hero */}
        <div className="video-hero jquery-background-video-wrapper demo-video-wrapper">
          <video className="jquery-background-video" autoPlay muted loop poster={this.image("video/totourav.jpg")}>
            <source src={this.image("video/totoura_v.mp4")} type="video/mp4" />
          </video>
          <div className="video-overlay" />
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-4 col-lg-5 col-md-6 col-sm-8">
                <div className="message_t">
                  <div className="text-center"><img src={this.image("img/logo_login_"+this.config().logo+".png")} alt="Image" data-retina="true" /></div>
                  <h6 dangerouslySetInnerHTML={{__html:this.t('A MESSAGE FROM <br />YOUR TRAVEL AGENT')}}></h6>
                  
                  <p style={{whiteSpace:'pre-line'}} dangerouslySetInnerHTML={{__html:user.TA_ClientMessage}}></p>
                  
                </div>
                <NavLink to="/home" className="btn btn_continuem">{this.t("Continue")}</NavLink>
              </div>
            </div>
          </div>
        </div>
        {/* End video hero */}
      </section>

      
    );
  }
}

export default travelagentmessage


