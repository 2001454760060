import React,{Component} from "react";
import { NavLink } from 'react-router-dom';
import Currency from "../components/component.currency";
import Language from "../components/component.language";
import moment from "moment";
import Global from '../globals/global';
//if (typeof window === "undefined")  var window={}
const $ = window.$;
const location = window.location;
var activestyle={
    fontWeight: "bold",
    color: "orange"
};


export default class footer extends Global {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentWillMount(){
           this.setmenuoptions();
           //this.forceUpdate()
    }

    render() {

         var disablepages = this.config().noheaderpages || [];

         const disable = disablepages.find(page => {
                return location.pathname.search(page) != -1;
         })
         if(disable) return null;
         
        return (


 <footer className="revealed footer-area">



  <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-footer-widget">
                <img src="https://embajador.toorzy.com/images/logo_toorzy_wh.png" alt="logo" className="img-fluid logo_header" />
                <p className="footer_left_text">
                {this.t("We are a company focused on providing the best customer experience through our family of ambassadors and our algorithms that allow us to sell, commission and monitor in an interactive way.")}
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-footer-widget">
                <h6>{this.t("Navigation")}</h6>
                <div className="row">
                  <div className="col">
                    <ul>
                    <li><NavLink to="/home">{this.t("Home")}</NavLink>  </li>
                    {this.config().tours?<li><NavLink to="/tours/1" activeStyle={activestyle} >{this.t("Tours")}</NavLink></li>:''}
                    {this.config().wishlist?<li><NavLink to="/wishlist" activeStyle={activestyle} >{this.t("Wishlist")}</NavLink></li>:''}
                    {this.config().contact?<li><NavLink to="/contact" activeStyle={activestyle} >{this.t("Contact")}</NavLink></li>:''}
                      {/*  <li><a href="https://embajador.toorzy.com/"> {this.t("Ambassador")} <i className="icon-star" /></a></li> */}
                    </ul>
                  </div>										
                </div>							
              </div>
            </div>	
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-footer-widget">
                <h6>{this.t("Store")}</h6>
                <div className="row">
                  <div className="col">
                    <ul>
                      <li><NavLink to="/cart" activeStyle={activestyle} >{this.t("Cart")}</NavLink></li>
                      <li><NavLink to="/checkout" activeStyle={activestyle} >{this.t("Checkout")}</NavLink></li>
                      <li><NavLink to="/terms" activeStyle={activestyle} >{this.t("Terms and condition")}</NavLink></li>
                      <li><NavLink to="/privacy" activeStyle={activestyle} >{this.t("Privacy Policy")}</NavLink></li>
                      <li><NavLink to="/cookies" activeStyle={activestyle} >{this.t("Cookies Policy")}</NavLink></li> 
                    </ul>
                  </div>										
                </div>							
              </div>
            </div>	
            <div className="col-lg-3  col-md-6 col-sm-6">
              <div className="single-footer-widget mail-chimp">
                <h6 className="mb-20">{this.t("Contact")}</h6>
                <ul>

                {this.config().phone != ''?
                  <li><a href="tel:1-855-847-5333"><i className="fas fa-phone-volume" /> 1-855-847-5333 USA/CANADA</a></li>
                  :''
                 }
                 
                  <li><a href="tel:322-888-3438"><i className="fas fa-phone-volume" /> +322 888 3438 {this.t("mexicofooter")}</a></li>
                  <li><a href="mailto:info@toorzy.com"><i className="fas fa-envelope" /> info@toorzy.com</a></li>
                  <li>
                    <div className="styled-select">
                      <Language header={false} />
                    </div>
                    <div className="styled-select">
                      <Currency header={false} />
                    </div>
                  </li>
                </ul>
              </div>
            </div>						
          </div>
          <div className="footer-bottom">
            <div className="row align-items-center">
              <p className="col-lg-8 col-sm-12 footer-text m-0  text-lg-left co_toor">
              {new Date().getUTCFullYear()} © Toorzy.com
              </p>
              <div className="col-lg-4 col-sm-12 footer-social text-center text-lg-right">
                <a href="https://www.instagram.com/toorzytours"><i className="fab fa-instagram" /></a>
              </div>
            </div>
          </div>
        </div>




{/*  <div className='text-center'>v {this.version()}</div> */} 
  
</footer>

        );
    }
}

