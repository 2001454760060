import React, { Component } from 'react';
import { NavLink,withRouter } from "react-router-dom";
import queryString from 'query-string';
import Global from '../globals/global';

//if (typeof window === "undefined")  var window={}

const $ = window.$;
const location = window.location;

class bancomerendpoint extends Global {

    constructor(props) {
        super(props);

          
          var result = false;

         if (this.props.match) {
      if (this.props.match.params) {

          if (this.props.match.params.result) result = this.props.match.params.result;
          
        
      }
    }

        this.state = {
           result:result
        };
    }

   

    componentDidMount() {

        var query = {nbResponse:''}; //queryString.parse(location.search);

        if(this.state.result == 'success')  {
          query.nbResponse = 'Aprobado';


          
          var iosmessage = String(JSON.stringify(query)); 

          try {

            
            window.webkit.messageHandlers.iosHandler.postMessage(iosmessage);
           } catch(err) {
             console.log("ErrorUpdated4",err)
            
           }

        }
        else{

           //google analytics event
        this.ga().event({
         category: 'Checkout',
         action: 'Payment declined',
         label: 'Payment declined'
        });

        }

          window.parent.postMessage(query, '*');

        


    }

    componentDidUpdate(prevProps, prevState) {
       
    }

  
    render() {
      var query = queryString.parse(location.search);
     
      return (
        <main>
         
         <div className="clearfix" />
        <div className="container m-t-10">

{this.state.result == 'pending'?
<div className="row">
        <div className="col-md-7 mx-auto icon_approved">
          <i className="icon-ok-circled approved_i" />
          <h3>{this.t('Pendiente de aprobación')}</h3>
          
        </div>
      </div>:''}


{this.state.result == 'success'?
 <div className="row">
        <div className="col-md-7 mx-auto icon_approved">
          <i className="icon-ok-circled approved_i" />
          <h3>{this.t('Aprobado')}</h3>

          
        </div>
      </div>:''}


               {this.state.result == 'error'?    <div className="row">
                        <div className="col-md-7 mx-auto icon_approved">
                  
                   
                            <i className="icon-cancel-circled cancel_i"></i>
                            <h3>{this.t('Transacción declinada')}</h3>
                             <p>{this.t('No se pudo realizar el pago')}</p>

                           


                             <p>
                             {this.t("o si necesitas ayuda comunicate al {{phone}}", {phone:this.config().phone})}
                             </p>
                        </div>

                    </div>:''}


        </div>
       
        </main>

        );

        return null;
    }
}

export default bancomerendpoint