import React, { Component } from 'react';
import { NavLink, withRouter } from "react-router-dom";
import Global from '../globals/global';
import PurchaseHistoryDetailTransferCard from './component.purchase.history.detail.transfer.card';

class PurchaseHistoryDetailTransfer extends Global {
    render() {
        const {
            Description,
            Date,
            AdultsNum,
            ChildsNum,
            ChildFreeNum,
            ConfirmCode,
            Status,
            Code,
            Amount,
            Id_TripType } = this.props.data;
        return (
            <div>
                <div className="row">
                    <div className="col-lg-12 purchasetitle">
                        <h3>{this.t("Purchase History")}</h3>
                        <NavLink to="/myaccount"><i className="icon-back"></i>{this.t("Back")}</NavLink>
                    </div>
                </div>


                <div className="strip_booking">
                    <div className="row">
                        <div className="col-md-12 purchase_t">
                            <div className="table-responsive">
                                <h6 className="title_accdet">{Description}</h6>
                                <table className="table table-striped">
                                    <thead>
                                        <tr className="success_t">
                                            <th>{this.t("Transaction Date")}</th>
                                            <th>{this.t("Type")}</th>
                                            <th>{this.t("Reservation Recap")}</th>
                                            <th>{this.t("Receipt Number")}</th>
                                            <th>{this.t("Status")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th>{Date}</th>
                                            <td>{this.t("Transfer")}</td>
                                            <td>{`${AdultsNum} Adults`}{ChildsNum > 0 ? `, ${ChildsNum} Childs` : ''}{ChildFreeNum > 0 ? `, ${ChildFreeNum} ChildFree` : ''}</td>
                                            <td>{ConfirmCode}</td>
                                            <td>{Status}</td>
                                        </tr>
                                        <tr className="amount_back">
                                            <td colSpan="6" className="">{this.t('Amount Paid:')}<strong>{`${Code} $${Amount}`}</strong>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            {
                                Id_TripType === '1' ? (
                                    <PurchaseHistoryDetailTransferCard data={{
                                        title: 'Arrival Info',
                                        date: this.props.data.ArrivalDate,
                                        time: this.props.data.ArrivalTime,
                                        airline: this.props.data.ArrivalAirlineName,
                                        flight: this.props.data.ArrivalFlightNum,
                                        airport: this.props.data.ArrivalAirportName,
                                        instructions: this.props.data.Instructions
                                    }} />
                                ) : Id_TripType === '2' ? (
                                    <PurchaseHistoryDetailTransferCard data={{
                                        title: 'Departure Info',
                                        date: this.props.data.DepartureDate,
                                        time: this.props.data.DepartureTime,
                                        airline: this.props.data.DepartureAirlineName,
                                        flight: this.props.data.DepartureFlightNum,
                                        airport: this.props.data.DepartureAirportName,
                                        instructions: this.props.data.Instructions
                                    }} />
                                ) : (
                                            <div>
                                                <PurchaseHistoryDetailTransferCard data={{
                                                    title: 'Arrival Info',
                                                    date: this.props.data.ArrivalDate,
                                                    time: this.props.data.ArrivalTime,
                                                    airline: this.props.data.ArrivalAirlineName,
                                                    flight: this.props.data.ArrivalFlightNum,
                                                    airport: this.props.data.ArrivalAirportName,
                                                    instructions: this.props.data.Instructions
                                                }} />
                                                <PurchaseHistoryDetailTransferCard data={{
                                                    title: 'Departure Info',
                                                    date: this.props.data.DepartureDate,
                                                    time: this.props.data.DepartureTime,
                                                    airline: this.props.data.DepartureAirlineName,
                                                    flight: this.props.data.DepartureFlightNum,
                                                    airport: this.props.data.DepartureAirportName,
                                                    instructions: this.props.data.Instructions
                                                }} />
                                            </div>
                                        )
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(PurchaseHistoryDetailTransfer)