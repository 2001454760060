import React, { Component } from 'react';
import { NavLink, withRouter } from "react-router-dom";
import AirlinesSelector from './component.airlines.selector';
import PersonsSelector from './component.persons.selector';
import Coupon from './component.coupon';
import DatePicker from 'react-datepicker';
import AgeSelector from './component.age.selector';
import TransferPricesTable from './component.transfer.prices.table';
import moment from 'moment';
import { setHours, setMinutes } from 'date-fns';
import Global from '../globals/global';

//if (typeof window === "undefined")  var window={}
const $ = window.$;

const ACTION_ATC = 'add_to_cart';
const ACTION_CHK = 'checkout';
const ACTION_CHG = 'change';
const ACTION_ED = 'edit';
const TIME_INTERVAL = 15;
class TransferPricesDetails extends Global {

    constructor(props) {
        super(props);

        this.airportInfo = this.airportInfo.bind(this);

        this.handleAdults = this.handleAdults.bind(this);
        this.handleChildren = this.handleChildren.bind(this);
        this.handleArrivalDatePickerCustom = this.handleArrivalDatePickerCustom.bind(this);
        this.handleArrivalAirlinesSelector = this.handleArrivalAirlinesSelector.bind(this);
        this.handleDepartureDatePickerCustom = this.handleDepartureDatePickerCustom.bind(this);
        this.handleDepartureAirlinesSelector = this.handleDepartureAirlinesSelector.bind(this);
        this.handleAgeSelector = this.handleAgeSelector.bind(this);
        this.handleCoupon = this.handleCoupon.bind(this);
        this.addToCart = this.addToCart.bind(this);
        this.builAgeChildSelector = this.builAgeChildSelector.bind(this);
        this.validate = this.validate.bind(this);
        this.comparteCart = this.comparteCart.bind(this);
        this.setLocalStorage = this.setLocalStorage.bind(this);
        this.buildCart = this.buildCart.bind(this);

        var couponcode = '';

       // this.config().groupinfo = {transfercoupon:'TEST-201905',freetransfersids:[14,15]}
        if(this.config().agencyinfo){
            if(this.config().agencyinfo.TransfersCoupon){
                
                if(this.props.match){
                if(this.props.match.params.id_transfer){

                var transferid = this.config().agencyinfo.coupontransfersids.find(transferid_=>{
                    return this.props.match.params.id_transfer == transferid_
                })

                if(transferid){
                    couponcode = this.config().agencyinfo.TransfersCoupon
                  }

              }
          }

                
            }
        }  

        this.state = {
            filters: {
              arrivaldate: new Date(),
              departuredate: new Date(),
              arrivaltime: '',
              departuredate: '',
              adultsnum: 1,
              childrennum: 0,
              childrenages: [],
              couponcode: couponcode,
              totalfordiscount:0,
              extras:[]
            },
            destinationid: null,
            data: null,
            languageid: null,
            currencyid: null,
            Id_Transfer: null,
            Id_TransferPrice: null,
            Id_Airport_Arrival: null,
            ArrivalAirportName:'',
            Id_Airport_Departure: null,
            DepartureAirportName:'',
            type: null,
            airports: null,

            errors: null,

            adults: { qty: 1 },
            children: { qty: 0 },
            childrenFree: { qty: 0 },
            childrenAge: [],
            realchildrenqty:0,

            arrivalActivityDateTime: moment().add(24,'hours')._d,
            arrivalAirline: null,
            arrivalAirlineName:'',
            arrivalFlightNumber: '',

            departureActivityDateTime: moment().add(2,'days')._d,
            departureAirline: null,
            departureAirlineName:'',
            departureFlightNumber: '',

            MinBookingTimeDeparture: null,
            count: null,
            calculations: null,
            coupon: {
                Id_Coupon: -1,
                name: '',
                discount: 0,
                code:couponcode,
            },
            discount: null,
            isLogin: null,
            addedtocart:false,
            itemindex:null,
            disableHours:6,
        };
    }

    componentDidMount(){

      

      if(this.props.type === 'departure') this.state.departureActivityDateTime = moment().add(1,'day');
        
    }

    componentWillMount(){
       this.setfilters()
    }

    setfilters(){

        var Cart = this.getglobaldata("Cart") || [];
        var itemindex = this.props.itemindex;


        if (itemindex >= 0) {
         if (Cart[itemindex]) {

            
             const item = Cart[itemindex];

             this.state.children = item.children;
             
             if(!this.props.onlytable) {
                this.state.children.qty = Number(item.children.qty)+Number(item.childrenfree.qty);
                //this.setchildrenfree()
            }

             this.state.adults= item.adults;
             this.state.childrenFree  = item.childrenfree;
             this.state.group = item.group;
             //this.state.coupon = item.coupon;


             this.state.arrivalActivityDateTime = moment(item.arrivalActivityDateTime) < moment(this.state.arrivalActivityDateTime)?this.state.arrivalActivityDateTime:moment(item.arrivalActivityDateTime)._d;
             this.state.arrivalAirline = item.arrivalairlineid;
             this.state.arrivalAirlineName=item.arrivalairline;
             this.state.arrivalFlightNumber= item.arrivalFlightNumber;

             this.state.departureActivityDateTime = moment(item.departureActivityDateTime) < moment(this.state.departureActivityDateTime)?this.state.departureActivityDateTime:moment(item.departureActivityDateTime)._d;
             this.state.departureAirlineName=item.departureairline;
             this.state.departureAirline = item.departureairlineid;
             this.state.departureFlightNumber = item.departureFlightNumber;
             this.state.type = item.triptype;
             this.state.Id_Transfer = item.transferid;
             this.state.Id_TransferPrice = item.transferpriceid;
             
            
             this.state.ArrivalAirportName = item.arrivalairport;
             this.state.DepartureAirportName = item.departureairport;
             this.state.realchildrenqty = item.realchildrenqty;
             this.state.childrenAge = item.childrenages;
             this.state.coupon.code = item.coupon.code;



           }
        }

    }

    setMinBookingTime(MinBookingTime){

        //12 = 19

        //return 23;

        if(!MinBookingTime) MinBookingTime = 24;


        return MinBookingTime; 
       
    }

    componentDidUpdate(prevProps, prevState) {
        $('.react-datepicker__input-container input').attr('readonly','readonly');
        
        if (prevProps.data !== this.props.data) {
             // this.setfilters();

             var MinBookingTime = this.setMinBookingTime(this.props.data.MinBookingTime);

            
//alert(this.props.data.MinBookingTime);  
            this.setState({
                destinationid: this.props.destinationid,
                data: this.props.data,
                languageid: this.props.languageid,
                currencyid: this.props.currencyid,
                Id_Transfer: this.props.Id_Transfer,
                Id_TransferPrice: this.props.Id_TransferPrice,
                Id_Airport_Arrival: this.props.Id_Airport_Arrival,
                Id_Airport_Departure: this.props.Id_Airport_Departure,
                type: this.props.type,
                airports: this.props.airports,
                arrivalActivityDateTime: moment().add(MinBookingTime,'hours')._d,
                departureActivityDateTime: moment().add(Number(MinBookingTime)+this.state.disableHours,'hours')._d,
                errors: [],
                isLogin: this.props.isLogin,
                itemindex:this.props.itemindex,
                arrivalActivityDateMinTime: moment().add(MinBookingTime,'hours')._d,
                departureActivityDateMinTime: moment().add(Number(MinBookingTime)+this.state.disableHours,'hours')._d,
            },function(){


            }.bind(this));
        }

        if (prevState.adults !== this.state.adults ||
            prevState.children !== this.state.children ||
            prevState.arrivalActivityDateTime !== this.state.arrivalActivityDateTime ||
            prevState.arrivalAirline !== this.state.arrivalAirline ||
            prevState.arrivalFlightNumber !== this.state.arrivalFlightNumber ||
            prevState.departureActivityDateTime !== this.state.departureActivityDateTime ||
            prevState.departureAirline !== this.state.departureAirline ||
            prevState.departureFlightNumber !== this.state.departureFlightNumber ||
            prevState.coupon !== this.state.coupon) {
            this.addToCart(ACTION_CHG);
        }

        if (prevState.arrivalActivityDateTime !== this.state.arrivalActivityDateTime) {

          

            var arrivalActivityDateTime = moment(this.state.arrivalActivityDateTime)._d;

             let date = arrivalActivityDateTime; //new Date(this.state.arrivalActivityDateTime.getTime());

            

           // let date = new Date(arrivalActivityDateTime.getTime());
           //date.setDate(date.getDate() + 1);
            //const today = new Date();
            //const milliseconds = date.getTime() - today.getTime();
            this.setState({
                //MinBookingTimeDeparture: Math.floor(milliseconds / 3600000),
            });
        }

        // if (prevState.children !== this.state.children) {
        //     if (this.state.childrenAge.length === 0) {
        //         let childrenAge = [];
        //         for (let i = 0; i < this.state.children.qty; i++) {
        //             childrenAge.push({
        //                 number: i + 1,
        //                 value: 0
        //             });
        //         }
        //         this.setState({
        //             childrenAge: childrenAge,
        //         });
        //     } else if (this.state.childrenAge.length < this.state.children.qty) {
        //         let childrenAge = this.state.childrenAge.map(data => data);
        //         for (let i = this.state.childrenAge.length; i < this.state.children.qty; i++) {
        //             childrenAge.push({
        //                 number: i + 1,
        //                 value: 0
        //             });
        //         }
        //         this.setState({
        //             childrenAge: childrenAge,
        //         });
        //     } else if (this.state.childrenAge.length > this.state.children.qty) {
        //         let childrenAge = this.state.childrenAge.filter(data => {
        //             if (data.number <= this.state.children.qty) return data;
        //         });
        //         this.setState({
        //             childrenAge: childrenAge,
        //         });
        //     }
        // }
       
      /*  if (prevState.childrenAge !== this.state.childrenAge) {
            let childrenQty = this.state.children.qty;
            console.log('this.state.children.qty',this.state.children.qty)
            let childrenFreeQty = 0;
            this.state.childrenAge.forEach(element => {
                if (element.value <= this.state.data.MaxFreeAge) {
                    childrenFreeQty++;
                    childrenQty--;
                }
            });

            this.state.children.qty = childrenQty;

            this.setState({
                children: {
                    qty: childrenQty,
                },
                childrenFree: {
                    qty: childrenFreeQty,
                }
            });
        }*/

        if (prevState.errors !== this.state.errors) {
            this.props.handle(this.state.errors);
        }

        if (prevState.calculations !== this.state.calculations) {
            this.setLocalStorage();
        }

        if (prevState.discount !== this.state.discount) {

            if(!this.state.discount) return;
            if (this.state.discount.result) {
                this.setState({
                    coupon: {
                        Id_Coupon: this.state.discount.CouponInfo.Id_Coupon,
                        name: this.state.discount.CouponInfo.Name,
                        discount: this.state.discount.CouponInfo.DiscountAmount,
                        code: this.state.discount.CouponInfo.Code,
                    }
                });
            } else {
                let errors = [];
                errors.push(this.state.discount.errorMessage);
                this.setState({ errors: errors,
                coupon: {
                        Id_Coupon: -1,
                        name: '',
                        discount: 0,
                        code: '',
                    }
                     });
            }
        }

        if (prevState.isLogin !== this.state.isLogin) {
            this.validate();
        }
    }

    updateChildreAge(){
            if (this.state.childrenAge.length === 0) {
                let childrenAge = [];
                for (let i = 0; i < this.state.children.qty; i++) {
                    childrenAge.push({
                        number: i + 1,
                        value: 0
                    });
                }
                this.setState({
                    childrenAge: childrenAge,
                },function (){
                    this.setchildrenfree()
                } );
            } else if (this.state.childrenAge.length < this.state.children.qty) {
                let childrenAge = this.state.childrenAge.map(data => data);
                for (let i = this.state.childrenAge.length; i < this.state.children.qty; i++) {
                    childrenAge.push({
                        number: i + 1,
                        value: 0
                    });
                }
                this.setState({
                    childrenAge: childrenAge,
                },function (){
                    this.setchildrenfree()
                });
            } else if (this.state.childrenAge.length > this.state.children.qty) {
                let childrenAge = this.state.childrenAge.filter(data => {
                    if (data.number <= this.state.children.qty) return data;
                });
                this.setState({
                    childrenAge: childrenAge,
                },function (){
                    this.setchildrenfree()
                });
            }
    }

    airportInfo(type) {
        if (type === 'arrival') {
            //if(this.props.itemindex >=0) return this.state.ArrivalAirportName;
            const airport = this.state.airports?this.state.airports.find(airport => airport.Id === this.state.Id_Airport_Arrival):null;
            
            return airport?`${airport.Code} - ${airport.Name}`:(this.props.itemindex >=0? this.state.ArrivalAirportName:'');
            
        } else if (type === 'departure') {
             //if(this.props.itemindex >=0) return this.state.DepartureAirportName;
           const airport = this.state.airports?this.state.airports.find(airport => airport.Id === this.state.Id_Airport_Departure):null;

          
           return airport?`${airport.Code} - ${airport.Name}`:(this.props.itemindex >=0? this.state.DepartureAirportName:'');
           
        }
    }

    handleAdults(data) {
        this.setState({
            adults: {
                qty: data,
            },
        });
    }

    handleChildren(data) {

        this.setState({
            children: {
                qty: data,
            },
        },function(){
            // this.setchildrenfree()
            this.updateChildreAge()
        }.bind(this));
    }

    setchildrenfree(){

           
           let childrenQty = this.state.children.qty;
           let childrenFreeQty = 0;

            this.state.childrenAge.forEach(element => {
                if (parseInt(element.value) <= this.state.data.MaxFreeAge) {
                    childrenFreeQty++;
                    childrenQty--;
                }
            });

            this.setState({
                realchildrenqty:childrenQty,
                childrenFree: {
                    qty: childrenFreeQty,
                }
            },function(){
              this.buildCart()
            }.bind(this));
    }

    handleArrivalDatePickerCustom(data) {

       /* if(moment(data) > moment(this.state.arrivalActivityDateTime)){
           data = moment(data).startOf('day')._d;
         }*/

        var state = {
            arrivalActivityDateTime: data,
        }

        if(moment(data) >= moment(this.state.departureActivityDateTime)){

        state.departureActivityDateTime=moment(data).add(this.state.disableHours,'hours')._d//moment(this.state.departureActivityDateTime) <= moment(data) ? moment(data).add(24,'hours')._d:this.state.departureActivityDateTime,
        state.departureActivityDateMinTime=moment(data).add(this.state.disableHours,'hours').d
        }

        this.setState(state);
    }

    handleArrivalAirlinesSelector(data, number,airlinename) {
        this.setState({
            arrivalFlightNumber: number,
            arrivalAirline: data,
            arrivalAirlineName:airlinename
        });
    }

    handleDepartureDatePickerCustom(data) {


      /*   if(type ==='date'){

         if(moment(data) > moment(this.state.arrivalActivityDateTime)){

           
           data = moment(data).startOf('day')._d;
            alert(data)
         }else{
            data = moment(this.state.arrivalActivityDateTime).add(this.state.disableHours,'hours')._d;
         }
        }*/

        this.setState({
            departureActivityDateTime: data,
        });
    }

    handleDepartureAirlinesSelector(data, number,airlinename) {

        this.setState({
            departureFlightNumber: number,
            departureAirline: data,
            departureAirlineName:airlinename
        });
    }

    handleAgeSelector(number, value) {
        this.setState({
            childrenAge: this.state.childrenAge.map(data => {
                if (data.number === number) {
                    data.value = value;
                }
                return data;
            }),
        },function(){
            this.setchildrenfree()
        }.bind(this));
    }

    handleCoupon(discount) {

        if(!discount) this.validate();

        this.setState({
            discount: discount,
        });
    }

    addToCart(action) {
        this.setState({
            action: action,
        });
        this.validate();
        this.buildCart();
    }

    validate() {

        let errors = [];

        if (this.state.adults !== null && this.state.adults.qty < 1) {
            errors.push(this.t('At least one adult!'));
        }


        if (this.state.type === 'arrival') {
            if (this.state.arrivalAirline === null || this.state.arrivalAirline.length < 1) {
                errors.push(this.t('Select the airline of arrival!'));
            }

            if (this.state.arrivalFlightNumber.length == 0) {
                errors.push(this.t('Enter the arrival flight number!'));
            }

        } else if (this.state.type === 'departure') {
            if (this.state.departureAirline === null || this.state.departureAirline.length < 1) {
                errors.push(this.t('Select the starting airline!'));
            }
            if (this.state.departureFlightNumber.length == 0) {
                errors.push(this.t('Enter the departure flight number!'));
            }
        } else if (this.state.type === 'round') {
            if (this.state.arrivalAirline === null || this.state.arrivalAirline.length < 1) {
                errors.push(this.t('Select the airline of arrival!'));
            }
            if (this.state.departureAirline === null || this.state.departureAirline.length < 1) {
                errors.push(this.t('Select the starting airline!'));
            }

            if (this.state.arrivalFlightNumber.length == 0) {
                errors.push(this.t('Enter the arrival flight number!'));
            }

             if (this.state.departureFlightNumber.length == 0) {
                errors.push(this.t('Enter the departure flight number!'));
            }
        }
        if (!this.state.isLogin) {
            if(this.config().disablebooking) errors.push(this.t('Contact your travel agent to register for your benefits. You must speak with a concierge to book this option. In the mean time, you can browse our destinations.'));
        }

        this.setState({ errors: errors, });
        return errors.length === 0;
    }



    buildCart() {

        if(this.props.onlytable) this.setfilters();


        var PaymentBy = -1;
        var PaymentByName = '';

        let adults = {
            total: 0,
            qty: Number(this.state.adults.qty),
            age: 0,
            price: 0
        };

        let children = {
            total: 0,
            qty: Number(this.state.realchildrenqty),
            minage: Number(this.state.data.MinAge),
            maxage: this.state.data.MaxAgeChild === '-1' ? Number(this.state.data.MaxFreeAge) : Number(this.state.data.MaxAgeChild),
            price: 0
        };
        const childrenFree = {
            total: 0,
            qty: Number(this.state.childrenFree.qty),
            minage: Number(this.state.data.MinAge),
            maxage: this.state.data.MaxAgeChild === '-1' ? Number(this.state.data.MaxFreeAge) : Number(this.state.data.MaxAgeChild),
            price: 0
        };
        let group = {
            total: 0,
            qty: 0,
            age: 0,
            price: 0
        };
        let total = 0;

       /* if (PaymentBy === '1') { // Significa que es por persona 'Person'.
            if (this.state.type === 'arrival') {
                adults.price = Number(this.state.data.Person_Price_Arrival);
                children.price = Number(this.state.data.Child_2_Price_Arrival);
            } else if (this.state.type === 'departure') {
                adults.price = Number(this.state.data.Person_Price_Departure);
                children.price = Number(this.state.data.Child_2_Price_Departure);
            } else if (this.state.type === 'round') {
                adults.price = Number(this.state.data.Person_Price_Round);
                children.price = Number(this.state.data.Child_2_Price_Round);
            }
            adults.total = Number(adults.price * adults.qty);
            children.total = Number(children.price * (children.qty));
            total = (adults.total + children.total) - this.state.coupon.discount;
        } else if (PaymentBy === '2') { // Significa que es por vehículo 'Vehicle'.
            if (this.state.type === 'arrival') {
                group.price = Number(this.state.data.Trip_Price_Arrival);
            } else if (this.state.type === 'departure') {
                group.price = Number(this.state.data.Trip_Price_Departure);
            } else if (this.state.type === 'round') {
                group.price = Number(this.state.data.Trip_Price_Round);
            }
            group.total = group.price;
            group.qty = adults.qty + children.qty;
            total = group.total - this.state.coupon.discount;
        }
        */



          const transfer = this.state.data;

         

      if (this.state.type === 'arrival'){

            PaymentBy = transfer.ArrivalPricePaymentBy;
            PaymentByName = transfer.ArrivalPricePaymentBy_;

          if (Number(transfer.ArrivalPricePaymentBy)== 1) {

            adults.price = Number(this.state.data.Person_Price_Arrival);
            children.price = Number(this.state.data.Child_2_Price_Arrival);
            adults.total = Number(adults.price * adults.qty);
            children.total = Number(children.price * (children.qty));
            total = (adults.total + children.total) - this.state.coupon.discount;
            
          }else if (Number(transfer.ArrivalPricePaymentBy) == 2) {
            

            group.price = Number(this.state.data.Trip_Price_Arrival);
            group.total = group.price;
            group.qty = adults.qty + children.qty;
            total = group.total - this.state.coupon.discount;
           
          }
      }else if (this.state.type === 'departure'){

           PaymentBy = transfer.DeparturePricePaymentBy;
           PaymentByName = transfer.DeparturePricePaymentBy_;

          if (Number(transfer.DeparturePricePaymentBy) == 1) {

            adults.price = Number(this.state.data.Person_Price_Departure);
            children.price = Number(this.state.data.Child_2_Price_Departure);
            adults.total = Number(adults.price * adults.qty);
            children.total = Number(children.price * (children.qty));
            total = (adults.total + children.total) - this.state.coupon.discount;
            
          }else if (Number(transfer.DeparturePricePaymentBy) == 2) {

            group.price = Number(this.state.data.Trip_Price_Departure);
            group.total = group.price;
            group.qty = adults.qty + children.qty;
            total = group.total - this.state.coupon.discount;
           
          }
      }else if (this.state.type === 'round'){


          PaymentBy = transfer.RoundPricePaymentBy;
          PaymentByName = transfer.RoundPricePaymentBy_;

          if (Number(transfer.RoundPricePaymentBy) == 1) {

            adults.price = Number(this.state.data.Person_Price_Round);
            children.price = Number(this.state.data.Child_2_Price_Round);
            adults.total = Number(adults.price * adults.qty);
            children.total = Number(children.price * (children.qty));
            total = (adults.total + children.total) - this.state.coupon.discount;
            
          }else if (Number(transfer.RoundPricePaymentBy) == 2) {
            group.price = Number(this.state.data.Trip_Price_Round);
            group.total = group.price;
            group.qty = adults.qty + children.qty;
            total = group.total - this.state.coupon.discount;
          }
      }


        const calculations = {
            itemtype: 2,
            PaymentBy: PaymentBy,
            PaymentByName:PaymentByName,
            adults: adults,
            children: children,
            childrenfree: childrenFree,
            group: group,
            coupon: this.state.coupon,
            arrivalActivityDateTime: this.state.arrivalActivityDateTime,
            arrivalAirline: this.state.arrivalAirline,
            arrivalFlightNumber: this.state.arrivalFlightNumber,
            departureActivityDateTime: this.state.departureActivityDateTime,
            departureAirline: this.state.departureAirline,
            departureFlightNumber: this.state.departureFlightNumber,
            total: total,
            triptype:this.state.type,
            tranfertype:this.state.data.Name,
            destinationid:this.state.data.Id_Destination,
            currency:this.state.data.CurrencyCode,
            hotelname:this.getglobaldata('hotelname'),
            triptypename:this.state.type === 'round' ? this.t('ROUND TRIP') : this.state.type === 'arrival' ? this.t('ARRIVAL ONLY') : this.state.type === 'departure' ? this.t('DEPARTURE ONLY') : null,
            triptypeid:this.state.type === 'round' ? 3 : this.state.type === 'arrival' ? 1 : this.state.type === 'departure' ? 2 : null,
            hotelid:this.getglobaldata('Id_Hotel'),
            arrivalairport:this.airportInfo('arrival'),
            departureairport:this.airportInfo('departure'),
            arrivalairportid:this.state.Id_Airport_Arrival,
            departureairportid:this.state.Id_Airport_Departure,
            arrivalairlineid:this.state.arrivalAirline,
            arrivalairline:this.state.arrivalAirlineName,
            departureairlineid:this.state.departureAirline,
            departureairline:this.state.departureAirlineName,
            arrivalflight:this.state.arrivalFlightNumber,
            departureflight:this.state.departureFlightNumber,
            transferid:this.state.Id_Transfer,
            transferpriceid:this.state.Id_TransferPrice,
            realchildrenqty:this.state.realchildrenqty,
            childrenages:this.state.childrenAge,
           /* filters:{
              arrivaldatetime: this.state.arrivalActivityDateTime,
              departuredatetime: this.state.departureActivityDateTime
              adultsnum: 1,
              childrennum: 0,
              childrenages: [],
              couponcode: ""
            }*/

        };

        this.setState({
            calculations: calculations
        },function(){

          if(this.props.itemindex>=0)  this.setLocalStorage()
        }.bind(this));
    }

    comparteCart(cart) {
        const matches = (obj1, obj2) => Object.keys(obj1).every(key => {
            if (key === 'arrivalActivityDateTime' || key === 'departureActivityDateTime') {
                obj1[key] = new Date(obj1[key]).toString();
                obj2[key] = new Date(obj2[key]).toString();
            }
            if ((typeof obj1[key] === 'object') || (typeof obj2[key] === 'object')) {
                return matches(obj1[key], obj2[key]);
            } else {
                return obj1[key] === obj2[key]
            }
        });
        return matches(this.state.calculations, cart);
    }

    setLocalStorage() {
        if (!this.getglobaldata("Cart")) this.setglobaldata("Cart", []);
        var Cart = this.getglobaldata("Cart");


        if(this.props.itemindex>=0) {

              
              if(this.props.onlytable)  {
                Cart[this.props.itemindex].adults = this.state.calculations.adults;
                Cart[this.props.itemindex].children = this.state.calculations.children;
                Cart[this.props.itemindex].childrenfree = this.state.calculations.childrenfree;
                Cart[this.props.itemindex].group = this.state.calculations.group;
                Cart[this.props.itemindex].total = this.state.calculations.total;
                Cart[this.props.itemindex].coupon = this.state.calculations.coupon;
                Cart[this.props.itemindex].currency = this.state.calculations.currency;
                //Cart[this.props.itemindex].childrenages = this.state.calculations.childrenages;
                //Cart[this.props.itemindex].realchildrenqty = this.state.calculations.realchildrenqty;
              }else Cart[this.props.itemindex] = this.state.calculations;

              /*  Cart[this.props.itemindex].departureActivityDateTime = this.state.calculations.departureActivityDateTime;
                Cart[this.props.itemindex].arrivalActivityDateTime = this.state.calculations.arrivalActivityDateTime;
              */

              //  Cart[this.props.itemindex] = this.state.calculations;


                this.setglobaldata("Cart", Cart);
                if(this.props.onlytable) {
                   this.senddispatcher('cart.view',{});// if(Cart.length-1 == this.props.itemindex) this.senddispatcher('cart.view',{});
                }
        }

        if(this.state.action === ACTION_CHG) this.state.addedtocart = false;

        if (this.state.errors.length === 0 &&
            (this.state.action === ACTION_ATC || this.state.action === ACTION_CHK)) {
           
           /* if (Cart.length > 0) {
                let exits = true;
                Cart = Cart.map(cart => {
                    if (cart.itemtype === 2) {
                        if (!this.comparteCart(cart)) {
                            cart = this.state.calculations;
                            exits = false;
                        }
                    }
                    return cart;
                });
                if (!exits) {
                    Cart.push(this.state.calculations);
                }
            } else {
                Cart.push(this.state.calculations);
            }*/

            

            if(this.state.action === ACTION_ATC && !this.state.addedtocart) {
                Cart.push(this.state.calculations);
                this.state.addedtocart = true;
            }

            this.setglobaldata("Cart", Cart);
            this.senddispatcher("cart.header", {});
            if (this.state.action === ACTION_CHK) {
                if(!this.state.addedtocart) {
                    Cart.push(this.state.calculations);
                    this.setglobaldata("Cart", Cart);
                    this.senddispatcher("cart.header", {});
                }
                this.props.history.push('/cart');
            }
        }
        this.setState({
            count: Cart.length,
        });
    }

    builAgeChildSelector() {
        var options = [];
        const maxAge = Number(this.state.data.MaxAgeChild) == -1 ? this.state.data.MaxFreeAge : this.state.data.MaxAgeChild;
        for (var i = 0; i < this.state.children.qty; i++) {

            var ageselected = this.state.childrenAge;
            options.push(
                <div key={`AgeSelector-${i}`} className="col-md-3">
                    <AgeSelector value={ageselected[i]?ageselected[i].value:0} number={i + 1} MinAge={this.state.data.MinAge} MaxAgeChild={maxAge} handle={this.handleAgeSelector} />
                </div>
            );
        }
        return options;
    }


    arrivalDatepickerOptions(){

         var minBookingTime = 24;
         if(this.state.data) minBookingTime = this.state.data.MinBookingTime;
         
         var selectedDate = this.state.arrivalActivityDateTime;
         var mindatetime = moment().add(minBookingTime,'hours');
         const arrivaldate = moment(this.state.arrivalActivityDateTime).startOf('day')._d;
         const mindate = moment().add(minBookingTime,'hours').startOf('day')._d;


         if(moment(arrivaldate) <= moment(mindate)){
           
             mindatetime = mindatetime._d;

             if(moment(selectedDate) < mindatetime)
             selectedDate = mindatetime;
             
         }else{
             mindatetime = mindatetime.startOf('day')._d;
         }
        
         

        return {
            selected:selectedDate,
            minTime:mindatetime
        }
    }


    datepickeroffdays(){
        
        var days = [];
        if(this.state.data){

            if(Number(this.state.data.Id_Destination) === 3){
            days = [
                moment('2023-09-13')._d, 
                moment('2023-09-14')._d, 
                moment('2023-09-15')._d, 
                moment('2023-09-16')._d, 
                moment('2023-09-17')._d, 
                moment('2023-09-18')._d, 
                moment('2023-09-19')._d, 
                moment('2023-09-20')._d, 
            ]
        }

        }

        return days;
        
    }


    departureDatepickerOptions(type){

         var minBookingTime = 24;
         if(this.state.data) minBookingTime = this.state.data.MinBookingTime;
         
         var selectedDate = this.state.departureActivityDateTime;
         var mindatetime = moment().add(minBookingTime,'hours');
         const departuredate = moment(this.state.departureActivityDateTime).startOf('day')._d;
         const mindate = moment().add(minBookingTime,'hours').startOf('day')._d;
         const arrivaldate = moment(this.state.arrivalActivityDateTime).startOf('day')._d;


         


         if(type === 'single'){
             if(moment(departuredate) <= moment(mindate)){
             mindatetime = mindatetime._d;

             if(moment(selectedDate) < mindatetime)
             selectedDate = mindatetime;
             
         }else{
             mindatetime = mindatetime.startOf('day')._d;
         }

         }else{
             
             const mindate_2 = moment(this.state.arrivalActivityDateTime).add(this.state.disableHours,'hours').startOf('day');

             if(moment(departuredate) <= moment(mindate_2)){
             mindatetime = moment(this.state.arrivalActivityDateTime).add(this.state.disableHours,'hours')._d;

             if(moment(selectedDate) < mindatetime)
             selectedDate = mindatetime;
             
         }else{
             mindatetime = mindatetime.startOf('day')._d;
         }

         }
        

        return {
            selected:selectedDate,
            minTime:mindatetime
        }
    }

   


    render() {
        
        var minBookingTime = 24;
        if(this.state.data) minBookingTime = this.state.data.MinBookingTime;


        var withPortal = true;
        //if(this.ismobile()) withPortal = false;

           console.log('this.state.calculations',this.state.calculations)

       // if(!this.state.calculations) return null;

     


        var maxAgeChildren = -1;
        if(this.state.data) maxAgeChildren = Number(this.state.data.MaxAgeChild) == -1 ? Number(this.state.data.MaxFreeAge) : Number(this.state.data.MaxAgeChild);

         if (this.props.onlytable && this.state.calculations) return (
            <div>
            <TransferPricesTable type={this.state.type} data={this.state.calculations} />

            {
                            this.state.type === 'round' ? (
                                (this.state.arrivalActivityDateTime !== null &&
                                    this.state.departureActivityDateTime !== null &&
                                    this.state.adults !== null &&
                                    this.state.children !== null &&
                                    this.state.Id_Transfer !== null &&
                                    this.state.Id_TransferPrice !== null &&
                                    this.state.Id_Airport_Arrival !== null &&
                                    this.state.Id_Airport_Departure !== null) ? (
                                        <Coupon
                                            onlytable={this.props.onlytable}
                                            Id_ActivityType={2}
                                            ArrivalDate={this.state.arrivalActivityDateTime}
                                            DepartureDate={this.state.departureActivityDateTime}
                                            AdultsNum={this.state.adults.qty}
                                            ChildsNum={this.state.children.qty}
                                            Id_Activity={this.state.Id_Transfer}
                                            Id_TransferPrice={this.state.Id_TransferPrice}
                                            hasMultipleAirPorts={
                                                this.state.Id_Airport_Arrival === this.state.Id_Airport_Departure ? 0 : -1
                                            }
                                            Id_TripType={3}
                                            Id_TravelAgent={
                                                this.state.Id_TravelAgent !== null ? this.state.Id_TravelAgent : null
                                            }
                                            Id_Language={this.getglobaldata('Id_Language')}
                                            Id_Currency={this.getglobaldata('Id_Currency')}
                                            total={this.state.calculations?(this.state.calculations.total || 0):''}
                                            code={this.state.coupon.code}
                                            handle={this.handleCoupon}
                                        />
                                    ) : null
                            ) : (
                                    (this.state.arrivalActivityDateTime !== null &&
                                        this.state.adults !== null &&
                                        this.state.children !== null &&
                                        this.state.Id_Transfer !== null &&
                                        this.state.Id_TransferPrice !== null &&
                                        this.state.Id_Airport_Arrival !== null &&
                                        this.state.Id_Airport_Departure !== null) ? (
                                            <Coupon
                                                onlytable={this.props.onlytable}
                                                Id_ActivityType={2}
                                                ArrivalDate={this.state.arrivalActivityDateTime}
                                                DepartureDate={this.state.arrivalActivityDateTime}
                                                AdultsNum={this.state.adults.qty}
                                                ChildsNum={this.state.children.qty}
                                                Id_Activity={this.state.Id_Transfer}
                                                Id_TransferPrice={this.state.Id_TransferPrice}
                                                hasMultipleAirPorts={
                                                    this.state.Id_Airport_Arrival === this.state.Id_Airport_Departure ? 0 : -1
                                                }
                                                Id_TripType={
                                                    this.state.type === 'departure' ? 2 :
                                                        this.state.type === 'arrival' ? 1 : -1
                                                }
                                                Id_TravelAgent={
                                                    this.state.Id_TravelAgent !== null ? this.state.Id_TravelAgent : null
                                                }
                                                Id_Language={this.getglobaldata('Id_Language')}
                                                Id_Currency={this.getglobaldata('Id_Currency')}
                                                total={this.state.calculations?(this.state.calculations.total || 0):''}
                                                code={this.state.coupon.code}
                                                handle={this.handleCoupon}
                                            />
                                        ) : null
                                )
                        }
                        </div>
        );

        return (
            this.state.data !== null ? (
                <div className="tour_container">
                    <div className="tour_title">
                        <h3>
                            <strong>
                                {this.t('TRANSFER TYPE:')} {this.state.data.Name},
                                {
                                    this.state.type === 'round' ? this.t('ROUND TRIP') : this.state.type === 'arrival' ? this.t('ARRIVAL ONLY') : this.state.type === 'departure' ? this.t('DEPARTURE ONLY') : null
                                }
                            </strong>
                        </h3>
                        <span>{`${this.t('HOTEL')}: ${this.getglobaldata('hotelname')}`}</span><br />
                        <span className="color_red">
                            {this.state.data.Description}
                        </span>
                        <br />
                        {
                            this.state.type === 'round' ? (
                                <div>
                                    <span><i className="icon-plane planev_arri"> </i><strong className="blue_text">{this.t('Arrival:')}</strong> {this.airportInfo('arrival')}</span>
                                    <br />
                                    <span><i className="icon-plane planev_dep"></i><strong className="blue_text">{this.t('Departure:')}</strong> {this.airportInfo('departure')}</span>
                                </div>
                            ) : <span><i className={"icon-plane planev_"+this.state.type=='arrival'?'arri':'dep'}></i> {this.airportInfo(this.state.type)}</span>
                        }
                    </div>

                    <div className="tour_title">
                        <div className="form-group row">
                            <PersonsSelector label={this.t("Adults")+(maxAgeChildren==-1?`/${this.t('Children')}`:'')} value={this.state.adults.qty} minValue="1" maxValue={
                                this.state.type === 'round' ? this.state.data.QtyInventoryPTransfer :
                                    this.state.type === 'arrival' ? this.state.data.ArrivalQtyInventoryPTransfer :
                                        this.state.type === 'departure' ? this.state.data.DepartureQtyInventoryPTransfer : 0
                            }
                                handle={this.handleAdults} />

                           
                           {maxAgeChildren > 0 ?
                            <PersonsSelector label={this.t('Children')} value={this.state.children.qty} minValue="0" maxValue={
                                this.state.type === 'round' ? this.state.data.QtyInventoryPTransfer :
                                    this.state.type === 'arrival' ? this.state.data.ArrivalQtyInventoryPTransfer :
                                        this.state.type === 'departure' ? this.state.data.DepartureQtyInventoryPTransfer : 0
                            }
                                handle={this.handleChildren} />
                                :''
                            }

                        </div>

                        {
                            this.state.children.qty > 0 ? (
                                <div className="form-group row">
                                    {
                                        this.builAgeChildSelector()
                                    }
                                </div>
                            ) : null
                        }

                        {  
                            this.state.type === 'round' ? (
                                <div>

             {/**************************Start Arival Date & time picker *****************************/}
                <div className="form-group row">
                    <div className="col-md-6">
                        <label>{this.t('Arrival Date')}</label>
                        <br />
                        <DatePicker
                            className="date-pick form-control"
                            placeholderText={this.t("Activity Date")}
                            excludeDates={this.datepickeroffdays()}
                            disabled={false}
                            withPortal={withPortal}
                            selected={this.arrivalDatepickerOptions().selected}
                            dateFormat='MMMM d, yyyy'
                            onChange={this.handleArrivalDatePickerCustom}

                            minDate={this.arrivalDatepickerOptions().minTime}
                            maxDate={moment().add(365, 'days')._d}
                        />
                    </div>

                    <div className="col-md-6">
                        <label>{this.t('Arrival FLIGHT Time')}</label>
                        <br />
                        <DatePicker
                            className="date-pick form-control"
                            placeholderText={this.t("Activity Time")}
                            disabled={false}
                            withPortal={withPortal}
                            showTimeSelect
                            showTimeSelectOnly
                       
                            selected={this.arrivalDatepickerOptions().selected}
                            dateFormat='hh:mm aa'
                            onChange={this.handleArrivalDatePickerCustom}

                            timeIntervals={15}
                            minTime={this.arrivalDatepickerOptions().minTime}
                            maxTime={setHours(setMinutes(this.state.arrivalActivityDateTime, 60 - TIME_INTERVAL), 23)}
                        />
                    </div>
                </div>
            {/**************************End Arival Date & time picker *****************************/}


                                    <AirlinesSelector type={this.state.type} airlinename={this.state.arrivalAirlineName} flightNumber={this.state.arrivalFlightNumber} option="start"
                                        handle={this.handleArrivalAirlinesSelector} airline={this.state.arrivalAirline} />

              
             {/**************************Start departure Date & time picker *****************************/}
                <div className="form-group row">
                    <div className="col-md-6">
                        <label>{this.t('Departrue Date')}</label>
                        <br />
                        <DatePicker
                            className="date-pick form-control"
                            placeholderText={this.t("Activity Date")}
                            excludeDates={this.datepickeroffdays()}
                            disabled={false}
                            withPortal={withPortal}
                            selected={this.departureDatepickerOptions().selected}
                            dateFormat='MMMM d, yyyy'
                            onChange={this.handleDepartureDatePickerCustom}

                            minDate={this.departureDatepickerOptions().minTime}
                            maxDate={moment().add(365, 'days')._d}
                        />
                    </div>

                    <div className="col-md-6">
                        <label>{this.t('Departure FLIGHT Time')}</label>
                        <br />
                        {console.log('this.state.departureActivityDateMinTime------------------12',this.state.departureActivityDateTime)}
                        <DatePicker
                            className="date-pick form-control"
                            placeholderText={this.t("Activity Time")}
                            disabled={false}
                            withPortal={withPortal}
                            showTimeSelect
                            showTimeSelectOnly

                            selected={this.departureDatepickerOptions().selected}
                            dateFormat='hh:mm aa'
                            onChange={this.handleDepartureDatePickerCustom}

                            timeIntervals={15}
                            minTime={this.departureDatepickerOptions().minTime}
                            maxTime={setHours(setMinutes(this.state.departureActivityDateTime, 60 - TIME_INTERVAL), 23)}
                        />
                    </div>
                </div>
            {/**************************End depaerture Date & time picker *****************************/}


                                    <AirlinesSelector type={this.state.type} option="end" airlinename={this.state.departureAirlineName} flightNumber={this.state.departureFlightNumber}
                                        handle={this.handleDepartureAirlinesSelector} airline={this.state.departureAirline} />
                                </div>

                            ) : (
                                    <div>
                                      

            {/**************************Start arrival ordeparture Date & time picker *****************************/}
                <div className="form-group row">
                    <div className="col-md-6">
                        <label>{this.t(this.state.type === 'arrival' ?'Arrival Date':'Departrue Date')}</label>
                        <br />
                        <DatePicker
                            className="date-pick form-control"
                            placeholderText={this.t("Activity Date")}

                            disabled={false}
                            withPortal={withPortal}
                            selected={this.state.type === 'arrival' ?this.arrivalDatepickerOptions().selected:this.departureDatepickerOptions('single').selected}
                            dateFormat='MMMM d, yyyy'
                            onChange={this.state.type === 'arrival' ?this.handleArrivalDatePickerCustom:this.handleDepartureDatePickerCustom}

                            minDate={this.state.type === 'arrival'?this.arrivalDatepickerOptions().minTime:this.departureDatepickerOptions('single').minTime}
                            maxDate={moment().add(365, 'days')._d}
                        />
                    </div>

                    <div className="col-md-6">
                        <label>{this.t(this.state.type === 'arrival' ?'Arrival Time':'Departure Time')}</label>
                        <br />
                        <DatePicker
                            className="date-pick form-control"
                            placeholderText={this.t("Activity Time")}
                            disabled={false}
                            withPortal={withPortal}
                            showTimeSelect
                            showTimeSelectOnly
                            
                            selected={this.state.type === 'arrival' ?this.arrivalDatepickerOptions().selected:this.departureDatepickerOptions('single').selected}
                            dateFormat='hh:mm aa'
                            onChange={this.state.type === 'arrival' ?this.handleArrivalDatePickerCustom:this.handleDepartureDatePickerCustom}

                            timeIntervals={15}
                            minTime={this.state.type === 'arrival'?this.arrivalDatepickerOptions().minTime:this.departureDatepickerOptions('single').minTime}
                            maxTime={setHours(setMinutes(this.state.type === 'arrival' ?this.state.arrivalActivityDateTime:this.state.departureActivityDateTime, 60 - TIME_INTERVAL), 23)}
                        />
                    </div>
                </div>
            {/**************************End Arrival or depaerture Date & time picker *****************************/}


                                        <AirlinesSelector airlinename={this.state.type === 'arrival'?this.state.arrivalAirlineName:this.state.departureAirlineName} airline={this.state.type === 'arrival' ?this.state.arrivalAirline:this.state.departureAirline} type={this.state.type} flightNumber={this.state.type === 'arrival' ?this.state.arrivalFlightNumber:this.state.departureFlightNumber} handle={
                                            this.state.type === 'arrival' ? this.handleArrivalAirlinesSelector : this.handleDepartureAirlinesSelector} />
                                    </div>
                                )
                        }


                        {
                            (this.state.errors !== null && this.state.errors.length > 0) ? (
                                <div className="errors">
                                    {
                                        this.state.errors.map((error, index) => {
                                            return (
                                                <div key={`icon-exclamation-${index}`}>
                                                    <i className="icon-exclamation"></i> <span>{error}</span><br />
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            ) : null
                        }
                        

                        {
                            this.state.type === 'round' ? (
                                (this.state.calculations && this.state.arrivalActivityDateTime !== null &&
                                    this.state.departureActivityDateTime !== null &&
                                    this.state.adults !== null &&
                                    this.state.children !== null &&
                                    this.state.Id_Transfer !== null &&
                                    this.state.Id_TransferPrice !== null &&
                                    this.state.Id_Airport_Arrival !== null &&
                                    this.state.Id_Airport_Departure !== null) ? (
                                        <Coupon
                                            Id_ActivityType={2}
                                            ArrivalDate={this.state.arrivalActivityDateTime}
                                            DepartureDate={this.state.departureActivityDateTime}
                                            AdultsNum={this.state.adults.qty}
                                            ChildsNum={this.state.children.qty}
                                            Id_Activity={this.state.Id_Transfer}
                                            Id_TransferPrice={this.state.Id_TransferPrice}
                                            hasMultipleAirPorts={
                                                this.state.Id_Airport_Arrival === this.state.Id_Airport_Departure ? 0 : -1
                                            }
                                            Id_TripType={2}
                                            Id_TravelAgent={
                                                this.state.Id_TravelAgent !== null ? this.state.Id_TravelAgent : null
                                            }
                                            Id_Language={1}
                                            Id_Currency={1}
                                            total={this.state.calculations?(this.state.calculations.total || 0):''}
                                            code={this.state.coupon.code}
                                            handle={this.handleCoupon}
                                        />
                                    ) : null
                            ) : (
                                    (this.state.calculations && this.state.arrivalActivityDateTime !== null &&
                                        this.state.adults !== null &&
                                        this.state.children !== null &&
                                        this.state.Id_Transfer !== null &&
                                        this.state.Id_TransferPrice !== null &&
                                        this.state.Id_Airport_Arrival !== null &&
                                        this.state.Id_Airport_Departure !== null) ? (
                                            <Coupon
                                                Id_ActivityType={2}
                                                ArrivalDate={this.state.arrivalActivityDateTime}
                                                DepartureDate={this.state.arrivalActivityDateTime}
                                                AdultsNum={this.state.adults.qty}
                                                ChildsNum={this.state.children.qty}
                                                Id_Activity={this.state.Id_Transfer}
                                                Id_TransferPrice={this.state.Id_TransferPrice}
                                                hasMultipleAirPorts={
                                                    this.state.Id_Airport_Arrival === this.state.Id_Airport_Departure ? 0 : -1
                                                }
                                                Id_TripType={
                                                    this.state.type === 'departure' ? 1 :
                                                        this.state.type === 'arrival' ? 0 : -1
                                                }
                                                Id_TravelAgent={
                                                    this.state.Id_TravelAgent !== null ? this.state.Id_TravelAgent : null
                                                }
                                                Id_Language={1}
                                                Id_Currency={1}
                                                total={this.state.calculations?(this.state.calculations.total || 0):''}
                                                code={this.state.coupon.code}
                                                handle={this.handleCoupon}
                                            />
                                        ) : null
                                )
                        }

                        {this.state.calculations !== null ? <TransferPricesTable type={this.state.type} data={this.state.calculations} /> : null}

                        {
                            this.state.isLogin && !this.props.itemindex? (
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="tour_rates">
                                            <a className="btn add_cartbtn" onClick={this.addToCart.bind(this, ACTION_ATC)}>
                                                {this.t('Add to Cart')}
                                                <span className="cart_icon">
                                                    <i className="icon-cart"></i>
                                                    <strong>{this.state.count}</strong>
                                                </span>
                                            </a>

                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="tour_rates">
                                            <a className="btn checkout_popc"
                                                onClick={this.addToCart.bind(this, ACTION_CHK)}>
                                                {this.t('Check out now')}<i className="icon-check" />{" "}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            ) : this.state.isLogin && this.props.itemindex >= 0?(

                               <div className="row">
                                    <div className="col-md-6">
                                        <div className="tour_rates">
                                            <NavLink to='/cart' className="btn add_cartbtn">
                                                {this.t('Finish Edition')}<i className="icon-edit" />{" "}
                                            </NavLink>
                                        </div>
                                    </div>
                                    </div>
                            ):null
                        }
                    </div>
                </div>
            ) : <div>{this.spinner()}</div>
        )
    }
}

export default withRouter(TransferPricesDetails)