import React, { Component } from 'react';
import { NavLink, withRouter } from "react-router-dom";
import Slider from "react-slick";

import DestinationPopularIDS from "./component.destinations.popular-ids";

import Global from '../globals/global';
class populardestinations extends Global {

    constructor(props) {
        super(props);

        this.state = {
           destinations: this.getglobaldata('destinations') || [],
           randomdestinations:[]
        };
    }

    componentDidMount() {

       

        this.getdispatcher('load.destinations',function(data){

            this.setState({
                    destinations: this.getglobaldata('destinations') || [],
                },function(){
                   
                }.bind(this));

        }.bind(this));


        
    }

    componentDidUpdate(prevProps, prevState) {
        
    }

    europa(){
        
        var destinations = this.state.destinations.filter(function(destination){

              //if(process.env.REACT_APP_BOOKING_WIDGET == 'bookingwidget2'){
             // return Number(destination.isState) == 0;
             // return Number(destination.isState) == -1 || (!destination.Id_State) || destination.Id_State == '';
              // }

              return Number(destination.isState) == 0 && Number(destination.Id_WorldRegion) == 3;

        });
       

        return destinations;

    }

    mexicocaribe(){
              var destinations =  this.state.destinations.filter(function(destination) {
                return Number(destination.isState) == 0 && (Number(destination.Id_WorldRegion) == 1  || Number(destination.Id_WorldRegion) == 2);
               });

       return destinations;

    }

    gotours(destination,event){

      this.eventpreventdefault(event)

        let locationid = -1;

        var stateid = '';
        var destinationid = destination.Id_Destination;

        if(destination.Id_Destination.search('state_') != -1) {  //detectamos si la variable lleva la plabra state_

            stateid = Number(destination.Id_Destination.replace('state_',''));
            destinationid = '';
        }

         if(destination.Id_Destination.search('area_') != -1) {  //detectamos si la variable lleva la plabra area_

            locationid = Number(destination.Id_Destination.replace('area_',''));
            destinationid = destination.destinationid;
            stateid = '';
        }

         this.setglobaldata('destinationinfo',destination);

        this.resetdestinationandfilters(destinationid);
        this.resetlocationandfilters(locationid);
        this.resetstateandfilters(stateid);

         this.gaDestinationEvent(destination.label);

        if(locationid != -1) this.navigate("tours/"+destination.friendlyurl+"/"+destinationid+"/"+locationid+"/1");
        else  this.navigate("tours/"+destination.friendlyurl+"/"+destinationid+"/1");

    }

    render() {

      if(process.env.REACT_APP_BOOKING_WIDGET == 'bookingwidget'){
         return <DestinationPopularIDS />
      }

      if(process.env.REACT_APP_BOOKING_WIDGET == 'bookingwidget2'){
         return <DestinationPopularIDS />
      }


let settings__ = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 5,
      initialSlide: 0,
      responsive: [
        {
      breakpoint: 1200,
      settings: {
        arrows: true,
        slidesToShow: 4,
        slidesToScroll: 4,
        infinite: true,
        dots: true,
      }
    },
    {
      breakpoint: 992,
      settings: {
        arrows: true,
        slidesToShow: 3,
        slidesToScroll: 3
      }
    },
    {
      breakpoint: 768,
      settings: {
        arrows: true,
        slidesToShow: 2,
        slidesToScroll:2
      }
    },
    {
      breakpoint: 501,
      settings: {
        arrows: true,
        slidesToShow: 1,
        autoplay: true,
        infinite: true,
        speed: 300,
        slidesToScroll:1
      }
    }
      ]
    }

        return (

             <div className="container margin_60">


             


        {this.europa().length >0?
          <div>
        
        <div className="main_title">
                    <h2 className="text-left text-capitalize">{this.t('Europe')}</h2>
         </div>


          <Slider {...settings__}>


          {
            this.europa().map(function(destination,index){

            if(destination.image2 == '') return;
            return(
              <div className="slidet">
            <a key={index} href={"tours/"+destination.friendlyurl+"/"+destination.Id_Destination+"/1"} onClick={this.gotours.bind(this,destination)} >
                <img className="dest_image" src={destination.image2}  />
                <img className="dest_image_resp" src={destination.image2.replace('.jpg','_r.jpg')}  />
                 <div className="slide__caption_a">
                    <p>{destination.Name}</p>
                    <small>{destination.country}</small>
                 </div>
            </a>
        </div>
        )

              }.bind(this))}
             


        </Slider>
        </div>
         :''}

        
        
     {this.mexicocaribe().length > 0?
     <div>

       <div className="main_title">

                     <br/><br/><br/>
                    <h2 className="text-left text-capitalize" dangerouslySetInnerHTML={{ __html: this.t('México & the Caribbean')}}></h2>
         </div>

        <Slider {...settings__}>


          {
            this.mexicocaribe().map(function(destination,index){

            if(destination.image2 == '') return;

            return(
              <div >
            <a key={index} href="#" onClick={this.gotours.bind(this,destination)} >
                <img className="dest_image" src={destination.image2}  />
                <img className="dest_image_resp" src={destination.image2.replace('.jpg','_r.jpg')}  />
                 <div className="slide__caption_a">
                    <p>{destination.Name}</p>
                 </div>
            </a>
        </div>
        )

              }.bind(this))
            }


        </Slider>

      </div>
      :''}


      </div>
            
        )
    }
}


export default withRouter(populardestinations)
