import React, { Component } from 'react';
import { NavLink, withRouter } from "react-router-dom";
import SearchTours from './component.search.tours';
import SearchTransfers from './component.search.transfers';
import Global from '../globals/global';
import autocomplete from 'autocompleter';
//if (typeof window === "undefined")  var window={}

const $ = window.$;
//const autocomplete = window.autocomplete;

class Search extends Global {

    constructor(props) {
        super(props);
    
        this.state = {
          tours:[],
          
        };
    }

    componentDidMount() {
        this.autocomplete()
        
    }

    autocomplete(){


      $('#tours_autocomplete').off('keypress').on('keypress',function(event) {
      console.log('event.keyCode',event.keyCode);
                            
                             if (event.keyCode == 13) {
                                
                                this.searchtour($('#tours_autocomplete').val())
                             }
      
                        }.bind(this))
         

        if(this.config().toursListCheckBox ) return;

        let allowedChars = new RegExp(/^[a-zA-Z\s]+$/);
      
        let autocomplete__ = autocomplete({
                      input: document.getElementById('tours_autocomplete'),
                      minLength: 1,
                      onSelect: (item, inputfield, keyCode) => {
      
                       /* $(inputfield).off('keypress').on('keypress',function(event) {
      
                            
                             if (event.keyCode == 13) {
                                
                                this.searchtour(inputfield.value)
                             }
      
                        }.bind(this))*/
      
                             if (!keyCode){
                               this.selectword(item,inputfield.value)
                             }
                      },
                      fetch: function (text, callback) {
                          var match = this.normalizeText(text.toLowerCase());
      
                          this.AutocompleteTours(text,callback)
                          
                      }.bind(this),
                      render: function (item, value) {
                          $('.autocomplete').remove();
                          var itemElement = document.createElement("div");
                          if (allowedChars.test(value)) {
                              var regex = new RegExp(value, 'gi');
                              var inner = item.Name.replace(regex, function (match) { return "<span style='font-weight:bold'>" + match + "</span>" });
                              itemElement.innerHTML = inner;
                          } else {
                              itemElement.textContent = item.Name;
                          }
      
      
                          return itemElement;
                      },
                      emptyMsg: this.t("No tours found"),
                      preventSubmit: false,
                      debounceWaitMs:500,
                      disableAutoSelect:true,
                      showOnFocus:true,
                      customize: function (input, inputRect, container, maxHeight) {
      
      
      
                          /*if(this.ismobile()){
                              container.style.top = "";
                              container.style.bottom = (window.innerHeight - inputRect.bottom + input.offsetHeight) + "px";
                              container.style.maxHeight = "140px";
                              container.style.left = "5px";
                              container.style.right = "5px";
                              container.style.width = "";
                          }
                          else if (maxHeight < 100) {
                              container.style.top = "";
                              container.style.bottom = (window.innerHeight - inputRect.bottom + input.offsetHeight) + "px";
                              container.style.maxHeight = "140px";
                          }*/
                      }.bind(this)
                  });
        
       // autocomplete__.destroy();
    }

    

    searchtour(tourname) {
  

        
        this.props.clearfilter();
        //this.clearfilter();

        if(!this.getglobaldata('filter'))  this.setglobaldata('filter',{});
        if(!this.getglobaldata('filter.toursfilter'))  this.setglobaldata('filter.toursfilter',{});

        this.setglobaldata('filter.toursfilter.tourname', tourname);
        
        this.props.setfilter();
        //this.setfilter()
        
        if(this.props.redirect){
            this.redirectToursList();
        }else{
            this.props.onChange();
        }
    }

    redirectToursList(){
        window.location.href = "/tours/1?byautocomplete=true";
    }
    
    selectword(Tour_,inputvalue_){

        
         this.props.clearfilter();
         //this.clearfilter();

         if(!this.getglobaldata('filter'))  this.setglobaldata('filter',{});
         if(!this.getglobaldata('filter.toursfilter'))  this.setglobaldata('filter.toursfilter',{});

         this.setglobaldata('filter.toursfilter.tourname',inputvalue_);
        
         
         if(Tour_) this.navigate('tourdetails/'+Tour_.friendlyurl+'/'+Tour_.Id);// window.location.href = 'tourdetails/'+Tour_.UrlName+'/'+Tour_.Id_Tour;
        
    }

    AutocompleteTours(text,callback) {

        var languageid = this.getglobaldata('Id_Language');
        var currencyid = this.getglobaldata('Id_Currency');
        var destinationid = this.getglobaldata('Id_Destination');
        var locationid = this.getglobaldata('Id_Location');
        var stateid = this.getglobaldata('Id_State');
    
        //var text = $('.searchtour').val();
        this.state.tours = [];
        this.state.searching = true;
        this.forceUpdate();
    
        var options = {
          arraydata: "searchtours", //Este es el nombre del estado  this.state.tours , que sera llenado una vez que se haga la llamada
          method: "SearchTours",
          query: {
            QtyWords:100,
            TourName:text,
            Id_Language: languageid,
            destination: destinationid,
            Id_Currency: currencyid,
            location_list: locationid,
            Id_State:stateid,
            limit:10,
            disabletoursids:this.config().disabletoursids?this.config().disabletoursids:'',
          },
          callback: function (tours, jsonresponse) {
            this.state.tours = jsonresponse.data;
            this.state.searching = false;
            var match = text.toLowerCase();
    
            callback(this.state.tours.filter(tour => {
    
              //  if(this.config().disabletoursids) return tour.Name.toLowerCase().indexOf(match) !== -1 && this.config().disabletoursids.indexOf(Number(tour.Id)) == -1;
                var TourName = this.normalizeText(tour.Name);
                return TourName.toLowerCase().indexOf(match) !== -1;
    
            }));
    
          }.bind(this)
        };
    
        
        this.all(options);
    }

    

    
    render() {
        return (
            
                <div className="col-lg-4">
                    <div className="form-group has-search" style={{position:'relative'}}>
                    <span className="icon-search form-control-feedback" ></span>
                        <div className='input-group'>
                            <input  id="tours_autocomplete" type="text" placeholder={this.t("What you are looking?")} autoComplete="off" className='form-control input_tour searchtour clearable' />
                        </div>
                    </div>
                </div>
           
        );
    }
}

export default withRouter(Search)