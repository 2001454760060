import $ from 'jquery'

var config = require('../../configs/config'); //important require for write config. Not import

if(window.brandconfig) config = $.extend(config,window.brandconfig);


const _config =  {
    url: config.apiurl.slice(0, -1) //"https://api-totoura-sandbox.mobilesail.com"
};

const createStripeCart = options => {


  return new Promise(resolve => {
      
   

$.ajax({
       method:'POST',
        url: `${_config.url}/createStripeCart`,
        xhrFields: {withCredentials: true},
        dataType: "json",
        data: options,
        success: function(data) {

     /* if (!data || data.StripePaymentIntent.error) {
        console.log("API error:", { data });
        throw new Error("PaymentIntent API Error");
      } else {*/

        resolve(data);

     // }

        }.bind(this),
        error: function(error, data) {
           // alert(error);
        }
    });

 });
  
 /* window
   .fetch(`${_config.url}/createStripeCart`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(options)
    })
    .then(res => {
      if (res.status === 200) {
        return res .json();
      } else {
        return null;
      }
    })
    .then(data => {
      if (!data || data.StripePaymentIntent.error) {
        console.log("API error:", { data });
        throw new Error("PaymentIntent API Error");
      } else {
        return data.StripePaymentIntent.client_secret;
      }
    });*/
};

const getStripePublicKey = options => {
  return window
    .fetch(`${_config.url}/getStripePublicKey`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    })
    .then(res => {
      if (res.status === 200) {
        return res.json();
      } else {
        return null;
      }
    })
    .then(data => {
      if (!data || data.error) {
        console.log("API error:", { data });
        throw Error("API Error");
      } else {
        return data.publicKey;
      }
    });
};

const api = {
  createStripeCart: createStripeCart,
  getStripePublicKey: getStripePublicKey  
};

export default api;
