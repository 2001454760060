import React, { Component } from 'react';
import { NavLink, withRouter } from "react-router-dom";
import Global from '../globals/global';

class totouraterms extends Global {
    render() {
        return (
            

                                <div className="row">

                                    <div className="col-lg-12">
                                        <h3 className="blue_text"><strong>Aviso de Privacidad</strong> </h3>

                                        <h5 className="terms_title mt-15 blue_text">Responsable de la protección de sus datos personales</h5>

                                        <p>Iconn Services S. de R.L. de C.V. (en lo sucesivo “Iconn Services”), con domicilio Álamo Plateado No.44 Int. 101 Col. Los Álamos, Naucalpan Estado de México C.P. 53230, es responsable del tratamiento de sus datos personales. De conformidad con lo dispuesto en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, (en lo sucesivo la “Ley”), Iconn Services cuenta con todas las medidas de seguridad físicas, técnicas y administrativas adecuadas para proteger sus datos personales. Bajo ninguna circunstancia comercializaremos sus datos personales sin su consentimiento previo y por escrito.</p>

                                        <p>Sus datos personales serán utilizados para las siguientes finalidades:</p>

                                    </div>

                                    <div className="col-sm-12 text-justify">

                                        <h5 className="terms_title mt-15 blue_text">¿Para qué fines recabamos y utilizamos sus datos personales?</h5>

                                        <div className="row">
                                            <div className="col-lg-3">
                                            <p>Si usted es <span className="blue_text">CLIENTE</span> para:</p>
                                                <ul>
                                                 <li>Proveer y hacer cargos en su tarjeta de crédito correspondientes a los servicios de venta de boletos de avión, renta de autos y adquisición de toda clase de servicios turísticos y de viaje comercializados por Iconn Services.</li>
                                                 <li>Trámites de obtención de visa y trámites relacionados con viajes.</li>
                                                 <li>Informarle sobre cambios en la línea de productos, precios, disponibilidad y condiciones de pago de los mismos.</li>
                                                 <li>Envío de información por medios electrónicos acerca de los productos comercializados por Iconn Services.</li>
                                                 <li>Evaluar la calidad del servicio que le brindamos.</li>
                                                 <li>Prestar servicios de atención al cliente.</li>
                                                </ul>
                                            </div>

                                            <div className="col-lg-3">
                                                <p>Si usted es <span className="blue_text">PROVEEDOR</span> para:</p>
                                                <ul>
                                                    <li>Contratar y promover los servicios y/ productos relacionados con viajes y turismo que ofrece a nuestros clientes.</li>
                                                    <li>Realizar consultas acerca de los servicios y productos que comercializa.</li>
                                                    <li>Obtener cotizaciones y efectuar pagos por sus servicios.</li>
                                                    <li>Enviar y coordinar solicitudes de reserva.</li>
                                                    <li>Verificación de sus datos y obtención de referencias comerciales.</li>
                                                </ul>
                                            </div>

                                            <div className="col-lg-3">
                                                <p>Si usted es <span className="blue_text">SOCIO DE NEGOCIOS</span> para:</p>
                                                <ul>
                                                    <li>Envío de información por medios electrónicos acerca de los productos comercializados por Iconn Services y por sus proveedores.</li>
                                                </ul>
                                            </div>

                                            <div className="col-lg-3">
                                                <p>Si usted es <span className="blue_text">EMPLEADO</span> para:</p>
                                                <ul>
                                                    <li>Verificar antecedentes laborales y académicos.</li>
                                                    <li>Realizar actividades de selección y contratación de personal.</li>
                                                    <li>Incluir en su expediente laboral.</li>
                                                </ul>
                                            </div>

                                        </div>


                                         <h5 className="terms_title mt-15 blue_text">Finalidades no Necesarias</h5>

                                         <p>Asimismo, al proporcionar sus datos, usted autoriza a Iconn Services su utilización con fines mercadotécnicos, estadísticos, promocionales, publicitarios, informativos o de prospección comercial a través de envío de correos electrónicos, newsletters y llamadas telefónicas directas, respecto a las actividades y productos comercializados por Iconn Services, sus filiales, subsidiarias y/o socios de negocios, sin que estas últimas sean finalidades necesarias ni finalidades que den origen a la relación jurídica entre usted y Iconn Services.</p>

                                         <h5 className="terms_title mt-15 blue_text">¿Cómo puedo manifestar mi negativa para el uso de mis datos para finalidades no necesarias?</h5>
                                         <p>Si usted no está de acuerdo con la utilización de sus datos para alguna de las denominadas “finalidades no necesarias”, o si usted desea limitar el uso o divulgación de sus datos personales, puede manifestar su negativa o limitación a dicho tratamiento o divulgación dentro de un plazo de 5 días hábiles a partir de que ha tenido conocimiento del presente aviso de privacidad de la siguiente manera:</p>

                                           <p>a) Mediante comunicación escrita dirigida al Departamento de Datos Personales de Iconn Services y podrá entregarse en el domicilio de Iconn Services antes indicado o enviarse al siguiente correo datospersonales@iconnservices.com.mx</p> 

                                           <p>b) Señalar su nombre completo y domicilio u otro medio para comunicarle la respuesta a su solicitud.</p>

                                           <p>c) Acompañar la documentación que acredite su identidad o en su caso la representación legal del titular de los datos, los elementos y/o documentos que faciliten la localización de los datos personales.</p>

                                           <p>d) Especificar las finalidades que desea eliminar o las limitaciones que desea establecer. </p>

                                           <p>Lo anterior, en el entendido de que en todo caso quedarán a salvo sus derechos de revocación y oposición. Una vez recibida su solicitud, la responderemos ya sea mediante correo electrónico o por escrito de acuerdo a la información que usted nos haya indicado en su solicitud, en un plazo de 20 días hábiles a partir de la recepción de su solicitud. Lo anterior, en el entendido de que podremos requerir información adicional en los términos previstos en la Ley y su Reglamento, en cuyo caso el plazo de respuesta se contará a partir de que nos proporcione la información/documentación adicional.</p>

                

                                           <h5 class="terms_title mt-15 blue_text">¿Qué datos personales obtenemos y de dónde?</h5>

                                        <p>Para las finalidades señaladas en el presente aviso de privacidad, podemos recabar sus datos personales de distintas formas: cuando usted nos los proporciona directamente; cuando visita nuestro sitio de internet o utiliza nuestros servicios en línea, y cuando obtenemos información a través de llamadas telefónicas u otras fuentes públicas permitidas por la Ley.</p>

                                        <div className="row">
                                            <div className="col-lg-3">
                                            <p>Si usted es <span className="blue_text">CLIENTE</span> recabaremos los siguientes datos::</p>
                                                <ul>
                                                    <li>Nombre</li>
                                                    <li>Correo electrónico</li>
                                                    <li> Número telefónico</li>
                                                    <li>Domicilio</li>
                                                    <li>Datos de tarjeta de crédito</li>
                                                    <li>Ciudad de residencia</li>
                                                </ul>
                                            </div>

                                            <div className="col-lg-3">
                                                <p>Si usted es <span className="blue_text">PROVEEDOR</span> recabaremos los siguientes datos:</p>
                                                <ul>
                                                    <li>Nombre</li>
                                                    <li>Correo electrónico</li>
                                                    <li> Número telefónico</li>
                                                    <li>Domicilio</li>
                                                </ul>
                                            </div>

                                            <div className="col-lg-3">
                                                <p>Si usted es <span className="blue_text">SOCIO DE NEGOCIOS</span> recabaremos los siguientes datos:</p>
                                                <ul>
                                                    <li>Nombre</li>
                                                    <li> Número telefónico</li>
                                                    <li>Correo electrónico</li>
                                                    <li>Ciudad de residencia</li>
                                                </ul>
                                            </div>

                                            <div className="col-lg-3">
                                                <p>Si usted es <span className="blue_text">EMPLEADO</span> recabaremos los siguientes datos:</p>
                                                <ul>
                                                    <li>Nombre</li>
                                                    <li>Edad</li>
                                                    <li>Correo electrónico</li>
                                                    <li>Número telefónico</li>
                                                    <li>Domicilio</li>
                                                    <li>Antecedentes laborales/ escolares</li>
                                                    <li>Datos familiares</li>
                                                    <li>Datos de tarjetas de crédito</li>
                                                </ul>
                                            </div>

                                        </div>

                                     

                                        <h5 class="terms_title mt-15 blue_text">Datos personales sensibles</h5>
                                        
                                        <p>No obtendremos ni trataremos datos considerados como “Datos Sensibles” en los términos del artículo 3ro. Fracción VI de la Ley.</p>

                                        <h5 class="terms_title mt-15 blue_text">¿Cómo acceder o rectificar sus datos personales o cancelar u oponerse a su uso?</h5>

                                        <p>Usted tiene derecho a conocer qué datos personales tenemos de usted, para qué los utilizamos y las condiciones del uso que les damos (<span className="blue_text">Acceso</span>). Asimismo, es su derecho solicitar la corrección de su información personal en caso de que esté desactualizada, sea inexacta o incompleta (<span className="blue_text">Rectificación</span>); que la eliminemos de nuestros registros o bases de datos cuando considere que la misma no está siendo utilizada conforme a los principios, deberes y obligaciones previstas en la normativa (<span className="blue_text">Cancelación</span>); así como oponerse al uso de sus datos personales para fines específicos (<span className="blue_text">Oposición</span>). Estos derechos se conocen como derechos <span className="blue_text">ARCO</span>. Para el ejercicio de cualquiera de los derechos <span className="blue_text">ARCO</span>, usted deberá presentar la solicitud respectiva de la siguiente manera:</p>

                                        <p>a) Mediante comunicación escrita dirigida al Departamento de Datos Personales de Iconn Services y podrá entregarse en el domicilio de Iconn Services o enviarse al siguiente correo: datospersonales@iconnservices.com.mx.</p>

                                        <p>b) Señalar su nombre completo y domicilio u otro medio para comunicarle la respuesta a su solicitud.</p>

                                        <p>c) Acompañar la documentación que acredite su identidad o en su caso la representación legal del titular de los datos, los elementos y/o documentos que faciliten la localización de los datos personales.</p>

                                        <p>d) Señalar la descripción clara y precisa de los datos personales respecto de los que se busca ejercer alguno de los derechos antes mencionados, los elementos y/o documentos que faciliten la localización de los datos personales, y los requisitos previstos para el ejercicio de los derechos ARCO en la Ley y su Reglamento.</p>

                                        <p>Una vez recibida su solicitud, la responderemos ya sea mediante correo electrónico o por escrito de acuerdo a la información que usted nos haya indicado en su solicitud, en un plazo de 20 días hábiles a partir de la recepción de su solicitud. Lo anterior, en el entendido de que podremos requerir información adicional en los términos previstos en la Ley y su Reglamento, en cuyo caso el plazo de respuesta se contará a partir de que nos proporcione la información/documentación adicional. Tratándose de solicitudes de acceso a sus datos personales, la información se le proporcionaría en su caso, mediante copias simples o electrónicas, dependiendo de la disponibilidad de las mismas en nuestros archivos.</p>

                                        <h5 class="terms_title mt-15 blue_text">¿Cómo puede revocar su consentimiento para el tratamiento de sus datos?</h5>

                                        <p>Usted puede <span className="blue_text">Revocar</span>el consentimiento que, en su caso, nos haya otorgado para el tratamiento de sus datos personales. Sin embargo, es importante que tenga en cuenta que no en todos los casos podremos atender su solicitud o concluir el uso de forma inmediata, ya que es posible que por alguna obligación legal requiramos seguir tratando sus datos personales. Asimismo, usted deberá considerar que, para ciertos fines, la revocación de su consentimiento implicará que no le podamos seguir prestando el servicio que nos solicitó, o la conclusión de su relación con nosotros. Para revocar su consentimiento deberá presentar su solicitud de la siguiente manera:</p>

                                        <p>a) Mediante comunicación escrita dirigida al Departamento de Datos Personales de Iconn Services y podrá entregarse en el domicilio de Iconn Services antes indicado o enviarse al siguiente correo datospersonales@iconnservices.com.mx</p>

                                        <p>b) Señalar su nombre completo y domicilio u otro medio para comunicarle la respuesta a su solicitud.</p>

                                        <p>c) Acompañar la documentación que acredite su identidad o en su caso la representación legal del titular de los datos, los elementos y/o documentos que faciliten la localización de los datos personales.</p>

                                        <p>d) Señalar la descripción clara y precisa de los datos personales respecto de los que se busca revocar su consentimiento.</p>

                                        <p>Una vez recibida su solicitud, la responderemos ya sea mediante correo electrónico o por escrito de acuerdo a la información que usted nos haya indicado en su solicitud, en un plazo de 20 días hábiles a partir de la recepción de su solicitud. Lo anterior, en el entendido de que podremos requerir información adicional en los términos previstos en la Ley y su Reglamento, en cuyo caso el plazo de respuesta se contará a partir de que nos proporcione la información/documentación adicional.</p>

                                        <h5 class="terms_title mt-15 blue_text">Sus datos pueden viajar a otro país o compartidos con otros</h5>

                                        <p>Le informamos que Iconn Services únicamente transfiere sus datos a autoridades fiscales o autoridades administrativas o judiciales. Lo anterior, a fin de cumplir con obligaciones de carácter fiscal, a cargo de Iconn Services o de resolver alguna controversia con el titular de los datos personales ante autoridades judiciales o administrativas. Lo anterior, sin perjuicio de las remisiones de datos que Iconn Services hace a sus encargados que le apoyan en cuestiones de manejo y almacenamiento de bases de datos, ventas, búsqueda y manejo de nuevos clientes, solicitud de referencias, pagos, envío de información, avisos, promociones y entrega de producto.</p>

                                        <p>Dado que cada una de las transferencias anteriores se encuentran previstas en la ley, de conformidad con lo dispuesto en el artículo 37 de la Ley, no requerimos obtener su consentimiento para efectuar dichas transferencias.</p>

                                        <p>Iconn Services no realiza transferencia alguna de datos personales para la cual sea necesario obtener su consentimiento.</p>

                                        <h5 class="terms_title mt-15 blue_text">Modificaciones al aviso de privacidad</h5>

                                        <p>Nos reservamos el derecho de efectuar en cualquier momento modificaciones o actualizaciones al presente aviso de privacidad, para la atención de novedades legislativas, políticas internas o nuevos requerimientos para la prestación u ofrecimiento de nuestros servicios o productos. Estas modificaciones estarán disponibles al público a través de nuestro sitio web www.viajeypunto.com.mx</p>

                                        <h5 class="terms_title mt-15 blue_text">Uso de cookies y web beacons</h5>

                                        <p><span className="blue_text">Le informamos que en nuestro sitio web utilizamos cookies y web beacons</span>. El portal y los servicios que brinda Iconn Services. utilizan “cookies” que se instalan en el disco duro para permitirnos identificar las preferencias que haya seleccionado el usuario y mejorar su experiencia con el portal y el servicio. Cuando usted visita el portal de Iconn Services,, podrá recabar y almacenarse información relativa a una visita, este registro es anónimo a través de la tecnología denominada “cookie”.  Una “cookie” es un elemento informático que una página de internet puede enviar al navegador de internet del usuario. Las “cookies” nos proporcionan la hora y duración de su estadía en el sitio. El acceso a la información por medio de las cookies, permite ofrecer al usuario un servicio personalizado, ya que almacenan no solo su información sino también la frecuencia de utilización del servicio. Se entiende como web beacons la imagen visible u oculta insertada dentro de un sitio web o correo electrónico, que se utiliza para monitorear el comportamiento del usuario en estos medios.</p>

                                        <p>Usted puede en todo momento bloquear las cookies y/o deshabilitar las web bacons modificando la configuración de su navegador de internet o contactarnos al correo electrónico <a href="mailto:datospersonales@iconnservices.com.mx" >datospersonales@iconnservices.com.mx</a>. Para que le brindemos la asesoría necesaria, sin embargo Iconn Services no se responsabiliza si de la desactivación de las mismas afecta el buen funcionamiento del sitio y sus servicios.</p>

                                        <p>Iconn Services utiliza la función de Google AdWords y las listas de remarketing para anunciantes de la Red de Display de Google. Iconn Services y Google usan las cookies de origen (como la cookie de Google Analytics) y las cookies de terceros (como la cookie de DoubleClick) combinadas para informar, optimizar y publicar anuncios basándose en las visitas anteriores de los usuarios de nuestro sitio web.</p>

                                        <p>Esto significa que proveedores, incluido Google, mostrarán material promocional de Iconn Services en otros sitios web que visite en internet. Usted puede inhabilitar los anuncios personalizados de la Red de Display de Google accediendo al Administrador de preferencias de anuncios. Para proporcionar a usuarios de internet más control sobre cómo su información es recopilada por Google Analytics, Google ha creado el complemento de inhabilitación para navegadores de Google Analytics, con el cual es posible inhabilitar Google Analytics.</p>

                                        <h5 class="terms_title mt-15 blue_text">¿Cómo puede limitar el uso o divulgación de sus datos personales?</h5>

                                        <p>En adición a cualquier mecanismo anteriormente descrito para limitar el uso o divulgación de sus datos personales, si usted desea dejar de recibir mensajes promocionales de nuestra parte puede solicitarlo a través del Departamento de Datos Personales al correo <a href="mailto:datospersonales@iconnservices.com.mx" >datospersonales@iconnservices.com.mx</a> o inscribirse en el Registro Público de Consumidores previsto en la Ley Federal de Protección al Consumidor y comunicarnos dicha inscripción.</p>

                                        <h5 class="terms_title mt-15 blue_text">Ultima fecha de actualización</h5>

                                        <p>25 abril 2016</p>

                                    </div>



                                </div>

        )
    }
}

export default withRouter(totouraterms)