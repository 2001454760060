import React, { Component } from 'react';
import { NavLink, withRouter } from "react-router-dom";
import Global from '../globals/global';

class totouraterms extends Global {
    render() {
        return (
            

                                <div className="row">

                                    <div className="col-lg-12">
                                        <h3 className="blue_text"><strong>Terminos y condiciones</strong> </h3>

                                        <p>La aceptación y/o pago de nuestra cotización y / o propuesta o compra de los servicios implica la aceptación de los siguientes términos y condiciones:

Estos términos y condiciones son legalmente vinculantes entre el comprador o pasajero comprador (en lo sucesivo el “Pasajero”) de los servicios operados por Iconn Services, S. de R.L de C.V. bajo la marca comercial “Viaje y Punto” (en lo sucesivo a la sociedad con Services, S. de R.L de C.V. se le denominará “Viaje y Punto”)</p>

                                    </div>

                                    <div className="col-sm-12 text-justify">

                                        <h5 className="terms_title mt-15 blue_text">PRECIOS</h5>

                                        <p>Todos los precios expresados en moneda nacional son de referencia y capacidad controlada, reflejan el precio al momento de publicar este documento para una fecha en que el itinerario esté disponible. Los precios están sujetos a cambios y/o variaciones sin previo aviso, al momento de realizar la compra. La vigencia indicada en cada programa o servicio, pueden ser retirados o modificados en cualquier momento sin previo aviso, así como su disponibilidad. En caso de que esto suceda, se hará el ajuste correspondiente en precios, ruta y se avisará a los Pasajeros lo más pronto posible. Viaje y Punto se reserva todo derecho de recibir pagos en otro tipo de moneda que no esté estipulada en las publicaciones.</p>

                                         <p>  Todos los precios de hoteles en la sección de promociones, beneficios adicionales y la disponibilidad mostrados constituyen datos estrictamente informativos y no una oferta pública. Tanto los precios como la disponibilidad están sujetos al inventario de los operadores. Las tarifas y las disponibilidades de los hoteles no se garantizan hasta el momento de recibir el correo electrónico con la confirmación de su compra.</p> 

                                         <p>    Los precios publicados, son expresados en parcialidades de 7, 12, 13, 15, 18 y 21 mensualidades sin intereses con tarjetas BBVA, y aplican únicamente para las tarjetas que califiquen para el programa Meses Sin Intereses, con un CAT 0% sin IVA, informativo.</p>

                                         <p>    La opción de pago con Puntos BBVA es únicamente para las tarjetas que califiquen para el programa de Puntos BBVA. También puede efectuarse la compra usando una combinación de intercambio de Puntos BBVA y cargo a La tarjeta BBVA que corresponda. En caso de que la compra se realice pagando con puntos y cargo a La tarjeta BBVA, el monto a cargar a la tarjeta BBVA no podrá ser diferido a Mensualidades sin Intereses.</p>

                                         <p>    Las promociones están sujetas a disponibilidad y cambios previo aviso al momento de comprar.</p>

                                         <h5 className="terms_title mt-15 blue_text">Información personal</h5>

                                         <p>Toda información que proporcione a Viaje y Punto deberá ser veraz y fidedigna para procesar sus solicitudes de cotización sobre los productos y/o servicios. Por ello, se garantiza que los datos recolectados serán protegidos debidamente.</p>

                                         <p>De conformidad con la Ley Federal de Protección de Datos Personales en Posesión de los Particulares vigente en México, le informamos que la información personal que facilite a Viaje y Punto a través del uso del Portal, vía telefónica o transferencia bancaria será tratada por Viaje y Punto en los términos del aviso de privacidad publicado en nuestro sitio web, y en todo caso, con la única y exclusiva finalidad de procesar la compra de los servicios. <a href="https://brand-viajeypunto-sandbox.mobilesail.com/privacy">Aviso de Privacidad</a></p>

                                         <h5 className="terms_title mt-15 blue_text">SERVICIOS</h5>

                                        <p className="blue_text">Paquetes</p>
                                        
                                        <p>Viaje y Punto confirmará todos los servicios convenidos por escrito y/o mediante un correo electrónico, en donde se especificará puntualmente cada uno de los servicios incluidos en la experiencia.</p>

                                        <p>Una vez confirmados los servicios Viaje y Punto fijará al cliente un tiempo límite para el pago total a través del medio que aplique al momento (MSI, depósito bancario, etc.).</p>

                                        <p>Todos los servicios serán coordinados con un itinerario específico para llevar a cabo la experiencia, en donde el Pasajero se obliga a seguir los horarios estipulados por Viaje y Punto asumiendo cualquier responsabilidad por el incumplimiento de los mismos.</p>

                                        <p>El Pasajero se compromete a mantener un comportamiento adecuado durante toda la experiencia, eximiendo a Viaje y Punto de cualquier responsabilidad personal o frente a terceros que derive de su comportamiento inadecuado o el de sus acompañantes.</p>

                                        <p>Viaje y Punto se reserva el derecho de suspender y/o cambiar en cualquier momento los servicios por cuestiones ajenas a su control, por caso fortuito o fuerza mayor, notificando siempre al Pasajero de estos cambios, así como de los cambios que se puedan llegar a generar en las tarifas.</p>

                                        <p>En caso de que el Pasajero rechace los cambios se procederá a la cancelación de los servicios y se aplicarán las políticas de cancelación correspondientes.</p>

                                        <p className="blue_text">Paquetes</p>

                                        <p>Los precios indicados en los paquetes publicados corresponden a precios por persona con base en ocupación indicada en paquete, sujeto a disponibilidad del operador final y a cambios sin previo aviso. En caso de incluir el servicio aéreo es saliendo de la Ciudad de México, en clase turista y no incluyen impuestos ni tasas de combustible, los cuales se calculan al momento de emitir los boletos de avión.</p>

                                        <p className="blue_text">Cruceros</p>

                                        <p>Precios por persona con base en ocupación doble en cabina interior, sin impuestos portuarios, propinas, cargos portuarios y otros cargos, estos son indicativos y de referencia, el dato real se calcula al momento en que la naviera confirma la reservación. Sujeto a disponibilidad del operador final y a cambios sin previo aviso.</p>

                                        <p className="blue_text">Escapadas</p>

                                        <p>Las escapadas que signifiquen esfuerzo físico o algún riesgo como por ejemplo: Paseo en globo / Moto / Helicóptero / Paracaídas o Parapente es necesario firmar una carta para deslindar responsabilidad a ICONN SERVICES, S. de R.L de C.V en el destino. El operador les proporcionará la asesoría necesaria.</p>

                                        <p>Los precios, descripciones y detalles de los productos se encuentran sujetos a cambio sin previo aviso.</p>

                                        <p className="blue_text">Avión + Hotel</p>

                                        <p>Vigencia para compra o viaje sujeto a la disponibilidad de cada oferta, hotel o cadena.</p>

                                        <p>Precios en pesos mexicanos, sujetos a cambios y/o variaciones y a la disponibilidad al momento de realizar la reservación o compra. Cancelaciones sujetas a las políticas de cada hotel o cadena.</p>

                                        <p className="blue_text">Alojamiento</p>

                                        <p>El tipo de alojamiento, el tipo de habitación y especificaciones se indican en el itinerario.</p>

                                        <p className="blue_text">Traslados</p>

                                        <p>Los traslados serán proporcionados a la llegada y salida desde el punto de encuentro previamente establecido según se especifica en el itinerario y el tipo de transporte indicado en el mismo.</p>

                                        <p className="blue_text">Comidas</p>
                                        
                                        <p>En caso de que la experiencia incluya alimentos, éstos serán indicados en el itinerario. De lo contrario, se entenderá que no los incluye.</p>

                                        <p className="blue_text">Itinerario</p>

                                        <p>Viaje y Punto y/o sus corresponsales se reservan el derecho de retirar una excursión, a realizar modificaciones en el itinerario o inclusiones al mismo, según lo consideren necesario y transferir el costo o gasto adicional en caso de ser requerido. Lo anterior, en el entendido de que cualquier modificación se le notificará al viajero para obtener la aceptación de los mismos. Los servicios contratados están sujetos a cambio en cualquier momento debido a circunstancias ajenas y/o imprevistas a Viaje y Punto o sus corresponsables.</p>
                                        
                                        <p>Una vez publicadas las experiencias, se podrá realizar la cotización de cualquier experiencia en la que estén interesados. Dichas cotizaciones deberán ser enviadas al Pasajero por Viaje y Punto y estarán sujetas a los términos y condiciones específicos incluidos en la cotización correspondiente a fin de que la solicitud de la experiencia sea confirmada por el Pasajero. En caso de que no haya aceptación por parte del Pasajero, se procederá a hacer la cancelación de la cotización por escrito vía correo electrónico.</p>

                                        <p className="blue_text">Impuestos y propinas</p>

                                        <p>Los impuestos están incluidos en las experiencias a menos que se especifique lo contrario. Las propinas en ningún caso están incluidas a excepción de algunos cruceros, en donde se especifica. Son totalmente de carácter voluntario, pero sugeridas por parte del viajero al prestador del servicio.</p>

                                        <p className="blue_text">Servicios de tranporte aéreo</p>
                                        
                                        <p>La prestación de los servicios de transporte aéreo está sujeta a lo establecido en la normatividad general aplicable, así como a los términos y condiciones particulares de cada una de las aerolíneas que prestan el servicio. Viaje y Punto, actúa únicamente como intermediario en la contratación de dichos servicios aéreos, por lo que no asume ninguna responsabilidad respecto de la oportunidad y calidad en la prestación de los mismos, lo cual es responsabilidad exclusiva de la aerolínea contratada. Es importante que el Pasajero consulte los términos y condiciones específicos de cada aerolínea en el sitio web de cada una de ellas.</p>

                                        <p className="blue_text">Equipaje</p>

                                        <p>Las restricciones sobre el mismo son especificadas por la línea aérea y/o el prestador del servicio y son responsabilidad del viajero, así como sus efectos personales. Viaje y Punto. NO tiene responsabilidad por el daño o pérdida que pudiera sufrir el mismo, por lo que se recomienda la contratación de seguros para el mismo.</p>

                                        <p className="blue_text">Seguro de Viaje</p>
                                        
                                        <p>Viaje y Punto recomienda ampliamente que todos los clientes contraten una póliza amplia que incluya: gastos médicos incluyendo la repatriación y evacuación del destino, equipaje personal, responsabilidad personal, la muerte y discapacidad permanente y pérdida de documentos, NO haciéndonos responsable por gastos derivados de dichas pérdidas o daños.</p>

                                        <p className="blue_text">Pasaportes y Visas</p>

                                        <p>Cada Pasajero es responsable de asegurarse de contar o, en su caso, tramitar la documentación de identificación, así como migratoria, de salud y/o aduanal que resulte necesaria para realizar su viaje, incluyendo sin limitarse pasaportes, visas, permisos sanitarios, vacunas y cualquier otro requisito impuesto por las autoridades mexicanas o del país de destino. Así mismo tratándose de menores, esta responsabilidad le corresponde a sus padres o tutor(es). Viaje y Punto, no tiene responsabilidad alguna por cualquier eventualidad que sea resultado de la falta de los documentos y/o requisitos anteriormente descritos.</p>

                                        <p className="blue_text">Medidas de Seguridad y de Sanidad</p>

                                        <p>Será responsabilidad del pasajero, informarse sobre las medidas de sanidad requeridas en cada destino y llevar los certificados pertinentes, ya que la falta de éstos no será responsabilidad de Viaje y Punto.</p>

                                        <p className="blue_text">Cambios y nueva programación</p>

                                        <p>Una vez confirmada la reserva, cualquier cambio, tendrá un cargo dependiendo del tipo de servicio, indicado por el asesor al momento del cambio, más la adecuación por cambio de tarifa. Todo cambio queda sujeto a términos y condiciones del prestador del servicio.</p>

                                        <p>No se realizan reembolsos sobre los servicios que no se hayan usado.</p>

                                        <p className="blue_text">Cancelaciones</p>

                                        <p>Las cancelaciones se realizarán en un lapso no mayor a 45 días previo a la fecha de salida y tendrán que ser enviadas vía correo electrónico a Viaje y Punto y estarán sujetas a un cargo que equivale al depósito realizado.</p>

                                        <ul>
                                            <li>Si la cancelación es realizada entre el día 45 y 30 será penalizado con el 25% del total del precio del paquete</li>
                                            <li>Si la cancelación es realizada entre el día 29 y 15 será penalizado con el 75% del total del precio del paquete</li>
                                            <li>Si la cancelación se realiza con 14 días o menos será penalizado con la totalidad del monto del paquete.</li>
                                        </ul>

                                        <p>Estas penalizaciones serán aplicadas en lo que corresponde a políticas propias de Viaje y Punto. En caso de las políticas de cada proveedor del servicio, siendo éste: traslado, comidas, boletos de entrada a lugares y/o shows, se tendrá que hacer el ajuste conforme a las mismas y dichas políticas prevalecerán, las cuales serán informadas desde el momento de la cotización, quedando Viaje y Punto libre de toda responsabilidad.</p>

                                        <p className="blue_text">Agente intermediario</p>

                                        <p>Los Pasajeros reconocen y aceptan que Viaje y Punto actúa única y exclusivamente en su carácter de agente intermediario con los prestadores de servicios turísticos y dichos servicios serán proporcionados por prestadores de servicios turísticos independientes que no actúan por o en representación de Viaje y Punto, por lo que en este acto las partes reconocen y aceptan que Viaje y Punto no tendrá responsabilidad ni obligación alguna en caso de que dichos prestadores de servicios turísticos incumplan con sus obligaciones contractuales, por lo que en caso de reclamaciones derivadas de dichos supuestos los viajeros las tendrán que hacer directamente a los prestadores de servicios turísticos. No obstante lo anterior, en caso de que los Pasajeros le notifiquen a Viaje y Punto alguna controversia o conflicto con los prestadores de servicios turísticos en relación con los servicios proporcionados, Viaje y Punto realizará las gestiones razonables ante dichos prestadores de servicios con la finalidad de presentar a los Pasajero alternativas de solución viables para la resolución de dichas controversias o conflictos.</p>

                                        <p>Asimismo, los Pasajeros reconocen y aceptan que al utilizar los servicios de viaje cuya contratación hayan realizado a través de Viaje y Punto, Viaje y Punto no será responsable de las pérdidas, costos, gastos, lesiones, accidentes o daños a cualesquiera de los viajeros o a las propiedades y/o pertenencias de los mismos que resulten directa o indirectamente de (i) actos u omisiones de dichos prestadores de servicios, incluyendo en forma enunciativa, mas no limitativa, retrasos o cancelaciones de servicios, suspensión de operaciones, descomposturas en maquinarias y equipos o cambios en tarifas, itinerarios o calendarios; y/o (ii) caso fortuito o fuerza mayor; y/o (iii) cualesquier otra causa fuera del control de Viaje y Punto.</p>

                                        <p>Para la interpretación, cumplimiento o ejecución forzosa del presente documento, las partes contratantes se sujetan a la competencia y Jurisdicción de los Tribunales de la Ciudad de México, renunciando a la jurisdicción y competencia de cualquier otro que les pueda corresponder por su domicilio presente o futuro.</p>

                                    </div>



                                </div>

        )
    }
}

export default withRouter(totouraterms)