import React, { Component } from "react";
import Global from "../globals/global";
import { NavLink, withRouter } from "react-router-dom";
import PurchaseHistory from "../components/component.purchase.history";
import NotificationsAlerts from "../components/component.notifications.alerts";
import Profile from "../components/component.profile";
const $ = window.$;

class wishlist extends Global {
  constructor(props) {
    super(props);

    this.logoutPopup = this.logoutPopup.bind(this);
    var payment = false;
    if (this.props.match) {
      if (this.props.match.params) {
        if (this.props.match.params.payment)
          payment = this.props.match.params.payment;
      }
    }

    this.state = {
      payment: payment,
      isbuyresult: false
    };
  }

  componentDidMount() {
    if (this.state.payment) {
      this.setglobaldata("Cart", []);
      this.senddispatcher("cart.header", {});
      this.isbuyresult = true;
    } else if (this.state.payment === "payment") {
      this.isbuyresult = true;
    }

    new window.CBPFWTabs(document.getElementById("tabs"));

    /* $('.nav_bottom li a').each(function () {
      $(this).addClass('active');
    });*/
  }

  logoutPopup() {
    $.magnificPopup.open({
      items: {
        src: "#close_ses"
      },
      type: "inline",
      fixedContentPos: true,
      fixedBgPos: true,
      overflowY: "auto",
      closeBtnInside: true,
      preloader: false,
      midClick: true,
      removalDelay: 300,
      mainClass: "my-mfp-zoom-in"
    });
  }

  render() {
    var user = this.getglobaldata("user");
    if (this.state.payment) {
      this.isbuyresult = true;
      this.state.isbuyresult = true;
    }
    return (
      <div>
        <section
          className="parallax-window"
          data-parallax="scroll"
          style={{
            background: "url(" + this.image("img/account_back.jpg") + ")",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center center"
          }}
        >
          <div className="parallax-content-1">
            {this.state.payment ? (
              <div className="animated fadeInDown">
                <h1>{this.t("Approved payment")}</h1>
                <p>
                  {this.t("Below you will find the details of your purchase")}
                </p>
              </div>
            ) : (
              <div className="animated fadeInDown">
                <h1>{this.t("My Account")}</h1>
                <p>{`${this.t(
                  "Hello"
                )} ${user.FirstName.toUpperCase()} ${user.LastName.toUpperCase()}!`}</p>
              </div>
            )}
          </div>
        </section>
        {/* End section */}
        <main>
          <div id="position">
            <div className="container">
              <ul>
                <li>
                  <NavLink to="/">{this.t("Home")}</NavLink>
                </li>
                <li>{this.t("My Account")}</li>
              </ul>
            </div>
          </div>
          {/* End Position */}
          <div className="margin_60 container">
            <div className="row">
              <div className="col-lg-9">
                <h6 className="blue_text">
                  <strong>
                    {user.FirstName} {user.LastName}
                  </strong>
                </h6>
              </div>

              <div className="col-lg-3">
                <a
                  onClick={this.logoutPopup}
                  className="close_session close_bt"
                >
                  {this.t("Close Session")} <i className="icon-off" />
                </a>
              </div>
            </div>
            <div id="tabs" className="tabs">
              <nav>
                <ul>
                  <li>
                    <a href="#section-1" className="icon-booking">
                      <span>{this.t("Purchase History")}</span>
                    </a>
                  </li>
                  <li>
                    <a href="#section-2" className="icon-bell-alt">
                      <span>
                        {this.t("Notifications")} &amp; {this.t("Alerts")}
                      </span>
                    </a>
                  </li>
                  <li>
                    <a href="#section-3" className="icon-profile">
                      <span>{this.t("Profile")}</span>
                    </a>
                  </li>
                </ul>
              </nav>
              <div className="content">
                {this.state.isbuyresult ? (
                  <div className="row">
                    <div className="col-lg-8 col-12 mx-auto">
                      <h4 className="title_detc">
                        {this.t(
                          "Felicidades estas a punto de vivir una gran experiencia"
                        )}
                      </h4>
                    </div>
                  </div>
                ) : null}
                <section id="section-1">
                  <PurchaseHistory isbuyresult={this.state.isbuyresult} />
                </section>
                {/* End section 1 */}
                <section id="section-2">
                  <NotificationsAlerts />
                </section>
                {/* End section 2 */}
                <section id="section-3">
                  <Profile />
                </section>
                {/* End section 3 */}
              </div>
              {/* End content */}
            </div>
            {/* End tabs */}
          </div>
          {/* end container */}
        </main>
        {/* End main */}
      </div>
    );
  }
}

export default wishlist;
