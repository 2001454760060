import React, {Component} from "react";
import { NavLink } from 'react-router-dom';
import Language from "../components/component.language";
import Currency from "../components/component.currency";
import Menu from "../components/component.menu";
import CartHeader from "../components/component.cart.header";
import DestinationSelector from "../components/component.destination.selector";
import Weather from "../components/component.weather";
import Search from "../components/component.search";
import Global from '../globals/global';
//if (typeof window === "undefined")  var window={}
const $ = window.$;

class headerwidget extends Global{

    constructor(props) {
        super(props);

        
        this.state = {};
    }

   


    render() {
      

var agentmoduleclass = '';
        if(this.config().enableagentsearch) agentmoduleclass = 'nav_editor';

        return (
              <header className={`back_blueh ${agentmoduleclass}`}>
              
                    <div className="container">
                        <div className="row">

                            <div className="col-3 icon_changec">
                               
                                {this.config().destinations?
                                <DestinationSelector locationNotification={true}/>
                                :''}
                                

                            </div>

                          

                            <nav className="col-8">
                            <a className="cmn-toggle-switch cmn-toggle-switch__htx open_close" href="javascript:void(0);"><span>{this.t('Menu mobile')}</span></a>
                            <Menu />


                            <ul id="top_tools">


                              
                                    {this.config().languages?
                                        <li  ><Language /></li>
                                    :''}
                                 

                                 
                                    {this.config().currencies?
                                        <li  ><Currency /></li>
                                    :''}
                             
                                <li className="cart_home">
                                    <CartHeader />
                                </li>
                            </ul>
                        </nav>

                        </div>{/*---- End row --- */}
                    </div>{/*---- End container--- */}
              

                 {/*  chat icon --*/}
                <a href="javascript:void(0)" className="chat_icon" onClick={this.openchat.bind(this)}>
                    <img src={this.image("img/chat_icon.png")} />
                </a>
                
            </header>
        );
    }
}

export default headerwidget;