import React, { Component } from 'react';
import { NavLink, withRouter } from "react-router-dom";
import Global from '../globals/global';
class NotificationsAlertsCard extends Global {

    constructor(props) {
        super(props);

        this.getDateTime = this.getDateTime.bind(this);
        this.handleChecked = this.handleChecked.bind(this);

        this.state = {
            data: null,
            checked: null,
        };
    }

    componentDidMount() {
        this.setState({
            data: this.props.data,
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.data !== this.state.data ||
            prevProps.data !== this.props.data) {
            document.getElementById(`Notifications-${this.state.data.Id_Notification}`).checked = this.state.data.checked;
            //this.props.handle(this.state.data.Id_Notification, this.state.checked);
            window.addEventListener(`action.check.${this.state.data.Id_Notification}`, this.handleChecked);
        }
        if (prevState.checked !== this.state.checked) {
            this.props.handle(this.state.data.Id_Notification, this.state.checked);
        }
    }

    getDateTime(delivery, type) {
        const deliveries = delivery.split('|');
        return deliveries[type === 'date' ? 0 : 1].trim();
    }
    
    handleChecked(event) {
        document.getElementById(`Notifications-${this.state.data.Id_Notification}`).checked = event.detail;
    }

    render() {
        return (
            this.state.data !== null ? (
                <div className="strip_booking notific_b">
                    <div className="row">
                        <div className="col-md-8 purchase_t">
                            {
                                this.state.data.IsRead === '0' ? (
                                    <span>{this.state.data.Message}</span>
                                ) : (
                                        <span className="read_notif">{this.state.data.Message}</span>
                                    )
                            }
                        </div>
                        <div className="col-md-2">
                            <ul className="info_booking">
                                <li><strong>{this.t('Hour')}</strong> {this.getDateTime(this.state.data.Delivery, 'time')}</li>
                                <li><strong>{this.t('Date')}</strong> {this.getDateTime(this.state.data.Delivery, 'date')} </li>
                            </ul>
                        </div>
                        <div className="col-md-2">
                            <div className="form-check">
                                <input type="checkbox" className="form-check-input check_acc"
                                    id={`Notifications-${this.state.data.Id_Notification}`}
                                    onChange={event => { this.setState({ checked: event.target.checked }) }} />
                            </div>
                        </div>
                    </div>
                    {/* End row */}
                </div>
            ) : null
        )
    }
}

export default withRouter(NotificationsAlertsCard)