import React, { Component } from 'react';
import { NavLink, withRouter } from "react-router-dom";
import Global from '../globals/global';

class TransferCardOption extends Global {

    constructor(props) {
        super(props);

        this.buildPath = this.buildPath.bind(this);

        this.state = {
            type: null,
            data: null,
            airports: null,
        };
    }

    componentDidMount() {
        this.setState({
            type: this.props.type,
            data: this.transfersdata(),
            airports: this.props.airports,
        });
    }

    buildPath() {
        const { Id, Id_TransferPrice } = this.state.data;
        const { Id_Airport_Arrival, Id_Airport_Departure } = this.state.airports;
        return `/transfers/prices/${Id}/${Id_TransferPrice}/${Id_Airport_Arrival}/${Id_Airport_Departure}/${this.state.type}`;
    }

    settransferdestination(data){
     this.setglobaldata('Tour_Id_Destination',data.Id_Destination);
     this.setglobaldata('Tour_Id_Location',data.Id_Location);
    }

    transfersdata(){

        var data = this.setfreetransfers()
    
        return data
    }

    setfreetransfers(){

        var data = this.props.data;
        
    //    this.config().groupinfo = {freetransfersids:[14,15]}
        if(this.config().agencyinfo){
            if(this.config().agencyinfo.TransfersCoupon){
            if(this.config().agencyinfo.coupontransfersids){

                  var transferid = this.config().agencyinfo.coupontransfersids.find(transferid=>{
                    return data.Id == transferid
                  })

                  if(transferid){
                    data.Price_Arrival = 0;
                    data.Price_Departure = 0;
                    data.Price_Round = 0;
                  }
              
            }
          }
        }

        return data;
    }


    render() {

     if(this.state.data){
                  var ArrivalPrice =Number(this.state.data.Price_Arrival)>0?`$${Number(this.state.data.Price_Arrival)}`:'';
                  var DeparturePrice =Number(this.state.data.Price_Departure)>0?`$${Number(this.state.data.Price_Departure)}`:'';
                  var RoundPrice =Number(this.state.data.Price_Round)>0?`$${Number(this.state.data.Price_Round)}`:'';
              }
         
     

        return (
            this.state.data !== null ? (

                 

                this.state.type === 'arrival' ? (
                    <NavLink onClick={this.settransferdestination.bind(this,this.state.data)} to={this.buildPath()} className={`btn transfer_prices${this.state.data.Price_Arrival_Disabled === 'disabled="disabled"' ? ' trans_btn disabled' : ' trans_btnb'}`}>
                        {`${this.state.data.Price_Arrival_Disabled === 'disabled="disabled"' ? this.t('NOT AVAILABLE') : `${ArrivalPrice} ${this.t('ARRIVAL ONLY')}`}`}
                    </NavLink>
                ) : this.state.type === 'departure' ? (
                    <NavLink onClick={this.settransferdestination.bind(this,this.state.data)} to={this.buildPath()} className={`btn transfer_prices${this.state.data.Price_Departure_Disabled === 'disabled="disabled"' ? ' trans_btn disabled' : ' trans_btna'}`}>
                        {`${this.state.data.Price_Departure_Disabled === 'disabled="disabled"' ? this.t('NOT AVAILABLE') : `${DeparturePrice} ${this.t('DEPARTURE ONLY')}`}`}
                    </NavLink>
                ) : this.state.type === 'round' ? (
                    <NavLink onClick={this.settransferdestination.bind(this,this.state.data)} to={this.buildPath()} className={`btn transfer_prices${this.state.data.Price_Round_Disabled === 'disabled="disabled"' ? ' trans_btn disabled' : ' trans_btnc'}`}>
                        {`${this.state.data.Price_Round_Disabled === 'disabled="disabled"' ? this.t('NOT AVAILABLE') : `${RoundPrice} ${this.t('ROUND TRIP')}`}`}
                    </NavLink>
                ) : null
            ) : <div>{this.spinner()}</div>
        )
    }
}

export default withRouter(TransferCardOption)