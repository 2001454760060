module.exports = {
    "Logout": "Déconnexion",
    "Where You're Staying": "Où vous restez",
    "Loading": "Chargement",
    "Customize your experience": "Personnalisez votre expérience",
    "Choose a destination": "Choisissez une destination",
    "Menu mobile": "Menu mobile",
    "Transfers": "Transferts",
    "Specials": "Promotions",
    "Trending & Reviews": "Tendances et évaluations",
    "All Tours": "Tous les excursions",
    "Home": "Accueil",
    "Wish List": "Liste de souhaits",
    "Dining": "Restauration",
    "Shopping": "Achats",
    "Get to your hotel with style, select or search your hotel to get our best selection of airport-hotel transfer services. Air conditioned comfortable coaches, minivans or private vehicles will be waiting to take you to your destination.": "Arrivez à votre hôtel avec style, sélectionnez ou recherchez votre hôtel pour obtenir notre meilleure sélection de services de transfert aéroport-hôtel. Des autocars, des minivans ou des véhicules privés, climatisés et confortables vous attendront pour vous emmener à destination.",
    "Tours": "Excursions",
    "My Account": "Mon Compte",
    "Contact": "Contact",
    "Arrival Airport": "Aéroport d'arrivée",
    "Departure Airport": "Aéroport de départ",
    "Select Airport Option": "Sélectionner les options d'aéroport",
    "Select your hotel": "Sélectionnez votre hôtel",
    "Select Below": "Sélectionnez ci-dessous",
    "Please select your hotel from the list of available options so we can provide an accurate list of transfer options for your location.": "Veuillez sélectionner votre hôtel dans la liste des options disponibles afin que nous puissions vous fournir une liste précise des options de transfert pour votre emplacement.",
    "Note:": "Remarque:",
    "If the system shows arrival & departure but round trip is not available, it means that they have to be booked separately.": "Si le système indique l'arrivée et le départ mais que l'aller-retour n'est pas disponible, cela signifie qu'ils doivent être réservés séparément.",
    "TRANSFERS FOUND:": "TRANSFERTS TROUVÉS:",
    " Loading...": "Chargement...",
    "Loading...": "Chargement...",
    "Type your Hotel Name": "Entrez le nom de votre hôtel",
    "Privacy Policy": "Politique de confidentialité",
    "Need help?": "Besoin d'aide pour?",
    "About": "À propos",
    "Account": "Compte",
    "About us": "À propos de nous",
    "Terms and condition": "Conditions générales",
    "Wishlist": "Liste de souhaits",
    "Tours Reviews": "Évaluations des excursions",
    "Discover": "Découvrez",
    "Settings": "Réglages",
    "Terms & Conditions": "Conditions générales",
    "Special Offers": "Offres spéciales",
    "We make good vacations great": "nous rendons les vacances merveilleuses",
    "CHECKOUT": "CAISSE",
    "Empty Cart": "Panier vide",
    "PLACE YOUR ORDER": "PASSEZ VOTRE COMMANDE",
    "No Hotels found": "Aucun hôtel trouvé",
    "Select Arrival Airport": "Sélectionnez l'aéroport d'arrivée",
    "Select": "Sélectionner",
    "Search Transfers": "Transferts de recherche",
    "Destination": "Destination",
    "Hotel Name": "Nom de l'hôtel",
    "Search now": "Rechercher maintenant",
    "ALL AREAS": "TOUTES LES RÉGIONS",
    "Seleccione": "Sélectionner",
    "CHANGE": "MODIFIEZ",
    "NOT CERTAIN": "PAS CERTAINES",
    "Search Tours": "Excursions de recherche",
    "Search terms": "Termes de recherche",
    "Type your search terms": "Entrez vos mots-clés de recherche ici",
    "DESCRIPTION:": "DESCRIPTION",
    "ROUND TRIP": "ALLER-RETOUR",
    "(NOT AVAILABLE)": "(NON DISPONIBLE)",
    "TRANSFER TYPE:": "TYPE DE TRANSFERT:",
    "ARRIVAL ONLY": "ARRIVÉE SEULEMENT",
    "..Rates are per Person or Per Vehicle in": "Les tarifs sont par Personne ou par véhicule dans",
    "PER": "PAR",
    "DEPARTURE ONLY": "DÉPART SEULEMENT",
    "WELCOME": "BIENVENU",
    "<span class='orange_t'>TOUR</span><span class='blue_t'> REVIEWS</span>": "<span class='blue_t'>ÉVALUATIONS DES</span> <span class='orange_t'>EXCURSIONS</span>",
    "Read Reviews": "Lisez les évaluations",
    "See Tours": "Voir le excursions",
    "TO": "À",
    "See What's Hot And What Other vacations have to say": "Voyez ce qui a la cote et ce que les autres vacanciers en disent",
    "\"...the Royal Swim was perfect for us ...<br />we wanted a great experience and we got it... \"": "\"... le Royal Swim était parfait pour nous ...<br />nous voulions une super expérience et nous l'avons eue ...\"",
    "<span class='orange_t'>FREE</span><span class='blue_t'> TOUR</span>": "<span class='blue_t'>EXCURSION</span> <span class='orange_t'>GRATUIT</span>",
    "BUY 2 Tours AND GET a 3<sup>rd</sup> ONE FREE": "",
    "See How!": "Regarde comment!",
    "Vacation Offers & Features": "",
    "<span class='orange_t'>GET</span><span class='yellow_t'> THE</span><span class='blue_t'> APP</span>": "<span class='orange_t'>TÉLÉCHARGEZ</span> <span class='blue_t'>L'APPLICATION</span>",
    "Download Now!": "Télécharger maintenant!",
    "\"FREE CALLS to the concierge before & during vacation<br />Transfers, Tours, and more!\"": "\"APPELS GRATUITS au concierge avant et pendant les vacances.<br />Transferts, Excursions, et plus!\"",
    "AIRPORT TRANSFERS ~ GET TO YOUR HOTEL IN STYLE!": "TRANSFERTS DE L'AÉROPORT ~ ARRIVEZ À VOTRE HÔTEL AVEC STYLE!",
    "Our <span class='blue_text'>Destinations</span>": "Nos <span class='blue_text'>Destinations</span>",
    "Know our places and be happy": "Découvrez nos sites et profitez-en",
    "Discover our Top tours <span>now</span>": "Découvrez nos meilleurs excursions <span>maintenant</span>",
    "See our best tour places at Totoura": "Nos meilleurs endroits à Totoura",
    "Read more": "En savoir plus",
    "Some <span class='blue_text'>good</span> reasons": "Quelques <span class='blue_text'>bonnes</span> raisons",
    "Products & Prices Guaranteed!": "",
    "We have the best reasons to travel": "Nous avons les meilleures raisons de voyage",
    "VACATIONER REVIEWS": "ÉVALUATIONS DES VACANCIERS",
    "FROM": "DE",
    "From": "De",
    "Details": "Détails",
    "Review": "Évaluation",
    "Add Cart": "Ajouter le panier",
    "To add this tour to your wish list  you must log in": "",
    "Login": "S'identifier",
    "Email": "Courriel",
    "YOUR TRAVEL AGENT &<br /> TOTOURA WELCOME YOU": "VOTRE AGENT DE VOYAGES &<br /> TOTOURA VOUS SOUHAITE LA BIENVENUE",
    "Language": "Langue",
    "Not registered?": "Non enregistré?",
    "Enter Your Email": "Entrez votre courriel",
    "Go": "Allez",
    "CLICK TO JUST BROWSE": "CLIQUEZ POUR SIMPLEMENT PARCOURIR",
    "Mobile Application available!": "Application mobile disponible!",
    "Don't miss our mobile app -- it's the way to get exclusives, even free calls home while vacationing.  Install the TOTOURA app from this site on your favorite mobile device and get your free features now!": "Ne manquez pas notre application mobile -- c'est la façon d'obtenir des exclusivités, et même de pouvoir appeler à la maison gratuitement pendant vos vacances. Installez l'application TOTOURA à partir de ce site sur votre appareil mobile favori et profitez dès maintenant de vos fonctionnalités gratuites!",
    "Professional Travel Agents": "Agents de voyages professionnels",
    "Add to wishlist": "Ajouter à la liste de souhaits",
    "Invalid email": "Invalide courriel",
    "Accessing...please wait": "Accéder à ... s'il vous plaît patienter",
    "Click on tours you like to build a Wish List": "Cliquez sur les excursions que vous aimez pour créer une liste de souhaits",
    "Find your Perfect Tour:": "Trouvez le excursion parfait pour VOUS:",
    "We will look for specials and enter you to win your favorites": "Nous chercherons des promotions",
    "Searching": "Recherche",
    "Tour & ACTIVITES in": "Excursions & activités à ",
    "By Category": "Par catégorie",
    "Type your search keyword here & press enter key...": "Entrez vos mots-clés de recherche ici & appuyez sur la touche Entrée...",
    "Land": "Terres",
    "Cultural": "Culturel",
    "3rd Tour Free": "3e excursion gratuit",
    "Water": "Eau",
    "Top Rated": "Les mieux notés",
    "Family": "Famille",
    "Unselect All": "Tout déselectionner",
    "Nightlife": "Vie nocturne",
    "Select All": "Tout sélectionner",
    "Adventure": "Aventure",
    "founds": "résultats trouvés",
    "Delete from wishlist": "Supprimer de la liste de souhaits",
    "Valid 3rd Tour Free": "3e excursion gratuit valide",
    "Contact Us!": "Communiquez avec nous!",
    "Be in Touch": "Remplissez notre formulaire de contact ou appelez-nous",
    "Name": "Prénom",
    "\"Enter Email\"": "\"Entrez votre courriel\"",
    "Enter Name": "Entrez votre nom",
    "Message": "Message",
    "Submit": "Soumettre",
    "Write your message": "Entrez votre message",
    "USA / CANADA": "ETATS-UNIS / CANADA",
    "Toll Free": "Numéro sans frais",
    "CANCUN / COZUMEL PUERTO VALLARTA / LOS CABOS": "CANCUN / COZUMEL PUERTO VALLARTA / LOS CABOS",
    "DOMINICAN REPUBLIC": "RÉPUBLIQUE DOMINICAINE",
    "PUERTO PLATA": "PUERTO PLATA",
    "For inquiries please email us at": "Pour toute demande, veuillez nous envoyer un courriel à",
    "PUNTA CANA": "PUNTA CANA",
    "Enter your name": "Entrez votre nom",
    "Enter a valid email": "Entrez un courriel valide",
    "or simply fill out the online contact form": "ou remplissez simplement le formulaire de contact en ligne.",
    "Enter your Message - More than 20 characters": "Entrez votre message - Plus de 20 caractères",
    "No results found": "Sans résultats",
    "Instant Savings on Exclusive Tour SPECIALS": "Économies instantanées sur les PROMOTIONS exclusives de excursions",
    "See More Tours": "Voir plus de excursions",
    "Just select your savings and bonuses as 'Tour Extras' at checkout.": "Sélectionnez simplement vos économies et vos bonus en tant  qu'extras du excursion au moment de passer à la caisse.",
    "Special": "Spécial",
    "NOW": "MAINTENANT",
    "from": "de",
    "Savings & Smiles": "Des Économies et des sourires",
    "Dining & Shopping": "Restauration et Magasinage",
    "Dining options in": "Options de restauration à",
    "RANGE": "PORTÉE",
    "SIMILAR SHOPS": "MAGASINS SIMILAIRES",
    "View on map": "Voir sur la carte",
    "Highlights": "Faits saillants",
    "Address": "Adresse",
    "Description": "Description",
    "Reservations": "Réservations",
    "View More": "Afficher plus",
    "SIMILAR DINING": "RESTAURANTS SIMILAIRES",
    "Cart": "Panier",
    "TOUR": "EXCURSIONS",
    "Edit": "Modifier",
    "Remove": "Supprimer",
    "Activity Date & Time": "Date et heure de l'activité",
    "Price by": "Prix par",
    "Arrival Info": "Informations d'arrivée",
    "TRANSFER TYPE": "TYPE DE TRANSFERT:",
    "Airport": "Aéroport",
    "Airline": "Aeroline",
    "Rates are per {{paymenttype}} in {{currency}} as shown for": "Les tarifs sont par {{paymenttype}}  dans {{currency}} comme indiqué pour",
    "Flight#": "Vol#",
    "Loading prices, please wait...": "",
    "Grand Total": "Total",
    "Check out": "Caisse",
    "Continue shopping": "Continuer à magasiner",
    "Need <span class='blue_text'>Help?</span>": "Besoin <span class='blue_text'>d'aide pour?</span>",
    "Our Vacation Concierge is available.": "Notre concierge de vacances est disponible.",
    "Everyday from 9:00am to 6:00pm Central": "9h à 18h, heure normale du Centre.",
    "Adults": "Adultes",
    "Contact your travel agent to register for your benefits. You must speak with a concierge to book this option. In the meantime, you can browse our destinations.": "Communiquez avec votre agent de voyages pour avoir droit à vos avantages. Vous devez parler à un concierge pour réserver cette option. En attendant, vous pouvez parcourir nos destinations.",
    "TOTAL": "TOTAL",
    "HOTEL": "Hôtel",
    "Children": "Enfants",
    "Arrival Date": "Date d'arrivée",
    "Activity Date": "Date de l'activité",
    "Flight Arrival Time": "Heure d'arrivée du vol",
    "Activity Time": "Heure de l'activité",
    "Coupon Code": "Code promo",
    "Validate Coupon": "Valider le coupon",
    "Seleccione la aerolinea de llegada!": "Entrez la compagnie aérienne à l'arrivée",
    "Contact your travel agent to register for your benefits. You must speak with a concierge to book this option. In the mean time, you can browse our destinations.": "Communiquez avec votre agent de voyages pour avoir droit à vos avantages. Vous devez parler à un concierge pour réserver cette option. En attendant, vous pouvez parcourir nos destinations.",
    "Ingrese el numero de vuelo de llegada!": "Entrez le numéro de vol à l'arrivée",
    "Prices": "Prix",
    "Searching...": "Recherche...",
    "OOPS!": "OUPS!",
    "That email is not in our system. Please try again or contact your travel agent to register you? No Agent? contact the concierge to get you one. In the meantime, you can browse our destinations.": "Ce courriel n'est pas dans notre système. Veuillez réessayer ou communiquez avec votre agent de voyages afin qu'il vous inscrive? Vous n'avez pas d'agent? Communiquez avec le concierge pour en obtenir un. En attendant, vous pouvez parcourir nos destinations.",
    "Check Availability & Prices": "Vérifier la disponibilité et les prix",
    "Hide map": "Masquer la carte",
    "Rules &amp; Things": "Règles et éléments",
    "Tour Description": "Description",
    "Reviews": "Évaluations",
    "What's included": "Ce qui est inclus",
    "Tour Requirement": "Exigences du excursion",
    "What To Bring": "Ce qu'il faut apporter",
    "PEOPLE ALSO LIKE": "LES GENS AIMENT AUSSI",
    "No results found...": "Sans résultats...",
    "My Wish List in": "Ma liste de souhaits dans",
    "Sin resultados": "Sans résultats",
    "Add to Cart": "Ajouter au panier",
    "Check out now": "Passer à la caisse maintenant",
    "Arrival Flight Number": "Numéro de vol à l'arrivée",
    "Select Airline": "Compagnie aérienne à l'arrivée",
    "Arrival Airline": "Compagnie aérienne à l'arrivée",
    "Availability": "Disponibilité",
    " Select Time": "Sélectionnez l?heure",
    "Select Children": "Sélectionnez le nombre d'enfants",
    "Select Adults": "Sélectionnez le nombre d'adultes",
    "Persons": "Personnes",
    "Finish Edition": "Terminer les modifications",
    "Child": "Enfant",
    "Children Ages": "Âge des enfants",
    "Select children ages": "Âge des enfants",
    "FOR MORE THAN {{qtyinventory}} PASSENGERS CONTACT CONCIERGE": "POUR PLUS DE {{qtyinventory}} PASSAGERS, COMMUNIQUEZ AVEC LE CONCIERGE",
    "Free Children": "Enfants gratuits",
    "Lead Name on Reservation": "Nom principal figurant à la réservation",
    "Last name": "Nom",
    "First name": "Prénom",
    "Billing Details": "Détails de la facturation",
    "Your Details": "Vos détails",
    "Checkout": "Caisse",
    "Name as it appears on the Credit Card": "Nom tel qu'il apparaît sur la carte de crédit",
    "Payment Information": "Informations de paiement",
    "Credit Card Type": "Type de carte de crédit",
    "Credit Card Number": "Numéro de carte de crédit",
    "Expiration date": "Date d'expiration",
    "Cards": "Cartes",
    "Month": "Mois",
    "Year": "Année",
    "User Travel Info": "Informations de voyage de l'utilisateur",
    "Departure Date": "Date de départ",
    "Last 3 digits": "",
    "Security code": "Code de sécurité",
    "Optional": "Optionnel",
    "Your Vacation Company": "Votre agence de voyages",
    "How did you find about us?": "Comment avez-vous entendu parler de nous?",
    "Summary": "Résumé",
    "Submit Payment": "Effectuer le paiement",
    "Back": "Précédent",
    "The Lead Name on Reservation must present a valid Photo ID when redeeming their Totoura Voucher(s). Accompanying children do not require Photo ID.": "Le nom principal figurant à la réservation doit présenter une pièce d'identité valide avec photo lors de la validation de leur Totoura bon d'achat. Les enfants accompagnants n'ont pas besoin de pièce d'identité avec photo.",
    "This booking will be listed as {{company}} on your credit card statement.": "Cette réservation sera répertoriée comme {{company}} sur votre relevé de carte de crédit.",
    "Select credit card type": "Sélectionnez un type de carte de crédit",
    "Secure Transaction Statement": "Déclaration de transaction sécurisée",
    "Vacation Company": "Agence de voyages",
    "Select Credit Card Type": "Sélectionnez un Type de Carte de Crédit",
    "Enter credit card number  - 16 Digits": "Entrez le numéro de carte de crédit - 16 chiffres",
    "Enter Security Code (CCV) - 3 Digits": "Entrez le CCV - 3 chiffres",
    "Select Hotel": "Sélectionnez un hôtel",
    "Totoura Website and Mobile Application": "Site Totoura et application mobile",
    "Effective Date: January": "",
    "Provided data": "Données fournies",
    "Mobile Ventures Ltd, dba Totoura, (hereinafter \"Totoura\" or \"We\") privacy policy contains information about collecting users' personal data, products and services offered or performed by Totoura or its related entities and service providers and the manner of its processing. We respect the right to privacy of our software users (hereinafter \"User(s) or \"You\") and take reasonable steps for the user to be familiar with the manner in which rendered accessible information via our mobile application is processed. Due to the implementation of comprehensive security regulations, personal data provided by our website and mobile application users is of highest security standards. The acceptance of terms and conditions for the use of any services or deciding on using or purchasing any product offered by Totoura or its related entities and service providers, means accepting Totoura Privacy Policy hereof.": "",
    "Collected by Totoura, personal data and other user's information is used in order to complete user registration, to process online excursion sales and at the same time to ensure access to Totoura services and products. While registering you on our mobile software system, we request that your travel agent  provide us with certain personal information, without limitation, the user's name, email address and other contact information, travel information such as tourist destination, hotel, arrival and departure dates, airlines, flight numbers, and optionally children´s age and possible interests you may want information and offers on which you can opt out of receiving at any time. Some of the provided user's information such as: your IP address, domain, browser type, operation system type, mobile phone specifications, may be automatically acquired when a user  uses the mobile app. When contacting Totoura, the user may be requested to render accessible analogical data to  us in order to complete transactions. Personal data provided in connection with the correspondence between the user and Totoura shall be used strictly to reply to inquiries made by the user or to transfer information. We use the information provided to us  for the purposes of managing access to your mobile app account, processing online excursion sales and other products or services as offered  by Totoura, processing reservations and operating with third party suppliers the rendering of services or goods you purchase from Totoura.": "",
    "Totoura may also use your personal information to send you push notifications or other direct communications about products or services that Totoura believes may be of interest to you per your customized preferences under the \"WISH LIST\" section of the website and app. Nonetheless, users may eliminate customized push notifications should they opt not to construct a \"WISH LIST\" and then receive only general offers and alerts.": "",
    "": "",
    "Extras & Specials": "Extras et promotions",
    "3x2 Discount": "3x2 Réduction",
    "Coupon": "Promo",
    "Notifications": "Notifications",
    "Close Session": "Fermer la session",
    "Purchase History": "Historique d'achat",
    "Profile": "Profil",
    "Your profile": "Votre profil",
    "Alerts": "Alertes",
    "Close": "Fermer",
    "Username": "Nom d'utilisateur",
    "Mail": "courrier",
    "Are you sure you want to close session?": "",
    "Yes": "Oui",
    "No": "Non",
    "Dismiss Selected": "Rejeter Sélectionné",
    "Notifications settings": "Paramètres de notifications",
    "Delete Notifications": "Supprimer les notifications",
    "Are you sure you want to delete these": "Voulez-vous vraiment supprimer ces",
    "message(s)? Once deleted, you will not be able to retrieve them again!": "messages? Une fois supprimés, vous ne pourrez plus les récupérer!",
    "Yes, delete": "Oui, supprimer",
    "No, cancel": "Non, annuler",
    "Hour": "Heure",
    "Date": "Date",
    "Children personal data": "Données personnelles des enfants",
    "We allow you to provide us with third party personal data in the form of your contacts if you choose to and activating your microphone after downloading the app. This will allow you to conveniently make calls to speak with our concierge assistants as well as provide you the possibility of making calls from destination to the USA and CANDIAN numbers in your contacts, or if you opt to not provide contacts, manually dialing numbers instead.  You may opt to use our online CHAT to contact the concierge assistants in addition to making calls and speaking with them. The information that you provide and features you opt in to use is solely for the purposes described. We will not use this information for any other purpose.": "",
    "Third Party Information - Contacts and Microphone Activations": "",
    "We do not ask you for, access or track any location based information from your mobile device at any time while downloading or using our mobile app or services. Nonetheless, we will request the name of hotel where you will be staying at the tourist destination of your choice and Totoura will use this information to process your online purchases for routing and pickup times for transfers or tours as well as send you the most updated offers and promotions of excursions or other goods and services most conveniently available from your hotel.": "",
    "Geo-Location Data": "",
    "When using our mobile application we may collect device information such as device type, operating system type and application version. We use this information in order to provide an optimized version of our application for your device type.": "",
    "Rates are per": "Les tarifs sont par",
    "in": "dans",
    "DESCRIPTION": "DESCRIPTION",
    "NOT AVAILABLE": "NON DISPONIBLE",
    "Departure:": "Départ:",
    "Arrival:": "Arrivée:",
    "Ingrese el numero de vuelo de salida!": "Entrez le numéro de vol au départ",
    "Seleccione la aerolinea de partida!": "Indiquez la compagnie aérienne de départ",
    "Departure Airline": "Compagnie aérienne de départ",
    "Flight Departure Time": "Heure de départ du vol",
    "Departure Flight Number": "Numéro de vol au départ",
    "Departure Info": "Informations de départ",
    "Free cancellation before {{date}}": "",
    "Free cancellation before 48 Hours": "Annulation gratuite avant 72 heures",
    "Every individual or legal entity accessing the Electronic Platform(s) (hereafter referred to as the \"Customer\") by downloading from, accessing, visiting or registering himself/herself in the Electronic Platforms, is automatically subject to these Terms and Conditions, as amended if amended from time to time.": "",
    "TOTOURA reserves the right, at any time, to modify the content of the present conditions of use and the information that is provided in the Web Site.  The customers, users and visitors of the Web Site shall be responsible of accessing the Web Site and the present terms and conditions of use from time to time to see if modifications have taken place.": "",
    "TERMS AND CONDITIONS.": "CONDITIONS GÉNÉRALES",
    "Terms and Conditions": "Conditions générales",
    "This document contains the terms and conditions that govern the use of the internet web site www.totoura.com and totoura vacation concierge application (hereafter jointly referred to as the \"Electronic Platform(s)\") a property of or proprietary software licensed to totoura LLC. (\"totoura\") by the Customer (as hereinafter defined).  made by Customer(s) using the Electronic Platform constitutes a binding agreement and signifies the Customer's acceptance of and agreement to follow these terms and conditions and to be bound by them. Please read these Terms and Conditions carefully in order to ensure you understand them prior to booking a reservation.": "",
    "Terms and Conditions of use of Totoura web sites and/or mobile applications.": "",
    "TOTOURA reserves the right, at any time, to modify the content of these Terms and Conditions of Use as well as the information that is provided in the Electronic Platform. Customers agree to be solely responsible for accessing the Electronic Platform and determining any amendments to these Terms and Conditions of Use.": "",
    "The Customer hereby declares and warrants that he/she is over 18 years of age and has the legal capacity necessary to purchase or hire in his/her own capacity or in behalf of the legal entity that the Customer declares to represent, as well as to order the charges to the credit card which information he/she provides. Any purchases or reservations made on-line by children under the age of 18 years shall be the responsibility of the child's parents regardless of whether such purchases or reservations were made with the knowledge and/or approval of the parents.": "",
    "TOTOURA hereby grants to Customer a non-exclusive, non-perpetual, revocable, limited license to use the Electronic Platforms for making reservations and/or purchases either by browsing on the www.totoura.com website or by downloading the Mobile Concierge application (the \"App\") to the wireless communication device on which it is initially installed. All rights not expressly granted are reserved by TOTOURA or its software licensor. This license does not allow Customer to use the App on any other wireless communication device other than that on which it was initially installed without the consent of TOTOURA, and Customer may not distribute or make the App available over a network where it could be used by multiple devices at the same time. Customer may not rent, lease, lend, sell, redistribute or sublicense the App. Customer may not copy, decompile, reverse engineer, disassemble, attempt to derive the source code of, modify, or create derivative works of the Electronic Platforms, any updates, or any part thereof (except as and only to the extent any foregoing restriction is prohibited by applicable law). Any attempt to do so is a violation of the rights of TOTOURA and its licensors. The terms of this license will govern any upgrades provided by TOTOURA that replace and/or supplement the original Electronic Platforms, unless such upgrade is accompanied by a separate license in which case the terms of that license will govern.": "",
    "Every individual or legal entity (hereafter referred to as the ?Customer?) by the mere fact of accessing, visiting or registering himself/herself in the Web Site, is automatically subject to the present terms and conditions, as well as to its future modifications. The Customer hereby declares and warrants that he/she is over 18 years of age and has the legal capacity and attributions necessary to purchase or hire in his/her own capacity or in behalf of the legal entity that the Customer declares to represent, as well as to order the charges to the credit card which information he/she provides. The parents and tutors are responsible for the purchases or reservations on-line that are made by the children under their responsibility, with or without their prior approval.": "",
    "CONSENT TO USE OF DATA.": "",
    "LICENSE.": "",
    "In order to use the Electronic Platforms, Customer may be required to register to create an account and may be asked to provide certain personal information including, without limitation, the Customer's name, email address, travel destination, hotel, dates of travel, departure airline, flight number, age of children and other contact information. TOTOURA will collect and use this information for the purposes of setting up Customer's selected password and username, maintaining and managing access to Customer's App account and processing online excursion sales made by the Customer or processing and operating with third party suppliers the rendering of services or goods presented to Customer through the App or www.totoura.com website catalogue as updated from time to time by TOTOURA. TOTOURA may also use Customer's personal information to send Customer e-mail or other communication about products and/or services that TOTOURA believes may be of interest to Customer. Customer may opt-out of this use of their personal information by contacting concierge@totoura.com.": "",
    "In order to proceed with transactions (such as making a reservation) through the Electronic Platforms, Customer may be required to provide additional personal information, including billing and/or credit information. This information may be collected and used either by TOTOURA or by the third party supplier of the product and/or service that is the object of the transaction and Customer agrees that the collection, use and disclosure of this personal information is also subject to the terms of the agreement governing such transaction. TOTOURA strongly recommends that Customer review these terms carefully.": "",
    "Customer specifically consents and authorizes TOTOURA to use all the information Customer provides upon registration for their App account in accordance with these Terms and Conditions. Further, Customer agrees to provide accurate, current and truthful information. Customer acknowledges and agrees that Customer is responsible for all activities occurring under their Electronic Platform account and for keeping their password and other Electronic Platform account information confidential. If Customer suspects someone is accessing or using its Electronic Platform account without its permission or if Customer needs to change its password or account information on the app, it is Customer's responsibility to contact TOTOURA.  Accordingly, Customer may contact by logging in their account on the App and go to \"EDIT ACCOUNT\" in the \"MY ACCOUNT\" section or by contacting TOTOURA at concierge@totoura.com.": "",
    "TOTOURA may periodically  collect and use technical data and related information, including but not limited to technical information about the device, system and application software, and peripherals gathered to facilitate the provision of software updates, product support and other services to Customer related to the Electronic Platforms. TOTOURA may use this information for other purposes, including, by way of description only, to improve its products or to provide additional services or technologies to Customer, as long as the Customer's information is in a form that does not personally identify Customer.": "",
    "By agreeing to the Conditions of Use of Totoura?s websites and/or mobile applications Customer agrees to the collection, use and disclosure of its personal information as outlined above.   To the extent provided by applicable law, Customer may request access and correction of its personal information held by TOTOURA by contacting it at the email address set out above.": "",
    "TOTOURA may disclose Customer's personal information as required by law or to any third parties who process the personal information on TOTOURA's behalf. Some of these service providers may be located in foreign jurisdictions and Customer's personal information may be collected, used, disclosed, stored or processed in such foreign jurisdictions and will then become subject to the legal requirements of these jurisdictions, including lawful requirements to disclose personal information to the governmental authorities in those jurisdictions.": "",
    "Unless specified otherwise, the base price of a package sold to a Customer will include the selected local excursion, transportation on site along with other inclusions, if any, specified in the selected product description on the Electronic Platforms. Customer acknowledges and agrees that the base price of a package excludes personal expenses, meals and drinks, insurances, taxes and service charge or other services not specified at the time of the booking.": "",
    "INCLUSIONS AND EXCLUSIONS.": "",
    "The purchase of one or more of the packages offered by TOTOURA to the Customer constitutes a contractual agreement between TOTOURA and the Customer and conclusively establishes the acceptance by the Customer of TOTOURA's Terms and Conditions of Use.": "",
    "TOURS AND EXCURSIONS PACKAGES.": "",
    "PRODUCT DESCRIPTIONS.": "",
    "TOTOURA has put forth all necessary efforts to ensure that, at time of publication, all products' descriptions on this Web Site are accurate. For several reasons beyond the control of TOTOURA, modifications can take place which may affect products' descriptions. TOTOURA will make reasonable efforts to update the Electronic Platform(s) upon receiving notification of any such modifications. It is the Customers' responsibility to consult the Electronic Platforms to be aware of these modifications.": "",
    "PRICES INDICATED.": "",
    "The prices confirmed at the time of the booking are the prices that will be charged to the Customer and supersede any pricing advertised or indicated before the confirmation of the booking.  In addition, Customer acknowledges and agrees that prices are subject to further change at any time, without prior notice, until the Customer has paid its reservation in full.": "",
    "Prices are quoted in United-States dollars (US$), unless otherwise indicated.": "",
    "PAYMENT.": "PAIEMENT",
    "Customers must pay the total of all sums due at the time of the booking by means of a valid credit card. The use of a credit card number by a Customer indicates its agreement to comply with the Terms and Conditions.": "",
    "Tour availability is not guaranteed until full payment is received and a confirmation message is sent by TOTOURA.": "",
    "CONFIRMATION OF RESERVATION.": "NUMÉRO DE CONFIRMATION DE LA RÉSERVATION",
    "MODIFICATION/CANCELLATION TO A TOUR RESERVATION.": "",
    "It is the responsibility of the Customer to ensure that the information appearing on the electronic ticket, voucher and any other reservation documents or electronic message is consistent with Customer's booking arrangements.  Restaurant reservations, when and if available from the Electronic Platforms, will be processed in accordance with the operating policies set forth from time to time by the management of restaurants affiliated to the Electronic Platforms.": "",
    "Date and time changes to reservations may be submitted no later than twenty-four (24) hours prior to scheduled departure time. To modify his/her reservation, Customer must contact TOTOURA by using his/her preferred form of contact available at www.totoura.com, or by sending an email to concierge@totoura.com  or through the interactive interface of Mobile Concierge mobile application.  Customer acknowledges and agrees that TOTOURA cannot guarantee that changes requested by Customer can be honored.": "",
    "TOTOURA uses electronic ticketing or voucher(s) to confirm the reservation. Upon completion of a booking by the Customer, the Customer must print the electronic ticket(s) or voucher(s) to present at check-in.  Customer may also be required to provide his/her confirmation number at check-in together with a picture id.": "",
    "Tour cancellation requests received more than twenty-four (24) hours prior to scheduled departure time are subject to a five (5) percent penalty based upon the total amount of the reservation.": "",
    "Except as provided in Article 10, no reimbursements or refunds will be granted to Customer for unused services.": "",
    "Tour cancellation requests received less than twenty-four (24) hours prior to scheduled departure time will be considered a \"no show\" and NO REFUNDS WILL BE MADE.": "",
    "REFUNDS.": "",
    "In the event of a medical and/or other related emergency, Customer may reschedule or cancel with a valid medical certificate. Customer must contact TOTOURA using his/her preferred form of contact available at www.totoura.com, or by sending an email to concierge@totoura.com  or through the interactive interface of the App.": "",
    "EXCLUSION OF LIABILITY.": "",
    "Most tours operate rain or shine; boat excursions will only be cancelled if the port authority deems sea conditions unsafe.": "",
    "Moreover, TOTOURA shall not be liable for the failure by the service providers to provide the services due to a force majeure event or any other cause beyond the reasonable control of TOTOURA or the service providers.": "",
    "The Customer acknowledges that TOTOURA makes arrangements with third-party service providers and other independent parties to provide Customers with the tours and excursions packages they purchase. Although TOTOURA takes great care in selecting these service providers, it does not have any control over their business activities and cannot be held responsible for their acts, omissions, fault or negligence, or that of their employees, nor for any loss or damages suffered as a result thereof.": "",
    "TOTOURA shall not be liable in any manner for any claims, loss, damage, cost or expense arising out of personal injury, accident or death of the Customer, its invitees or guests, or for any claims, loss, damage, cost or expense arising out of loss of and/or damage to the Customers' property or any inconvenience, loss of enjoyment or disappointment that may arise from the participation of the Customer, its invitees and/or guests in any such activities and/or services  offered by TOTOURA's service providers.": "",
    "Customer acknowledges that in the Electronic Platforms, there could be links to web pages of third parties and that TOTOURA's Web Site could provide information displayed by third parties that are not part of TOTOURA. Customer(s) specifically acknowledge and agree that TOTOURA does not grant any class of warranty with regards to such information and that TOTOURA shall not be held responsible for any lack of compliance by the third parties.": "",
    "TOTOURA shall not be liable for the quality or safety of activities and/or services (such as excursions, optional tours, activities, etc.) offered by its service providers, nor for any representations made by TOTOURA's agent on any such activities and/or services.": "",
    "Customer(s) specifically agree that TOTOURA, as well as its associates, affiliates or subsidiaries are not and shall not be responsible for any damages, direct, indirect, incidental, special or consequential, that may arise from use of the Electronic Platform(s), including, by way of description:": "",
    "If TOTOURA elects to substitute a comparable tour or excursion and the price of the substituted package is greater than that of the originally scheduled package, the Customer may refuse such substitution and TOTOURA shall reimburse the Customer the price paid by the Customer for the unavailable tour and/or excursion.  If the Customer accepts such substitution, the Customer will be required to disburse an amount equal to the difference between the price of the initial package purchased and the price of the substitute package. The Customer shall notify TOTOURA within 48 hours following notification of the proposed substitution if Customer refuses such substitution, otherwise it will be conclusively presumed that Customer has accepted the substitution.": "",
    "If TOTOURA elects to substitute a comparable tour or excursion and the substituted package is of a lesser value the Customer may refuse such substitution and TOTOURA shall reimburse the Customer the price paid by the Customer for the unavailable tour and/or excursion.  If the Customer accepts such substitution, then TOTOURA's sole obligation to Customer shall be to refund the difference in cost to Customer.  The Customer shall notify TOTOURA within 48 hours following notification of the proposed substitution if Customer refuses such substitution, otherwise it will be conclusively presumed that Customer has accepted the substitution.": "",
    "In TOTOURA elects to cancel the Customer's reservation for such unavailable tour and/or excursion,  the liability of TOTOURA shall be limited to the reimbursement of the price of the initial package paid by the Customer.": "",
    "Sometimes, reserved tours or excursion packages become unavailable for reasons beyond TOTOURA's control. In such case, TOTOURA reserves the right, exercisable by TOTOURA in its sole and absolute discretion, to substitute a comparable alternate tour or excursion for the unavailable tour or excursion or, alternatively, to cancel the Customer's reservation for such tour or excursion.": "",
    "CHANGES TO TOURS AND EXCURSIONS PACKAGES.": "",
    "general malfunction, transfer of electronic virus or similar when accessing, downloading or using the Electronic Platforms; typographic, technical, orthographic, design errors or those caused by the delay in the update of the server and/or Electronic Platforms or part of these, the lack of availability or delay in sending or delivering the messages of data (including electronic mail, reservation requests or sending of reservation confirmations); intromissions to the Electronic Platforms or interception of data messages; errors of the Customer when providing the information required by the Electronic Platforms, that are not immediately notified by the Customer to TOTOURA for its attention; duplicating or mistake in the processing of the charges to a credit card, due to the responsibility of the credit or banking entity; Notwithstanding the above, in case that any responsibility arises on behalf of TOTOURA, such liability shall always be limited to the amount of the consideration paid by the Customer.": "",
    "Any incident, service cancellation, problem or dissatisfaction must be reported by the Customer at the time of the event or as soon as possible after, to allow TOTOURA the opportunity to rectify the situation or provide assistance.": "",
    "At each destination, the Customer must contact TOTOURA's local customer service department to get assistance and to remedy any problem without delay whenever such problem can be reasonably remedied. TOTOURA's customer service department can be contacted via telephone or email as shown on www.totoura.com or the interactive interface of the App. If a problem cannot be solved or dealt with immediately, Customers are requested to forward the specifics of the problem to TOTOURA, within thirty (30) days upon return from their trip, in writing to the following address:": "",
    "GENERAL COMMENTS AND COMPLAINTS.": "",
    "TOTOURA LLC.": "TOTOURA LLC.",
    "1901 South Meyers Road Suite 500": "1901 South Meyers Road Suite 500",
    "Oakbrook Terrace, Illinois": "Oakbrook Terrace, Illinois",
    "Notwithstanding any other provision of these terms and conditions, TOTOURA and its licensors reserve the right to change, suspend, remove, or disable access to any of the Electronic Platforms, content, or other materials that are presented through the Electronic Platforms without need of prior notice. In no event will TOTOURA be liable for making these changes. TOTOURA may also impose additional limits on the use of or access to certain features or portions of the Electronic Platforms, in any case and without prior notice or liability.": "",
    "Customer shall have no right or license of use whatsoever over any material or content of TOTOURA's suppliers or merchants affiliated to the Electronic Platforms.": "",
    "By accepting the terms and conditions hereof Customer agrees the Electronic Platforms contain proprietary information and material that is owned by TOTOURA and/or its applicable licensors or suppliers, and is protected by applicable intellectual property and other laws, including but not limited to copyright. Customer may not use such proprietary information or materials in any way whatsoever except for use of the Electronic Platforms in compliance with the terms and conditions hereof.": "",
    "INTELLECTUAL PROPERTY": "",
    "TOTOURA and/or its licensors or suppliers reserve all copyright derived from the Electronic Platforms. Unauthorized or unlawful use of the Electronic Platforms shall be subject to civil and criminal penalties as well as pecuniary damages for copyright infringement.": "",
    "LAWS.": "",
    "This Agreement shall be governed, interpreted and construed in accordance with the laws of Barbados, and the courts of St. Michael Barbados shall have exclusive jurisdiction for all matters or proceedings between TOTOURA and the Customer.  In the event one or more of the provisions contained in this Agreement shall for any reason be held to be invalid, illegal, or unenforceable in any respect by a court of competent jurisdiction, such invalidity, illegality, or unenforceability shall not affect any other provision of this Agreement, and this Agreement shall be construed as if such invalid, illegal, or unenforceable provision were not contained herein or therein.": "",
    "By accepting these Terms and Conditions of Use, the Customer also agrees to receive alerts": "",
    "PRIVACY, NOTIFICATIONS": "CONFIDENTIALITÉ, NOTIFICATIONS",
    "ALERTS": "ALERTES",
    "For TOTOURA's complete current PRIVACY POLICY please refer to the user account section of the Electronic Platform(s). Acceptance of these Terms and Conditions of Use are also deemed an acceptance of TOTOURA's PRIVACY POLICY.": "",
    "When accessing the Electronic Platform(s) and/or when creating an account, the Customer may receive an option to elect to allow TOTOURA to send alerts and notifications to them on an ongoing basis for the purpose of providing updated information on the Customer's vacation details as well as offers and promotions that the user may be interested in.": "",
    "ENTIRE AGREEMENT.": "",
    "These Terms and Conditions, as amended, if amended from time to time, constitute the entire agreement between TOTOURA and the Customer and therefore, any prior agreement, whether verbal or written, is superseded.": "",
    "OOPS!! PAGE NOT FOUND": "",
    "View all tours": "Voir tous les excursions",
    "Back to home": "Retour à accueil",
    "Shopping in": "Magasinage à",
    "Rules & Things": "Règles et éléments",
    "Map": "Carte",
    "Tour Requirements": "Exigences du excursion",
    "Continue": "Continuer",
    "For this activity please call concierge to confirm": "Pour cette activité, veuillez appeler le concierge pour confirmer. Ou sélectionnez une autre date disponible.",
    "Reservation Recap": "Récapitulatif de la réservation",
    "Regresar": "Retour",
    "Transaction Date": "Date de la transaction",
    "Receipt Number": "Numéro de reçu",
    "Type": "Type",
    "Status": "Statut",
    "Transfer": "Transfert",
    "Departure": "Départ",
    "Round": "",
    "Arrival": "Arrivée",
    "3<sup>rd</sup> Tour Free": "3<sup>e</sup> Excursion Gratuit",
    "Airport - Hotel Transfer Services": "Services de Transferts Aéroport - Hôtel",
    "Your details": "Vos détails",
    "We'd love to hear from you.": "Nous aimerions vous entendre.",
    "Your Cart": "Votre panier",
    "Enter Name as it appears on the Credit Card": "Nom tel qu'il apparaît sur la carte de crédit",
    "Enter First Name": "Entrez le prénom",
    "Enter Last Name": "Entrez le nom",
    "Enter Security Code (CCV) - 4 Digits": "Entrez le CCV - 4 chiffres",
    "Enter credit card number  - 15 Digits": "Entrez le numéro de carte de crédit - 15 chiffres",
    "We are processing your request, please wait...": "",
    "Please verify your information": "",
    "Invalid Account Number": "",
    "Select Expiration Month": "Sélectionnez le mois d'expiration",
    "Select Expiration Year": "Sélectionnez l'année d'expiration",
    "Validating Coupon..": "Validation du coupon...",
    "Invalid Coupon": "Coupon non valable",
    "Rates are per {{pricetype}} in {{currency}}": "Les tarifs sont par {{pricetype}} en {{currency}}",
    "That email is not in our system. Please try again or contact your travel agent to register you... No Agent? contact the concierge to get you one. In the meantime, you can browse our destinations.": "Ce courriel n'est pas dans notre système. Veuillez réessayer ou communiquez avec votre agent de voyages afin qu'il vous inscrive? Vous n'avez pas d'agent? Communiquez avec le concierge pour en obtenir un. En attendant, vous pouvez parcourir nos destinations.",
    "Find your next experience": "Trouvez votre prochaine aventure",
    "Why": "",
    "Your Travel": "Votre Voyage",
    "Agent Knows": "Agent de voyages connaît...",
    "us": "nous",
    "Your travel agent knows that we only use the best, most reliable insured companies to provide transportation and tours ? you get the best service, value and price for what we offer, Guaranteed? You won?t find a better price for our offers, but if you do, we will match it.": "Votre agent de voyages sait que nous ne faisons affaire qu'avec les meilleures et les plus fiables entreprises assurées pour offrir le transport et les excursions. Vous obtenez le meilleur service et le meilleur rapport qualité-prix pour ce que nous proposons, c'est garanti? Vous ne trouverez pas un meilleur prix pour nos offres, mais si cela se produit, nous allons l'égaler.",
    "Only the BEST -- GUARANTEED!": "Juste ce qu'il y a de MIEUX -- GARANTI!",
    "Online": "En ligne",
    "Concierge": "Concierge",
    "At Destination -- Before & During Vacation!": "À destination -- Avant et pendant les vacances!",
    "Chat Online or tap to talk for FREE from our app, directly with your destination concierge, before you arrive AND throughout your vacation. Get ?insider info? and advice from a local professional to make sure you get the best of everything, for YOU.": "À l'aide de notre application, clavardez en ligne ou appuyez pour parler GRATUITEMENT avec le concierge de votre destination, avant votre arrivée ET pendant vos vacances. Obtenez des « informations privilégiées » et les conseils d'un professionnel local pour vous assurer d'obtenir le meilleur de tout, pour VOUS.",
    "Tour": "Tour",
    "Secure": "Secure",
    "Book Early Adjust Plans ~ No Charge": "Réservez tôt et ajustez vos plans -- Sans frais",
    "See What's": "Voir ce qui est",
    "Home FREE!": "à la maison GRATUITEMENT!",
    "Call": "Appelez",
    "Trending": "Tendances",
    "Vacationer Reviews": "Évaluations des vacanciers",
    "See what's hot, top tours at your destination other vacationers are taking and what they are saying about their experiences.": "Voyez ce qui a la cote et ce que les autres vacanciers en disent",
    "About Us": "À propos de Nous",
    "Totoura, has grown to deliver quality ground services to over 1 million international vacationers every year. Providing Airport/Hotel Transportation, Tours and Activities for vacationers, by using only the very best reliable companies at each destination is our standard operating policy.": "Totoura fondée en 1999, s'est développée pour offrir des services au sol de qualité à plus de 1 million de vacanciers internationaux chaque année. Notre politique de fonctionnement standard consiste à offrir le transport aéroport/hôtel, des excursions et des activités aux vacanciers, en utilisant uniquement les entreprises les meilleures et les plus fiables, à chaque destination. Chez /* @echo BRAND */, « nous rendons les vacances merveilleuses » pour certaines des agences de voyages les plus connues au monde, y compris : Transat, Look Voyages, Vacances Transat France, Delta Vacations, Price Travel et Palace Resorts.",
    "At Totoura": "À Totoura",
    "we make good vacations great": "nous rendons les vacances merveilleuses",
    "for some of the world's best known vacation companies including: Transat, Look Voyages, Vacances Transat France, Delta Vacations, Price Travel and Palace Resorts.": "pour certaines des agences de voyages les plus connues au monde, y compris : Transat, Look Voyages, Vacances Transat France, Delta Vacations, Price Travel et Palace Resorts.\";",
    "TOURS, TRANSFERS": "TOURS, TRANSFERTS",
    "Chat Online or tap to talk for FREE from our app, directly with your destination concierge, before you arrive AND throughout your vacation. Get \"insider info\" and advice from a local professional to make sure you get the best of everything, for YOU.": "À l'aide de notre application, clavardez en ligne ou appuyez pour parler GRATUITEMENT avec le concierge de votre destination, avant votre arrivée ET pendant vos vacances. Obtenez des « informations privilégiées » et les conseils d'un professionnel local pour vous assurer d'obtenir le meilleur de tout, pour VOUS.",
    "<span class='blue_text'>Your Travel</span> Agent Knows...": "<span class='blue_text'>Votre agent</span> de voyages connaît...",
    "Why <span class='blue_text'>us</span>?": "Pourquoi <span class='blue_text'>nous</span>?",
    "<span class='blue_text'>Online</span> Concierge": "Concierge <span class='blue_text'>En Ligne</span>",
    "Your travel agent knows that we only use the best, most reliable insured companies to provide transportation and tours ? you get the best service, value and price for what we offer, Guaranteed? You won't find a better price for our offers, but if you do, we will match it.": "Votre agent de voyages sait que nous ne faisons affaire qu'avec les meilleures et les plus fiables entreprises assurées pour offrir le transport et les excursions. Vous obtenez le meilleur service et le meilleur rapport qualité-prix pour ce que nous proposons, c'est garanti? Vous ne trouverez pas un meilleur prix pour nos offres, mais si cela se produit, nous allons l'égaler.",
    "<span class='blue_text'>Tour</span> Secure": "<span class='blue_text'>Tour</span> Secure",
    "<span class='blue_text'>See What's</span> Trending": "<span class='blue_text'>Voyez ce</span> qui est tendance",
    "<span class='blue_text'>Call</span> Home FREE!": "<span class='blue_text'>Appelez</span> à la maison GRATUITEMENT!",
    "Totoura shall take precautions to ensure the highest security level of all information and all personal data provided using our services and products. We do not knowingly collect personal information from anyone under the age of 18.": "",
    "When a user's age of children are provided in their profile by their agent it is for the purpose of making sure a specific activity is conditionally available to a person of that age, and the eligibility for any special offers and rates to be accessible for persons of that age.": "",
    "The name, gender, date of birth or other minor's personal information is not required.": "",
    "Users may also choose not disclose children's ages until the time of purchase, but must do so at that time, in order to make certain the child's age will not disallow participation in an activity and correct rate is charged, otherwise Totoura will be relieved of responsibilities otherwise binding in terms and conditions.": "",
    "Data disclosure": "",
    "We do not sell your personal data to third parties.  Totoura shall transfer no information or personal data to third parties for marketing purposes. Totoura's main purpose of gathering personal data and users' other information is to  operate, maintain, and provide to you the features and functionality of the Mobile Concierge website and app.": "",
    "Personal data and other information provided by users shall not be disclosed to third parties unless necessary to further process your excursion or other service purchases or unless the obligation to disclose the information to third parties results from the currently effective provisions of law, such as to comply with a subpoena, or similar legal process, when we believe in good faith that disclosure is necessary to protect our rights, protect your safety or the safety of others, investigate fraud, or respond to a government request, or if it is necessary for  Totoura's security and its rights protection, including the protection against the claims submitted by the third parties. The provided user's information may be utilized in the event of breach of terms and conditions for the use of services, misuse of funds, the necessity to take action against all user's unlawful actions.": "",
    "Totoura reserves the right to use the provided data for communication and other purposes related with your purchase and confirmations as well as to send you push notifications per your customized \"Wish List\".": "",
    "Totoura reserves the right to disclose personal data and other information relating to the user to third parties suppliers or affiliated with Totoura connected with providing services and its current business activity, such as payment processing, executing or managing excursion packages, processing reservations, providing customer support and sending emails out on our behalf. Totoura shall observe due diligence to make sure for the processing of data disclosed to entities affiliated with Totoura to be in compliance with our privacy policy and the currently effective law.": "",
    "Credit/payment card data provided by the user are disclosed solely to professional companies that conduct non-cash transactions and only to the extent necessary to effect the payments.<br />Except for the above situations Totoura shall not disclose any information or personal data provided by users.<br />If we are involved in a merger, acquisition, or sale of all or a portion of our assets, you will be notified via email and/or prominent notice on our Web site of any change in ownership or uses of your personal data, as well as any choices you may have regarding your personal data.": "",
    "Mobile Analytics": "",
    "We use online and mobile analytics software to allow us to better understand and improve the functionality of our systems. This software may record information such as how often you use the application, the events that occur within the application, aggregated usage, performance data, and where the application was downloaded from. We do not link the information we store within the analytics software to any personal data you submit within the mobile app.": "",
    "Surveys": "",
    "From time-to-time we may provide you the opportunity to participate in a survey. If you participate, we will require your name and email address. Participation in these surveys is completely voluntary and you therefore have a choice whether or not to participate and disclose this information.": "",
    "Data protection": "",
    "Totoura applies technical safety measures of the highest standards to protect the provided data against loss, destruction, misuse, unauthorized access or disclosure. The used measures and technology ensure complete safety of the data provided by the user. The data is available solely to the user or to a person indicated by him provided authorized access has been granted to that person. You control who you allow access to the app by  disclosing your account password.": "",
    "The security of your personal data is important to us. We follow generally accepted industry standards to protect the personal data submitted to us, both during transmission and once we receive it. No method of transmission over the Internet, or method of electronic storage, is 100% secure, however. Therefore, we cannot guarantee its absolute security.<br />Totoura takes all necessary steps to update and modernize data protection system. No data transmission via the Internet, however, can guarantee 100% safety.<br />When you enter sensitive information (such as credit card number) on our Web site or within our mobile application, we encrypt the transmission of that information using secure socket layer technology (SSL) This way no one can read, interpret or access this data without the appropriates algorithms to decode them.": "",
    "Privacy policy modification": "",
    "We may update this privacy statement to reflect changes to our information practices. If we make any material changes we will notify you by email (sent to the e-mail address specified in your account) or by means of a notice either on our Web Site or on the Mobile App interphase prior to the change becoming effective. We encourage you to periodically review this  policy for the latest information on our privacy practices.": "",
    "Data gathering entity": "",
    "Conducting its activity, under the business name of  Mobile Ventures, with its registered office: The Accountants Center, River Road, St. Michael, Barbados, shall be the entity gathering personal data and other information.": "",
    "Commercial transactions": "",
    "Totoura reserves the right to use its privacy policy, user's account and provided data and information for the benefit of an entity that has become either a dependent entity, an associated entity, or has come into being as a result of merger or transformation of Totoura.": "",
    "Access, Amendment, Deactivation and Opposition.": "",
    "If your personal data changes, or if you no longer desire our service, you may access, amend, update, delete inaccuracies or request deactivation of your account by making the change within the \"Edit Account\" section of the Mobile Concierge application, website or by emailing us at<a href=\"mailto:info@totoura.com\">info@totoura.com</a><br />You may also contact Totoura to oppose on the use of your personal information, provided, however, Totoura may not be able to continue providing you the services and functionalities of the website or Totoura application. We will respond to your privacy policy requests within 30 days. Should you request deactivation of your Totoura app account Totoura undertakes to have all your personal data deleted in its records. All users are encouraged to contact Totoura at the email address cited above to obtain further information on the applicable procedures to access, amend, update, delete inaccuracies, deactivate your account or oppose to the use of your personal data.": "",
    "Terms and conditions of use": "Conditions générales d'utilisation",
    "Using offered products and services rendered by Totoura the user is obliged to comply with rules contained in the terms and conditions available in our Terms of Use sections of the website and mobile application.": "",
    "Should you have any inquiries or doubts regarding our privacy policy or applied procedures feel free to contact us at": "",
    "Use the app's FREE international calling system in conjunction with a wifi connection at destination and call anyone, any line directly - cells, land lines, whatever... 30 minutes included free": "Utilisez, lors de votre voyage, le système d'appels internationaux GRATUIT de l'application, en conjonction avec une connexion wifi et appelez n'importe qui, n'importe quelle ligne directement - cellulaires, lignes fixes, peu importe? 30 minutes incluses gratuitement",
    "No Results Found": "Sans résultats",
    "Detail": "Détails",
    "No se encontraron coincidencias de la búsqueda...": "",
    "Sending": "Envoi",
    "Message sent successfully.": "Message envoyé avec succès.",
    "Invalid Amount": "",
    "ALL AREAS / NOT CERTAIN": "TOUTES LES RÉGIONS / PAS CERTAINES",
    "Special Offers in": "Offres spéciales au ",
    "Select the airline of arrival!": "",
    "Enter the arrival flight number!": "Indiquez le numéro du vol d'arrivée!",
    "Enter the departure flight number!": "Indiquez le numéro du vol de départ!",
    "Select the starting airline!": "",
    "Detail Information": "Informations détaillées",
    "Cart List": "",
    "Hello": "Bonjour",
    "Notifications & Alerts": "Notifications et Alertes",
    "Below you will find the details of your purchase": "",
    "Approved payment": "",
    "Children Free": "",
    "No matches were found in the search!": "",
    "A MESSAGE FROM <br />YOUR TRAVEL AGENT": "",
    "MEETING POINT": "POINT DE RENCONTRE",
    "Tour Date": "Date du Excursion",
    "Departure Time": "Heure de Départ",
    "Provider Cfrm#": "Numéro de confirmation du fournisseur",
    "Res Cfrm#": "Reservation Confirmation Number",
    "INSTRUCTIONS": "INSTRUCTIONS",
    "Amount Paid:": "Montant Payé:",
    "INSTRUCTIONS:": "INSTRUCTIONS:",
    "Time": "Durée",
    "Departrue Date": "",
    "Arrival Departure Time": "Heure d'arrivée et départn",
    "Arrival Time": "Heure d'arrivée",
    "Cozumel, a mostly undeveloped Mexican island in the Caribbean Sea, is a popular cruise ship port of call famed for its scuba diving. At Arrecifes de Cozumel National Park, divers can explore a section of the Mesoamerican Reef.": "",
    "At the tip of Baja California Sur you will find the dual destination of Los Cabos. Wondering why they call it a dual destination? Part of the fun comes with exploring the regions two dramatically distinct personas. Tranquil San Jose del Cabo retains the look and vibe of an authentic Mexican town.": "",
    "Having been considered for a long time as the \"jewel\" in the Caribbeans tourism crown, Jamaica is way more than just sun, sea and sand! This vibrant island offers you a variety of outdoor activities. Jamaicas culture is world renowned with Bob Marley leading the charge as the King of reggae, and his spicy food and warm friendly people are making from this island the perfect travel destination.": "",
    "The island of Hispaniola lies in the heart of the Caribbean. To the north is the Atlantic Ocean and to the south the Caribbean Sea. Hispaniola is the second largest island in the Greater Antilles after Cuba. The Dominican Republic occupies 2/3 of the island and has become the most popular destination in the Caribbean in the last 10 years.": "",
    "Located on the Pacific Coast of Mexico, in the state of Jalisco, Puerto Vallarta is among the top resort destinations in Mexico. Banderas Bay, the second largest bay in the Americas, offers travelers to Puerto Vallarta a chance to enjoy a delightful beach.": "",
    "The Riviera Maya of Mexico is a wonderful piece of paradise under the tropical Caribbean sun and alongside the beautiful turquoise waters of the Caribbean Sea, surrounded by amazing white sand that never gets so hot it burns your feet.": "",
    "See 3<sup class='t_low'>rd</sup> Condition Tour": "",
    "<strong>3<sup class='t_low'>rd</sup> Tour FREE!</strong>": "",
    "<strong>Book 3 Diferent Tours - ALL MUST BE FROM THOSE LISTED AND SHOWN AS \"Valid 3<sup class=\"t_low\">rd</sup> Tour Free\" on the promotion page.</strong>": "",
    "<strong>Here's How 3<sup class='t_low'>rd</sup> Tour Free Promo Works</strong>": "<strong>Voici comment 3<sup class='t_low'>rd</sup> Promotion d'excursion gratuit fonctionne</strong>",
    "Condition Tour": "",
    "Book ALL 3 Tours at the SAME time": "",
    "BOOK ALL 3 TOURS in ONE Reservation through the Shopping Cart.": "",
    "NOTE:": "REMARQUE:",
    "The Free Tour is the one of equial or lesser value to the others and your savings is automatically calculated and shown at checkout when you finalize your purchase online. Or, you may contact us to make your reservation we will review the pricing and details with you when we make your booking.": "",
    "Only the valid displayed <span class=\"blue_text\"><strong>\"3<sup class=\"t_low\">rd</sup> Tour Free\" </strong></span> activities shown online are valid in this promotion. The promotion rules are for a \"set-offer\" as described, and once booked.": "Seulement les valides affichées <span class=\"blue_text\"><strong>\"3<sup class=\"t_low\">rd</sup> Excursion Gratuit\" </strong></span> activitées en ligne sont valides pour cette promotion. Les règlements de la promotion sont pour les \"offres\" décrites, et une fois réservée,",
    "IMPORTANT RULES/RESTRICTIONS": "IMPORTANTS RÈGLEMENTS/RESTRICTIONS",
    "ALL participants on the reservation must be doing the exact same 3 tours...otherwise, make separate reservations when the selections vary by participant.": "TOUS les participants de la réservation doivent faire les mêmes excursions? sinon, bien vouloir faire des reservations séparées pour chaque participant.",
    "NO CHANGES OR CANCELLATIONS ARE ALLOWED": "",
    "SEE ALL 3<sub class='t_low'>rd</sub> Tour FREE Activities": "Voir Toutes 3<sub class='t_low'>rd</sub> Activités des Excursions Gratuites",
    "Book online <span class=\"blue_text\">or call</span>": "Réservez en ligne <span class=\"blue_text\">ou appelez</span>",
    "Booking": "Réservation",
    "Remove from whislist": "",
    "No price available for {{qtypersons}} passenger": "",
    "My Wish List": "",
    "Filters": "",
    "Type your search keyword here": "",
    "Searching..": "",
    "User Info": "Informations utilisateur",
    "Phone": "Téléphone ",
    "You will be charged the total amount once your order is confirmed.": "Le montant total vous sera facturé une fois votre commande confirmée.",
    "If confirmation isn't received instantly, an authorization for the total amount will be held until your booking is confirmed.": "",
    "You can cancel for free up to 48 hours before the day of the experience, local time.": "Vous pouvez annuler gratuitement jusqu'à 72 heures avant la date du début de l'aventure, heure locale.",
    "Enter phone number": "Indiquez le numéro de téléphone",
    "Discover our Top experiences <span>now</span>": "",
    "ALL participants on the reservation must be doing the exact same 3 experiences...otherwise, make separate reservations when the selections vary by participant.": "TOUS les participants de la réservation doivent faire les mêmes excursions? sinon, bien vouloir faire des reservations séparées pour chaque participant.",
    "Click on experiences you like to build a Wish List": "",
    "BOOK ALL 3 experiences in ONE Reservation through the Shopping Cart.": "",
    "All Experiences": "",
    "Experiences": "",
    "Condition Experience": "",
    "<strong>3<sup class='t_low'>rd</sup> Experience FREE!</strong>": "",
    "<strong>Book 3 Diferent Experiences - ALL MUST BE FROM THOSE LISTED AND SHOWN AS \"Valid 3<sup class=\"t_low\">rd</sup> Experience Free\" on the promotion page.</strong>": "",
    "<strong>Here's How 3<sup class='t_low'>rd</sup> Experience Free Promo Works</strong>": "<strong>Voici comment 3<sup class='t_low'>rd</sup> Promotion d'excursion gratuit fonctionne</strong>",
    "Book ALL 3 Experiences at the SAME time": "",
    "BOOK ALL 3 EXPERIENCES in ONE Reservation through the Shopping Cart.": "",
    "The Free Experience is the one of equial or lesser value to the others and your savings is automatically calculated and shown at checkout when you finalize your purchase online. Or, you may contact us to make your reservation we will review the pricing and details with you when we make your booking.": "",
    "Only the valid displayed <span class=\"blue_text\"><strong>\"3<sup class=\"t_low\">rd</sup> Experience Free\" </strong></span> activities shown online are valid in this promotion. The promotion rules are for a \"set-offer\" as described, and once booked.": "Seulement les valides affichées <span class=\"blue_text\"><strong>\"3<sup class=\"t_low\">rd</sup> Excursion Gratuit\" </strong></span> activitées en ligne sont valides pour cette promotion. Les règlements de la promotion sont pour les \"offres\" décrites, et une fois réservée,",
    "SEE ALL 3<sub class='t_low'>rd</sub> Experience FREE Activities": "Voir Toutes 3<sub class='t_low'>rd</sub> Activités des Excursions Gratuites",
    "Experience & ACTIVITES in": "",
    "Find your Perfect Experience:": "",
    "3rd Experience Free": "",
    "Experiences Reviews": "",
    "Valid 3rd Experience Free": "",
    "See our best experience places at Totoura": "",
    "EXPERIENCE": "",
    "Experience Description": "",
    "Experience Requirement": "",
    "Could not register the account, please try again or contact for help": "",
    "Experience Requirements": "",
    "Just browse": "",
    "english": "",
    "Proffesional Travel Agents": "",
    "Expired Travel Dates": "",
    "Select destination": "",
    "Select Area": "",
    "Thanks for booking your vacation with us.": "",
    "Enjoy your time and use this Vacation Concierge app for assistance and activities in Destination to make it even better.": "",
    "Let me know all about your trip when you return.": "",
    "spanish": "",
    "Regards and Sage Travel.": "",
    "Most top rated Tours in": "",
    "Airport Transfer": "",
    "View destinations": "",
    "Check our destinations": "",
    "Weather": "",
    "My Fun List": "",
    "The best places at Totoura": "",
    "Call / Chat": "",
    "Be in touch": "",
    "Calls & Chat features function from within the app and are Free": "",
    "Find the best of": "",
    "Chat - Mobile Concierge": "",
    "Call USA or CANADA": "",
    "minutes available": "",
    "Additional contacts": "",
    "Concierge from USA / CANADA": "",
    "In destination Local Calls to Concierge": "",
    "charges may apply for local calls": "",
    "Filter": "Filtrer",
    "No filter": "",
    "Transportation included from hotel": "",
    "Enter children ages": "",
    "NEXT": "",
    "Have a coupon?": "",
    "Travelers": "",
    "Ages": "",
    "Arrival flight": "",
    "Departure flight": "",
    "Browse only": "",
    "Call concierge": "",
    "to Pick YOUR Fun TAP HEARTS": "",
    "We'll Look for Specials": "",
    "START NOW": "",
    "EXIT FUN LIST": "",
    "Make your Fun List": "",
    "RETURN TO LIST": "",
    "FUN? Tap ?? to Save": "",
    "Ready, Set... Fun!": "",
    "We know what's in your heart! Now we'll look for specials.": "",
    "Add more, manage list, any time": "",
    "Ask us, Call or CHAT Online, look for this icon": "",
    "Not sure?": "",
    "We can help find the best fun for you!n": "",
    "To update your travel dates please contact your travel agent. No agent? contact the concierge to get you one. In the meantime, you can browse our destinations.": "",
    "Call - Mobile Concierge": "",
    "See More Experiences": "",
    "Instant Savings on Exclusive Experience SPECIALS": "",
    "Just select your savings and bonuses as 'Experience Extras' at checkout.": "",
    "View all experiences": "Voir tous les excursions",
    "See 3<sup class='t_low'>rd</sup> Condition Experience": "",
    "3<sup>rd</sup> Experience Free": "",
    "Baja California Sur": "",
    "Estado de Mexico": "",
    "Veracruz": "",
    "Quintana Roo": "",
    "Chihuahua": "",
    "Queretaro": "",
    "Puebla": "",
    "San Luis Potosi": "",
    "Jalisco": "",
    "Hidalgo": "",
    "Ciudad de Mexico": "",
    "Chiapas": "",
    "Yucatan": "",
    "Michoacan": "",
    "Nuevo Leon": "",
    "Campeche": "",
    "Colima": "",
    "Guanajuato": "",
    "Guerrero": "",
    "Morelos": "",
    "Tlaxcala": "",
    "Oaxaca": "",
    "Cozumel": "",
    "Cancun/Riviera Maya": "",
    "Puerto Vallarta/Riviera Nayarit": "",
    "Experiences found": "Excursions trouvés",
    "Activity type": "Type d'activité",
    "FILTER BY": "FILTRER PAR:",
    "ORDER BY": "TRIER PAR: ",
    "All": "Tous",
    "What you are looking?": "Que cherchez-vous?",
    "EXPERIENCES": "",
    "TOURS": "EXCURSIONS",
    "Experiencias": "",
    "Tours found": "Résultats",
    "tropical storm": "",
    "severe thunderstorms": "",
    "hurricane": "",
    "drizzle": "",
    "hail": "",
    "foggy": "",
    "thunderstorms": "",
    "windy": "",
    "mostly cloudy (night)": "",
    "mostly cloudy (day)": "",
    "partly cloudy (night)": "",
    "partly cloudy (day)": "",
    "clear (night)": "",
    "cloudy": "",
    "rain": "",
    "hot": "",
    "heavy rainn": "",
    "mixed rain and hail": "",
    "sunny": "",
    "The best <br />Water Tours": "Les meilleurs <br />excursions aquatiques",
    "Most <br />Popular <br /> Tours": "Excursions <br />les plus <br /> populaires",
    "Only on {{brandname}}!": "Uniquement sur {{brandname}}!",
    "SEE NOW!": "À DÉCOUVRIR SANS TARDER!",
    "Most <br />Popular <br /> Experiences": "Excursions <br />les plus <br /> populaires",
    "The best <br />Water Experiences": "The best <br />Water Experiences",
    "Tap Hearts <i class='icon-heart' /> on Tours & Special Offers throughout the app to build your wish list --we'll look for deals for you! We'll look for specials & enter you to win make it in the app.": "Appuyez sur les coeurs <i class=\"icon-heart\" /> sur Excursions et Offres spéciales dans pour créer votre liste de souhaits -- nous chercherons des aubaines pour vous! ",
    "Tap Hearts <i class='icon-heart' /> on Experiences & Special Offers throughout the app to build your wish list --we'll look for deals for you! We'll look for specials & enter you to win make it in the app.": "Appuyez sur les coeurs <i class=\"icon-heart\" /> sur Excursions et Offres spéciales dans pour créer votre liste de souhaits -- nous chercherons des aubaines pour vous! ",
    "Tap Hearts <i class='icon-heart' /> on Tours & Special Offers throughout the app to build your wish list --we'll look for deals for you! We?ll look for specials & enter you to win make it in the app.": "",
    "Greece": "",
    "France description": "",
    "Spain": "",
    "EXPERIENCIAS": "",
    "FILTERS": "",
    "Experience Date": "",
    "{{qty}} people booked in the last 24 hours": "",
    "We have only {{qty}} tickets": "",
    "High demand we have few places": "",
    "Only {{qty}} places left": "",
    "Last minute offer": "Offre de dernière minute",
    "OFFER": "",
    "Only {{qty}} tickets of this type are available in {{brandname}}": "Seuls {{qty}} billets de ce type sont disponibles chez {{brandname}}",
    "Booked {{qty}} times in the last 24 hours": "",
    "Booked {{qty}} times in the last 6 hours": "",
    "There are 24 hours left with this rate": "",
    "Limited availability": "Disponibilité limitée",
    "Special sale": "",
    "There are {{qty}} more users watching this activity": "",
    "Weekend preference, high demand": "",
    "This activity is ready to close due to space limit": "",
    "Lowest Price": "Prix le plus bas",
    "Longer Duration": "Durée plus longue",
    "Shorter Duration": "Durée plus courte",
    "Highest Price": "Prix le plus élevé",
    "Discount": "",
    "BOOKINGS REPORT": "",
    "Without reviews": "Sans avis",
    "No options": "",
    "No Destination found": "",
    "Type your City": "Tapez votre ville",
    "Popular Destinations": "Destinations Populaires",
    "Destinations": "Destinations",
    "Payment": "",
    "EXPERIENCES AND EXCURSIONS PACKAGES.": "",
    "Experience cancellation requests received more than twenty-four (24) hours prior to scheduled departure time are subject to a five (5) percent penalty based upon the total amount of the reservation.": "",
    "Experience availability is not guaranteed until full payment is received and a confirmation message is sent by TOTOURA.": "",
    "Experience cancellation requests received less than twenty-four (24) hours prior to scheduled departure time will be considered a \"no show\" and NO REFUNDS WILL BE MADE.": "",
    "Most experiences operate rain or shine; boat excursions will only be cancelled if the port authority deems sea conditions unsafe.": "",
    "The Customer acknowledges that TOTOURA makes arrangements with third-party service providers and other independent parties to provide Customers with the experiences and excursions packages they purchase. Although TOTOURA takes great care in selecting these service providers, it does not have any control over their business activities and cannot be held responsible for their acts, omissions, fault or negligence, or that of their employees, nor for any loss or damages suffered as a result thereof.": "",
    "TOTOURA shall not be liable for the quality or safety of activities and/or services (such as excursions, optional experiences, activities, etc.) offered by its service providers, nor for any representations made by TOTOURA's agent on any such activities and/or services.": "",
    "CHANGES TO EXPERIENCES AND EXCURSIONS PACKAGES.": "",
    "Sometimes, reserved experiences or excursion packages become unavailable for reasons beyond TOTOURA's control. In such case, TOTOURA reserves the right, exercisable by TOTOURA in its sole and absolute discretion, to substitute a comparable alternate experience or excursion for the unavailable tour or excursion or, alternatively, to cancel the Customer's reservation for such tour or excursion.": "",
    "If TOTOURA elects to substitute a comparable experience or excursion and the substituted package is of a lesser value the Customer may refuse such substitution and TOTOURA shall reimburse the Customer the price paid by the Customer for the unavailable tour and/or excursion.  If the Customer accepts such substitution, then TOTOURA's sole obligation to Customer shall be to refund the difference in cost to Customer.  The Customer shall notify TOTOURA within 48 hours following notification of the proposed substitution if Customer refuses such substitution, otherwise it will be conclusively presumed that Customer has accepted the substitution.": "",
    "If TOTOURA elects to substitute a comparable experience or excursion and the price of the substituted package is greater than that of the originally scheduled package, the Customer may refuse such substitution and TOTOURA shall reimburse the Customer the price paid by the Customer for the unavailable tour and/or excursion.  If the Customer accepts such substitution, the Customer will be required to disburse an amount equal to the difference between the price of the initial package purchased and the price of the substitute package. The Customer shall notify TOTOURA within 48 hours following notification of the proposed substitution if Customer refuses such substitution, otherwise it will be conclusively presumed that Customer has accepted the substitution.": "",
    "In TOTOURA elects to cancel the Customer's reservation for such unavailable experience and/or excursion,  the liability of TOTOURA shall be limited to the reimbursement of the price of the initial package paid by the Customer.": "",
    "Select Agency": "",
    "Select a Agency": "",
    "Agent Name": "",
    "Agency Name": "",
    "Select Agent / Agency": "",
    "Pendiente de aprobación": "",
    "Rechazado": "",
    "Aprobado": "",
    "Ingresa otro método de pago": "",
    "Transaccion declinada": "",
    "Declinada FS": "",
    "Reference": "",
    "Amount": "Montant",
    "Currency": "",
    "La tarjeta no pudo ser autenticada en 3DS.": "",
    "MODIFICATION/CANCELLATION TO A EXPERIENCE RESERVATION.": "",
    "All Destinations": "Toute les Destinations",
    "YOUR TRAVEL AGENT &<br /> {{brandname}} WELCOME YOU": "VOTRE AGENT DE VOYAGE ET<br /> {{brandname}} VOUS SOUHAITENT LA BIENVENUEn",
    "Los Cabos": "",
    "Los Cabos & La Paz": "",
    "No available": "",
    "Sure you want leave the site?": "",
    "Paris": "",
    "o si necesitas ayuda comunicate al {{phone}}": "",
    "Aprovado": "",
    "Estado de México, Mexico": "",
    "¿Qué significa 3DSecure?": "Que signifie 3DSECURE?",
    "3DSecure": "",
    "¿Que significa 3DSECURE?": "Que signifie 3DSECURE?",
    "- Al realizar la reserva del billete en nuestro sitio web, deben indicarse los datos de la tarjeta.": "",
    "- Por último, después de confirmar la etapa anterior*, y de autorizar el pago, se confirma la compra.": "",
    "* Tres intentos fallidos durante la etapa de autenticación provocan la cancelación de la transacción y el bloqueo de la tarjeta bancaria. En tal caso, le recomendamos contactar con su banco.": "",
    "Estado de México, México": "",
    "Ciudad de México, Mexico": "",
    "3DSecure, ": "",
    "nuevo sistema de pago desarrollado por Visa y Mastercard para la realización de compras seguras en Internet que autentifica al comprador como legítimo titular de la tarjeta que está utilizando.": "",
    "¡No dejes pasar la oportunidad de vivir una gran experiencia, estas a solo unos pasos!": "",
    "¿Desea continuar con la compra?": "",
    "We do not ask you for, access or track any location based information from your mobile device at any time while downloading or using our mobile app or services. Nonetheless, we will request the name of hotel where you will be staying at the tourist destination of your choice and Totoura will use this information to process your online purchases for routing and pickup times for transfers or experiences as well as send you the most updated offers and promotions of excursions or other goods and services most conveniently available from your hotel.": "",
    "¿Desea utilizar su ubicacion actual {this.state.currentGeolocation} como destino?": "",
    "¿Desea utilizar su ubicacion actual como destino?": "",
    "Todos Los Destinos": "",
    "Zacatecas, México": "",
    "Ciudad de México, Ciudad de México": "",
    "Ciudad de México": "",
    "Mexico CityCDMX": "",
    "Mexico MX": "",
    "Estado de México": "",
    "Instrucciones": "Instructions",
    "Geolocalización desactivada": "",
    "El sistema de geolocalización esta desactivado, para activarlo sigue las instrucciones.": "",
    "Ver instrucciones": "",
    "I agree to the <a href=\"{{termsurl}}\" target=\"_blank\">terms & conditions</a>": "J'accepte les <a href=\"{{termsurl}}\" target=\"_blank\">conditions générales</a>",
    "{{brandname}} facilitates your booking, but a third party experience operator provides the experience/activity directly to you. {{brandname}} does not provide experiences or activities itself. By clicking 'Book Now', you are acknowledging that you have read and are bound by Totoura <a href='{{termsurl}}' target='_blank'>Customer Terms of Use</a>, <a href='{{privacyurl}}' target='_blank'>Privacy Policy</a>, plus the tour operator's rules & regulations.": "Bien que {{brandname}} facilite votre réservation, le excursion ou l'activité sont proposés directement par un voyagiste tiers. {{brandname}} ne propose pas d'aventure ni d'activité en tant que telles. En cliquant sur  'Réservez maintenant', vous reconnaissez avoir lu les <a href='{{termsurl}}' target='_blank'>conditions d'utilisation</a> et la <a href='{{privacyurl}}' target='_blank'>politique de confidentialité du client</a> de Totoura, ainsi que les règles et règlements du voyagiste, et les acceptez.",
    " {{brandname}} facilitates your booking, but a third party experience operator provides the tour/activity directly to you. Totoura does not provide experiences or activities itself. By clicking 'Book Now', you are acknowledging that you have read and are bound by Totoura <a href='{{termsurl}}'' target='_blank'>Customer Terms of Use</a>, <a href='{{privacyurl}}' target='_blank'>Privacy Policy</a>, plus the tour operator's rules & regulations.": "Bien que {{brandname}} facilite votre réservation, le excursion ou l'activité sont proposés directement par un voyagiste tiers. Totoura ne propose pas d'aventure ni d'activité en tant que telles. En cliquant sur  'Réservez maintenant', vous reconnaissez avoir lu les <a href='{{termsurl}}' target='_blank'>conditions d'utilisation</a> et la <a href='{{privacyurl}}' target='_blank'>politique de confidentialité du client</a> de Totoura, ainsi que les règles et règlements du voyagiste, et les acceptez.",
    "Transacción declinada": "",
    "No se pudo realizar el pago": "",
    "{{brandname}} facilitates your booking, but a third party experience operator provides the tour/activity directly to you. {{brandname}} does not provide experiences or activities itself. By clicking 'Book Now', you are acknowledging that you have read and are bound by Totoura <a href='{{termsurl}}' target='_blank'>Customer Terms of Use</a>, <a href='{{privacyurl}}' target='_blank'>Privacy Policy</a>, plus the tour operator's rules & regulations.": "Bien que {{brandname}} facilite votre réservation, le excursion ou l'activité sont proposés directement par un voyagiste tiers. {{brandname}} ne propose pas d'aventure ni d'activité en tant que telles. En cliquant sur  'Réservez maintenant', vous reconnaissez avoir lu les <a href='{{termsurl}}' target='_blank'>conditions d'utilisation</a> et la <a href='{{privacyurl}}' target='_blank'>politique de confidentialité du client</a> de Totoura, ainsi que les règles et règlements du voyagiste, et les acceptez.",
    "Zacatecas": "",
    "Android": "",
    "Location": "",
    "Let a site know your location": "",
    "By default, Chrome asks you when a site wants to see your location. To let the site know where you are, choose Allow. Before sharing your location, review the site's privacy policy.": "",
    "Computer": "",
    "If you use Google as your default search engine on your phone, your location is used by default for your searches on Google.": "",
    "iPhone &amp; iPad": "",
    "Change your default location settings": "",
    "On your computer, open Chrome.": "",
    "At the top right, click More ": "",
    "Settings.": "",
    "At the bottom, click": "",
    "Under \"Privacy and security,\" click ": "",
    "Advanced.": "",
    "Click ": "",
    "Turn ": "",
    "Site settings.": "",
    "Location.": "",
    "Ask before accessing": "",
    "on or off.": "",
    "On your Android phone or tablet, open the Chrome app ": "",
    "To the right of the address bar, tap More ": "",
    "Site settings": "",
    "Tap ": "",
    " on or off.": "",
    "On your iPhone or iPad, open the settings app.": "",
    "Find and tap ": "",
    "Chrome.": "",
    "Choose the option you want as your default setting.": "",
    "iPhone & iPad": "",
    " Settings.": "",
    " Advanced.": "",
    "Ask before accessing ": "",
    " Settings": "",
    "Site settings ": "",
    " Location.": "",
    "Location ": "",
    "Felicidades estas a punto de vivir una gran experiencia": "",
    "FS-147482": "",
    "To Be Announced": "",
    "Se anunciará": "",
    "Mexico": "",
    "Nayarit": "",
    "Recordar Selección": "",
    "Vaudreuil-Soulanges": "",
    "México": "",
    "Minnesota": "",
    "Utah": "",
    "República Dominicana": "",
    "Ontario": "",
    "Québec": "",
    "Le Fjord-du-Saguenay": "",
    "Division No. 11": "",
    "Lévis": "",
    "Communauté-Urbaine-de-Montréal": "",
    "CANCELLED": "",
    "Toronto Division": "",
    "Cancún": "",
    "Le Val-Saint-François": "",
    "Hamilton Division": "",
    "Georgia": "",
    "Les Collines-de-l'Outaouais": "",
    "Chatham-Kent Division": "",
    "Dominican Republic": "",
    "Michoacán": "",
    "No such issuer": "",
    "Error while register the Transfer Booking": "",
    "Henrico County": "",
    "Tour not exist": "",
    "Do Not Honor": "",
    "2333232": "",
    "Pick Up Card": "",
    "Select start Time": "",
    "8857": "",
    "IO16104-0J": "",
    "16IG2IL1B204628": "",
    "Los Cabos is one of the five municipalities of the state of Baja California Sur, Mexico, and is located at the southern tip of the State. The municipal capital is San José del Cabo and the most important city is Cabo San Lucas, 32 km from the capital.": "",
    "Children ({{minage}}+) Adult Price": "",
    "Children under ({{minage}}) are not allowed": "",
    "Children Free ({{minage}} - {{maxfreeage}})": "",
    "Children are not allowed on this experience": "",
    "Cabo San Lucas": "",
    "Riviera Nayarit": "",
    "Cancun": "",
    "Puerto Vallarta": "",
    "La Paz": "",
    "Riviera Maya": "",
    "Cargar más": "",
    "Ver más": "Voir plus",
    "México & the Caribbean": "Mexique et <span class='blue_text'>les Caraïbes</span>",
    "Europe": "",
    "Account Number Does Not Match Payment Type": "",
    "Tour Activity Date Unavailable": "",
    "18AOY0SP": "",
    "Information": "Informationsn",
    "This special site is just for YOU -- complete with our own destination concierge who can assist with answering your questions, making plans and booking activities --<b class=\"blue_color_g\"> EXPERIENCED LOCAL PROFESSIONALS YOU CAN COUNT ON</b><br /> <br />Top Quality, Service and Great Prices so <b class=\"phone_traficag\">YOU GET THE BEST EXPERIENCES POSSIBLE.</b><br /> <br /><span>FOR ASSISTANCE</span> <br /> with our Concierge 9:00am to 6:00pm Local Time Daily. <br /><br /><b> CALL TOLL FREE BEFORE YOUR ARRIVE</b>, {{phone}} <br />": "",
    "OR OUR LOCAL OFFICE DURING YOUR STAY:": "",
    "Online Bookings for": "",
    "are not longer available.": "",
    "Please contact your local host/representative at the hotel to book <br /> additional excursions.": "",
    "Join the Featured Group Activity": "",
    "for": "",
    "Group Tour Event:": "",
    "Tour Duration:": "",
    "Sold Out": "",
    "Experience Activity Date Unavailable": "",
    "343767": "",
    "After payment has been processed, all cancellations are subject to penalties.": "",
    "Betty": "",
    "User not exist": "",
    "Lost/Stolen Card": "",
    "That email is not in our system. Please try again or contact  for help. In the meantime, you can browse our destinations.": "",
    "Children under {{MinAge}} are not allowed on this tour": "",
    "See our best tour places at {{brandname}}": "",
    "The Lead Name on Reservation must present a valid Photo ID when redeeming their {{brandname}} Voucher(s). Accompanying children do not require Photo ID.": "Le nom principal figurant à la réservation doit présenter une pièce d'identité valide avec photo lors de la validation de leur {{brandname}} bon d'achat. Les enfants accompagnants n'ont pas besoin de pièce d'identité avec photo.",
    "At {{brandname}}, <strong>'we make good vacations great'</strong> for some of the world's best known vacation companies including: Transat, Look Voyages, Vacances Transat France, Delta Vacations, Price Travel and Palace Resorts.": "À {{brandname}} <strong>'nous rendons les vacances merveilleuses'</strong> pour certaines des agences de voyages les plus connues au monde, y compris : Transat, Look Voyages, Vacances Transat France, Delta Vacations, Price Travel et Palace Resorts.",
    "{{brandname}}, has grown to deliver quality ground services to over 1 million international vacationers every year. Providing Airport/Hotel Transportation, Tours and Activities for vacationers, by using only the very best reliable companies at each destination is our standard operating policy.": "{{brandname}} fondée en 1999, s'est développée pour offrir des services au sol de qualité à plus de 1 million de vacanciers internationaux chaque année. Notre politique de fonctionnement standard consiste à offrir le transport aéroport/hôtel, des excursions et des activités aux vacanciers, en utilisant uniquement les entreprises les meilleures et les plus fiables, à chaque destination. Chez {{brandname}}, « nous rendons les vacances merveilleuses » pour certaines des agences de voyages les plus connues au monde, y compris : Transat, Look Voyages, Vacances Transat France, Delta Vacations, Price Travel et Palace Resorts.",
    "OUR VACATION CONCIERGE IS AVAILABLE EVERYDAY FROM 9:00AM TO 6:00PM CENTRAL TIME, TO ANSWER YOUR QUESTIONS.": "",
    "HTI 28/1-5": "",
    "See our best experience places at {{brandname}}": "",
    "12213232": "",
    "SDI 25/1-1 ": "",
    "See what's hot, top experiences at your destination other vacationers are taking and what they are saying about their experiences.": "",
    "<span class='blue_text'>Experience</span> Secure": "",
    "{{brandname}}, has grown to deliver quality ground services to over 1 million international vacationers every year. Providing Airport/Hotel Transportation, Experiences and Activities for vacationers, by using only the very best reliable companies at each destination is our standard operating policy.": "{{brandname}} fondée en 1999, s'est développée pour offrir des services au sol de qualité à plus de 1 million de vacanciers internationaux chaque année. Notre politique de fonctionnement standard consiste à offrir le transport aéroport/hôtel, des excursions et des activités aux vacanciers, en utilisant uniquement les entreprises les meilleures et les plus fiables, à chaque destination. Chez {{brandname}}, « nous rendons les vacances merveilleuses » pour certaines des agences de voyages les plus connues au monde, y compris : Transat, Look Voyages, Vacances Transat France, Delta Vacations, Price Travel et Palace Resorts.",
    "Your travel agent knows that we only use the best, most reliable insured companies to provide transportation and tours - you get the best service, value and price for what we offer, Guaranteed... You won't find a better price for our offers, but if you do, we will match it.": "Votre agent de voyage sait que nous n'utilisons que les meilleures compagnies et les plus fiables pour assurer le transport et les visites - vous recevrez le meilleur service, la valeur et le prix pour ce que nous offrons, garanti? Vous ne trouverez pas un meilleur prix pour nos offres, mais si vous le trouvez, nous l'égalerons.",
    "Select your activities early to make sure you get the best day, AND time of day, reserved for you in advance... change or even cancel 48 hrs before your scheduled reservation, no penalty, no problem.": "Sélectionnez vos activités tôt pour vous assurer d'obtenir le meilleur jour, ET la meilleure heure... modifiez ou même annulez 48 heures avant votre activité, sans pénalité ni problème.",
    "Our Vacation Concierge is available Everyday from 9:00am to 6:00pm Central Time, to answer your questions.": "",
    "3D Secure (o 3DS) es un sistema de seguridad para los pagos online con tarjeta bancaria. Este sistema funciona para las tarjetas Visa y MasterCard. El procedimiento de autenticación del titular de la tarjeta es muy sencillo y consta de 3 etapas.": "",
    "- Tras la verificación, se abre una ventana 3D Secure: el banco del titular de la tarjeta pide que se introduzcan ciertos datos para verificar si es el propietario de la misma. En la mayoría de los casos, se trata de un código de un solo uso que el banco envía por SMS al teléfono móvil.": "",
    "El pago 3D Secure está disponible en los bancos con el nombre 'Verified by Visa' para las tarjetas Visa y 'MasterCard SecureCode' para las tarjetas MasterCard. Para más información sobre este servicio y los trámites para suscribirse, contacte con su banco.": "",
    "CCB5484": "",
    "Esta transaccion esta protegida con": "",
    "Save time & money": "",
    "You won't find it cheaper online or anywhere else!": "",
    "When you book ahead, you are guaranteed not to miss out on popula experiences & activities.": "",
    "Don't be disappointed": "",
    "Travel with confidence": "",
    "Our providers are hand-picked, safe and reliable, so you always get what you pay for.": "",
    "See All Experiences": "",
    "Store": "",
    "Why <span class='blue_text'>book ahead</span>?": "",
    "notices whose purpose may include, but not be limited to, assistance and updates on vacation information as well as marketing and promotional offers Customer may find valuable. Customer may opt out of receiving such alerts and notices by notifying TOTOURA via the interactive interface of the App or emailing TOTOURA at concierge@totoura.com": "",
    "When you book ahead, you are guaranteed not to miss out on popula tours & activities.": "",
    "No experiences found": "",
    "5495078": "",
    "No tours found": "",
    "Insufficient Funds": "",
    "Transportation Included from Hotel": "",
    "MEXICO": "",
    "18IG5TTE406890": "",
    "Generic Decline": "",
    "Credit Card": "",
    "Reserve 24hrs": "",
    "Select payment type": "",
    "Payment type": "",
    "CM04043-0C": "",
    "CM04043-0C ": "",
    "Decline CVV2/CID Fail": "",
    "Meeting Point": "",
    "Not Available": "",
    "Activity Time Unavailable": "",
    "N3384941": "",
    "5760989": "",
    "CHANEL": "",
    "griselda": "",
    "Christine": "",
    "State": "",
    "City": "",
    "Save": "",
    "Error while mail settings": "",
    "View All Destinations": "Afficher toutes les destinations",
    "What you are looking for?": "Qu'est-ce que tu cherches?",
    "Payment failed": "",
    "No item selected": "",
    "Zip Code": "",
    "Enter Zip Code": "",
    "Use bancomer points": "",
    "Bancomer points": "",
    "Error while register the CCBookingInfo": "",
    "Enter a valid 10 digit phone number": "Entrez un numéro de téléphone à 10 chiffres valide",
    "meses sin intereses": "",
    "Ninguno": "",
    "Beneficios BBVA": "",
    "Enter your City": "Entrez votre ville",
    "Enter at least 10 characters in review": "Entrez au moins 10 caractères en revue",
    "Enter your State/Province": "Entrez votre état / province",
    "HOW DID YOU LIKE THIS ACTIVITY?": "COMMENT AVEZ-VOUS AIMÉ CETTE ACTIVITÉ?",
    "Send us a review of your experience using the following form.": "Envoyez-nous une revue de votre expérience en utilisant le formulaire suivant.",
    "Your Country": "Votre Pays",
    "Your State/Province": "Votre état / province",
    "Your City": "Ta ville",
    "Your Review": "Votre Avis",
    "Send Review": "Envoyer un Avis",
    "Thank you for sending us your review": "Merci de nous avoir envoyé votre avis",
    "There was an error, please try again": "Une erreur s'est produite, veuillez réessayer",
    "Valid Thru": "",
    "YOUR NAME HERE": "",
    "Invalid credit card": "",
    "Invalid credit card 2": "",
    "Enter credit card 2 number  - 16 Digits": "",
    "Select Expiration Month - card 2": "",
    "Select Expiration Year - card 2": "",
    "Enter Security Code (CCV) - card 2 - 3 Digits": "",
    "Enter Zip Code - card 2": "",
    "Enter Security Code (CCV) - card 2 - 4 Digits": "",
    "Enter credit card 2 number  - 15 Digits": "",
    "Usar BBVA Wallet": "",
    "Credito": "",
    "I confirm the missing payment if I do not have enough points": "",
    "Tarjeta de debito": "",
    "Usar 2 tarjetas": "",
    "Precio en un Solo Pago": "",
    "notices whose purpose may include, but not be limited to, assistance and updates on vacation information as well as marketing and promotional offers Customer may find valuable. Customer may opt out of receiving such alerts and notices by notifying TOTOURA via the interactive interface of the App or emailing TOTOURA at": "",
    "hola": "",
    "hola prueba": "",
    "hola prueba test": "",
    "Con esta forma de pago se comunicaran con usted para validar su codigo de seguridad Digital (CVV)": "",
    "Folio": "",
    "Ingresa el folio": "",
    "Invalid expiration date": "",
    "Invalid expiration date - card 2": "",
    "Es importante proporcionar un número de teléfono correcto a 10 dígitos, de esta manera podremos comunicarnos con usted en caso de requerir validar cierta información o en caso de algún problema con su método de pago.": "",
    "Because the current global health situation our government implemented a security and sanitize mesures. The STC demands that vehicles must operate at 50% of the maximum capacity. Please take this into consideration when making your reservation.": "",
    "Dear visitor, due the contingency of COVID-19, a travel authorization is now required to travel. Get everything you need to know about travelling to Jamaica at this time:": "",
    "Find out more and get yours here": "",
    "RESERVE EXPERIENCE": "",
    "Whoever visits a destination wants to experience it. Learn about the options to enjoy the local flavor and live experiences wherever you arrive.": "",
    "DESTINATIONS": "",
    "RESERVE HOTEL": "",
    "In each destination there is much to see, enjoy, savor or travel. And by location, everything good is very close.": "",
    "Children ({{MaxFreeAge}}+) Adult Price": "",
    "Un destino famoso por sus aguas cristalinas, deportes acuáticos, vida nocturna, y la vasta cultura Maya. En los hoteles City Express Plus o City Express Suites podrás conocer no solo la ciudad, sino toda la Riviera Maya e increíbles parques acuáticos, playas escondidas, islas, cenotes y hermosos destinos como Tulum o Isla Mujeres.": "",
    "Uno de los paraísos del Pacífico por su aguas tibias y gran variedad de experiencias acuáticas. Ideal para descansar, nadar o hacer experiences en bosque y mar. Disfruta la piscina y restaurante del hotel City Express Plus y cierra el día con una gran vista al atardecer.": "",
    "Este destino es un sueño por su mezcla de mar y desierto. En el mar, disfruta arenas rosas, el mejor snorkeling, buceo o experiences en lancha por mencionar algunas actividades y en el desierto, haz senderismo o pasea en camello. Los hoteles City Express Suites y City Express Plus están uno junto al otro, así que puedes escoger una cómoda habitación o toda una suite.": "",
    "Escápate en bici al centro histórico, visita Xilitla, haz ecoturismo en la Huasteca o disfruta la gran gastronomía potosina. Todo está cerca. Después de vivir increíbles experiencias, disfruta la gran vista del centro histórico en la terraza con piscina del hotel City Centro.": "",
    "La Mejor Ciudad del Mundo para visitar por su gastronomía, arquitectura y un centro histórico patrimonio de la humanidad, cada rincón es único e inigualable. Podrás disfrutar de una excelente vista desde la terraza del hotel City Centro. Si quieres un experience: conoce Mazunte, Hierve el Agua o Monte Albán; te van a encantar.": "",
    "Transportation optional with additional cost is available": "",
    "What you can expect during your visit": "Détail du programme de votre visite",
    "Keeping you safe during COVID-19": "Vous garder en sécurité pendant le COVID-19",
    "Hand sanitizer available to travelers and staff": "Gel hydro alcoolique disponible pour les voyageurs et le personnel",
    "Face masks required for travelers in public areas": "Port du masque obligatoire pour les voyageurs dans les lieux publics ",
    "Face masks required for guides in public areas": "Port du masque obligatoire pour les guides dans les lieux publics ",
    "Ver detalles": "Voir les détails",
    "Social distancing enforced throughout experience": "Distances de sécurité appliquées pendant l?expérience ",
    "Regularly sanitized high-traffic areas": "Zones à fort passage régulièrement désinfectées ",
    "Gear/equipment sanitized between use": "Matériel et équipements désinfectés entre chaque utilisation",
    "Regular temperature checks for staff": "Contrôle de température régulier du personnel ",
    "Transportation vehicles regularly sanitized": "Véhicules de transport régulièrement désinfectés ",
    "Guides required to regularly wash hands": "Lavage des mains régulier obligatoire pour les guides ",
    "Temperature checks for travelers upon arrival": "Contrôle de température pour les voyageurs dès l?arrivée",
    "12345": "",
    "COVID-19 protocols, what you can expect:": "",
    "COVID-19 protocols, What to expect during your visit:": "",
    "Face mask is mandatory for travelers in all public spaces": "",
    "Face mask is mandatory for guides in all public spaces": "",
    "Equipment is sanitized between uses": "",
    "High traffic zones are sanitized regularly": "",
    "Social distancing mandatory during all experience": "",
    "Transportation vehicles are sanitized regularly": "",
    "Temperature checks for staff": "",
    "Guides are required to wash hands regularly": "",
    "Temperature checks for travellers before the activity": "",
    "What to expect during your visit:": "",
    "At Totoura, <strong>'we make good vacations great'</strong> for some of the world's best known vacation companies including: Transat, Look Voyages, Vacances Transat France, Delta Vacations, Price Travel and Palace Resorts.": "",
    "Select your activities early to make sure you get the best day, AND time of day, reserved for you in advance... change or even cancel 24 hrs before your scheduled reservation, no penalty, no problem.": "",
    "10% discount on experiences valid through Tuesday September 22th, using the coupon code: VGB10SE": "",
    "All listed prices include taxes": "Les prix publiés incluent les taxes",
    "USA": "",
    "10% discount on experiences valid through Wednesday September 30th, using the coupon code: VGB10SE": "",
    "Children under {{MinAge}} are not allowed on this experience": "",
    "days": "",
    "Children are not allowed on this tour": "",
    "3<sup>rd</sup> Tour FREE!": "3<sup>rd</sup> Excursion GRATUIT!",
    "3<sup>rd</sup> Experience FREE!": "3<sup>rd</sup> Excursion GRATUIT!",
    "<strong>Book 3 Different Tours - ALL MUST BE FROM THOSE LISTED AND SHOWN AS \"Valid 3<sup class=\"t_low\">rd</sup> Tour Free\" on the promotion page.</strong>": "<strong>RÉSERVEZ 3 excursions différents -- TOUTES LES EXCURSIONS DOIVENT APPARAÎTRE DANS LA LISTE ET ÊTRE \"Valid 3<sup class=\"t_low\">rd</sup> Tour Free\"  sur la page de promotion.</strong>",
    "<strong>Book 3 Different Experiences - ALL MUST BE FROM THOSE LISTED AND SHOWN AS \"Valid 3<sup class=\"t_low\">rd</sup> Experience Free\" on the promotion page.</strong>": "<strong>RÉSERVEZ 3 excursions différents -- TOUTES LES EXCURSIONS DOIVENT APPARAÎTRE DANS LA LISTE ET ÊTRE \"Valid 3<sup class=\"t_low\">rd</sup> Tour Free\"  sur la page de promotion.</strong>",
    "Book ALL 3 Tours at the SAME time using the shopping cart": "Réservez les 3 excursions en même temps",
    "Book ALL 3 Experiences at the SAME time using the shopping cart": "Réservez les 3 excursions en même temps",
    "BOOK ALL 3 TOURS in ONE Reservation through the shopping cart.": "RÉSERVEZ LES 3 EXCURSIONS dans UNE seule transaction à travers du panier d'achats.",
    "BOOK ALL 3 EXPERIENCES in ONE Reservation through the shopping cart.": "RÉSERVEZ LES 3 EXCURSIONS dans UNE seule transaction à travers du panier d'achats.",
    "The Free Experience is the one of equal or lesser value to the others and your savings is automatically calculated and shown at checkout just before you finalize your purchase online. Or, you may contact us to make your reservation on phone, we will review the pricing and details with you when we make your booking.": "L'excursion gratuite est celle du même prix ou inférieur aux autres excursions et votre économie sera calculé et apparaîtra au momment de faire le paiement en ligne. Ou, vous pouvez faire vos réservations avec nous et nous reviserons les prix et conditions avec vous.",
    "The Free Tour is the one of equal or lesser value to the others and your savings is automatically calculated and shown at checkout just before you finalize your purchase online. Or, you may contact us to make your reservation on phone, we will review the pricing and details with you when we make your booking.": "L'excursion gratuite est celle du même prix ou inférieur aux autres excursions et votre économie sera calculé et apparaîtra au momment de faire le paiement en ligne. Ou, vous pouvez faire vos réservations avec nous et nous reviserons les prix et conditions avec vous.",
    "NO RETURNS, CHANGES OR CANCELLATIONS ARE ALLOWED": "AUCUN CHANGEMENT OU ANNULATIONS SONT PERMIS.",
    "A0E3F28": "",
    "<strong>  Special Offer: <i class=\"icon-tag\"></i>25% discount on experiences until October 31, 2020. *Restrictions apply</strong>": "<strong>  Special Offer: <i class=\"icon-tag\"></i>25% discount on experiences until October 31, 2020. *Restrictions apply</strong>",
    "<strong>  Special Offer: <i class=\"icon-tag\"></i>25% discount on experiences until October 31, 2020. <span>*Restrictions apply</span></strong>": "<strong>  Special Offer: <i class=\"icon-tag\"></i>25% discount on experiences until October 31, 2020. <span>*Restrictions apply</span></strong>",
    "<strong>  Special Offer: <i class=\"icon-tag\"></i>25% discount on experiences until November 2, 2020. <span>*Restrictions apply</span></strong>": "<strong>  Special Offer: <i class=\"icon-tag\"></i>25% discount on experiences until November 2, 2020. <span>*Restrictions apply</span></strong>",
    "2527668": "",
    "<strong>  Special Offer: <i class=\"icon-tag\"></i>25% discount on experiences until November 20, 2020. <span>*Restrictions apply</span></strong>": "",
    "<strong>  Special Offer: <i class=\"icon-tag\"></i>25% discount on experiences ony Black Friday and Cyber Monday, November 27 and 30, 2020. <span>*Restrictions apply</span></strong>": "<strong>  Special Offer: <i class=\"icon-tag\"></i>25% discount on experiences ony Black Friday and Cyber Monday, November 27 and 30, 2020. <span>*Restrictions apply</span></strong>",
    " <strong>  Special Offer: <i class=\"icon-tag\"></i>Get a 15% Off discount for MHD members <span>*Restrictions apply</span></strong>": " <strong>  Special Offer: <i class=\"icon-tag\"></i>Get a 15% Off discount for MHD members <span>*Restrictions apply</span></strong>",
    "EMIN3280485-160518-2.0": "",
    "Cardholder transaction not permitted": "",
    "15FA09B0": "",
    "translate test": "",
    "algo2222": "",
    "Departure FLIGHT Time": "",
    "Arrival FLIGHT Time": "",
    "Duration:": "Duration:",
    "Tour:": "",
    "Error while register the Booking": "",
    "5308949": "",
    "CHE140507M": "",
    "RMPXDC611660-130521": "",
    "Ambassador": "Ambassador",
    " The Best <span class=\"blue_text\">Experiences</span>": " The Best <span class=\"blue_text\">Experiences</span>",
    "Welcome to <span class=\"blue_text\"><b>Toorzy</b></span>, the best place where we give you the best experiences. Explore our destinations and discover the options we have for you.": "Welcome to <span class=\"blue_text\"><b>Toorzy</b></span>, the best place where we give you the best experiences. Explore our destinations and discover the options we have for you.",
    " The Best <span class=\"blue_text\"><b>activities for you</b></span>": " The best <span class=\"blue_text\"><b>activities for you</b></span>",
    "Beach <br />time!": "Beach <br />time!",
    "Its swimsuit time and enjoy the sun and the sea breeze": "It's swimsuit time and enjoy the sun and the sea breeze",
    "Explore": "Explore",
    "Excitement <br /> to the fullest": "Excitement <br /> to the fullest",
    "Drive and dare to travel the best roads": "Drive and dare to travel the best roads",
    "Tranquility <br /> in the ocean": "Tranquility <br /> in the ocean",
    "Sail and relax on a voyage on the high seas": "Sail and relax on a voyage on the high seas",
    "Explore <br /> the seabed": "Explore <br /> the seabed",
    "Dive and learn about the best of the ocean": "Dive and learn about the best of the ocean",
    " Why should <span class=\"blue_text\"><b>you choose us</b></span>": " Why should <span class=\"blue_text\"><b>you choose us</b></span>",
    "Quick booking": "Quick booking",
    " The Best <br/>Experiences": " The Best <br/>Experiences",
    "Discover and plan your trip": "Discover and plan your trip",
    " Ambassador <br/>Program": " Ambassador <br/>Program",
    "Earn money recommending experiences": "Earn money recommending experiences",
    "Destiny": "Destiny",
    "What destination do you want to visit?": "What destination do you want to visit?",
    "Cancunp": "Cancun",
    "We are a company focused on providing the best customer experience through our family of ambassadors and our algorithms that allow us to sell, commission and monitor in an interactive way.": "We are a company focused on providing the best customer experience through our family of ambassadors and our algorithms that allow us to sell, commission and monitor in an interactive way.",
    "Navigation": "Navigation",
    "mexicofooter": "Mexico",
    "AJL0634792": "",
    "MVIN32592142608182.0": "",
    "HLL2402A02": "",
    "220657267381": "",
    "24IG980D9C30": "",
    "SBI does not exist to get Generic Mode": "",
    "AS659911": "",
    "Dive and learn <br /> about the best of the ocean": "Dive and learn <br /> about the best of the ocean",
    "Drive and dare <br /> to travel the best roads": "Drive and dare <br /> to travel the best roads",
    "Know the best <br /> of our culture": "Know the best <br /> of our culture",
    "Live  <br /> and cultivate": "Live  <br /> and cultivate",
    "Enjoy <br /> the best environment": "Enjoy <br /> the best environment",
    "Dinner and <br /> have a quiet evening": "Dinner and <br /> have a quiet evening",
    "Master <br /> your skills": "Master <br /> your skills",
    "Dare to have <br /> an extreme adventure": "Dare to have <br /> an extreme adventure",
    "Have a magical  <br /> and unique adventure": "Have a magical  <br /> and unique adventure",
    "Family <br /> fun": "Family <br /> fun",
    "Discover the most outstanding": "Discover the most outstanding",
    " The most <br/>Popular": " The most <br/>Popular",
    "Welcome to <span class=\"blue_text\"><b>{{brandname}}</b></span>, the best place where we give you the best experiences. Explore our destinations and discover the options we have for you.": "Welcome to <span class=\"blue_text\"><b>{{brandname}}</b></span>, the best place where we give you the best experiences. Explore our destinations and discover the options we have for you.",
    "Cookies Policy": "Politique de cookies",
    "Guests": "Guests",
    "The Bay": "The Bay",
    "Top Activities": "Top Activities",
    "Supermarkets": "Supermarkets",
    "Where to Eat": "Where to Eat",
    "Services": "Services",
    "Transportation": "Transportation",
    "Hosts": "Hosts",
    "Real State": "Real State",
    "Properties": "Properties",
    "Users": "Users",
    "Owners": "Owners",
    "Follow us": "Follow us",
    "Contact us": "Contact us",
    "Go to": "Go to",
    "Privacy Policies": "Privacy Policies",
    "Terms and conditions": "Terms and conditions",
    "IS25007-12M": "",
    "Coming Soon": "Coming Soon",
    "5331166": "",
    "Restricted Card": "",
    "Expired Card": "",
    "Need help with experiences?": "Need help with experiences?",
    "Donation": "",
    "Donate": "",
    "custom": "",
    "Continue with the donation": "",
    "Your donation has been made correctly": "",
    "The minimum amount is {{amount}} {{currencyLabel}}": "",
    "Range Prices": "Range Prices",
    "CHE081102AG": "",
    "N549629-0611": "",
    "The account was closed": "",
    "ID04005-67N": "",
    "Sign up free": "Sign up free",
    "I recommend this experience": "",
    "N550787-082": "",
    "M07122107PT1NER1": "",
    "CD06034-67N": "",
    "SDI 17/11-0": "",
    "Providers": "",
    "Clear filters": "",
    "0312AGH59642": "",
    "*mandatory": "",
    "To add to the shopping cart, complete the fields marked as * mandatory": "",
    "To be able to add to the shopping cart, complete the fields marked as *mandatory": "",
    "remaining": "",
    "To be able to add to the shopping cart, complete the following fields according to the remaining quantity marked": "",
    "To continue with the reservation, it is necessary to complete the following options:": "",
    "CHA120102AE": "",
    "Payment processor": "",
    "BBVA": "",
    "STRIPE": "",
    "WorldPay": "",
    "Stripe": "",
    "BBVA MST": "",
    "Stripe MST": "",
    "BBVA Trafic": "",
    "MST BBVA": "",
    "MST Stripe": "",
    "Generate Payment Link": "",
    "Could not generate payment link, please try again or contact for help": "",
    "This reservation has been processed": "",
    "An error has been detected, try again or contact for help (wp)": "",
    "The payment has been success": "",
    "Error has been detected, try again or contact for help (wp-e)": "",
    "Error has been detected, try again or contact for help": "",
    "Reason discount": "",
    "Discount amount": "",
    "Invalid Transaction": "",
    "01R27V01C4-9": "",
    "IM25013-0K": "",
    "An error has been detected, try again or contact for help": "",
    "SX220607001": "",
    "5584567": "",
    "295806CTCJ": "",
    "GST07C8060": "",
    "RMRDC653219-2406": "",
    "PJ25004-0L": "",
    "IJ2006-22E": "",
    "CA01108-29Y": "",
    "506324": "",
    "Issuer Generated Error": "",
    "Exceeds Approval Amount Limit": "",
    "1910-12 ": "",
    "MI710-37 ": "",
    "ID13003-40L": "",
    "GP7029R2": "",
    "Chantelle": "",
    "ID19014-0D": "",
    "ID28026-4A": "",
    "31128402": "",
    "Invalid or expired card; contact cardholder to update": "",
    "IE20011-0F": "",
    "Decline - Re-try Transaction": "",
    "IF02050-10M": "",
    "110212": "",
    "Suspected Fraud": "",
    "A7BC9138": "",
    "0705189": "",
    "10051023": "",
    "280751": "",
    "CONFIRMED": "",
    "J31082302PTINIG5": "",
    "Invalid transaction or card restriction; verify information and resubmit": "",
    "A54510E2": "",
    "37467": "",
    "PGM-A65-291223": "",
    "MF81-37 ": "",
    "Welcome to <span class=\"blue_text\"><b>{{brandname}} t</b></span>, the best place where we give you the best experiences. Explore our destinations and discover the options we have for you.": "",
    "210231": "",
    "645315": "",
    "2001224": "",
    "Card Not Active": "",
    "662049": "",
    "21IG2IMA8FE67A2": "",
    "33710": "",
    "PA11004-8K": "",
    "04042024": "",
    "IA03021-0A": "",
    "05IG8THA90F94C7": "",
    "2101010SSFJ": "",
    "09IG2A6B46C74": "",
    "23042024": "",
    "26fr3a92849e9": "",
    "IJ18011-40L": "",
    "At240730020": "",
    "01IG2RSA9833FB1": "",
    "Invalid Merchant": "",
    "IN08007-29Y": "",
    "2410253": "",
    "23IG2IMA9E85417": "",
    "CHE211105LP": "",
    "UW2I2611152": "",
    "N104726267": "",
    "SX241218001": ""
}