import React, { Component } from 'react';
import { NavLink,withRouter } from "react-router-dom";
import Global from '../globals/global';

import AdviceMessage from "../components/component.advice.message";
import TransferPricesDetails from '../components/component.transfer.prices.details';
import Section from '../components/component.section';
//if (typeof window === "undefined")  var window={}

const $ = window.$;

class TransferPrices extends Global {

    constructor(props) {
        super(props);

        this.handleErrors = this.handleErrors.bind(this);

        this.state = {
            destinationid: null,
            data: null,
            languageid: null,
            currencyid: null,
            Id_Transfer: null,
            Id_TransferPrice: null,
            Id_Airport_Arrival: null,
            Id_Airport_Departure: null,
            type: null,
            airports: null,
            errors: null,
            isLogin: null,
            itemindex: this.props.match.params.itemindex || -1
        };
    }

    componentDidMount() {
       
        console.log('this.props.match', this.props.match)

        const destinationid = this.getglobaldata('Id_Destination');
        const languageid = this.getglobaldata('Id_Language');
        const currencyid = this.getglobaldata('Id_Currency');
        const idtransfer = this.props.match.params.id_transfer;
        const idtransferprices = this.props.match.params.id_transfer_prices;
        const idairportarrival = this.props.match.params.id_airport_arrival;
        const idairportdeparture = this.props.match.params.id_airport_departure;
        const type = this.props.match.params.type;
        var isLogin = true;

        if(process.env.REACT_APP_BOOKING_WIDGET == 'bookingwidget3'){
                isLogin = this.getglobaldata('user');
        }

        const itemindex = this.props.match.params.itemindex;
       // this.state.itemindex = itemindex;
       // console.log('this.props.match', this.props.match)

        let options = {
            arraydata: 'transferDetails',
            method: 'GetTransferDetails',
            query: {
                Id_Transfer: idtransfer,
                Id_TransferPrice: idtransferprices,
                Id_Language: languageid,
                Id_Currency: currencyid
            },
            callback: function (data, result) {



                options = {
                    arraydata: 'airports',
                    method: 'GetAirports',
                    query: {
                        Id_Destination: destinationid
                    },
                    callback: function (data) {
                        this.setState({
                            destinationid: destinationid,
                            data: result.transferdata[0],
                            languageid: languageid,
                            currencyid: currencyid,
                            Id_Transfer: idtransfer,
                            Id_TransferPrice: idtransferprices,
                            Id_Airport_Arrival: idairportarrival,
                            Id_Airport_Departure: idairportdeparture,
                            type: type,
                            airports: data,
                            isLogin: isLogin,
                            itemindex: itemindex
                        });
                    }.bind(this)
                };
                this.all(options);
            }.bind(this)
        };
        this.all(options);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.match.params.id_transfer !== this.props.match.params.id_transfer ||
            prevProps.match.params.id_transfer_prices !== this.props.match.params.id_transfer_prices ||
            prevProps.match.params.id_airport_arrival !== this.props.match.params.id_airport_arrival ||
            prevProps.match.params.id_airport_departure !== this.props.match.params.id_airport_departure ||
            prevProps.match.params.type !== this.props.match.params.type) {
            const idtransfer = this.props.match.params.id_transfer;
            const idtransferprices = this.props.match.params.id_transfer_prices;
            const idairportarrival = this.props.match.params.id_airport_arrival;
            const idairportdeparture = this.props.match.params.id_airport_departure;
            const type = this.props.match.params.type;

            const destinationid = this.getglobaldata('Id_Destination');
            const languageid = this.getglobaldata('Id_Language');
            const currencyid = this.getglobaldata('Id_Currency');

            var isLogin = true;

            if(process.env.REACT_APP_BOOKING_WIDGET == 'bookingwidget3'){
                isLogin = this.getglobaldata('user');
           }


            const itemindex = this.props.match.params.itemindex;
            this.state.itemindex = itemindex;

            let options = {
                arraydata: 'transferDetails',
                method: 'GetTransferDetails',
                query: {
                    Id_Transfer: idtransfer,
                    Id_TransferPrice: idtransferprices,
                    Id_Language: languageid,
                    Id_Currency: currencyid
                },
                callback: function (data, result) {



                    options = {
                        arraydata: 'airports',
                        method: 'GetAirports',
                        query: {
                            Id_Destination: destinationid
                        },
                        callback: function (data) {
                            this.setState({
                                destinationid: destinationid,
                                data: result.transferdata[0],
                                languageid: languageid,
                                currencyid: currencyid,
                                Id_Transfer: idtransfer,
                                Id_TransferPrice: idtransferprices,
                                Id_Airport_Arrival: idairportarrival,
                                Id_Airport_Departure: idairportdeparture,
                                type: type,
                                airports: data,
                                isLogin: isLogin,
                                itemindex: itemindex
                            });
                        }.bind(this)
                    };
                    this.all(options);
                }.bind(this)
            };
            this.all(options);
        }
    }

    handleErrors(errors) {
        this.setState({
            errors: errors,
        });
    }

    render() {


        if (this.props.onlytable)
            return (

                <TransferPricesDetails
                    destinationid={this.state.destinationid}
                    data={this.state.data}
                    languageid={this.state.languageid}
                    currencyid={this.state.currencyid}
                    Id_Transfer={this.state.Id_Transfer}
                    Id_TransferPrice={this.state.Id_TransferPrice}
                    Id_Airport_Arrival={this.state.Id_Airport_Arrival}
                    Id_Airport_Departure={this.state.Id_Airport_Departure}
                    type={this.state.type}
                    airports={this.state.airports}
                    handle={this.handleErrors}
                    history={this.props.history}
                    isLogin={this.state.isLogin}
                    itemindex={this.state.itemindex}
                    onlytable={this.props.onlytable}
                />
            )

        return (
            <div>
                <Section origin="transfers" />
                <main>
                    <div id="position">
                        <div className="container">
                            <ul>
                                <li><NavLink to="/">{this.t('Home')}</NavLink></li>
                                <li><NavLink to="/transfers">{this.t('Transfers')}</NavLink></li>
                                <li>{this.t('Prices')}</li>
                            </ul>
                        </div>
                    </div>
                    {/* End Position  */}

                    <div className="container margin_60">


                   {this.state.data?
                    <AdviceMessage id_destination= {Number(this.state.data.Id_Destination)} />
                    :null}
                        

                        <TransferPricesDetails
                            destinationid={this.state.destinationid}
                            data={this.state.data}
                            languageid={this.state.languageid}
                            currencyid={this.state.currencyid}
                            Id_Transfer={this.state.Id_Transfer}
                            Id_TransferPrice={this.state.Id_TransferPrice}
                            Id_Airport_Arrival={this.state.Id_Airport_Arrival}
                            Id_Airport_Departure={this.state.Id_Airport_Departure}
                            type={this.state.type}
                            airports={this.state.airports}
                            handle={this.handleErrors}
                            history={this.props.history}
                            isLogin={this.state.isLogin}
                            itemindex={this.state.itemindex}
                            onlytable={this.props.onlytable}
                        />


                    </div>
                    {/* End container  */}

                    <div id="overlay"></div>
                </main>
            </div>
        )
    }
}


export default TransferPrices

//export default withRouter(TransferPrices)