import React, { Component } from 'react';
import Section from '../components/component.section';
import { NavLink,withRouter } from "react-router-dom";
import Global from '../globals/global';
const $ = window.$;

class AboutUs extends Global {

    setcolumn(){


         if(process.env.REACT_APP_BOOKING_WIDGET == 'bookingwidget5'){

        return (<div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                                <div className="feature">
                                    <i className="icon_set_1_icon-30"></i>
                                    <h3 dangerouslySetInnerHTML={{__html:this.t("<span className='blue_text'>Your Travel</span> Agent Knows...")}}></h3>
                                    <span className="blue_text"><strong>{this.t('Only the BEST -- GUARANTEED!')}</strong></span>
                                    <p>
                                        {this.t("Your travel agent knows that we only use the best, most reliable insured companies to provide transportation and tours - you get the best service, value and price for what we offer, Guaranteed... You won't find a better price for our offers, but if you do, we will match it.")}
                        </p>
                                </div>
                            </div>
                )

        }

        return null;

    }

    render() {
        return (
            <div>
                <Section origin='about.us' />

                {/* End section */}

                <main>
                    <div id="position">
                        <div className="container">
                            <ul>
                                <li><NavLink to="/home">{this.t("Home")}</NavLink>
                                </li>
                                <li>{this.t("About Us")}</li>
                            </ul>
                        </div>
                    </div>
                    {/* End Position */}


                    <div className="container margin_60">

                        <div className="main_title">
                            <h2 dangerouslySetInnerHTML={{__html:this.t("Why <span className='blue_text'>us</span>?")}} ></h2>
                        </div>


                        <div className="row">

                            {this.setcolumn()}

                            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.2s">
                                <div className="feature">
                                    <i className="icon_set_1_icon-57"></i>
                                    <h3 dangerouslySetInnerHTML={{__html:this.t("<span className='blue_text'>Online</span> Concierge")}}></h3>
                                    <span className="blue_text"><strong>{this.t('At Destination -- Before & During Vacation!')}</strong></span>
                                    <p>
                                        {this.t('Chat Online or tap to talk for FREE from our app, directly with your destination concierge, before you arrive AND throughout your vacation. Get "insider info" and advice from a local professional to make sure you get the best of everything, for YOU.')}
                        </p>
                                </div>
                            </div>
                      

                            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.4s">
                                <div className="feature">
                                    <i className="icon_set_1_icon-61"></i>
                                    <h3 dangerouslySetInnerHTML={{__html:this.t("<span className='blue_text'>Tour</span> Secure")}}></h3>
                                    <span className="blue_text"><strong>{this.t('Book Early Adjust Plans ~ No Charge')}</strong></span>
                                    <p>
                                        {this.t('Select your activities early to make sure you get the best day, AND time of day, reserved for you in advance... change or even cancel 48 hrs before your scheduled reservation, no penalty, no problem.')}
                        </p>
                                </div>
                            </div>


                            {this.config().brand == 'sunexcursions' || this.config().brand == 'totoura'?
                            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                                <div className="feature pad-40">
                                    <i className="icon_set_1_icon-90"></i>
                                    <h3 dangerouslySetInnerHTML={{__html:this.t("<span className='blue_text'>Call</span> Home FREE!")}}></h3>
                                    <span className="blue_text"><strong>{this.t('USA / CANADA')}</strong></span>
                                    <p>
                                        {this.t("Use the app's FREE international calling system in conjunction with a wifi connection at destination and call anyone, any line directly - cells, land lines, whatever... 30 minutes included free")}
                        </p>
                                </div>
                            </div>:''
                        }

                       
                            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
                                <div className="feature pad-65">
                                    <i className="icon_set_1_icon-41"></i>
                                    <h3 dangerouslySetInnerHTML={{__html:this.t("<span className='blue_text'>See What's</span> Trending")}}></h3>
                                    <span className="blue_text"><strong>{this.t('Vacationer Reviews')}</strong></span>
                                    <p>
                                        {this.t("See what's hot, top tours at your destination other vacationers are taking and what they are saying about their experiences.")}
                        </p>
                                </div>
                            </div>
                        </div>
                        {/* End row */}

                    </div>
                    {/* End container */}

                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-6 nopadding features-intro-img">
                                <div className="about-bg">
                                    <div className="features-img"></div>
                                </div>
                            </div>
                            <div className="col-lg-6 nopadding">
                                <div className="features-content">
                                    <h3>"{this.t('About Us')}"</h3>
                                    <p className="text-justify">{this.t('{{brandname}}, has grown to deliver quality ground services to over 1 million international vacationers every year. Providing Airport/Hotel Transportation, Tours and Activities for vacationers, by using only the very best reliable companies at each destination is our standard operating policy.',{brandname:this.config().brandname})}
                                    </p>
                        <br /><br />
                                      <p  dangerouslySetInnerHTML={{__html:this.t("At {{brandname}}, <strong>'we make good vacations great'</strong> for some of the world's best known vacation companies including: Transat, Look Voyages, Vacances Transat France, Delta Vacations, Price Travel and Palace Resorts.",{brandname:this.config().brandname})}}></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End container-fluid  */}


                    <div id="overlay"></div>
                    {/* Mask on input focus */}

                </main>
                {/* End main */}
            </div>
        )
    }
}

export default AboutUs